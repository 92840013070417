<template>
  <div v-if="!isIOS && !isContainWV_UA">
    <v-row no-gutters class="font-weight-bold pt-4" align="end">
      <label>{{ $t(`label.downloadClient`) }}</label>
    </v-row>
    <div class="wrapper-div" :class="$vuetify.breakpoint.mdAndDown ? 'pt-2 pb-4' : 'py-4'">
      <v-row no-gutters justify="center" class="mx-0" style="width: 100%">
        <v-col cols="12" class="pt-2 download-banner">
          <v-img :src="`/static/image/banner/downloadClient/downloadApp_${language}.jpg`" style="border-radius: 12px" @click="downloadApk()"></v-img>
          <!--        <v-img src="/static/image/banner/downloadClient/bb88_downloadnow_appbanner_mobile.jpg" class="hidden-md-and-up banner-img" @click="downloadApk()"></v-img>-->
          <!--          <v-btn class=" bhaggo-downloadApp-btn" @click="downloadApk">{{ $t(`mobileMenuTitle.downloadApp`) }} </v-btn>-->
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { uiHelper } from '@/util'

export default {
  name: 'appHomeDownloadClient',
  data: () => ({
    isIOS: uiHelper.IsIos(),
    isContainWV_UA: uiHelper.isAndroidWebView() || uiHelper.isAndroidWebView_LowerVer(),
    language: uiHelper.getLanguage().toLowerCase()
  }),
  created() {},
  methods: {
    downloadApk() {
      uiHelper.downloadAPK()
    }
  }
}
</script>

<style lang="scss">
.wrapper-div {
  display: flex;
  align-items: center;
  .download-banner {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .bhagyo-downloadApp-btn {
    text-transform: capitalize;
    color: #ffffff;
    background-color: var(--v-newMobilePrimary-base) !important;
    border-radius: 12px;
    padding: 0 16px;
  }
}

.banner-img {
  border-radius: 20px;
}
</style>
