<template>
    <v-row no-gutters justify="end">
        <v-col cols="12" class="text-right hidden-sm-and-down">
            <v-btn height="auto" @click="this.openLogin" color="black" class="mr-6 primary-button embedded-login-button pa-2 font-weight-bold yellow--text subtitle-1">{{ $t(`button.login`) }}
            </v-btn>
            <app-button :customClass= "'buttonPrimary subtitle-1 full-width font-weight-bold pa-2 theme-button embedded-register-button'" :action="this.openRegister" :title="$t(`label.signUp`)"></app-button>
            <v-btn class="pt-0 ml-6" icon small @click="openLanguageDialog()" v-if="!hideLanguage">
                <v-avatar size="48" class="language-button">
                    <img :src="`/static/image/country/${currentCurrency}.svg`"/>
                </v-avatar>
            </v-btn>
        </v-col>
        <v-col cols="12" class="text-right hidden-md-and-up" v-show="$route.path!=='/register-mobile'" >
            <v-btn @click="this.openLogin" color="black" class="mr-1 primary-button embedded-mobile-login-button font-weight-bold yellow--text">{{ $t(`button.login`) }}
            </v-btn>
            <app-button :customClass= "'buttonPrimary theme-button embedded-mobile-register-button'" :action="this.openRegister" :title="$t(`label.signUp`)"></app-button>
        </v-col>
    </v-row>
</template>

<script>
    import stingFormat from 'string-format'
    import {
        formValidator,
        uiHelper
    } from '@/util'
    import {
        SHARED
    } from '@/constants/constants'
    import {
        MEMBER_LOGIN
    } from '@/store/member.module'
    export default {
        name: 'appLoginEmbedded',
        props: {
            openRegisterDialog: {
                type: Function,
                required: true,
                default: () => {}
            },
            openLoginDialog: {
                type: Function,
                required: true,
                default: () => {}
            },
            openLanguageDialog: {
                type: Function,
                required: true,
                default: () => {}
            },
            hideLanguage: {
                type:Boolean,
                require: false,
                default: false
            },
          isMobileRegistration: {
            type: Boolean,
            required: false,
            default: false
          }
        },
        data: () => ({
            validator: formValidator,
            stingFormat: stingFormat,
            retainSignIn: false,
            loginObj: {
                memberCode: '',
                password: '',
                domain: '',
                platform: '',
                option: SHARED.DEFAULT_LOGIN_PASSWORD_METHOD
            },
            currentCurrency: uiHelper.getCurrency(),
            currentLanguage: uiHelper.getLanguage(),
            items: [{
                    title: 'Bengali',
                    key: 'bd'
                },
                {
                    title: 'English',
                    key: 'en'
                },
                {
                    title: 'Tamil',
                    key: 'ta'
                }
            ]
        }),
        computed: {
            isLoggedIn() {
                return this.$store.state.member.isLoggedIn
            }
        },
        watch: {
            isLoggedIn() {
                // this.$refs.loginForm.reset()
            }
        },
        methods: {
            openRegister() {
                this.openRegisterDialog()
            },
            openLogin() {
                this.openLoginDialog()
            },
            changeLanguage(language) {
                this.$store.dispatch(`${SHARED.LOADING}`)
                uiHelper.removeCookie('language')
                uiHelper.setCookie('language', language)
                window.location.reload()
            }
        }
    }
</script>

<style lang="scss">
    .login-form .input-field .v-input__control .v-input__slot .v-text-field__slot .v-label {
        line-height: 15px;
        text-transform: capitalize !important;
    }
    
    .language-button-div {
        line-height: 15px;
        min-height: 40px !important;
    }
    .embedded-register-button {
      min-height: 40px;
        width: 130px !important;
        padding: 4px !important;
    }
    .forgot-button {
        position: absolute;
        top: 65px;
        right: 37%;
        color: white;
    }
    .embedded-login-button {
        width: 110px !important;
      min-height: 40px;
    }

    @media (max-width: 959px) {
        .embedded-mobile-login-button {
            width: 70px !important;
            height: 30px !important;
            font-size: 12px !important;
        }
        .embedded-mobile-register-button {
            width: 98px !important;
        }
    }
</style>
