import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import { SHARED } from '@/constants/constants'
import '@/filters'
import '@/plugins/vuetify'
import './styles.scss'
import jQuery from 'jquery'
import LayoutMaster from '@/components/layout/LayoutMaster.vue'
import BlankLayoutMaster from '@/components/layout/BlankLayoutMaster.vue'
import AppFormField from '@/components/FormField.vue'
import AppButton from '@/components/Button.vue'
import AppDialog from '@/components/dialog.vue'
import AppDateTimePicker from '@/components/DateTimePicker.vue'
import VueClipboard from 'vue-clipboard2'
import VueAnalytics from 'vue-analytics'
import Siema from 'vue2-siema'
import OneSignalVue from 'onesignal-vue'
import VueCaptchaCode from "@johnnyguan/vue-captcha-code";
Vue.prototype.$eventHub = new Vue()
Vue.use(Siema)
Vue.use(VueClipboard)
Vue.use(VueAnalytics, {
  id: SHARED.GA_TRACKING_CODE,
  router
})
Vue.use(OneSignalVue)
Vue.use(VueCaptchaCode)
Vue.component('layout-master', LayoutMaster)
Vue.component('blank-layout-master', BlankLayoutMaster)
Vue.component('app-form-field', AppFormField)
Vue.component('app-button', AppButton)
Vue.component('app-dialog', AppDialog)
Vue.component('app-date-time-picker', AppDateTimePicker)
Vue.component('v-style', {
  render: function(createElement) {
    return createElement('style', this.$slots.default)
  }
})
Vue.config.productionTip = false
export const eventBus = new Vue()
Vue.prototype.$eventHub = new Vue()
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: SHARED.ONE_SIGNAL_APP_ID }).then(() => {
      console.log('app id key set successful', SHARED.ONE_SIGNAL_APP_ID)
    })
  }
}).$mount('#app')
