<template>
  <div v-if="isLoggedIn" class="balance-details" :class="[{'gamepage':parent=='gamepage'}]">
    <v-row no-gutters>
      <v-col class="pa-2">
        <v-row no-gutters>
          <v-col>{{ $t(`label.balance`) }}</v-col>
          <v-col>
            <div @click="getMemberWallet()" class="refresh-balance-icon"></div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>{{ memberWallet.totalBalance | currency(currentCurrency) }}</v-col>
        </v-row>
      </v-col>
      <!--   first draft design   -->
      <!--      <v-col cols="6" class="nav-container">-->
      <!--        <div class="mx-auto nav-wrapper">-->
      <!--          <v-card class="nav-item" :to="{ name: routeName.VIP }">-->
      <!--            <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/bettingPass.svg`" />-->
      <!--          </v-card>-->
      <!--          <span class="nav-item-span">{{ $t(`mobileMenuTitle.bettingPass`) }}</span>-->
      <!--        </div>-->
      <!--        <div class="mx-auto nav-wrapper">-->
      <!--          <v-card class="nav-item" :to="{ name: routeName.REWARDS }">-->
      <!--            <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/rewards.svg`" />-->
      <!--          </v-card>-->
      <!--          <span class="nav-item-span">{{ $t(`mobileMenuTitle.rewards`) }}</span>-->
      <!--        </div>-->
      <!--        <div class="mx-auto nav-wrapper">-->
      <!--          <v-card class="nav-item" :to="{ name: routeName.TRANSACTION_HISTORY }">-->
      <!--            <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/betHistory.svg`" />-->
      <!--          </v-card>-->
      <!--          <span class="nav-item-span">{{ $t(`mobileMenuTitle.betHistory`) }}</span>-->
      <!--        </div>-->
      <!--        <div class="mx-auto nav-wrapper">-->
      <!--          <v-card class="nav-item" :to="{ name: routeName.WITHDRAWAL }">-->
      <!--            <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/withdrawal.svg`" />-->
      <!--          </v-card>-->
      <!--          <span class="nav-item-span">{{ $t(`mobileMenuTitle.withdrawal`) }}</span>-->
      <!--        </div>-->
      <!--      </v-col>-->

      <!--  2nd draft design    -->
      <!--      <v-col cols="8" class="d-flex align-center">-->
      <!--        <v-row class="justify-space-around">-->
      <!--          <div>-->
      <!--            <v-card dark class="nav-item" :to="{ name: routeName.VIP }">-->
      <!--              <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/bettingPass.svg`" />-->
      <!--            </v-card>-->
      <!--            <span class="nav-item-span">{{ $t(`mobileMenuTitle.bettingPass`) }}</span>-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <v-card dark class="nav-item" :to="{ name: routeName.REWARDS }">-->
      <!--              <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/rewards.svg`" />-->
      <!--            </v-card>-->
      <!--            <span class="nav-item-span">{{ $t(`mobileMenuTitle.rewards`) }}</span>-->
      <!--          </div>-->
      <!--          <div v-if="parent.toString() == this.routeName.HOME">-->
      <!--            <v-card dark class="nav-item" :to="{ name: routeName.MOBILE_BETTING_HISTORY }">-->
      <!--              <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/betHistory.svg`" />-->
      <!--            </v-card>-->
      <!--            <span class="nav-item-span">{{ $t(`mobileMenuTitle.betHistory`) }}</span>-->
      <!--          </div>-->
      <!--          <div v-if="parent.toString() == this.routeName.MOBILE_ACCOUNT">-->
      <!--            <v-card dark class="nav-item" :to="{ name: routeName.REFERRAL_SUMMARY }">-->
      <!--              <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/referral.svg`" />-->
      <!--            </v-card>-->
      <!--            <span class="nav-item-span">{{ $t(`label.referral`) }}</span>-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <v-card dark class="nav-item" :to="{ name: routeName.WITHDRAWAL }">-->
      <!--              <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/withdrawal.svg`" />-->
      <!--            </v-card>-->
      <!--            <span class="nav-item-span">{{ $t(`mobileMenuTitle.withdrawal`) }}</span>-->
      <!--          </div>-->
      <!--        </v-row>-->
      <!--      </v-col>-->

      <!--      bhagyo-->
      <v-col cols="8" class="d-flex align-center">
        <v-row no-gutters>
          <v-btn @click="toDeposit()" class="deposit-btn">{{ $t(`label.deposit`) }}</v-btn>
          <v-btn @click="toWithdrawal()" class="withdrawal-btn">{{ $t(`label.withdrawal`) }}</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { uiHelper } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
import {  MEMBER_WALLET } from '@/store/member.module'

export default {
  name: 'MemberBalance',
  data: () => ({
    walletBalance: 0,
    routeName: ROUTE_NAME,
    currentCurrency: uiHelper.getCurrency()
  }),
  props: {
    parent: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    }
  },
  watch: {},
  methods: {
    getMemberWallet() {
      this.notLoading = false
      this.$store.dispatch(MEMBER_WALLET)
    },
    toDeposit() {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT
      })
    },
    toWithdrawal() {
      this.$router.push({
        name: ROUTE_NAME.WITHDRAWAL
      })
    }
  }
}
</script>

<style scoped>
.refresh-balance-icon {
  width: 24px;
  height: 24px;
  background-color: var(--v-primary-lighten2);
  mask: url(/static/image/profile/icon-refresh-type01.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask: url(/static/image/profile/icon-refresh-type01.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  cursor: pointer;
  transition: transform 0.5s;
}
.refresh-balance-icon:hover {
  transform: rotate(215deg);
}
.gamepage{
  margin-left:-12px;
  margin-right:-12px;
  background-color: #FFFFFF;
  padding: 15px 20px;
}
.nav-wrapper {
  min-width: auto;
}

.balance-details {
  height: 100px;
  padding-top: 12px;
}
.nav-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  /*justify-content: space-evenly;*/
}
.nav-item {
  display: flex;
  width: 5rem;
  height: 5rem;
  background-color: #333333;
  border-radius: 10px;
  margin: 0 10px 3px 10px;
}
.nav-item-span {
  font-size: 0.825rem;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.img-nav-item {
  margin: auto;
  width: 3rem;
  height: 3rem;
}

.deposit-btn {
  text-transform: capitalize;
  height: 42px !important;
  border-radius: 32px 0px 0px 32px;
  background: radial-gradient(farthest-corner at 0% 50%, #848a2b, #435131);
  color: #ffffff;
  width: 50% !important;
}
.withdrawal-btn {
  text-transform: capitalize;
  height: 42px !important;
  border-radius: 0px 32px 32px 0px;
  color: #000000;
  background-color: #eaeaea;
  width: 50% !important;
}
@media (max-width: 599px) {
  .nav-item-span {
    font-size: 0.8rem;
  }
  .nav-item {
    width: 4rem;
    height: 4rem;
    margin: auto;
  }
  .img-nav-item {
    width: 3rem;
    height: 3rem;
  }
}
@media (max-width: 429px) {
  .balance-details {
    height: 75px !important;
  }
  .nav-item-span {
    font-size: 0.625rem;
  }
  .nav-item {
    width: 3rem;
    height: 3rem;
    margin: auto;
  }
  .img-nav-item {
    width: 2rem;
    height: 2rem;
  }
}
</style>
