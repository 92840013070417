<template>
  <div class="home-banner-box">
<!--    <v-row no-gutters class="hidden-md-and-up">-->
    <v-row no-gutters>
      <v-col cols="12">
        <v-carousel class="home-banner-carousel-mobile" hide-delimiters cycle interval="10000"
                    height="auto">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
            <v-img :src="`${banner.mobile_banner}`" @click="callBannerAction(banner)" :aspect-ratio="414/184">
              <!-- <v-overlay class="banner-overlay" :absolute="true" :value="true" :opacity="0.85">
                  <div class="ma-5">
                    <p class="font-weight-bold body-1 mb-2">{{banner.title}}</p>
                    <p class="caption mb-2">{{banner.desc}}</p>
                    <app-button class="apply-button" :action="callBannerAction" :title="$t(`label.applyAsAffiliateAgent`)"></app-button>
                  </div>
                </v-overlay> -->
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <!-- <app-sport-casino-holder></app-sport-casino-holder> -->
    </v-row>
    <v-row no-gutters class="hidden-sm-and-down" v-if="false">
      <v-col cols="12">
        <v-carousel cycle height="380px" hide-delimiter-background interval="10000"
                    class="home-banner-carousel hidden-sm-and-down">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
            <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner)" :aspect-ratio="1756/792">
              <!-- <v-overlay class="banner-overlay" :absolute="true" :value="true" :opacity="0">
                <div class="ma-10">
                  <h1>{{banner.title}}</h1>
                  <p>{{banner.desc}} </p>
                  <app-button class="apply-button" :action="callBannerAction" :title="$t(`label.applyAsAffiliateAgent`)"></app-button>
                </div>
              </v-overlay> -->
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <!-- <app-sport-casino-holder></app-sport-casino-holder> -->
    </v-row>
    <v-row no-gutters>
      <marquee bgcolor="#435131" style="width: 100%;">
        <template v-for="announcement in filteredAnnouncement">
                  <span :key="announcement.id" class="pr-6 mr-6">
                    <label class="d-inline-block home-announcment-content">{{ announcement.title }}</label>
                    <label class="d-inline-block home-announcment-content"
                           v-html="announcement.message != null ? ' :' + announcement.message.replace(/<[p>]*>/g, ' ') : ''"></label>
                  </span>
        </template>
      </marquee>
    </v-row>
  </div>
</template>

<script>
import {
  ROUTE_NAME
} from '@/constants/route.constants'
import {
  SHARED
} from '@/constants/constants'
import {
  uiHelper
} from '@/util'
import {
  DevicePlatform
} from '@/constants/enums'
import axios from 'axios'
import AppSportCasinoHolder from '@/components/home/SportCasinoHolder.vue'
import {
  CMS_BANNER_ACTIVE
} from "@/store/cms.module";

export default {
  name: 'appHomeBanner',
  props: {
    filteredAnnouncement: {
      type: Array,
      required: true,
      default: []
    }
  },
  data: () => ({
    routeName: ROUTE_NAME,
    banners: [],
    mobilebanners: [],
    bannerIndex: 0,
    currentLanguage: uiHelper.getLanguage(),
    mediaUrl: SHARED.MEDIA_SERVER_URL
  }),
  components: {
    AppSportCasinoHolder
  },
  computed: {
    bannerContent() {
      return this.$store.state.cms.bannerContent
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  created() {
    this.getCmsBanner()
    // this.generateDesktopBanner()
    // this.generateMobileBanner()
  },
  methods: {
    callBannerAction(action) {
      try {
        let s = action.action.replaceAll("'", '"');
        let jsonData = JSON.parse(s);
        this.bannerAction({
          'action': jsonData['action'],
          'action_url': action.action_url,
          'bonusId': ''
        })
      } catch (err) {
        this.bannerAction({
          'action': 'promotionpage',
          'bonusId': ''
        })
      }
    },
    getCmsBanner() {
      let obj = {
        language: uiHelper.getLanguage()
      }
      this.$store.dispatch(`${CMS_BANNER_ACTIVE}`, {
        obj
      })
    },
    generateDesktopBanner() {
      axios({
        method: 'GET',
        url: SHARED.DESKTOP_BANNER_JSON_URL,
        responseType: 'json'
      }).then(response => {
        if (response.status == 200) {
          response.data.forEach(banner => {
            this.banners.push({
              src: banner.src,
              action: banner.action,
              bonusId: banner.bonusId
            })
          })
        }
      })
    },
    generateMobileBanner() {
      axios({
        method: 'GET',
        url: SHARED.MOBILE_BANNER_JSON_URL,
        responseType: 'json'
      }).then(response => {
        if (response.status == 200) {
          response.data.forEach(banner => {
            this.mobilebanners.push({
              src: banner.src,
              action: banner.action
            })
          })
        }
      })
    },
    bannerAction(banner) {
      let action = banner.action
      let action_url = banner.action_url
      let bonusId = banner.bonusId
      switch (action.toLowerCase()) {
        case 'register':
          if (!this.isLoggedIn) {
            this.$parent.openRegisterDialog()
          } else {
            this.$router.push({
              name: ROUTE_NAME.PROMOTION
            })
          }
          break
        case 'livecasinopage':
          this.$router.push({
            name: ROUTE_NAME.CASINO
          })
          break
        case 'urlpage':
          window.open(action_url, '_blank')
          break
        case 'sportsbookpage':
          this.$router.push({
            name: ROUTE_NAME.SPORTSBOOK
          })
          break
        case 'paynowdeposit':
          if (!this.isLoggedIn) {
            this.$parent.openLoginDialog()
          } else {
            this.$router.push({
              name: ROUTE_NAME.DEPOSIT
            })
          }
          break;
        case 'rewards':
          this.$router.push({
            name: ROUTE_NAME.REWARDS_BY_TYPE,
            params: {
              type: 'luckyspin'
            }
          })
          break;
        case 'promotionpage':
          this.$router.push({
            name: ROUTE_NAME.PROMOTION,
          })
          break
        case 'slotpage':
          this.$router.push({
            name: ROUTE_NAME.SLOT
          })
          break
        case 'affiliatepage':
          this.$router.push({
            name: ROUTE_NAME.AFFILIATE_HOME
          })
          break
        case 'launchmjsgame':
          if (!this.isLoggedIn) {
            this.$parent.openLoginDialog()
          } else {
            if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
              let routeData = this.$router.resolve({
                name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
                params: {
                  provider: 'mjs',
                  type: 'sb',
                  lang: this.$route.params.lang
                },
                query: {
                  demo: 0
                }
              })
              uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1200, 900)
            } else {
              this.$router.push({
                name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
                params: {
                  provider: 'mjs'
                }
              })
            }
          }
          break
      }
    }
  }
}
</script>

<style lang="scss">
.banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-button {
  flex-grow: 1;
  width: 120px;
  height: 90px;
  padding: 9px !important;

  .v-btn__content {
    color: black;
  }
}

.banner-overlay {
  height: auto;
  width: 35%;
  display: block;
  margin-left: auto;
}

.home-announcment-content span {
  color: #FFFFFF !important;
}

.home-banner-box {
  height: auto;
  position: relative;

  label {
    color: #FFFFFF;
  }

  button {
    color: #000000DE !important;
  }

  .home-banner-carousel-mobile {
    .v-image {
      cursor: pointer;
    }
  }

  .home-banner-carousel {
    // border-radius: 40px;
    // height: 100% !important;
    width: 100%;

    .v-window__container {
      height: 100% !important;
    }

    .v-image {
      height: 100% !important;
      cursor: pointer;
    }

    .v-carousel__controls {
      bottom: 16px;
    }
  }
}

.home-announcment-content {
  font-size: 14px
}

@media (max-width: 959px) {
  .banner-overlay {
    height: auto;
    width: 50%;
    display: block;
    margin-left: auto;
  }
  .home-announcment-content {
    font-size: 12px
  }
}
</style>
