<template>
  <div>
    <!--    download header banner -->
    <!--    <v-row no-gutters v-if="!isIos && $vuetify.breakpoint.smAndDown && showDownloadAPK && !isAndroidWebView"-->
    <!--           class="download-bar">-->
    <!--      <v-icon @click="hideDownloadBar()" class="mr-2">mdi-close</v-icon>-->
    <!--      <img class="mr-2" width="32" height="32" src="/static/image/layout/download-app.png" alt="download"/>-->
    <!--      <v-col>-->
    <!--        <label>{{ $t(`label.downloadApp`) }}</label>-->
    <!--      </v-col>-->
    <!--      <v-btn @click="downloadApk" class="download-bar-button subtitle-1">{{ $t(`label.downloadAppBtn`) }}</v-btn>-->
    <!--    </v-row>-->
    <div>
      <v-col cols="12" class="full-width  header-top mobile elevation-3" :class="showHomepageHeaderBanner ? 'homepage-header' : 'non-homepage-header'">
        <!-- <v-toolbar class="hidden-md-and-up header-top mobile elevation-3"> -->
        <v-row no-gutters align="center" class="fixed-header">
          <v-col cols="12" class="px-3" style="">
            <v-row no-gutters align="center" justify="space-between">
              <v-col cols="1" class="text-left">
                <v-app-bar-nav-icon style="color:#FFF;" @click.stop="drawer = !drawer" height="18" width="22"></v-app-bar-nav-icon>
              </v-col>
              <v-col cols="9" class="mobile-header-logo">
                <router-link :to="{ name: routeName.HOME }" style="display: flex;align-items: center;">
                  <img src="/static/svg/logo_bhagyo_white.svg" alt width="110" height="30" />
                </router-link>
              </v-col>
              <v-col cols="2" class="text-right">
                <!--                <span>currency lancuage switcher</span>-->
                <!--                <app-login-embedded-->
                <!--                  v-if="!isLoggedIn"-->
                <!--                  :openRegisterDialog="this.openRegisterDialog"-->
                <!--                  :openLoginDialog="this.openLoginDialog"-->
                <!--                  :openLanguageDialog="this.openLanguageDialog"-->
                <!--                ></app-login-embedded>-->
                <!--                <v-btn v-if="isLoggedIn" icon :to="{ name: routeName.INBOX }">-->
                <!--                  <v-badge dot bottom left offset-x="5" offset-y="8" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">-->
                <!--                    <v-icon class="color-primary" size="32">email</v-icon>-->
                <!--                  </v-badge>-->
                <!--                </v-btn>-->

                <v-btn width="55" @click="openLanguageDialog()" v-if="!isLoggedIn" style="cursor:pointer;padding-left: 5px;" text>
                  <v-avatar size="30" class="language-button">
                    <img :src="`/static/image/country/${currentCurrency}.svg`" />
                  </v-avatar>
                </v-btn>
                <v-btn v-if="isLoggedIn" icon :to="{ name: routeName.INBOX }">
                  <v-badge dot bottom left offset-x="5" offset-y="8" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">
                    <v-icon color="white" dense>mdi-bell-outline</v-icon>
                  </v-badge>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
        </v-row>
        <!-- </v-toolbar> -->
        <v-row v-if="!showHomepageHeaderBanner" no-gutters>
          <span class="subpage-header-name">{{ MobileHeaderName }}</span>
        </v-row>
      </v-col>
    </div>

    <v-navigation-drawer class="mobile-navigation-drawer" v-model="drawer" absolute left temporary>
      <v-row no-gutters>
        <v-col cols="12" class="mobile-drawer-menu-header-section">
          <v-icon class="mobile-drawer-menu-icon" @click.stop="drawer = !drawer" small>
            mdi-close
          </v-icon>
          <router-link class="mobile-drawer-menu-logo" to="/">
            <img src="/static/svg/logo_bhagyo.svg" alt width="124" height="20" style="float: center" />
          </router-link>
          <v-btn width="20" @click="openLanguageDialog()" style="cursor:pointer" text small>
            <v-avatar size="20" class="language-button">
              <img class="mobile-left-pane-logo" :src="`/static/image/country/${currentCurrency}.svg`" />
            </v-avatar>
          </v-btn>
        </v-col>
      </v-row>
      <app-leftpane-info></app-leftpane-info>
      <v-divider class="mx-3 my-2"></v-divider>
      <v-list nav class="pb-16 mobileMenuListItem">
        <div v-if="isLoggedIn">
          <v-list-item-group active-class="grey-darken-1 text--accent-1">
            <v-list-item :to="{ name: routeName.MOBILE_ACCOUNT }">
              <v-list-item-icon>
                <img :src="`/static/svg/mobileMenu/profile.svg`" alt width="30" height="30" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.myProfile`) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <!--          <v-list-item-group active-class="grey-darken-1 text&#45;&#45;accent-1">-->
          <!--            <v-list-item to="/">-->
          <!--              <v-list-item-icon>-->
          <!--                <img :src="`/static/svg/mobileMenu/betHistory.svg`" alt width="30" height="30"/>-->
          <!--              </v-list-item-icon>-->
          <!--              <v-list-item-content>-->
          <!--                <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.betHistory`) }}</v-list-item-title>-->
          <!--              </v-list-item-content>-->
          <!--            </v-list-item>-->
          <!--          </v-list-item-group>-->
          <v-divider class="my-4 mx-4"></v-divider>
        </div>
        <v-list-item-group active-class="grey-darken-1 text--accent-1">
          <v-list-item :to="{ name: routeName.PROMOTION }">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/promotion.svg`" alt width="30" height="30" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.promotion`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--          <v-list-item :to="{name:routeName.VIP}">-->
          <!--            <v-list-item-icon>-->
          <!--              <img :src="`/static/svg/mobileMenu/leftpane-coin.svg`" alt width="30" height="30"/>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.bettingPass`) }}</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
          <v-list-item :to="{ name: routeName.VIPTIER }">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/leftpane-coin.svg`" alt width="30" height="30" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.vipTier`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: routeName.AFF_NEWS }">
            <v-list-item-icon>
              <img :src="`/static/image/mobileMenu/moneyBag.png`" alt width="30" height="30" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.affiliateNews`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="checkIsLogin">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/voucher.svg`" alt width="30" height="30" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.voucher`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--          <v-list-item :to="{name:routeName.TOURNAMENT}" >-->
          <!--            <v-list-item-icon>-->
          <!--              <img :src="`/static/svg/mobileMenu/tournaments.svg`" alt width="30" height="30"/>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>{{ $t(`mobileMenuTitle.tournaments`) }}</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
          <!--          <v-list-item :to="{name:routeName.REWARDS}">-->
          <!--            <v-list-item-icon>-->
          <!--              <img :src="`/static/svg/mobileMenu/rewards.svg`" alt width="30" height="30"/>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.rewards`) }}</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item :to="{ name: routeName.VIP }">-->
          <!--            <v-list-item-icon>-->
          <!--              <img :src="`/static/svg/mobileMenu/bettingPass.svg`" alt width="30" height="30"/>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>-->
          <!--                &lt;!&ndash; <v-badge right overlap color="light" offset-x="5" offset-y="7">-->
          <!--                <template v-slot:badge>-->
          <!--                    <img src="/static/svg/game-icon-new.svg" height="20" width="20"/>-->
          <!--                    <span class="font-weight-bold" style="background-color:green;border-radius:15px;color:white; padding:2px; font-size:10px">  {{ $t(`label.new`) }} </span>-->
          <!--                </template>-->
          <!--                {{ $t(`label.vip`) }}-->
          <!--                </v-badge> &ndash;&gt;-->
          <!--                <v-row no-gutters justify="start" align="center">-->
          <!--                  <v-col cols="auto" class="mt-1">{{ $t(`label.vip`) }}</v-col>-->
          <!--                  <v-col cols="2" class="text-center"-->
          <!--                         style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px">-->
          <!--                    <span class="font-weight-bold">{{ $t(`label.new`) }}</span>-->
          <!--                  </v-col>-->
          <!--                </v-row>-->
          <!--              </v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
          <v-list-item :to="{ name: routeName.IPLVIP }" v-if="isIPLEventActive">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/fifa_icon.svg`" alt width="30" height="30" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="list-item-title">
                <v-row no-gutters justify="start" align="center">
                  <v-col cols="auto" class="mt-1">{{ $t(`label.iplVIP`) }}</v-col>
                  <v-col cols="2" class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px">
                    <span class="font-weight-bold">{{ $t(`label.new`) }}</span>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider class="my-4 mx-4"></v-divider>

        <v-list-item-group active-class="grey-darken-1 text--accent-1">
          <v-list-item v-for="gameType in storageGameProviderType" :key="gameType.type" :to="getListItemTo(gameType)">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/${gameType.type}.svg`" alt width="30" height="30" />
            </v-list-item-icon>
            <!--            <v-list-item-icon >-->
            <!--              <div :class="gameType.type+'-div'"></div>-->
            <!--            </v-list-item-icon>-->
            <v-list-item-content>
              <v-list-item-title class="list-item-title">
                <v-row no-gutters justify="start" align="center">
                  <v-col cols="auto" class="mt-1">{{ $t(`label.${gameType.type}`) }}</v-col>
                  <v-col v-if="gameType.type == 'crash'" cols="2" class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px">
                    <span class="font-weight-bold">{{ $t(`label.new`) }}</span>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider class="my-4 mx-4"></v-divider>

        <v-list-item-group>
          <!--          <v-list-item @click="openLanguageDialog()">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-btn icon small style="margin-left: -3px">-->
          <!--                <v-avatar size="30" class="language-button">-->
          <!--                  &lt;!&ndash;                  <img :src="`/static/image/country/${currentCurrency}.svg`" />&ndash;&gt;-->
          <!--                  <img :src="`/static/svg/mobileMenu/language.svg`"/>-->
          <!--                </v-avatar>-->
          <!--              </v-btn>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>{{ $t(`mobileMenuTitle.language`) }}</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
          <v-list-item :to="{ name: routeName.REFERRAL_SUMMARY }">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/referAndEarn.svg`" alt width="30" height="30" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="list-item-title">{{ $t(`mobileMenuTitle.referral`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--          <v-list-item href="https://direct.lc.chat/13183530/" target="_blank">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-btn icon small style="margin-left: -3px">-->
          <!--                <v-avatar size="30">-->
          <!--                  <img :src="`/static/svg/mobileMenu/liveChat.svg`"/>-->
          <!--                </v-avatar>-->
          <!--              </v-btn>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>{{ $t(`mobileMenuTitle.liveChat`) }}</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
          <v-list-item @click="logout" v-if="isLoggedIn">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/logout.svg`" alt width="30" height="30" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="list-item-title">{{ $t(`label.logout`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-row class=" header-top" no-gutters align="center" justify="space-between" v-if="false">
      <v-col cols="3" class="header-column">
        <router-link :to="{ name: routeName.HOME, params: { lang: regionLocale } }">
          <!-- <img src="/static/image/logo/logo.png" class="brand-logo hidden-sm-and-down">
          <img src="/static/image/logo/logo.png" class="mobile-brand-logo hidden-md-and-up"> -->
          <img src="/static/svg/logo.svg" alt width="212" height="38" style="float: left" />
        </router-link>
      </v-col>

      <v-col cols="7" class="header-column register-dialog text-right">
        <div v-if="isLoggedIn" class="greeting-text font-weight-bold">
          <label class="color-primary mr-4 greeting-text-btn" @click="goToProfilePage()">{{ $t(`label.hi`) }}, {{ memberInfo.memberCode }}</label>
          <!-- &nbsp;<span class="caption">({{ vipLevel }})</span> -->
          <!-- <img src="/static/svg/inbox.svg" alt style="float:center; height:50px; width=50px;" />
          <v-img src="/src/assets/svg/ld_icon.svg" style="float:center; height:50px; width=50px;" > </v-img> -->
          <v-btn icon :to="{ name: routeName.INBOX }" class="mr-4">
            <v-badge bottom left offset-x="6" offset-y="12" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">
              <v-icon size="48" class="color-primary">email</v-icon>
            </v-badge>
          </v-btn>
          <!-- <v-btn to="/bank/deposit" class="elevation-0"> -->
          <v-chip pill class="color-base header-wallet" @click="getMemberWallet()" :disabled="!notLoading">
            <label class="color-primary text-capitalize" v-if="notLoading">
              {{ memberWallet.totalBalance | currency(currentCurrency) }}
            </label>
            <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3" :size="24"></v-progress-circular>
          </v-chip>
          <v-icon x-large left @click="goToDepositPage()" class="color-primary header-wallet-icon mr-3">add_circle</v-icon>
          <!-- </v-btn> -->
          <v-btn class="pt-0 ml-6" icon small @click="openLanguageDialog()">
            <v-avatar size="48" class="language-button">
              <img :src="`/static/image/country/${currentCurrency}.svg`" />
            </v-avatar>
          </v-btn>
          <v-btn class="ml-12 elevation-0" @click="logout" small icon>
            <img :src="`/static/svg/mobileMenu/logout.svg`" style="fill: #3a3a3a" alt width="40" height="40" />
          </v-btn>
        </div>
        <app-login-embedded v-if="!isLoggedIn" :openRegisterDialog="this.openRegisterDialog" :openLoginDialog="this.openLoginDialog" :openLanguageDialog="this.openLanguageDialog"></app-login-embedded>
      </v-col>
    </v-row>
    <!-- <v-row class="div_header hidden-sm-and-down">
      <v-col cols="11" class="splitter_header">
        <v-divider></v-divider>
      </v-col>
    </v-row> -->
    <app-page-navigator v-if="false"></app-page-navigator>

    <div class="body">
      <!--      <v-row no-gutters v-if="isLoggedIn" class="hidden-md-and-up mobile-header-detail" justify="space-between" align="center">-->
      <!--        <v-col cols="6">-->
      <!--          <label class="color-primary font-weight-bold mr-3">{{ $t(`label.hi`) }}, {{ memberInfo.memberCode }}</label>-->
      <!--        </v-col>-->
      <!--        <v-col cols="6" class="text-right">-->
      <!--          &lt;!&ndash; <v-btn to="/bank/deposit" class="elevation-0"> &ndash;&gt;-->
      <!--          <v-chip pill class="color-base header-wallet" @click="getMemberWallet()">-->
      <!--            <label class="color-primary text-capitalize font-weight-bold" v-if="notLoading">-->
      <!--              {{ memberWallet.totalBalance | currency(currentCurrency) }}-->
      <!--            </label>-->
      <!--            <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3" :size="24"></v-progress-circular>-->
      <!--          </v-chip>-->
      <!--          <v-icon large left class="color-primary header-wallet-icon mr-0" @click="goToDepositPage()">add_circle</v-icon>-->
      <!--          &lt;!&ndash; </v-btn> &ndash;&gt;-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <router-view></router-view>
      <app-footer v-if="!hideDesktopFooter"></app-footer>
    </div>

    <!--    <div v-if="false" class="mobile-navigator ">-->
    <!--      <v-row no-gutters>-->
    <!--        <v-col cols="auto" class="grow">-->
    <!--          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active"-->
    <!--                 :to="{ name: routeName.REFERRAL_TIER }">-->
    <!--            <div class="text-center">-->
    <!--              <img-->
    <!--                  class="icon_btm_nav"-->
    <!--                  alt-->
    <!--                  :src="'/static/svg/mobileBottomNavigation/referral.svg'"-->
    <!--              />-->
    <!--              <p>{{ $t(`mobileMenuTitle.btmReferral`) }}</p>-->
    <!--            </div>-->
    <!--          </v-btn>-->
    <!--        </v-col>-->
    <!--        <v-col cols="auto" class="grow">-->
    <!--          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active"-->
    <!--                 :to="{ name: routeName.PROMOTION }">-->
    <!--            <div class="text-center">-->
    <!--              <img-->
    <!--                  class="icon_btm_nav"-->
    <!--                  alt-->
    <!--                  :src="'/static/svg/mobileBottomNavigation/promotion.svg'"-->
    <!--              />-->
    <!--              <p>{{ $t(`mobileMenuTitle.promotion`) }}</p>-->
    <!--            </div>-->
    <!--          </v-btn>-->
    <!--        </v-col>-->
    <!--        <v-col cols="auto" class="grow">-->
    <!--          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.HOME }">-->
    <!--            <div class="text-center">-->
    <!--              &lt;!&ndash; <account-home class="icon_btm_nav"></account-home> &ndash;&gt;-->
    <!--              <img class="icon_btm_nav" alt-->
    <!--                   :src="'/static/svg/mobileBottomNavigation/home.svg'"/>-->
    <!--              <p>{{ $t(`mobileMenuTitle.home`) }}</p>-->
    <!--            </div>-->
    <!--          </v-btn>-->
    <!--        </v-col>-->
    <!--        <v-col cols="auto" class="grow">-->
    <!--          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active"-->
    <!--                 :to="{ name: routeName.DEPOSIT }">-->
    <!--            <div class="text-center">-->
    <!--              <img class="icon_btm_nav" alt-->
    <!--                   :src="'/static/svg/mobileBottomNavigation/deposit.svg'"/>-->
    <!--              <p>{{ $t(`label.deposit`) }}</p>-->
    <!--            </div>-->
    <!--          </v-btn>-->
    <!--        </v-col>-->
    <!--        <v-col cols="auto" class="grow">-->
    <!--          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active"-->
    <!--                 :to="{ name: routeName.MOBILE_ACCOUNT }">-->
    <!--            <div class="text-center">-->
    <!--              <img-->
    <!--                  class="icon_btm_nav"-->
    <!--                  alt-->
    <!--                  :src="'/static/svg/mobileBottomNavigation/account.svg'"-->
    <!--              />-->
    <!--              <p>{{ $t(`mobileMenuTitle.account`) }}</p>-->
    <!--            </div>-->
    <!--          </v-btn>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </div>-->

    <div class="bottom-navigator " v-if="!isTransferWalletLoginDialog && !hideBottomNavigator && !isLoggedIn">
      <v-row no-gutters v-if="!isLoggedIn" class=" bottom-navigator-before-login">
        <v-col cols="6" @click="goToLoginPage()" class="bottom-navigator-before-login-second d-flex justify-center align-center no-gutters">
          <v-btn class="bottom-navigator-login-btn bottom-navigator-btn" rounded>
            <b>{{ $t(`label.login`) }}</b>
          </v-btn>
        </v-col>
        <v-col cols="6" @click="goToRegisterPage()" class="bottom-navigator-before-login-first d-flex justify-center align-center no-gutters">
          <v-btn class="bottom-navigator-register-btn bottom-navigator-btn" rounded>
            <b>{{ $t(`label.register`) }}</b>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- WAIT REGISTER DIALOG -->
    <app-dialog :dialogShow="waitRegisterDialogShow" :max-width="600" :title="$t(`label.wait`)" :closeAction="closeWaitRegisterDialog">
      <app-wait-register :openRegisterDialog="openRegisterDialog" :openLoginDialog="openLoginDialog"></app-wait-register>
    </app-dialog>

    <!-- REGISTER DIALOG -->
    <app-dialog
      :dialogShow="registerDialogShow"
      :max-width="400"
      :min-height="724"
      :title="$t(`label.signUp`)"
      :isAuth="false"
      :header_title="'register'"
      :openLoginDialog="openLoginDialog"
      :closeAction="this.closeRegisterDialog"
    >
      <app-register :openLoginDialog="this.openLoginDialog" :registerErrorMsg="this.registerErrorMsg"></app-register>
    </app-dialog>

    <!-- FORGOT PASSWORD DIALOG -->
    <app-dialog :dialogShow="forgotPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)" :closeAction="this.closeForgotPasswordDialog">
      <app-forgot-password :openRegisterDialog="openRegisterDialog" :openResetPasswordDialog="openResetPasswordDialog"></app-forgot-password>
    </app-dialog>

    <!-- RESET PASSWORD DIALOG -->
    <app-dialog :dialogShow="resetPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)" :closeAction="this.closeResetPasswordDialog">
      <app-reset-password :resetPasswordMemberCode="resetPasswordObj.memberCode" :resetPasswordKey="resetPasswordObj.key"></app-reset-password>
    </app-dialog>

    <!-- LOGIN DIALOG -->
    <app-dialog
      :dialogShow="loginDialogShow"
      ref="loginDialog"
      :max-width="400"
      :title="$t(`label.login`)"
      :isAuth="false"
      :header_title="'login'"
      :openRegisterDialog="openRegisterDialog"
      :closeAction="this.closeLoginDialog"
    >
      <app-login
        :openRegisterDialog="this.openRegisterDialog"
        :loginErrorMsg="this.loginErrorMsg"
        :openForgotPasswordDialog="this.openForgotPasswordDialog"
        :closeAction="this.closeLoginDialog"
      ></app-login>
    </app-dialog>

    <!-- REGISTER SUCCESS DIALOG -->
    <app-dialog
      :dialogShow="registerSuccessDialogShow"
      :isRegSuccess="true"
      ref="registerSuccessDialog"
      :max-width="400"
      :title="$t(`label.registerSuccess`)"
      :closeAction="this.closeRegisterSuccessDialog"
    >
      <app-register-success :closeRegisterSuccessDialog="closeRegisterSuccessDialog"></app-register-success>
    </app-dialog>

    <!--PT Quick Transfer Dialog-->
    <app-dialog :dialogShow="quickTransferDialogShow" :max-width="450" :title="this.transferMessage" :closeAction="this.closeQuickTransferDialog">
      <app-transfer-component :isNonBanking="true" :provider="this.transferToProvider" :ptGameInfo="this.ptGameInfo"></app-transfer-component>
    </app-dialog>

    <!-- PAGE DIALOG -->
    <app-dialog :dialogShow="pageDialogShow" :max-width="400" :title="pageDialog.title" :closeAction="this.pageDialog.dialogXButton">
      <app-page-dialog :dialogMessageTitle="pageDialog.messageTitle" :dialogMessage="pageDialog.message" :dialogButton="pageDialog.button" :dialogNoteMessage="pageDialog.note"></app-page-dialog>
    </app-dialog>

    <!-- INSTANT POPUP DIALOG -->
    <instant-popup-dialog :dialog-show="instantDialogShow" :max-width="438" :closeAction="this.closeInstantPopup" :array-info="popupAnnouncement"></instant-popup-dialog>

    <instant-login-popup-dialog
      :dialog-show="instantLoginDialogShow"
      :max-width="450"
      :max-height="360"
      :closeAction="this.closeInstantLoginPopup"
      :array-info="loginPopupAnnouncement"
    ></instant-login-popup-dialog>

    <!-- LANGUAGE DIALOG -->
    <app-dialog :dialogShow="languageDialogShow" ref="languageDialog" :max-width="450" :title="'Currency and Language'" :isAuth="false" :header_title="'login'" :closeAction="this.closeLanguageDialog">
      <app-language :closeAction="this.closeLanguageDialog"></app-language>
    </app-dialog>

    <app-loading-progress ref="loading"></app-loading-progress>

    <!--        <v-btn class="full-width font-weight-bold px-4 py-2 theme-button liveChatButton" height="auto"-->
    <!--               @click="openLiveChat">-->
    <!--            {{ $t(`label.liveChat`) }}-->
    <!--            <img :src="`${mediaUrl}/others_icons/live-chat.svg`" width="30" height="42"-->
    <!--                 class="btn_img_lc"/>-->
    <!--        </v-btn>-->
  </div>
</template>

<script>
import AppLeftpaneInfo from '@/components/member/LeftPaneUserInfo'
import { GRAB_COIN_SETTING } from '@/store/bonus.module'
import AccountLiveChat from '@/assets/svg/liveChat.svg'
import AppTransferComponent from '@/views/bank/transfer'
import AccountProfile from '@/assets/svg/account-profile.svg'
import AccountWallet from '@/assets/svg/account-deposit.svg'
import AccountWithdrawal from '@/assets/svg/account-withdrawal.svg'
import AccountHistory from '@/assets/svg/account-history.svg'
import NavTransfer from '@/assets/svg/transfer.svg'
import NavDeposit from '@/assets/svg/deposit.svg'
import NavWithdrawal from '@/assets/svg/withdrawal.svg'
import NavLogout from '@/assets/svg/logout.svg'
import vipIcon from '@/assets/svg/vip.svg'
import InstantPopupDialog from '@/components/InstantPopupDialog.vue'

import { AnnouncementType, DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppRegister from '@/components/member/register.vue'
import AppRegisterSuccess from '@/components/member/registerSuccess.vue'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import AppWaitRegister from '@/components/member/waitRegister.vue'
import AppForgotPassword from '@/components/member/forgotPassword.vue'
import AppResetPassword from '@/components/member/resetPassword.vue'
import AppPageDialog from '@/components/layout/PageDialog.vue'
import AppPageNavigator from '@/components/layout/PageNavigator.vue'
import AppFooter from '@/components/layout/Footer.vue'
import AppLoadingProgress from '@/components/layout/LoadingProgress.vue'
import AppLogin from '@/components/member/login.vue'
import AppLanguage from '@/components/layout/Language.vue'
import { SESSION, SHARED, CACHE_KEY, MobileHeaderPageName } from '@/constants/constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import * as eventBus from 'vue'
import AppMemberBalance from '@/components/member/MemberBalance.vue'
import {
  MEMBER_RESET_LOGIN_STORE,
  MEMBER_RESET_MOBILE_LOGIN_STORE,
  MEMBER_LOGOUT,
  MEMBER_REFRESH_TOKEN,
  MEMBER_RESET_LOGOUT_STORE,
  MEMBER_CHECK_TOKEN_VALIDITY,
  MEMBER_WALLET,
  MEMBER_RESET_FORGOT_PASSWORD_STORE,
  MEMBER_RESET_RESET_PASSWORD_STORE,
  MEMBER_CONTACT_VERIFICATION,
  MEMBER_RESET_CONTACT_VERIFICATION_STORE,
  MEMBER_RESET_REGISTER_STORE,
  MEMBER_GET_VIP_PROGRESS,
  MEMBER_RESET_SMS_VERIFICATION,
  ONE_SIGNAL_PLAYER_IDS,
  ONE_SIGNAL_PLAYER_IDS_RESET_STORE,
  MEMBER_GET_IPL_VIP_PROGRESS,
  ADMILL_REG_SUCCEED_TRACK,
  MEMBER_RESET_UPDATE_FORGOT_PASSWORD
} from '@/store/member.module'
import { POPUP_LIST, LOGIN_POPUP_LIST } from '@/store/announcement.module'
import { WHEEL_MODULE } from '@/store/wheel.module'
import { INBOX_GET_MSG } from '@/store/inbox.module'
import { GET_IPL_EVENT_STATUS } from '@/store/member.module'
import InstantLoginPopupDialog from '@/components/InstantLoginPopupDialog'
import OneSignalVue from 'onesignal-vue'
import gameCard from '@/components/home/GameCard.vue'

export default {
  name: 'layoutMaster',
  components: {
    AppLeftpaneInfo,
    InstantLoginPopupDialog,
    AccountLiveChat,
    AppTransferComponent,
    AccountProfile,
    AccountWallet,
    AccountWithdrawal,
    AccountHistory,
    AppLogin,
    AppLanguage,
    AppRegister,
    AppRegisterSuccess,
    AppLoginEmbedded,
    AppForgotPassword,
    AppResetPassword,
    AppPageDialog,
    AppPageNavigator,
    AppFooter,
    AppLoadingProgress,
    AppWaitRegister,
    NavDeposit,
    NavWithdrawal,
    NavLogout,
    NavTransfer,
    vipIcon,
    InstantPopupDialog
  },
  data: () => ({
    userIp: '',
    regionLocale: uiHelper.getRegionLocale(),
    isAndroidWebView: uiHelper.isAndroidWebView(),
    playerIds: '',
    showDownloadAPK: true,
    isIos: uiHelper.IsIos(),
    instantDialogShow: false,
    instantLoginDialogShow: false,
    instantPopupAnnouncement: [],
    drawer: false,
    notLoading: true,
    currentLanguage: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    transferMessage: locale.getMessage(`label.ptQuickTransfer`, ['PT']),
    isTransferWalletLoginDialog: false,
    ptGameInfo: null,
    transferToProvider: 'PT',
    devicePlatform: DevicePlatform,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    uihelper: uiHelper,
    quickTransferDialogShow: false,
    registerSuccessDialogShow: false,
    waitRegisterDialogShow: false,
    registerDialogShow: false,
    loginDialogShow: false,
    forgotPasswordDialogShow: false,
    resetPasswordDialogShow: false,
    languageDialogShow: false,
    firstTimeRegister: true,
    routeName: ROUTE_NAME,
    resetPasswordObj: {
      key: '',
      memberCode: ''
    },
    pageDialogShow: false,
    pageDialog: {
      title: '',
      closePageDialog: () => {},
      messageTitle: '',
      note: '',
      message: [],
      button: []
    },
    memberLink: SHARED.MEMBER_HEADER_NAVIGATOR_LINK,
    loginErrorMsg: '',
    registerErrorMsg: ''
  }),
  computed: {
    gameCard() {
      return gameCard
    },
    loginPopupAnnouncement() {
      let p = []
      let k = this.$store.state.announcement.popupLoginAnnouncement
      k.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      if (p.length > 0) {
        this.openInstantLoginPopup()
      }
      console.log(p)
      return p
    },
    changeForgotPasswordResponse() {
      return this.$store.state.member.changeForgotPassword.complete
    },
    showHomepageHeaderBanner() {
      let pageToShowPlainHeader = [
        ROUTE_NAME.CRICKET,
        ROUTE_NAME.CRICKET_BY_PROVIDER,
        ROUTE_NAME.SPORTSBOOK,
        ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
        ROUTE_NAME.CASINO,
        ROUTE_NAME.CASINO_BY_PROVIDER,
        ROUTE_NAME.SLOT,
        ROUTE_NAME.SLOT_BY_PROVIDER,
        ROUTE_NAME.SLOT_BY_PROVIDER_AND_GAMENAME,
        ROUTE_NAME.SLOT_BY_GAMENAME,
        ROUTE_NAME.TABLE_GAMES,
        ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
        ROUTE_NAME.LOTTERY,
        ROUTE_NAME.LOTTERY_BY_PROVIDER,
        ROUTE_NAME.CRASH,
        ROUTE_NAME.CRASH_BY_PROVIDER,
        ROUTE_NAME.HOME,
        ROUTE_NAME.AFF_NEWS,
        ROUTE_NAME.VIPTIER
      ]
      return pageToShowPlainHeader.indexOf(this.$route.name) != -1
    },
    MobileHeaderName() {
      let headerName = MobileHeaderPageName[this.$route.name] ? MobileHeaderPageName[this.$route.name] : this.$route.name
      return headerName
    },
    hideBottomNavigator() {
      let currentPage = this.$route.name
      let pagesToBHidden = [this.routeName.REGISTER, this.routeName.LOGIN, this.routeName.AFF_NEWS]
      if (pagesToBHidden.indexOf(currentPage) != -1) {
        return true
      }
      return false
    },
    hideDesktopFooter() {
      let currentPage = this.$route.name
      let isDesktop = this.$vuetify.breakpoint.mdAndUp
      let pagesToBHidden = [
        this.routeName.REGISTER,
        this.routeName.LOGIN,
        this.routeName.DEPOSIT,
        this.routeName.WITHDRAWAL,
        this.routeName.MOBILE_ACCOUNT,
        this.routeName.REFERRAL_TIER,
        this.routeName.REFERRAL_SUMMARY,
        this.routeName.VIP,
        this.routeName.REFERRAL_SUMMARY_REPORT,
        this.routeName.REFERRAL_SUMMARY_HISTORY,
        this.routeName.MOBLE_HISOTRY,
        this.routeName.MOBILE_PROMO_HISTORY,
        this.routeName.MOBILE_TRANSACTION_HISTORY,
        this.routeName.MOBILE_TO_HISTORY,
        this.routeName.MOBILE_BETTING_HISTORY,
        this.routeName.CLAIM_VOUCHER,
        this.routeName.CHANGE_PASSWORD,
        this.routeName.TURNOVER,
        this.routeName.AFF_NEWS
      ]
      if (pagesToBHidden.indexOf(currentPage) != -1 && !isDesktop) {
        return true
      }
      return false
    },
    isIPLEventActive() {
      return this.$store.state.member.iplEventDate.status
    },
    popupAnnouncement() {
      let p = []
      let k = this.$store.state.announcement.popupAnnouncement
      k.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      if (p.length > 0) {
        this.openInstantPopup()
      }
      return p
    },
    hasUnreadInboxMsg() {
      return this.$store.state.inbox.inboxMsg.filter(m => !m.has_read).length > 0
    },
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      return d
    },
    wof() {
      return this.$store.state.wheel.wheel
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    loginResponseComplete() {
      return this.$store.state.member.loginResponse.complete
    },
    verifyContactResponseComplete() {
      return this.$store.state.member.verifyContactResponse.complete
    },
    resetPasswordResponseComplete() {
      return this.$store.state.member.resetPasswordResponse.complete
    },
    registerResponseComplete() {
      return this.$store.state.member.registerResponse.complete
    },
    forgotPasswordResponseComplete() {
      return this.$store.state.member.forgotPasswordResponse.complete
    },
    logoutResponseComplete() {
      return this.$store.state.member.logoutResponse.complete
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    vipLevel() {
      if (uiHelper.getCookie(SESSION.VIP) == null) {
        let m = this.$store.state.member.info
        let vip = m.group
        uiHelper.setCookie(SESSION.VIP, vip, 1)
        return vip
      }
      return uiHelper.getCookie(SESSION.VIP)
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    vipDepositProgress() {
      return (this.vipProgress.current_deposit_progress / this.vipProgress.required_deposit_amount) * 100
    },
    vipRolloverProgress() {
      return (this.vipProgress.current_rollover_progress / this.vipProgress.required_account_rollover) * 100
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    ComregisterDialogShow() {
      return this.registerDialogShow
    }
  },
  watch: {
    popupAnnouncement() {
      if (this.popupAnnouncement.length > 0) {
        this.openInstantPopup()
      }
    },
    changeForgotPasswordResponse() {
      //new forgotpassword flow
      let response = this.$store.state.member.changeForgotPassword
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.updateForgotPasswordCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_FORGOT_PASSWORD}`)
      }
    },
    ComregisterDialogShow() {
      if (!this.registerDialogShow) {
        //reset err msg once close popup
        this.registerErrorMsg = ''
      }
    },
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.getMemberWallet()
        this.getMemberVipProgress()
        this.getMemberIplVipProgress()
        this.getInboxMsg()
        this.getLoginPopupAnnouncement()
      }
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },
    memberInfo() {
      let m = this.$store.state.member.info
      let vip = m.group
      uiHelper.setCookie(SESSION.VIP, vip, 1)
      this.currentCurrency = uiHelper.getCurrency()

      // if(this.currentLanguage != m.preferredLanguage && !uiHelper.getCookie("changeLanguage")){
      //   uiHelper.removeCookie('language')
      //   uiHelper.setCookie('language', m.preferredLanguage)
      //   this.currentLanguage = m.preferredLanguage
      //   window.location.reload()
      // }
    },
    memberWallet() {
      this.notLoading = true
    },
    registerResponseComplete() {
      let response = this.$store.state.member.registerResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Register`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)

          if (response.success && response.membercode != '' && response.memberID) {
            this.injectTrackierIframe(response.memberID, response.membercode)
            this.pushAdmillRegSucceed(window._admillClickId, response.membercode, this.userIp)
          }
        } catch (err) {
          console.log(err)
        }
        this.registerResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_REGISTER_STORE}`)
      }
    },
    loginResponseComplete() {
      let response = this.$store.state.member.loginResponse
      let memberInfo = this.$store.state.member.info

      /* Check Path Should Reload */
      if (this.$route.fullPath == '/sportsbook/bti') {
        window.location.reload()
      }
      if (response.complete) {
        try {
          this.$ga.event('Account', `Login`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)

          if (response.success) {
            this.pushAdmillLoginSucceed(window._admillClickId, memberInfo.memberCode, this.userIp)
          }
        } catch (err) {
          console.log(err)
        }
        if (response.success) {
          this.setGoogleAnalyticUserId()
          this.oneSignal()

          try {
            srtmCommands.push({
              event: 'track.user.login',
              payload: {
                action: 'complete',
                userId: memberInfo.memberCode
              }
            })
            console.log('done sradar login')
          } catch (err) {
            console.log(err)
          }
        }
        this.loginResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGIN_STORE}`)
        this.$store.dispatch(`${ONE_SIGNAL_PLAYER_IDS_RESET_STORE}`)
      }
    },
    logoutResponseComplete() {
      let response = this.$store.state.member.logoutResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Logout`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.logoutResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGOUT_STORE}`)
      }
    },
    verifyContactResponseComplete() {
      let response = this.$store.state.member.verifyContactResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Verify Contact`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.verifyContactResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_CONTACT_VERIFICATION_STORE}`)
      }
    },
    resetPasswordResponseComplete() {
      let response = this.$store.state.member.resetPasswordResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.resetPasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_RESET_PASSWORD_STORE}`)
      }
    },
    forgotPasswordResponseComplete() {
      let response = this.$store.state.member.forgotPasswordResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Forgot Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.forgotPasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_FORGOT_PASSWORD_STORE}`)
      }
    }
  },
  created() {
    this.getUserIP()
    this.getPopupAnnouncement()
    this.pageDialog = this.initializePageDialogProperty()
    if (localStorage.getItem(SESSION.TOKEN)) {
      this.getMemberVipProgress()
      this.getMemberIplVipProgress()
      this.validateMemberToken()
    }
    this.setGoogleAnalyticUserId()
    this.getInboxMsg()
    this.$eventHub.$on('open-page-dialog', dialog => {
      this.openPageDialog(dialog)
    })
    this.$eventHub.$on('close-page-dialog', unt => {
      this.closePageDialog()
    })
    this.$eventHub.$on('open-quick-transfer-dialog', gameInfo => {
      this.openQuickTransfer(gameInfo)
    })
    this.$eventHub.$on('close-quick-transfer-dialog', dialog => {
      this.closeQuickTransferDialog()
    })
    this.$eventHub.$on('open-login-dialog', obj => {
      if (obj != null) {
        this.isTransferWalletLoginDialog = obj.isTransfer
      }
      this.openLoginDialog()
    })
    this.$eventHub.$on('close-login-dialog', dialog => {
      this.closeLoginDialog()
    })
    this.getIPLEventStatus()
  },
  beforeCreate() {
    this.$OneSignal.getUserId(result => {
      if (result == null) {
        localStorage.removeItem('onesignal-notification-prompt')
        this.$OneSignal.showSlidedownPrompt()
      } else {
        this.oneSignal()
      }
    })
    this.$OneSignal.on('subscriptionChange', result => {
      if (result) {
        this.oneSignal()
        console.log('subscribe status ', result)
      }
    })
  },
  methods: {
    getListItemTo(gameType) {
      let routeName = ''
      let prm = {}

      switch (gameType.type) {
        case 'sb':
          routeName = ROUTE_NAME.SPORTSBOOK
          break
        case 'rng':
          routeName = ROUTE_NAME.SLOT
          // prm = { provider: 'slothotgames' }
          break
        case 'fishing':
          routeName = ROUTE_NAME.FISHING
          break
        case 'special':
          routeName = ROUTE_NAME.SPECIAL
          break
        case 'ld':
          routeName = ROUTE_NAME.CASINO
          // prm = { provider: 'casinohotgames'}
          break
        case 'table':
          routeName = ROUTE_NAME.TABLE_GAMES
          break
        case 'lottery':
          routeName = ROUTE_NAME.LOTTERY
          break
        case 'crash':
          routeName = ROUTE_NAME.CRASH
          // prm = { provider: 'crashhotgames'}
          break
        case 'cricket':
          routeName = ROUTE_NAME.CRICKET
          break
        default:
          routeName = ROUTE_NAME.HOME
          break
      }

      if (this.$route.params.lang) {
        // If it has a value, include it in the route

        return { name: routeName, params: { ...prm, lang: this.$route.params.lang } }
      } else {
        // If not, just return the route without params
        return { name: routeName }
      }
    },
    updateForgotPasswordCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.resetPassword`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        let self = this
        dialog.message.push(locale.getMessage(`message.resetPasswordComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: self.closeResetPasswordSuccessDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    injectTrackierIframe(memberID, memberCode) {
      var iframe = document.createElement('iframe')
      iframe.src = 'https://10panda.gotrackier.com/pixel?av=64671e489169ee3a335426ea'
      let customQS = '&txn_id={0}&sub1={1}&user_id={2}'
      iframe.src += customQS
        .replace('{0}', memberID)
        .replace('{1}', memberCode)
        .replace('{2}', memberID)
      iframe.scrolling = 'no'
      iframe.frameBorder = '0'
      iframe.width = '1'
      iframe.height = '1'

      document.body.appendChild(iframe)
    },
    pushAdmillLoginSucceed(admillCookie, membercode, userIP) {
      let admillURL = 'https://track.adform.net/v1/sitetracking/2896594/trackingpoints/'
      let intCookie = admillCookie ? parseInt(admillCookie) : 0
      let _body = [
        {
          name: 'Bhaggo_Login',
          identity: {
            cookieId: intCookie
          },
          userContext: {
            userAgent: navigator.userAgent,
            userIp: userIP
          },
          variables: {
            orderid: membercode
          }
        }
      ]

      fetch(admillURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(_body)
      })
        .then(res => {
          console.log('Adform login succeed pixel post complete! response: ', res)
        })
        .catch(err => {
          console.log('Adform login succeed  pixel post failed! err: ', err)
        })
    },
    pushAdmillRegSucceed(admillCookie, membercode, userIP) {
      let admillURL = 'https://track.adform.net/v1/sitetracking/2896594/trackingpoints/'
      let intCookie = admillCookie ? parseInt(admillCookie) : 0
      let _body = [
        {
          name: 'Bhaggo_Registeration',
          identity: {
            cookieId: intCookie
          },
          userContext: {
            userAgent: navigator.userAgent,
            userIp: userIP
          },
          variables: {
            orderid: membercode
          }
        }
      ]

      fetch(admillURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(_body)
      })
        .then(res => {
          console.log('Adform reg succeed pixel post complete! response: ', res)
        })
        .catch(err => {
          console.log('Adform reg succeed  pixel post failed! err: ', err)
        })
    },
    getUserIP() {
      fetch(`https://api.ipify.org`)
        .then(res => res.text())
        .then(text => {
          console.log('user ip:' + text)
          this.userIp = text
        })
    },
    // pushRegSucceedAdmillTracking(memberCode){
    //   debugger
    //   window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
    //   window._adftrack.push({
    //     HttpHost: 'asia.adform.net',
    //     pm: 2896594,
    //     divider: encodeURIComponent('|'),
    //     pagename: encodeURIComponent('Bhaggo_Registeration'),
    //     order : {
    //       orderid: memberCode
    //     }
    //   });
    //   var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
    // },
    checkIsLogin() {
      if (!this.isLoggedIn) {
        this.openLoginDialog()
      } else {
        this.$router.push({ name: ROUTE_NAME.CLAIM_VOUCHER })
      }
    },
    async oneSignal() {
      let data = {
        player_ids: await this.$OneSignal.getUserId(),
        is_subscribe: await this.$OneSignal.getSubscription(),
        permission_status: await this.$OneSignal.getNotificationPermission()
      }
      await this.$store.dispatch(`${ONE_SIGNAL_PLAYER_IDS}`, { data })
    },
    hideDownloadBar() {
      this.showDownloadAPK = false
    },
    downloadApk() {
      uiHelper.downloadAPK()
    },
    getIPLEventStatus() {
      this.$store.dispatch(`${GET_IPL_EVENT_STATUS}`)
    },
    async getPopupAnnouncement() {
      let obj = {
        site: uiHelper.getPopup(),
        type: AnnouncementType.ALL,
        page: '',
        itemsPerPage: ''
      }
      await this.$store.dispatch(`${POPUP_LIST}`, { obj })
    },
    async getLoginPopupAnnouncement() {
      let obj = {
        site: uiHelper.getLoginPopup(),
        type: AnnouncementType.ALL,
        page: '',
        itemsPerPage: ''
      }
      await this.$store.dispatch(`${LOGIN_POPUP_LIST}`, {
        obj
      })
    },
    openInstantLoginPopup() {
      let dayClose = uiHelper.getCookie('popupLogin')
      if (dayClose == null) {
        this.instantLoginDialogShow = true
      }
    },
    closeInstantLoginPopup() {
      let dayClose = uiHelper.getCookie('popupLogin')
      if (dayClose == null) {
        dayClose = 'yes'
      }
      uiHelper.setCookie('popupLogin', 'yes', 5)
      this.instantLoginDialogShow = false
    },
    openInstantPopup() {
      let dayClose = uiHelper.getCookie('popup')
      if (dayClose == null) {
        this.instantDialogShow = true
      }
    },
    closeInstantPopup() {
      let dayClose = uiHelper.getCookie('popup')
      if (dayClose == null) {
        dayClose = 'yes'
      }
      uiHelper.setCookie('popup', 'yes', 0.5)
      this.instantDialogShow = false
    },
    getInboxMsg() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${INBOX_GET_MSG}`)
      }
    },
    navigateToCoinPage() {
      this.$router.push({
        name: ROUTE_NAME.GRAB_COIN,
        params: {
          type: 'coin'
        }
      })
    },
    getGrabCoinSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, { filter })
    },
    checkShouldHideCoin() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_COIN) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_COIN) == 1) {
        this.showCoin = false
      } else {
        this.showCoin = true
      }
    },
    refreshCurrentState() {
      this.$router.push({
        name: ROUTE_NAME.HOME
      })
      this.$forceUpdate()
    },
    navigateWOFPage() {
      if (uiHelper.getPlatform() == DevicePlatform.DESKTOP) {
        this.$router.push({
          name: ROUTE_NAME.WOF
        })
      } else {
        this.$router.push({
          name: ROUTE_NAME.MWOF
        })
      }
    },
    getWOF() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, { filter })
    },
    checkShouldHideAngpao() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_ANG_PAO) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_ANG_PAO) == 1) {
        this.showAngpao = false
      } else {
        this.showAngpao = true
      }
    },
    dontShowMeAgain() {
      this.showAngpao = false
      uiHelper.setCookie(SESSION.HIDE_ANG_PAO, 1, 0.5)
    },
    navigateToAngpao() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'angpao'
        }
      })
    },
    setGoogleAnalyticUserId() {
      if (this.memberInfo.memberCode != '') this.$ga.set('userId', this.memberInfo.memberCode)
    },
    openLiveChat() {
      try {
        this.$ga.event('Live Chat', `Open`, `Open Live Chat`)
      } catch (err) {
        console.log(err)
      }
      window.open('/livechat', 'Live Chat', 'width=350,height=600')
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN
      })
    },
    goToTransferPage() {
      this.$router.push({
        name: ROUTE_NAME.TRANSFER
      })
    },
    goToWithdrawalPage() {
      this.$router.push({
        name: ROUTE_NAME.WITHDRAWAL
      })
    },
    goToDepositPage() {
      // if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
      //   let routeData = this.$router.resolve({
      //     name: ROUTE_NAME.DEPOSIT
      //   })
      //   window.open(routeData.href)
      // } else {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT
      })
      //}
    },
    goToProfilePage() {
      this.$router.push({
        name: ROUTE_NAME.PERSONAL
      })
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    validateMemberToken() {
      if (new Date(parseInt(localStorage.getItem(SESSION.TOKEN_EXPIRY))) - new Date() < 60000) {
        if (new Date() - new Date(parseInt(localStorage.getItem(SESSION.LAST_ACTIVITY_TIME))) > 10800000) {
          const logoutObj = {}
          let sessionTimedOut = true
          this.$store.dispatch(MEMBER_LOGOUT, {
            logoutObj,
            sessionTimedOut
          })
        } else {
          this.$store.dispatch(MEMBER_REFRESH_TOKEN)
        }
      } else {
        let requiredLoading = false
        this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, {
          requiredLoading
        })
      }
    },
    memberContactVerification(memberCode, otp, type, contact) {
      let verificationObj = {
        memberCode: memberCode,
        otp: otp,
        type: type,
        contact: contact
      }
      this.$store.dispatch(MEMBER_CONTACT_VERIFICATION, {
        verificationObj
      })
    },
    logout() {
      const logoutObj = {
        domain: uiHelper.getHostname(),
        platform: uiHelper.getPlatform()
      }
      let sessionTimedOut = false
      this.$store.dispatch(MEMBER_LOGOUT, {
        logoutObj,
        sessionTimedOut
      })
    },
    getMemberWallet() {
      this.notLoading = false
      this.$store.dispatch(MEMBER_WALLET)
    },
    getMemberIplVipProgress() {
      this.$store.dispatch(MEMBER_GET_IPL_VIP_PROGRESS)
    },
    getMemberVipProgress() {
      this.$store.dispatch(MEMBER_GET_VIP_PROGRESS)
    },
    /* DIALOG */
    closeRegisterSuccessDialog() {
      this.registerSuccessDialogShow = false
    },
    closeQuickTransferDialog() {
      this.quickTransferDialogShow = false
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: this.ptGameInfo.provider,
          type: this.ptGameInfo.type,
          lang: this.$route.params.lang
        },
        query: {
          id: this.ptGameInfo.id,
          code: this.ptGameInfo.code,
          demo: 0
        }
      })
      try {
        this.$ga.event('Game', `${0 ? 'Demo' : 'Real'} Play`, `${this.ptGameInfo.provider.toUpperCase()} - Slot - ${this.ptGameInfo.locale.find(x => x.language == 'en').name}`, this.ptGameInfo.id)
      } catch (err) {
        console.log(err)
      }
      uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
    },
    openRegisterDialog() {
      this.registerDialogShow = true
      this.closeWaitRegisterDialog()
      this.closeLoginDialog()
      this.closeForgotPasswordDialog()
      // try {
      //   srtmCommands.push({
      //     event: 'track.user.registration',
      //     payload: {
      //       action: 'start'
      //     }
      //   })
      // } catch (err) {
      //   console.log(err)
      // }
    },
    closeRegisterDialog(actionFromRegisterDialog = true) {
      this.registerDialogShow = false
      //this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      // if (this.firstTimeRegister && actionFromRegisterDialog) {
      //   this.openWaitRegisterDialog()
      //   this.firstTimeRegister = false
      // }
    },
    closeLoginDialog() {
      if (this.isTransferWalletLoginDialog) {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: 'all'
          }
        })
      }
      this.loginDialogShow = false
    },
    openForgotPasswordDialog() {
      this.forgotPasswordDialogShow = true
      this.closeLoginDialog()
    },
    closeForgotPasswordDialog() {
      this.forgotPasswordDialogShow = false
    },
    openResetPasswordDialog(memberCode, contact) {
      this.resetPasswordObj.memberCode = memberCode
      this.resetPasswordObj.contact = contact
      this.closeForgotPasswordDialog()
      this.resetPasswordDialogShow = true
    },
    closeResetPasswordDialog() {
      this.resetPasswordObj.memberCode = ''
      this.resetPasswordObj.contact = ''
      this.resetPasswordDialogShow = false
    },
    openLanguageDialog() {
      this.languageDialogShow = true
    },
    closeLanguageDialog() {
      this.languageDialogShow = false
    },
    openLoginDialog() {
      this.loginDialogShow = true
      this.closeWaitRegisterDialog()
      this.closeRegisterDialog(false)
    },
    openQuickTransfer(gameInfo) {
      this.ptGameInfo = gameInfo
      this.quickTransferDialogShow = true
      this.transferToProvider = gameInfo.provider == 'pt' ? 'PT' : 'MAIN'
      this.transferMessage = locale.getMessage(`label.ptQuickTransfer`, [this.transferToProvider])
    },
    openPageDialog(dialog) {
      this.pageDialog = dialog
      this.pageDialogShow = true
    },
    closeTimeOutDialog() {
      localStorage.removeItem(SESSION.TOKEN)
      this.closePageDialog()
      this.refreshCurrentState()
    },
    closePageDialog() {
      this.pageDialogShow = false
      this.initializePageDialogProperty()
    },
    openWaitRegisterDialog() {
      this.waitRegisterDialogShow = true
    },
    closeWaitRegisterDialog() {
      this.waitRegisterDialogShow = false
    },
    /* RESPONSE COMPLETE DIALOG */
    loginResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`) + ' Error'
      let currentPage = this.$route.name

      if (response.code != 0 && !response.success) {
        this.loginErrorMsg = errorCodeHelper.getErrorCodeDesc(response.code)
      } else {
        this.closeLoginDialog()

        this.currentCurrency = uiHelper.getCurrency()
        this.closeRegisterDialog(false)

        if (currentPage == this.routeName.LOGIN) {
          this.$router.push({
            name: ROUTE_NAME.HOME
          })
        }
      }
    },
    logoutResponseCompleteDialog(response) {
      if (response.isSessionTimedOut) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.${response.action}`)
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closeTimeOutDialog
        })
        this.openPageDialog(dialog)
      }
    },
    forgotPasswordResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.forgotPasswordSendText`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.closePageDialog
        })
        this.closeForgotPasswordDialog()
      }
      this.openPageDialog(dialog)
    },
    resetPasswordResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        let self = this
        dialog.message.push(locale.getMessage(`message.resetPasswordComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: self.closeResetPasswordSuccessDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    closeResetPasswordSuccessDialog() {
      let self = this
      this.closePageDialog()
      setTimeout(function() {
        window.location.reload()
      }, 1)
    },
    registerResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      // console.log(this.$route.name +' , '+ this.$route.fullPath)

      if (response.code != 0 && !response.success) {
        // dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        // dialog.button.push({
        //   title: locale.getMessage(`label.close`),
        //   action: this.closePageDialog
        // })
        // this.openPageDialog(dialog)

        this.registerErrorMsg = errorCodeHelper.getErrorCodeDesc(response.code)

        // dialog.title = locale.getMessage(`label.${response.action}`)+' Error'
        // dialog.message.push(this.registerErrorMsg)
        // let currentPage = this.$route.name
        // if(currentPage== this.routeName.REGISTER){
        //   this.openPageDialog(dialog, true)
        // }
      } else {
        let currentPage = this.$route.name
        if (currentPage != this.routeName.REGISTER) {
          // desktop view homepage popup registration
          this.registerSuccessDialogShow = true
          let $inObj = this
          setTimeout(function() {
            $inObj.registerSuccessDialogShow = false
            $inObj.$router.push({
              name: ROUTE_NAME.DEPOSIT
            })
          }, 5000)
        } else {
          //  mobile view full page registration
        }
      }
    },
    verifyContactResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.contactVerificationComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.closePageDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    }
  }
}
</script>

<style lang="scss">
.list-item-title {
  font-size: 1.05rem;
}
.download-bar {
  z-index: 5;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 12px;
  background-color: #f5f5f5;
  line-height: 1;

  .download-bar-button {
    background: var(--v-newMobilePrimary-base) !important;
    color: #333;
  }
}

.mobile-drawer-itemgroup-title {
  font-size: 1rem;
  color: #9b9b9b;
}

.mobile-drawer-menu-header-section {
  max-width: 256px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 16px;
}

.mobile-drawer-menu-icon {
  margin-top: auto;
  margin-bottom: auto;
}

.mobile-drawer-menu-logo {
}

.mobile-left-pane-logo {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.sb-div::before {
  content: '\26BD';
  font-size: 1.4rem;
}
.rng-div::before {
  content: '\1F352';
  font-size: 1.4rem;
}
.ld-div::before {
  content: '\1F3B0';
  font-size: 1.4rem;
}

.fishing-div::before {
  content: '\1F3B0';
  font-size: 1.4rem;
}

.special-div::before {
  content: '\1F3B0';
  font-size: 1.4rem;
}
.table-div::before {
  content: '\1F46F';
  font-size: 1.4rem;
}
.cricket-div::before {
  content: '\1F3CF';
  font-size: 1.4rem;
}
.crash-div::before {
  content: '\1F680';
  font-size: 1.4rem;
}
.mobile-header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-navigator {
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  box-shadow: 0px 20px 20px -16px rgb(0 0 0 / 95%);
  backdrop-filter: blur(3px);
  border-radius: 16px 16px 0px 0px;
  font-family: 'DM Sans', sans-serif;

  .bottom-navigator-before-login {
    height: 75px;

    ._bottom-navigator-before-login-first {
      color: black;
      background-color: var(--v-newMobilePrimary-base);
      border: 1px solid #ddd;
    }

    ._bottom-navigator-before-login-second {
      color: white;
      background-color: var(--v-newMobileSecondary-base);
    }

    .bottom-navigator-before-login-third {
      color: white;
      border: 1px solid var(--v-primary-base);
    }

    .bottom-navigator-btn {
      height: 45px;
      width: 150px;
      border-radius: 32px;
    }
    .bottom-navigator-login-btn {
      background: #ffffff;
    }
    .bottom-navigator-register-btn {
      background: radial-gradient(64.36% 672.4% at 35.64% 50%, #a1ac2f 0%, #757c2d 100%);
      color: #ffffff;
    }
  }
}

.mobileMenuListItem {
  //height: 100%;
  color: #9b9b9b !important;

  .v-list-item__title {
    color: #9b9b9b !important;
  }

  .v-avatar {
    border-radius: unset !important;
  }
}

.vip-badge-mobile {
  .v-badge {
    margin-top: 11px;
  }
}

.v-btn--is-elevated {
  box-shadow: 0px 0px !important;
}

.coin_box_gif {
  position: fixed;
  bottom: 32%;
  right: 10px;
  z-index: 200;
  cursor: pointer;
}

.grab_coin_icon {
  animation: swing 1.2s;
  animation-iteration-count: 3;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.color-primary {
  color: var(--v-primary-base) !important;
}

.color-base {
  background-color: var(--v-base-base) !important;
}

.color-base-yellow {
  color: var(--v-buttonPrimary-base) !important;
}

// .v-badge__badge {
//   bottom: 14px !important;
// }
.language_desktop {
  position: absolute;
  top: 70px;
  right: 3.5%;
}

.icon_middle_nav {
  width: 30px;
  stroke: white;
  fill: white;
}

.icon_btm_nav {
  width: 35px;
  height: 30px;
  margin-top: 8px;
  //stroke: #fff;
  fill: #fff;

  &.chat {
    stroke: white;
    fill: white;
    width: 23px;
    margin-bottom: -8px;
  }
}

.icon_normal {
  width: 25px;
  fill: white;
  color: black;
  stroke: #e4282f;
}

.icon_large {
  width: 35px;
  fill: white;
  color: black;
  margin-top: -5px;
}

.gold-gradient {
  background: transparent linear-gradient(180deg, #ffa15c 0%, #e2d200 100%) 0% 0% no-repeat padding-box;
  font-weight: bold;
}

.btn_img_lc {
  width: 30px;
  padding-bottom: 0;
  padding-left: 5px;
  margin-left: 10px;
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
}

.gold-color {
  background-color: #b87953 !important;
}

.yellow-color {
  background-color: #fddd04 !important;
}

.v-btn.theme-button.liveChatButton {
  background: transparent linear-gradient(90deg, #fa786a 0%, #c83563 50%, #86286d 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 15px;
}

.mobile-member-info-box {
  padding: 5px 15px;
  background: #121734;
  box-shadow: 0px 3px 6px #00000042;
  border-radius: 8px;

  .mobile-member-info-box-text {
    font-size: 15px;
  }

  .mobile-member-info-box-text-icon {
    font-size: 12px;
  }

  .mobile-member-info-box-balance {
    font-size: 15px;
    font-weight: bold;
  }

  .mobile-member-info-box-icon {
    height: auto !important;

    .v-icon {
      font-size: 28px;
    }
  }
}

.liveChatButton {
  width: 200px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  z-index: 1;
  min-height: auto !important;
  position: fixed;
  height: auto;
  top: 30%;
  right: -80px;
  transform: rotate(-90deg);

  span {
    font-size: 24px;
    font-weight: normal;
  }
}

.v-application--wrap {
  > div {
    height: 100%;
    background-color: white;
  }
}

.header-row {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
}

button {
  &.round {
    border-radius: 50% !important;
  }

  &.buttonWhite {
    box-shadow: none;
  }

  span {
    letter-spacing: 0;
  }
}

.v-btn.primary-button {
  border-radius: 10px;
  color: black;
  text-transform: capitalize; // border-radius: 25px;
  width: 100%;
  font-size: 16px;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.body {
  padding-top: 0px;
  height: auto;
  /*min-height: 100%;*/
}

.brand-logo {
  position: absolute;
  left: 3%;
  transform: translateX(-3%);
  z-index: 1;
  top: -1%;
  width: 300px;
  margin-top: 20px;
}

.mobile-brand-logo {
  width: 250px;
  text-align: center;
}

.img_flag_select {
  width: 23px;
  height: 17px;
  margin-left: -10px;
  margin-right: 4px;
}

.member-menu-divider {
  border-color: white !important;
}

header {
  .v-toolbar__content {
    height: 100% !important;
  }
}

.div_header {
  background-color: #2d3487;
  padding-top: 20px;
}

.splitter_header {
  padding: 0;
  background-color: #3a3eb1;
  margin: 0 auto;
}

.header-icon-button-link {
  height: 40px !important;
  min-width: auto !important;
  width: 40px !important;
  margin-left: 8px;
}

.header-icon-button-more {
  height: 40px !important;
}

.header-text {
  font-size: 16px;
}

.header-top {
  min-height: 50px;
  color: #3a3a3a;
  background: #ffffff no-repeat padding-box;
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: space-between;
  height: 50px;
  position: relative;

  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 38px;
    height: 30px;
    padding: unset;
  }

  &.mobile {
    // position: fixed;
    z-index: 2;
    //background: #324232;
    height: 55px;
    padding: unset;
  }

  &.homepage-header {
    background-image: url('/static/image/banner/bhagyo-mainpage-header.png');
    background-size: cover;
    width: 100%;
  }

  &.non-homepage-header {
    background-image: url('/static/image/banner/bhagyo-subpage-header.png');
    height: 115px;
    background-size: cover;
  }

  .homepage-banner-detail {
    margin-top: 50px;
    position: relative;

    .header-banner-desc {
      padding-left: 27px;
      .header-banner-h1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 70px;
        /* identical to box height, or 81% */

        letter-spacing: -0.02em;

        color: #edc679;
      }
      .header-banner-span {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 25px;
        /* identical to box height, or 126% */

        letter-spacing: -0.02em;

        color: #ffffff;
      }
    }

    .header-banner-lady {
      //background-image: url("/static/image/banner/lady-banner.png");
      width: 100%;
      z-index: 3;
      .v-responsive__content {
        width: 100% !important;
      }
    }
    .div-header-banner-chips {
      position: absolute;
      z-index: 2;
      bottom: -20px;
      //background-size: cover;
      background-image: url(/static/image/banner/banner-chips-828px.png);
      height: 220px;
      width: 100vw;
      background-position: center;
      background-size: cover;
    }

    .img-header-banner-chips {
      position: absolute;
      height: 298px;
      bottom: -59px;
      z-index: 2;
    }

    .vImg-header-banner-chips {
      z-index: 2;
      bottom: -20px;
      position: absolute;
    }
  }
  .greeting-text {
    padding-left: 1em;
    font-size: 18px;

    .greeting-text-btn {
      cursor: pointer;
    }
  }
  .fixed-header {
    position: absolute;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
    top: 11px;
  }

  .subpage-header-name {
    position: absolute;
    bottom: 15px;
    font-weight: 500;
    font-size: 35px;
    line-height: 39px;
    text-transform: capitalize;
    padding-left: 27px;
    letter-spacing: -0.02em;

    color: #edc679;
  }
}

.header-column {
  // display: flex;
  height: 100%;
  align-items: center; // margin-top: 10px;
  margin-left: 50px;
  margin-right: 30px; // margin-top: 20px;
  &.mobile {
    margin: 0 auto;
    height: 80px;
  }

  .header-wallet {
    font-size: 20px;
    padding: 15px 35px 15px 15px;
  }

  .header-wallet-icon {
    margin-left: -25px;
  }
}

.account-actions {
  display: flex;

  a {
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
  }

  .flex-column {
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;

    img {
      height: 35px;
    }
  }
}

.login-form .v-messages__message {
  font-size: 9px !important;
}

.register-dialog {
  .row {
    form {
      display: flex;
      position: relative;
      align-items: baseline;

      .v-text-field__details {
        /*display: flex*/
      }

      .v-input {
        padding-right: 5px;
        min-width: 40px;
        max-width: 200px;

        .v-input__slot {
          background-color: #00000090;

          label {
            color: white;
          }

          input {
            color: white;
          }
        }
      }

      .v-input__control {
        margin-bottom: 0;
      }

      .login-button {
        width: 120px;
        padding: 9px !important;
      }

      .forgot-button {
        position: absolute;
        top: 65px;
        right: 37%;
        color: white;
      }

      .signup-button {
        color: black;
        border: 2px solid black;
        width: 110px;
        text-transform: capitalize; // border-radius: 25px;
      }
    }
  }
}

.header-bottom {
  min-height: 100px;
  color: #ffffff;
  background: transparent linear-gradient(180deg, #4145c4 0%, #121734 150%) 0% 0% no-repeat padding-box;
  position: sticky;
  top: 0;
  z-index: 15;
}

.mobile-navigation-drawer {
  font-size: 12px;
  min-height: 100vh;
  position: absolute;

  .v-navigation-drawer__content {
    height: 100vh !important;
  }

  .header-wallet {
    font-size: 14px;
  }

  .vip-progress-bar {
    // color: var(--v-primary-base);
    // background-color: var(--v-base-base);
    .v-progress-linear__background {
      background-color: #ebebeb !important;
      border-color: #ebebeb !important;
      opacity: 1 !important;
    }

    .v-progress-linear__determinate {
      border-radius: 10px;
    }

    color: #3a3a3a;

    label {
      font-size: 11px;
      color: #acacac;
    }
  }

  .mobile-icon {
    fill: #fff !important;
  }

  p {
    font-size: 9px;
  }
}

.mobile-navigator {
  min-height: 50px;
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  border-radius: 15px 15px 0 0;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ffffff;
  box-shadow: 0px 20px 20px -16px rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(13px);
  color: #000000;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px 12px 0px 0px;

  .theme--light.v-btn.v-btn--has-bg {
    background-color: transparent;
  }

  a {
    //height: 50px !important;
    letter-spacing: 1px;
    padding: 0 !important;
    display: block;
    border-color: var(--v-base_3-base);
    text-decoration: none;

    //&::before {
    //  opacity: 0 !important;
    //}

    // &.link-active {
    //   border-color: #8C8CDB;
    //   .icon_btm_nav {
    //     fill: #8C8CDB;
    //     stroke: #8C8CDB;
    //     ;
    //   }
    //   .v-icon {
    //     color: #8C8CDB;
    //   }
    //   p {
    //     color: #8C8CDB;
    //   }
    // }
    p {
      margin: -2px 0 0 0;
      text-transform: capitalize;
      color: #000;
    }
  }
}

.mobile-header-detail {
  // margin-top: 36px !important;
  background-color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 12px;
  min-height: 46px;

  .header-wallet {
    font-size: 14px;
    padding: 15px 35px 15px 15px;
  }

  .header-wallet-icon {
    margin-left: -25px;
  }
}

@media (max-width: 1499px) {
  .div_header {
    padding-top: 0;
  }
  .header-top {
    min-height: 50px;
  }
  // .header-column {
  //   margin-top: -30px;
  // }
  .language_desktop {
    top: 60px;
    right: 4%;
  }
}

@media (max-width: 1263px) {
  .language_desktop {
    right: 6%;
  }
  .header-icon-button-link {
    height: 35px !important;
    width: 35px !important;
    margin-left: 4px;
  }
  .header-icon-button-more {
    height: 35px !important;
    margin-left: 4px;
    min-width: auto !important;
    width: 30px !important;

    .v-icon {
      height: 15px !important;
      font-size: 28px !important;
    }
  }
  .header-text {
    font-size: 13px !important;
  }
}

@media (max-width: 1024px) {
  .header-text-statis-word {
    display: none;
  }
}

@media (max-width: 959px) {
  .v-application--wrap {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .v-btn.primary-button {
    font-size: 12px;
    width: 100%;
  }
  .language_desktop {
    position: absolute;
    top: 50px;
    right: 13%;
  }
  .liveChatButton {
    display: none;
  }
  .body {
    padding-top: 0px;
  }
  .header {
    height: 50px;
    background-image: linear-gradient(to right, var(--v-primary-base), var(--v-primary_3-base));
    padding: 0 15px;

    .mobile-brand-logo {
      width: auto !important;
      height: 25px !important;
      margin-top: 5px;
    }

    .header-mobile-livechat {
      position: absolute;
      top: 0;
      right: 15px;
      height: 100%;

      .v-icon {
        font-size: 25px;
        padding-right: 5px;
      }

      .v-btn__content {
        font-size: 16px;
      }
    }
  }
  .v-btn.v-size--default {
    font-size: 16px;
  }
}

@media (max-width: 599px) {
  .header {
    padding: 0 15px;

    .header-mobile-livechat {
      .v-icon {
        font-size: 25px;
      }

      .v-btn__content {
        font-size: 16px;
      }
    }
  }
  .mobile-brand-logo {
    margin-top: -10px;
    margin-left: -10px;
    width: 200px;
  }
  .profile-alert-success {
    font-size: 14px;
  }
  .profile-alert-failed {
    font-size: 14px;
  }

  .icon_btm_nav {
    width: 26px !important;
    height: 22px !important;
  }
}

@media (max-width: 425px) {
  .mobile-member-info-box {
    padding: 2px;
  }
  .header {
    .header-mobile-livechat {
      .v-icon {
        font-size: 22px;
      }

      .v-btn__content {
        font-size: 14px;
      }
    }
  }
  .mobile-navigator {
    a {
      .v-icon {
        font-size: 23px;
      }

      p {
        font-size: 11px;
      }
    }

    button {
      .v-icon {
        font-size: 28px;
      }

      p {
        font-size: 11px;
      }
    }
  }
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
</style>
