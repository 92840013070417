import { apiHelper } from '@/util'
import { SHARED } from '@/constants/constants'

/* SERVICE EXPORT FUNCTION NAME */
export const memberService = {
  changePassword,
  forgotPassword,
  getDetail,
  getWallet,
  getWithdrawStatus,
  login,
  logout,
  refreshToken,
  getSmsVerificationRequirements,
  sendContactVerificationRequest,
  verifyContactBySms,
  getRewardsUrl,
  verifyContact,
  getShortenedLink,
  register,
  resetPassword,
  sendContactVerificationEmail,
  updateMember,
  updateMemberEmail,
  validateToken,
  getVipProgress,
  getIplVipProgress,
  sendOTP,
  verifyOTP,
  getSpecifyWallet,
  checkUsername,
  getIplEventStatus,
  getTurnoverTicket,
  turnoverChecking,
  oneSignalPlayerIds,
  pushAdmillRegSucceed,
  addNewNumberContact,
  requestForgotPassword,
  verifyForgotPassword,
  updateForgotPassword,
  requestCaptchaCode,
  getVipTierProgress
}

/* SERVICE URL PATH */
const MEMBER = {
  COMMUNICATION: 'communication',
  DETAILS: 'details',
  INVALIDATE: 'invalidate',
  MEMBER: 'member',
  MOBILE_REQUIREMENTS: 'mobile/requirement',
  MOBILE_REQUEST: 'mobile/request',
  MOBILE_VERIFY: 'mobile/verify',
  SHORTCUT_LINK: 'shortcut',
  PASSWORD: 'password',
  REFRESH: 'refresh',
  RESET: 'reset',
  TOKEN: 'token',
  VALIDATE: 'validate',
  VERIFICATION: 'verification',
  WALLETS: 'wallets',
  WITHDRAW_STATUS: 'withdrawstatus',
  REWARDS: 'rewardsLogin',
  VIP_PROGRESS: 'vipProgress',
  IPLVIP_PROGRESS: 'vipEventProgress',
  SEND_OTP: 'sendOTP',
  VERIFY_OTP: 'verifyOTP',
  SPECIFIC_WALLET: 'specificWallet',
  CHECK_USERNAME: 'checkUsername',
  TURNOVER_LIST: 'turnover_ticket',
  RESTRICT_WITHDRAWAL: 'restrict_withdrawal',
  IPLEVENT_STATUS: 'ipl/eventDate',
  PLAYER_IDS: 'player_ids',
  ONE_SIGNAL: 'oneSignal',
  VIP_TIER_PROGRESS: 'vipTier/level/progress',
  ADD_NEW_NUMBER_CONTACT: 'mobile/contact/add',
  REQUEST_FORGOT_PASSWORD_OTP: 'reqFgtPsw',
  VERIFY_FORGOT_PASSWORD_OTP: 'verifyFgtPsw',
  UPDATE_FORGOT_PASSWORD: 'updateFgtPsw',
  REQUEST_CAPTCHA_CODE: 'requestCaptchaCode'
}

/* SERVICE FUNCTION */
function requestForgotPassword(data) {
  const requestBody = {
    mobile: data.mobileNumber,
    prefix: data.prefix,
    captcha_id: data.captchaId,
    captcha_code: data.captchaCode
  }
  return apiHelper().post(`/${MEMBER.MEMBER}/${MEMBER.REQUEST_FORGOT_PASSWORD_OTP}`, requestBody)
}

function verifyForgotPassword(data) {
  const requestBody = {
    mobile: data.mobileNumber,
    sms_token: data.smsToken,
    captcha_id: data.captchaId,
    captcha_code: data.captchaCode
  }
  return apiHelper().post(`/${MEMBER.MEMBER}/${MEMBER.VERIFY_FORGOT_PASSWORD_OTP}`, requestBody)
}

function updateForgotPassword(data) {
  const requestBody = {
    membercode: data.memberCode,
    new_password: data.newPassword,
    contact: data.contact
  }
  return apiHelper().patch(`/${MEMBER.MEMBER}/${MEMBER.UPDATE_FORGOT_PASSWORD}`, requestBody)
}
function pushAdmillRegSucceed(admillCookie, membercode) {
  let admillURL = 'https://track.adform.net/v1/sitetracking/2839906/trackingpoints/'
  let _body = [
    {
      name: 'Bhaggo_Login',
      identity: {
        cookieId: admillCookie
      },
      userContext: {
        userAgent: navigator.userAgent,
        userIp: '111.111.111.111'
      },
      variables: {
        orderid: membercode
      }
    }
  ]

  fetch(admillURL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(_body)
  })
    .then(res => {
      console.log('Adform reg succeed pixel post complete! response: ', res)
    })
    .catch(err => {
      console.log('Adform reg succeed  pixel post failed! err: ', err)
    })
}

function checkUsername(data) {
  if (data.memberCode && data.memberCode.trim() !== '') {
    const requestBody = {
      membercode: data.memberCode
    }
    return apiHelper().post(`/${MEMBER.MEMBER}/${MEMBER.CHECK_USERNAME}`, requestBody)
  } else {
    return { success: false, code: 'EMPTY_USERNAME' }
  }
}

function getSmsVerificationRequirements() {
  return apiHelper().get(`/${MEMBER.MOBILE_REQUIREMENTS}`)
}

function getRewardsUrl() {
  return apiHelper().post(`/${MEMBER.MEMBER}/${MEMBER.REWARDS}`)
}

function sendContactVerificationRequest(data) {
  const requestBody = {
    membercode: data.memberCode,
    mobile: data.mobileNumber,
    currency: data.currency,
    language: data.language,
    request_otp: data.request_otp,
    captcha_id: data.captchaId,
    captcha_code: data.captchaCode
  }
  return apiHelper().post(`/${MEMBER.MOBILE_REQUEST}`, requestBody)
}

function verifyContactBySms(data) {
  const requestBody = {
    verification_code: data.verificationCode,
    mobile: data.mobileNumber,
    memberCode: data.memberCode,
    captcha_id: data.captchaId,
    captcha_code: data.captchaCode
  }
  return apiHelper().post(`/${MEMBER.MOBILE_VERIFY}`, requestBody)
}

function getShortenedLink(guid) {
  return apiHelper().get(`/${MEMBER.SHORTCUT_LINK}/${guid}`)
}

function register(data) {
  const requestBody = {
    membercode: data.memberCode,
    password: data.password,
    currency: data.currency,
    email: data.email,
    registration_site: data.platform,
    mobile: data.mobileNumber,
    line: data.line,
    referral_code: data.referralCode,
    is_early_bird: data.isEarlyBird,
    domain: data.domain,
    language: data.language,
    reg_type: 2,
    agent_team: data.agent_team,
    utm_source: data.utm_source,
    utm_medium: data.utm_medium,
    utm_campaign: data.utm_campaign,
    s2: data.s2,
    fp: typeof data.fp == 'undefined' ? null : data.fp,
    c_id: data.cid
  }
  return apiHelper().post(`/${MEMBER.MEMBER}`, requestBody)
}

function login(data) {
  const requestBody = {
    membercode: data.memberCode,
    password: data.password,
    domain: data.domain,
    platform: data.platform,
    option: data.option,
    fp: data.fp
  }
  return apiHelper().post(`/${MEMBER.TOKEN}`, requestBody)
}

function validateToken() {
  return apiHelper().get(`/${MEMBER.TOKEN}/${MEMBER.VALIDATE}`)
}

function logout(data) {
  const requestOptions = {
    domain: data.domain,
    platform: data.platform
  }
  return apiHelper().post(`/${MEMBER.TOKEN}/${MEMBER.INVALIDATE}`, requestOptions)
}

function refreshToken() {
  return apiHelper().post(`/${MEMBER.TOKEN}/${MEMBER.REFRESH}`)
}

function getWallet() {
  return apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.WALLETS}`)
}

function getSpecifyWallet(wallets) {
  let promises = []
  wallets.forEach(w => {
    if (!w.is_locked && !w.is_maintenance) {
      promises.push(
        apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.SPECIFIC_WALLET}`, {
          params: { provider: w.wallet_code }
        })
      )
    }
  })
  return Promise.all(promises)
}

async function getDetail() {
  return apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.DETAILS}`)
}

function forgotPassword(data) {
  let contact = ''
  switch (data.contactType) {
    case SHARED.EMAIL:
      contact = data.email
      break
    case SHARED.MOBILE:
      contact = data.mobile
      break
  }
  const requestBody = {
    contact_type: data.contactType,
    membercode: data.memberCode,
    contact: contact,
    domain: data.domain,
    language: data.language
  }
  return apiHelper().post(`/${MEMBER.PASSWORD}/${MEMBER.COMMUNICATION}`, requestBody)
}

function changePassword(data) {
  const requestBody = {
    old_password: data.oldPassword,
    new_password: data.newPassword
  }
  return apiHelper().patch(`/${MEMBER.MEMBER}/${MEMBER.PASSWORD}`, requestBody)
}

function updateMember(data) {
  /* ONLY SUBMIT PASSED IN DATA */
  const requestBody = {
    surname: data.surname != undefined ? data.surname : undefined,
    name: data.name != undefined ? data.name : undefined,
    gender: data.gender != undefined ? data.gender : undefined,
    date_of_birth: data.dateOfBirth != undefined ? data.dateOfBirth : undefined,
    place_of_birth: data.nationality != undefined ? data.nationality : undefined,
    address: data.address != undefined ? data.address : undefined,
    country: data.country != undefined ? data.country : undefined,
    province: data.province != undefined ? data.province : undefined,
    postcode: data.postcode != undefined ? data.postcode : undefined,
    nationality: data.nationality != undefined ? data.nationality : undefined,
    email: data.email != undefined ? data.email.value : undefined,
    mobile: data.mobile != undefined ? data.mobile.value : undefined,
    line: data.line != undefined ? data.line.value : undefined
  }
  return apiHelper().patch(`/${MEMBER.MEMBER}/${MEMBER.DETAILS}`, requestBody)
}

function updateMemberEmail(email) {
  const requestBody = {
    email: email
  }
  return apiHelper().patch(`/${MEMBER.MEMBER}/${MEMBER.DETAILS}`, requestBody)
}

function sendContactVerificationEmail(data) {
  const requestBody = {
    type: data.type,
    value: data.value,
    language: data.language,
    domain: data.domain
  }
  return apiHelper().post(`/${MEMBER.MEMBER}/${MEMBER.VERIFICATION}/${MEMBER.COMMUNICATION}`, requestBody)
}

function resetPassword(data) {
  /* Replace all space with '+' because encryption limitation */
  const requestBody = {
    membercode: data.memberCode.replace(/\s/g, '+'),
    old_password: data.key.replace(/\s/g, '+'),
    new_password: data.newPassword
  }
  return apiHelper().post(`/${MEMBER.PASSWORD}/${MEMBER.RESET}`, requestBody)
}

function verifyContact(data) {
  /* Replace all space with '+' because encryption limitation */
  const requestBody = {
    membercode: data.memberCode.replace(/\s/g, '+'),
    otp: data.otp.replace(/\s/g, '+'),
    contact_type: data.type.replace(/\s/g, '+'),
    contact_value: data.contact.replace(/\s/g, '+')
  }
  return apiHelper().post(`/${MEMBER.VERIFICATION}`, requestBody)
}

function getWithdrawStatus() {
  return apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.WITHDRAW_STATUS}`)
}

function getVipProgress() {
  return apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.VIP_PROGRESS}`)
}

function getIplVipProgress() {
  return apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.IPLVIP_PROGRESS}`)
}

function getIplEventStatus() {
  return apiHelper().get(`/${MEMBER.IPLEVENT_STATUS}`)
}

function sendOTP(data) {
  const requestBody = {
    params: {
      membercode: data.memberCode,
      mobile: data.mobile,
      prefix: data.prefix
    }
  }
  return apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.SEND_OTP}`, requestBody)
}

function verifyOTP(data) {
  const requestBody = {
    membercode: data.memberCode,
    mobile: data.mobile,
    sms_token: data.smsToken
  }
  return apiHelper().post(`/${MEMBER.MEMBER}/${MEMBER.VERIFY_OTP}`, requestBody)
}

function getTurnoverTicket(data) {
  const requestBody = {
    params: {
      is_served: data.is_served
    }
  }
  return apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.TURNOVER_LIST}`, requestBody)
}

function turnoverChecking() {
  return apiHelper().post(`/${MEMBER.MEMBER}/${MEMBER.RESTRICT_WITHDRAWAL}`)
}

function oneSignalPlayerIds(data) {
  const requestBody = {
    player_ids: data.player_ids,
    is_subscribe: data.is_subscribe,
    permission_status: data.permission_status
  }
  return apiHelper().post(`/${MEMBER.ONE_SIGNAL}/${MEMBER.PLAYER_IDS}`, requestBody)
}
function getVipTierProgress() {
  return apiHelper().get(`/${MEMBER.VIP_TIER_PROGRESS}`)
}
function addNewNumberContact(data) {
  const requestBody = {
    verification_code: data.verification_code,
    mobile: data.mobileNumber,
    membercode: data.memberCode,
    required_only_primary_verified: true
  }
  return apiHelper().post(`/${MEMBER.ADD_NEW_NUMBER_CONTACT}`, requestBody)
}

function requestCaptchaCode(data) {
  const requestBody = {
    params: {
      captcha_id: data.captcha_id,
      captcha_code: data.captcha_code
    }
  }
  return apiHelper().get(`/${MEMBER.MEMBER}/${MEMBER.REQUEST_CAPTCHA_CODE}`, requestBody)
}
