import {apiHelper} from '@/util'
import {SESSION} from '@/constants/constants'

export const wheelService = {
    getWheel,
    getLogs,
    getChances,
    getMyLogs,
    spinWheel
}

//API urls
const WHEEL = {
    EVENT: '/jevent',
    CHANCES: '/my-chances',
    MYLOGS: '/my-logs',
    SPIN: '/spin'
}

function getWheel(filter) {
    const requestOptions = {
        params: {
            currency: filter.currency
        }
    }
    return apiHelper().get(`${WHEEL.EVENT}`, requestOptions)
}

function getLogs(filter) {
    const requestOptions = {
        params: {
            size: 50
        }
    }
    return apiHelper().get(`${WHEEL.EVENT}/${filter.id}`, requestOptions)
}

function getChances(filter) {
    return apiHelper().get(`${WHEEL.EVENT}/${filter.id}${WHEEL.CHANCES}`)
}

function getMyLogs(filter) {
    const requestOptions = {
        params: {
            size: 50
        }
    }
    return apiHelper().get(`${WHEEL.EVENT}/${filter.id}${WHEEL.MYLOGS}`, requestOptions)
}

function spinWheel(filter) {
    return apiHelper().post(`${WHEEL.EVENT}/${filter.id}${WHEEL.SPIN}`)
}

