import { locale } from './localization-helper'

export const errorCodeHelper = {
  getErrorCodeTitle,
  getErrorCodeDesc
}

function getErrorCodeTitle(code) {
  let errorCode = code.toString().replace(/\./g, '_')
  let errorTitle = ''

  errorTitle = locale.getMessage(`errorCodeTitle.${errorCode}`)

  return errorTitle
}

function getErrorCodeDesc(code) {
  let errorDescription = ''
  if(typeof code != "undefined"){
    let errorCode = code.toString().replace(/\./g, '_')
    errorDescription = locale.getMessage(`errorCodeDesc.${errorCode}`)
  }else{
    errorDescription = locale.getMessage(`errorCodeDesc.0`)
  }

  return errorDescription
}
