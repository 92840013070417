import { apiHelper } from '@/util'

export const referralService = {
  referralOverview,
  referralDownlines,
  referralReport,
  redeemCommission,
  referralConfig
}

//API urls
const REFERRAL = {
  REFERRAL: 'referral',
  OVERVIEW: 'overview',
  DOWNLINES: 'downlines',
  REPORT: 'report',
  COMMISSION: 'commission',
  CONFIG: 'config'
}

function referralOverview() {
    return apiHelper().get(`/${REFERRAL.REFERRAL}/${REFERRAL.OVERVIEW}`)
}

function referralDownlines() {
  return apiHelper().get(`/${REFERRAL.REFERRAL}/${REFERRAL.DOWNLINES}`)
}

function referralReport() {
  return apiHelper().get(`/${REFERRAL.REFERRAL}/${REFERRAL.REPORT}`)
}

function redeemCommission(data) {
  const requestBody = {
    amount: data.amount
  }
  return apiHelper().post(`/${REFERRAL.REFERRAL}/${REFERRAL.COMMISSION}`, requestBody)
}

function referralConfig() {
  return apiHelper().get(`/${REFERRAL.REFERRAL}/${REFERRAL.CONFIG}`)
}