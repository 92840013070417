<template>
    <v-col cols="12" :lg="3">
        <v-row no-gutters class="hidden-md-and-down">
            <v-col :lg="12" cols="6">
                <v-img src="/static/image/banner/product_home_banner/sb_home.png" :aspect-ratio="574/790">
                    <div class="pa-6">
                        <v-row no-gutters justify="space-between" align="center" class="pa-3">
                            <v-col cols="auto" :class="['py-0 title']">
                                {{ $t(`label.sb`) }}
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-btn outlined rounded
                                       class="px-6 sb_ply_now title text-uppercase font-weight-bold"
                                       large
                                       color="white" :to="{ name: routeName.SPORTSBOOK }">
                                    {{ $t(`label.playNow`) }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-img>
            </v-col>
<!--            <v-col :lg="12" cols="6">-->
<!--                <v-img src="/static/image/banner/product_home_banner/ld_home.png"-->
<!--                       :aspect-ratio="592/528">-->
<!--                    <div class="ld_holder">-->
<!--                        <v-row>-->
<!--                            <v-col class="display-1 py-0 text-capitalize">-->
<!--                                {{ $t(`label.ld`) }}-->
<!--                            </v-col>-->
<!--                        </v-row>-->
<!--                        <v-row>-->
<!--                            <v-col>-->
<!--                                <v-btn outlined rounded-->
<!--                                       class="px-6 sb_ply_now title text-uppercase font-weight-bold"-->
<!--                                       large-->
<!--                                       color="white" :to="{ name: routeName.CASINO }">-->
<!--                                    {{ $t(`label.playNow`) }}-->
<!--                                </v-btn>-->
<!--                            </v-col>-->
<!--                        </v-row>-->
<!--                    </div>-->
<!--                </v-img>-->
<!--            </v-col>-->
        </v-row>
        <v-row class="hidden-lg-and-up">
            <v-col :lg="12" cols="12" class="px-5 pt-0">
                <v-img src="/static/image/banner/product_home_banner/mobile_sport.png" :aspect-ratio="864/864">
                    <div>
                        <v-row no-gutters justify="space-between" align="center" class="pa-5">
                            <v-col cols="auto" class="headline py-0 text-capitalize mt-0">
                                {{ $t(`label.sb`) }}
                            </v-col>
                            <v-col cols="auto" >
                                <v-btn outlined rounded
                                       class="px-6 sb_ply_now title text-uppercase font-weight-bold mt-0"
                                       small
                                       color="white" :to="{ name: routeName.SPORTSBOOK }">
                                    {{ $t(`label.playNow`) }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-img>
            </v-col>
<!--            <v-col :lg="12" cols="12" class="px-5 pt-5">-->
<!--                <v-img src="/static/image/banner/product_home_banner/mobile_ld.png"-->
<!--                       :aspect-ratio="864/250">-->
<!--                    <div class="ld_holder">-->
<!--                        <v-row>-->
<!--                            <v-col class="title py-0 text-capitalize mt-0">-->
<!--                                {{ $t(`label.ld`) }}-->
<!--                            </v-col>-->
<!--                        </v-row>-->
<!--                        <v-row>-->
<!--                            <v-col>-->
<!--                                <v-btn outlined rounded-->
<!--                                       class="px-6 sb_ply_now title text-uppercase font-weight-bold mt-0"-->
<!--                                       small-->
<!--                                       color="white" :to="{ name: routeName.CASINO }">-->
<!--                                    {{ $t(`label.playNow`) }}-->
<!--                                </v-btn>-->
<!--                            </v-col>-->
<!--                        </v-row>-->
<!--                    </div>-->
<!--                </v-img>-->
<!--            </v-col>-->
        </v-row>
    </v-col>
</template>

<script>
    import {ROUTE_NAME} from '@/constants/route.constants'
    import {SHARED} from '@/constants/constants'

    export default {
        name: "SportCasinoHolder",
        data: () => ({
            routeName: ROUTE_NAME,
        })
    }
</script>

<style lang="scss">
    .sb_holder {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .ld_holder {
        position: absolute;
        top: 25%;
        left: 10%;
    }

    @media (max-width: 599px) {
        .ld_holder {
            position: absolute;
            top: 10%;
            left: 5%;
        }
    }

    .sb_ply_now.v-btn--outlined {
        border: 2px solid white;
    }

</style>