<template>
  <div class="page-navigator hidden-sm-and-down">
    <div class="page-navigator-item" v-for="gameType in storageGameProviderType" :key="gameType.type">
      <v-menu open-on-hover bottom offset-y min-width="100%" class="mt-2">
        <template v-slot:activator="{ on }">
          <v-btn class="pt-4 pb-2 px-0 page-navigator-button text-capitalize"
                 active-class="page-navigator-button--active" height="auto" text :ripple="false" light depressed dark
                 v-on="on" href :to="{
                      name:
                        gameType.type == 'sb'
                          ? routeName.SPORTSBOOK
                          : gameType.type == 'rng'
                          ? routeName.SLOT
                          : gameType.type == 'ld'
                          ? routeName.CASINO
                          : gameType.type == 'fishing'
                          ? routeName.FISHING
                          : gameType.type == 'special'
                          ? routeName.SPECIAL
                          : gameType.type == 'table'
                          ? routeName.TABLE_GAMES
                          : gameType.type == 'lottery'
                          ? routeName.LOTTERY
                          : gameType.type == 'cricket'
                          ? routeName.CRICKET
                          : gameType.type == 'crash'
                          ? routeName.CRASH
                          : routeName.HOME
                    }">
            <v-row>
              <v-col cols="12" class="pa-0 text-center">
                <label class="menu-navigator px-2">{{
                    $t(`label.${gameType.type}`)
                  }}</label>
              </v-col>
              <v-col cols="12" class="px-0 py-1 underline_bar">
                <v-divider class="mx-2 add_border_bold"></v-divider>
              </v-col>
            </v-row>
            <!--            <v-icon v-if="gameType.type != 'fishing' && gameType.type != 'special'">arrow_drop_down</v-icon>-->
            <!-- <v-divider
                                class="mx-3 gold-color"
                                vertical
                                style="border-width: 0 2px 0 0; max-height: 35px;"
                        ></v-divider> -->
          </v-btn>
        </template>
        <div class="page-sub-navigator elevation-2">
          <div class="page-sub-navigator-item" v-for="provider in gameType.providers" :key="provider.providerCode">
            <v-card flat class="elevation-0 mx-8 game_card text-center">
              <!-- <v-img class="item-image-icon mx-auto mb-2" contain
                     :src="`/static/image/game/icon/${provider.providerCode}.png`"></v-img> -->
              <div style="position:relative">
                <!-- <v-img :aspect-ratio="384/372" @click="accessGame(gameType.type, provider.providerCode)" class="item-image mx-auto" contain
                :src="`${mediaUrl}/navigator/${gameType.type}/${provider.providerCode}.png`> -->
                <v-avatar size="128" class="game_card_avatar mt-4"
                          @click="accessGame(gameType.type, provider.providerCode)">
                  <img
                      :src="`${mediaUrl}/menu/${gameType.type}/${provider.providerCode}.png`"
                      :alt="'bhagyo-'+ provider.providerCode"
                      @mouseover="submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, true)"
                      @mouseleave="submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, false)"
                  >
                </v-avatar>
                <!-- <v-col cols="12" :class="['text_hover', gameType.type + '_nav']">
                    {{$t(`gameVendor.${provider.providerCode}_long`)}}
                    <img :src="`${mediaUrl}/category_icons/new_icon.png`" v-if="provider.providerCode == 'one'" alt="BABU88 one game" width="45" />
                </v-col> -->
                <!-- </v-img> -->
                <div class="item-image item-maintenance" v-if="isGameMaintenance(gameType.type, provider.providerCode)">
                  <span class="item-maintenance-label white--text">{{ $t(`label.underMaintenance`) }}</span>
                </div>
              </div>
              <v-card-text class="white--text font-weight-bold vendor-text text-capitalize text-center">
                {{ stringFormat('{0}', $t(`gameVendor.${provider.providerCode}_${gameType.type}_long`)) }}
                <!-- {{subMenu[provider.providerCode]}} -->
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-menu>
    </div>
    <div class="page-navigator-item">
      <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark href
             :to="{ name: routeName.PROMOTION }">
        <v-row>
          <v-col cols="12" class="pa-0 text-center">
            <label class="px-2">{{ $t(`label.promotion`) }}</label>
          </v-col>
          <v-col cols="12" class="px-0 py-1 underline_bar">
            <v-divider class="mx-2 add_border_bold"></v-divider>
          </v-col>
        </v-row>
      </v-btn>
    </div>
    <div class="page-navigator-item">
      <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark href
             :to="{ name: routeName.REFERRAL_SUMMARY }">
        <v-row>
          <v-col cols="12" class="pa-0 text-center">
            <!-- <v-badge color="red accent-4" content="New"> -->
            <label class="px-2">{{ $t(`label.referral`) }}</label>
            <!-- </v-badge> -->
          </v-col>
          <v-col cols="12" class="px-0 py-1 underline_bar">
            <v-divider class="mx-2 add_border_bold"></v-divider>
          </v-col>
        </v-row>
      </v-btn>
    </div>
    <div class="page-navigator-item">
      <v-badge right overlap color="light" offset-x="11" offset-y="4">
        <template v-slot:badge>
          <v-img src="/static/svg/game-icon-new.svg"></v-img>
        </template>
        <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark href
               :to="{ name: routeName.VIP }">
          <v-row>
            <v-col cols="12" class="pa-0 text-center">
              <label class="px-2">{{ $t(`label.vip`) }}</label>
            </v-col>
            <v-col cols="12" class="px-0 py-1 underline_bar">
              <v-divider class="mx-2 add_border_bold"></v-divider>
            </v-col>
          </v-row>
        </v-btn>
      </v-badge>
    </div>
    <div class="page-navigator-item" v-if="isIPLEventActive">
      <v-badge right overlap color="light" offset-x="11" offset-y="4">
        <template v-slot:badge>
          <v-img src="/static/svg/game-icon-new.svg"></v-img>
        </template>
        <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark href
               :to="{ name: routeName.IPLVIP }">
          <v-row>
            <v-col cols="12" class="pa-0 text-center">
              <label class="px-2">{{ $t(`label.iplVIP`) }}</label>
            </v-col>
            <v-col cols="12" class="px-0 py-1 underline_bar">
              <v-divider class="mx-2 add_border_bold"></v-divider>
            </v-col>
          </v-row>
        </v-btn>
      </v-badge>
    </div>
  </div>
</template>

<script>
import format from 'string-format'
import {
  CACHE_KEY
} from '@/constants/constants'
import {
  uiHelper
} from '@/util'
import {
  ROUTE_NAME
} from '@/constants/route.constants'
import {
  GAME_LAUNCH_GAME,
  GAME_RESET_LAUNCH_GAME_RESPONSE,
  GAME_CASINO_GAMES
} from '@/store/game.module'
import {
  SHARED
} from "@/constants/constants";
import {
  SEMI_TRANSFER
} from '@/store/transfer.module'
import {
  GET_IPL_EVENT_STATUS
} from '@/store/member.module'

export default {
  name: 'AppPageNavigator',
  data: () => ({
    regionLocale: uiHelper.getRegionLocale(),
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    routeName: ROUTE_NAME,
    stringFormat: format,
    test: [],
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    hover: {},
    isIplActive: true
  }),
  created() {

  },
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    isIPLEventActive() {
      return this.$store.state.member.iplEventDate.status;
    }
  },
  watch: {
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },

  },
  methods: {
    redirectHome() {
      this.$router.push({
        name: ROUTE_NAME.HOME,
        params: {
          lang: uiHelper.getRegionLocale()
        }
      })
    },
    submenuOnMouseOver(element, gameType, providerCode, mouseover) {
      if (mouseover) {
        element.src = format('{0}/menu/{1}/{2}_hover.png', this.mediaUrl, gameType, providerCode);
      } else {
        element.src = format('{0}/menu/{1}/{2}.png', this.mediaUrl, gameType, providerCode);
      }
    },
    navigateToLuckySpin() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'luckyspin'
        }
      })
    },
    isGameMaintenance(gameType, providerCode) {
      if (
          this.storageGameProviderType == null ||
          this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
          this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance
      }
    },
    async getCasinoProvidersGames() {
      let Obj = {
        currency: uiHelper.getCurrency(),
        type: 'ld',
        size: '',
        page: '',
        platform: uiHelper.getPlatform(),
        category: '',
        provider: '',
        mode: 'All'
      }
      await this.$store.dispatch(`${GAME_CASINO_GAMES}`, {
        Obj
      })
    },
    async launchCasinoGame(type, provider) {
      this.$store.dispatch(`${GAME_RESET_LAUNCH_GAME_RESPONSE}`)
      this.gameUrl = ''
      if (this.Games.find(x => x.provider == provider) != null) {
        let game = this.Games.find(x => x.provider == this.$route.params.provider)
        let gameObj = {
          provider: game.provider,
          gameId: game.id,
          gameCode: game.code,
          isDemo: !this.isLoggedIn,
          platform: uiHelper.getPlatform(),
          language: uiHelper.getLanguage(),
          webSite: uiHelper.getHostname(),
          currency: uiHelper.getCurrency(),
          gameType: game.type
        }
        await this.$store.dispatch(`${GAME_LAUNCH_GAME}`, {
          gameObj
        })
      } else {
        await this.getCasinoProvidersGames()
        this.launchGame()
      }
    },
    async accessGame(type, providerCode) {
      let gaType = ''
      switch (type) {
        case 'sb':
          gaType = 'Sportsbook'
          break
        case 'fishing':
          gaType = 'Fishing'
          break
        case 'ld':
          gaType = 'Casino'
          break
        case 'special':
          gaType = 'Special'
          break
        case 'cricket':
          gaType = 'Cricket'
          break
        case 'crash':
          gaType = 'crash'
          break
      }
      if (type == 'rng') {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'lottery') {
        this.$router.push({
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'table') {
        this.$router.push({
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'special') {
        this.$router.push({
          name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'cricket') {
        this.$router.push({
          name: ROUTE_NAME.CRICKET_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'crash') {
        this.$router.push({
          name: ROUTE_NAME.CRASH_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else {
        if (this.isLoggedIn) {
          let routeData = this.$router.resolve({
            name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
            params: {
              provider: providerCode,
              type: type,
              lang: this.$route.params.lang
            },
            query: {
              demo: 0
            }
          })
          try {
            this.$ga.event('Game', 'Real Play', `${providerCode.toUpperCase()} - ${gaType}`)
          } catch (err) {
            console.log(err)
          }
          // uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1200, 900)
          if (this.providerSelected != 'sb') {
            this.semiTransfer(providerCode).then(() => {
              uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
            })
          } else {
            await uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
            this.semiTransfer(providerCode)
          }
        } else {
          this.$parent.openLoginDialog()
        }
      }
    },
    semiTransfer(providerCode) {
      let obj = {
        to_wallet: providerCode
      }
      return this.$store.dispatch(`${SEMI_TRANSFER}`, {obj})
    }
  }
}
</script>

<style>
.v-menu__content {
  top: 206px;
}
</style>

<style lang="scss">
.rng_nav {
  position: absolute;
  bottom: 15px;
}

.ld_nav {
  position: absolute;
  bottom: 0;
}

.sb_nav {
  position: absolute;
  bottom: -15px;
}

.add_border_bold {
  border: 3px solid;
  border-color: #fff !important;
}

.underline_bar {
  opacity: 0;
}

.page-navigator {
  padding: 15px 55px;
  display: table;
  width: 100%;
  color: black;
  height: auto; //background-image: linear-gradient(to bottom, #2D3387, #24296D, #121734);
  background-color: black;
  text-align: left;

  .page-navigator-item {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    padding-right: 40px;

    .page-navigator-button {
      color: #FDDD04; // caret-color: #9C9FF3 !important;
      text-transform: capitalize !important;

      label {
        cursor: pointer;
      }

      &.v-btn--active {
        color: white !important;

        &::before {
          opacity: 0;
        }

        .underline_bar {
          opacity: 1;
        }
      }

      &:hover {
        color: white !important;
        // caret-color: var(--v-navigator_active-base) !important;
        // &::before {
        //     opacity: 0;
        // }
        // .underline_bar {
        //     opacity: 1;
        // }
      }
    }

    label {
      letter-spacing: 0.36px !important;
      font-size: 18px;
    }
  }
}

.page-sub-navigator {
  display: table;
  width: 100%;
  text-align: left;
  background-color: black !important;
  opacity: 0.9 !important;

  .page-sub-navigator-item {
    display: inline-block;
    vertical-align: middle;
    width: 250px;

    .game_card {
      background-color: transparent;

      .game_card_avatar {
        &:hover {
          //opacity: 50%;
          // border: 2px;
          // border-color: #FDDD04;
          cursor: pointer
        }
      }
    }

    // &:hover>.game_card {
    //     margin: 20px 0;
    //     border-radius: 10px;
    // }
    &:hover .text_hover {
      color: #9C9FF3;
    }

    &:hover .item-image {
      opacity: 1.0;
    }

    .vendor-text {
      font-size: 16px;
      padding-top: 4px !important;
      padding: 4px 0px 10px !important;
    }

    .v-card {
      padding: 16px 0;
    }

    .item-image {
      max-width: 250px;
      cursor: pointer;
      opacity: 0.5;
    }

    .item-maintenance {
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      opacity: 1;
      position: absolute;
      z-index: 1;
      border-radius: 25px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .item-maintenance-label {
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-weight: bold;
      }
    }

    .item-image-icon {
      height: 20px;
    }
  }
}

@media (max-width: 1650px) {
}

@media (max-width: 1500px) {
  .page-sub-navigator .page-sub-navigator-item .v-card {
    padding-top: 12px;
  }
  .page-sub-navigator .page-sub-navigator-item {
    width: 250px;
  }
  .page-sub-navigator .page-sub-navigator-item .item-image {
    max-width: 225px;
  }
}

@media (max-width: 1350px) {
  .page-navigator .page-navigator-item label {
    font-size: 18px;
  }
}

@media (max-width: 1263px) {
  .page-navigator .page-navigator-item {
    margin: 0 auto;
  }
  .page-sub-navigator .page-sub-navigator-item {
    width: 210px;
  }
  .page-sub-navigator .page-sub-navigator-item .item-image {
    max-width: 195px;
  }
  .page-sub-navigator {
    .page-sub-navigator-item {
      .vendor-text {
        font-size: 12px;
        padding-bottom: 4px !important;
      }
    }
  }
  .page-sub-navigator .page-sub-navigator-item .item-image-icon {
    height: 18px;
  }
}

@media (max-width: 1024px) {
  .page-navigator {
    padding: 10px 30px;

    .page-navigator-item {
      padding-right: 25px;
      font-size: 13px;
    }
  }
}
</style>
