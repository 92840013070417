var DevicePlatform = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  MOBILEIOS: 'ios',
  APP: 'app',
  POPUP: 'popup',
  POPUPLOGIN: 'login'
}

export { DevicePlatform }
