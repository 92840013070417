<template>
  <div>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="1000" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="mdi-close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <div class="game-banner">
      <!-- <v-carousel class="banner" cycle delimiter-icon="panorama_fish_eye" :show-arrows="false" interval="10000" height="auto">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
              <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner.action)" :contain="true" :aspect-ratio="3"></v-img>
          </v-carousel-item>
      </v-carousel> -->
      <v-img class="align-end" :aspect-ratio="360 / 140" :src="`/static/image/background/vip_banner_${language}.jpg`"></v-img>
      <!--      <v-img v-else class="align-end" :aspect-ratio="1800 / 350"-->
      <!--      v-if="$vuetify.breakpoint.xsOnly"        :src="`/static/image/background/vip_banner_${language}.png`">-->
      <!--      </v-img>-->
    </div>
    <div>
      <h2 class="viptitle">{{ $t(`label.myCurrentLvl`) }}</h2>
      <div class="vip-tier">
        <div class="pb-5">
          <div class="mini-icons-wrapper">
            <v-avatar v-for="tier in tiers" :key="tier.name" tile class="tier-all-png">
              <!--              <v-img-->
              <!--                  class="tier-mini-icon"-->
              <!--                  :src="`/static/image/vipassets/Lavel-${tier.name}.png`"-->
              <!--                  :class="currentLvSequence === tier.sequence ? 'enlarged-img' : ''"-->
              <!--                  style="order: 1;"-->
              <!--              ></v-img>-->
              <img :src="`/static/image/vipassets/Lavel-${tier.name}.png`" :class="currentLvSequence === tier.sequence ? 'enlarged-img' : ''" class="tier-mini-icon" style="order: 1;" />
              <!--              <p class="linesmobile" style="order: 2;">|</p>-->
              <div class="mini-icon-desc-line" :class="currentLvSequence === tier.sequence ? 'selected-line' : ''"></div>
              <div class="mini-icon-lvl-desc">{{ $t(`label.level_noun`) }} {{ tier.localizedName }}</div>
            </v-avatar>
          </div>
          <v-progress-linear class="mt-2 custom-vip-tier-progress" width="95%" height="10" color="#d9d9d9" :value="currentProgress" rounded>
            <template v-slot:progress>
              <div v-for="(progress, index) in progressLevels" :key="index" :class="['v-progress-linear__progress__segment', 'tier-' + progress.toLowerCase()]">
                {{ progress }}
              </div>
            </template>
          </v-progress-linear>
        </div>
        <div class="climb-up-text">
          <h2 class="headertext">{{ $t(`label.climbUpTitleRow1`) }}</h2>
          <h2 class="headertext">
            <span class="climb-up-text-special-text">{{ $t(`label.climbUpTitleSpecialText`) }}</span>
            {{ $t(`label.climbUpTitleRow2`) }}
          </h2>
          <p class="text-center climb-up-des-text">{{ $t(`label.climbUpDesRow1`) }}</p>
          <p class="text-center climb-up-des-text">
            {{ $t(`label.climbUpDesRow2`) }}
            <span class="font-weight-bold">{{ $t(`label.climbUpTitleSpecialText`) }}</span>
            {{ $t(`label.climbUpDes2Row2`) }}
          </p>
          <!--          <p>5. {{ $t(`label.howtojoindes5`) }}</p>-->
        </div>
      </div>
      <div style="background-color: #fff">
        <v-row no-gutters class="MB-row">
          <v-col class="MB-left-col pt-3" style="display: flex; align-items: center;">
            <v-row no-gutters>
              <p class="text-covered">{{ $t(`label.vipprivileges`) }}</p>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="py-5" no-gutters style="background-color: #FEFCD7;">
          <v-col cols="12" class="MB-right-col d-flex align-center justify-center">
            <v-row class="justify-center align-center" style="max-width: 100% !important;">
              <!--              <v-col cols="1">-->
              <!--                <v-btn @click="showPreviousLevel" class="btnleft">-->
              <!--                  <img src="/static/image/vipassets/left-btn.png" style=" background-color: transparent;" width="10" height="15" />-->
              <!--                </v-btn>-->
              <!--              </v-col>-->
              <v-col cols="12" class="justify-center d-flex align-center pa-0">
                <v-col cols="3" class="pa-0">
                  <v-avatar tile class="tierpng grayscale-effect">
                    <!--            <v-img v-if="memberVipTierProgress && memberVipTierProgress.image" :src="`${memberVipTierProgress.image}`"></v-img>-->
                    <!--            <v-img v-else :src="`/static/svg/vipassets/vip_${tierColorThemeName}.svg`" eager></v-img>-->
                    <v-img :src="`/static/image/vipassets/Lavel-${getLevelTextBefore()}.png`" eager></v-img>
                  </v-avatar>
                </v-col>

                <v-btn @click="showPreviousLevel" class="btnleft" style="padding: 0 8px;">
                  <img src="/static/image/vipassets/left-btn.png" style=" background-color: transparent;" width="15" height="25" />
                </v-btn>
                <v-col cols="3" class="pa-0">
                  <v-avatar tile class="tierpng">
                    <!--            <v-img v-if="memberVipTierProgress && memberVipTierProgress.image" :src="`${memberVipTierProgress.image}`"></v-img>-->
                    <!--            <v-img v-else :src="`/static/svg/vipassets/vip_${tierColorThemeName}.svg`" eager></v-img>-->
                    <v-img :src="`/static/image/vipassets/Lavel-${getLevelTextExact()}.png`" eager></v-img>
                  </v-avatar>
                </v-col>

                <v-btn @click="showNextLevel" class="btnright" style="padding: 0 8px;">
                  <img src="/static/image/vipassets/right-btn.png" style="background-color: transparent" width="15" height="25" />
                </v-btn>
                <v-col cols="3" class="pa-0">
                  <v-avatar tile class="tierpng grayscale-effect">
                    <!--            <v-img v-if="memberVipTierProgress && memberVipTierProgress.image" :src="`${memberVipTierProgress.image}`"></v-img>-->
                    <!--            <v-img v-else :src="`/static/svg/vipassets/vip_${tierColorThemeName}.svg`" eager></v-img>-->
                    <v-img :src="`/static/image/vipassets/Lavel-${getLevelTextAfter()}.png`" eager></v-img>
                  </v-avatar>
                </v-col>
              </v-col>
              <!--              <v-col cols="1">-->
              <!--                <v-btn @click="showNextLevel" class="btnright ">-->
              <!--                  <img src="/static/image/vipassets/right-btn.png" style="background-color: transparent" width="10" height="15" />-->
              <!--                </v-btn>-->
              <!--              </v-col>-->
              <!--          <div>-->
              <!--            viptiers badge-->
              <!--          </div>-->
            </v-row>
            <!--        <v-row>-->
            <!--          <br/>-->
            <!--          <span style="text-transform: uppercase;text-align: center">{{tierName}}</span>-->
            <!--        </v-row>-->
          </v-col>
          <v-row no-gutters>
            <v-col cols="12" class="text-center currentLevel">{{ $t(`label.level_noun`) }} {{ combinedText }}</v-col>
          </v-row>
        </v-row>
      </div>
      <div style="background-color: #fff">
        <v-row no-gutters class="MB-row">
          <v-col class="MB-left-col py-5" style="display: flex; align-items: center;">
            <v-row no-gutters>
              <p class="text-covered mb-0">{{ $t(`label.vipbenefits`) }}</p>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters style="background-color: #F0F0F0">
          <v-col cols="6" class="lefttext">
            {{ $t(`label.cashPrize`) }}
          </v-col>
          <v-col cols="6" class="righttext">
            {{ cashPrize }}
          </v-col>
        </v-row>
        <v-row no-gutters style="background-color: #D4D4D4">
          <v-col cols="6" class="lefttext">
            {{ $t(`label.coinsLabel`) }}
          </v-col>
          <v-col cols="6" class="righttext">
            {{ coinPrize }}
          </v-col>
        </v-row>
        <!--        <table class="custom_table" style="width: 100%">-->
        <!--          <tbody>-->
        <!--            <tr style="background-color: #F0F0F0">-->
        <!--              <td class="lefttext noborder">{{ $t(`label.cashPrize`) }}</td>-->
        <!--              <td class="righttext noborder">{{ slotpercentage }}</td>-->
        <!--            </tr>-->
        <!--            <tr style="background-color: #D4D4D4">-->
        <!--              <td class="lefttext noborder">{{ $t(`label.coins`) }}</td>-->
        <!--              <td class="righttext noborder">{{ casinopercentage }}</td>-->
        <!--            </tr>-->
        <!--          </tbody>-->
        <!--        </table>-->
      </div>
      <div style="background-color: #fff">
        <v-row no-gutters class="MB-row">
          <v-col class="MB-left-col py-5" style="display: flex; align-items: center;">
            <v-row no-gutters>
              <p class="text-covered mb-0">{{ $t(`label.upgradereq`) }}</p>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters style="background-color: #F0F0F0">
          <v-col cols="6" class="lefttext">
            {{ $t(`label.lifeTimeDepo`) }}
          </v-col>
          <v-col cols="6" class="righttext">
            {{ lifeTimeDepoRequired }}
          </v-col>
        </v-row>
        <v-row no-gutters style="background-color: #D4D4D4">
          <v-col cols="6" class="lefttext">
            {{ $t(`label.lifeTimeTO`) }}
          </v-col>
          <v-col cols="6" class="righttext">
            {{ lifeTimeTORequired }}
          </v-col>
        </v-row>
      </div>
      <div class="vip-tier">
        <div class="how-to-join-text">
          <h2 class="headertext py-3" style="border-bottom: 1px solid;">{{ $t(`label.howToJoin`) }}</h2>
          <!--          <h1 class="headertext">-->
          <!--            <span class="climb-up-text-special-text">{{ $t(`label.climbUpTitleSpecialText`) }}</span>-->
          <!--            {{ $t(`label.climbUpTitleRow2`) }}-->
          <!--          </h1>-->
          <p class="climb-up-des-text pa-5 ma-0">
            {{ $t(`label.howToJoinDes1`) }}
            <span class="font-weight-bold">{{ $t(`label.howToJoinDes1s`) }}</span>
          </p>
          <p class="climb-up-des-text pa-5 ma-0">{{ $t(`label.howToJoinDes2`) }}</p>
          <!--          <p>5. {{ $t(`label.howtojoindes5`) }}</p>-->
        </div>
      </div>
      <div class="faq-tnc-container">
        <v-btn class="tncbtn" width="100%" @click="toggleTnc" color="primary">
          <span class="faqspan">{{ $t(`label.tnctitle`) }}</span>
          <div :class="['triangle', showTnc ? 'down' : 'up']"></div>
        </v-btn>
        <v-expand-transition>
          <div v-if="showTnc" style="padding-top: 10px; padding-bottom: 20px">
            <ul>
              <li class="pp">{{ $t(`label.tncviptier1`) }}</li>
              <li class="pp">{{ $t(`label.tncviptier2`) }}</li>
              <li class="pp">{{ $t(`label.tncviptier3`) }}</li>
              <li class="pp">{{ $t(`label.tncviptier4`) }}</li>
              <li class="pp">{{ $t(`label.tncviptier5`) }}</li>
              <li class="pp">{{ $t(`label.tncviptier6`) }}</li>
              <li class="pp">{{ $t(`label.tncviptier7`) }}</li>
            </ul>
          </div>
        </v-expand-transition>
        <v-btn class="faqbtn" width="100%" @click="toggleFaq" color="primary">
          <span class="faqspan">{{ $t(`label.faqtitle`) }}</span>
          <div :class="['triangle', showFaq ? 'down' : 'up']"></div>
        </v-btn>
        <v-expand-transition>
          <div v-if="showFaq" style="padding-top: 10px; padding-bottom: 20px">
            <p class="pp font-weight-bold">{{ $t(`label.faqviptier1`) }}</p>
            <p class="pp">{{ $t(`label.faqviptier1des`) }}</p>
            <p class="pp font-weight-bold">{{ $t(`label.faqviptier2`) }}</p>
            <p class="pp">{{ $t(`label.faqviptier2des`) }}</p>
            <p class="pp font-weight-bold">{{ $t(`label.faqviptier3`) }}</p>
            <p class="pp">{{ $t(`label.faqviptier3des`) }}</p>
            <p class="pp font-weight-bold">{{ $t(`label.faqviptier4`) }}</p>
            <p class="pp">{{ $t(`label.faqviptier4des`) }}</p>
            <p class="pp font-weight-bold">{{ $t(`label.faqviptier5`) }}</p>
            <p class="pp">{{ $t(`label.faqviptier5des`) }}</p>
          </div>
        </v-expand-transition>
        <!--    </div>-->
        <!--    <div class="tnc-container">-->
      </div>
    </div>
    <app-seo-content></app-seo-content>
    <!--    <div v-else>-->
    <!--      <div class="vip-tierdesktop">-->
    <!--        <h1 class="titledesktop">{{ $t(`label.benefitasvip`) }}</h1>-->
    <!--        <v-container style="max-width: 1900px;">-->
    <!--          <v-row no-gutters class="MB-row Height-Vip" >-->
    <!--            <v-col cols="3" class="MB-left-col" style="display: flex; align-items: center;">-->

    <!--              <v-row no-gutters class="justify-center">-->
    <!--                <img src="/static/svg/vipassets/vipcard_asset.png" style="max-width: 90%;" />-->
    <!--              </v-row>-->
    <!--            </v-col>-->
    <!--            <v-col cols="9" class="MB-right-col d-flex align-center" style="height: 200px;">-->
    <!--              <v-row>-->
    <!--                <v-col cols="12">-->
    <!--                  <p class="title2">{{ $t(`label.currentstatus`) }}</p>-->
    <!--                </v-col>-->
    <!--                <v-col cols="12">-->
    <!--                  <v-progress-linear style=" border-radius: 10px;" class="mt-3" height="18" color="#F26A21" :value="DesktopcurrentProgress" rounded>-->
    <!--                    <template v-slot:progress>-->
    <!--                      <div v-for="(progress, index) in progressLevels" :key="index" :class="['v-progress-linear__progress__segment', 'tier-' + progress.toLowerCase()]">-->
    <!--                        {{ progress }}-->
    <!--                      </div>-->
    <!--                    </template>-->
    <!--                  </v-progress-linear><br/>-->
    <!--                </v-col>-->
    <!--                <v-col cols="12" class="align-center d-flex justify-center">-->
    <!--                  <v-col cols="2" class="align-center d-flex justify-center pa-0" v-for="tier in tiers" :key="tier.name">-->
    <!--                    <v-avatar class="tier-all-png-desktop">-->
    <!--                      <p class="lines" style="order: 1;">|</p>-->
    <!--                      <v-img :src="`/static/svg/vipassets/${tier.color}.png`" class="tier-img" style="order: 2; height: auto; width: 110px;"></v-img>-->
    <!--                    </v-avatar>-->
    <!--                  </v-col>-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-container>-->
    <!--      </div>-->
    <!--      <v-col cols="12" style="padding-left: 8% !important; padding-right: 8% !important; background-color: #060936;">-->
    <!--        <v-divider class="custom-divider"></v-divider>-->
    <!--      </v-col>-->
    <!--      <div class="vip-tierdesktop">-->
    <!--        <v-row no-gutters class="MB-row" style="height: auto;">-->
    <!--          <v-col cols="3" class="MB-left-col pr-4" style="display: flex; align-items: center;">-->

    <!--            <v-row no-gutters>-->
    <!--              <p style="font-size: 18px; color: #FF9E22;padding-left: 20px;">{{ $t(`label.vipprivileges`) }}</p>-->
    <!--            </v-row>-->
    <!--          </v-col>-->
    <!--          <v-col cols="9" class="MB-right-col d-flex align-center">-->
    <!--            <v-col cols="2" class="align-center d-flex justify-center pa-0" v-for="tier in tiers" :key="tier.name">-->
    <!--              <v-avatar class="vip-all-tier-desktop">-->
    <!--                <v-img :src="`/static/svg/vipassets/vip_${tier.color}.png`" style="order: 2;"></v-img>-->
    <!--              </v-avatar>-->
    <!--            </v-col>-->
    <!--          </v-col>-->
    <!--        </v-row>-->
    <!--        <div class="vip-benefits">-->
    <!--          <p class="titlewithbackgroundcolor">{{ $t(`label.vipbenfits`) }}</p>-->
    <!--          <table class="custom_table" style="width: 100%">-->
    <!--            <tbody>-->
    <!--            <tr>-->
    <!--              <td class="lefttextdesktop noborder">{{ $t(`label.slotrebate`) }}</td>-->
    <!--              <td  v-for="percentage in slotpercentages" class="righttextdesktop noborder">{{ percentage }}</td>-->
    <!--            </tr>-->
    <!--            <tr>-->
    <!--              <td class="lefttextdesktop noborder">{{ $t(`label.casinorebate`) }}</td>-->
    <!--              <td  v-for="percentage in casinopercentages" class="righttextdesktop noborder">{{ percentage }}</td>-->
    <!--            </tr>-->
    <!--            <tr>-->
    <!--              <td class="lefttextdesktop noborder">{{ $t(`label.sportrebate`) }}</td>-->
    <!--              <td  v-for="percentage in sportpercentages" class="righttextdesktop noborder">{{ percentage }}</td>-->
    <!--            </tr>-->
    <!--            <tr>-->
    <!--              <td class="lefttextdesktop noborder">{{ $t(`label.depositrebate`) }}</td>-->
    <!--              <td  v-for="percentage in depositpercentages" class="righttextdesktop noborder">{{ percentage }}</td>-->
    <!--            </tr>-->
    <!--            </tbody>-->
    <!--          </table>-->
    <!--        </div>-->
    <!--        <div class="upgrade-requirement">-->
    <!--          <p class="titlewithbackgroundcolor">{{ $t(`label.upgradereq`) }}</p>-->
    <!--          <table class="custom_table" style="width: 100%">-->
    <!--            <tbody>-->
    <!--            <tr>-->
    <!--              <td class="lefttextdesktop noborder">{{ $t(`label.upgradedepreq`)}}</td>-->
    <!--              <td  v-for="percentage in depositrequirements" class="righttextdesktop noborder">{{ percentage }}</td>-->
    <!--            </tr>-->
    <!--            <tr>-->
    <!--              <td class="lefttextdesktop noborder">{{ $t(`label.upgradeturnreq`)}}</td>-->
    <!--              <td  v-for="percentage in turnoverrequirements" class="righttextdesktop noborder">{{ percentage }}</td>-->
    <!--            </tr>-->
    <!--            <tr>-->
    <!--              <td class="lefttextdesktop noborder">{{ $t(`label.upgradebonus`)}}</td>-->
    <!--              <td  v-for="percentage in upgradebonuses" class="righttextdesktop noborder">{{ percentage }}</td>-->
    <!--            </tr>-->
    <!--            </tbody>-->
    <!--          </table>-->
    <!--        </div>-->
    <!--        <div class="retention-requirement ">-->
    <!--          <p class="titlewithbackgroundcolor">{{ $t(`label.rententionreq`) }}</p>-->
    <!--          <table class="custom_table" style="width: 100%">-->
    <!--            <tbody>-->
    <!--            <tr>-->
    <!--              <td class="lefttextdesktop noborder">{{ $t(`label.downcriteria`)}}</td>-->
    <!--              <td v-for="percentage in downgradecriterias" class="righttextdesktop noborder">{{ percentage }}</td>-->
    <!--            </tr>-->
    <!--            </tbody>-->
    <!--          </table>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-col cols="12" style="padding-left: 8% !important; padding-right: 8% !important; background-color: #060936;">-->
    <!--        <v-divider class="custom-divider"></v-divider>-->
    <!--      </v-col>-->
    <!--      &lt;!&ndash;      <div class="be-nagad88-vip">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <h1 style="    font-size: 18px;justify-content: center;display: flex; color: #FF9E22; padding-bottom: 20px; text-align: center;">Be A NAGAD88 VIP</h1>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <p>Nagad88 is an online entertainment brand and a leading specialist of cricket exchange platform in Bangladesh.</p>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <p>With over 100 games mixed with remarkable sromotions and 24/7 customer support services.</p>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <p>There are multiple deposit payment methods for Bangladesh such as BKASH, ROCKET, NAGAD and many more to come. Be a Nagad88 VIP and enjoy generous benefits all time long!</p>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <v-col cols="12" style="padding: 0px !important; background-color: #0a0637;">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <v-divider class="custom-divider"></v-divider>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </v-col>&ndash;&gt;-->
    <!--      <div class="vip-tierdesktop" style="padding-left: 7% !important; padding-right: 7% !important;">-->
    <!--        <div class="vip-level-upgrade-info">-->
    <!--          <div class="vip-level-upgrade-info-inner-desktop">-->
    <!--            <div class="infospace">-->
    <!--              <h1 style=" font-size: 18px;color: #FF9E22; text-align: left; padding-bottom: 20px;">{{ $t(`label.viplevelupgrade`) }}</h1>-->
    <!--              <p style=" color:orange; text-align: left;">{{ $t(`label.howtojointitle`) }}</p>-->

    <!--              <p>1. {{ $t(`label.howtojoindes1`) }}</p>-->
    <!--              <p>-->
    <!--                2. {{ $t(`label.howtojoindes2`) }}-->
    <!--              </p>-->
    <!--              <p>3. {{ $t(`label.howtojoindes3`) }}</p>-->
    <!--              <p>-->
    <!--                4. {{ $t(`label.howtojoindes4`) }}-->
    <!--              </p>-->
    <!--              &lt;!&ndash;            <p>5. {{ $t(`label.howtojoindes5`) }}</p>&ndash;&gt;-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-col cols="12" style="padding-left: 8% !important; padding-right: 8% !important; background-color: #060936;">-->
    <!--        <v-divider class="custom-divider"></v-divider>-->
    <!--      </v-col>-->
    <!--      <div class="vip-tierdesktop" style="padding-left: 7% !important; padding-right: 7% !important;">-->
    <!--        <div class="faq-tnc-container" style="display: flex; justify-content: space-between;">-->
    <!--          <div class="leftsidefaq">-->
    <!--            <h2 style="font-size: 18px;display: flex; color: #FF9E22; padding-bottom: 20px;">{{ $t(`label.faqtitle`) }}</h2>-->
    <!--            <p class="pp">1. {{ $t(`label.faqviptier1`) }}</p><p class="pp">{{ $t(`label.faqviptier1des`) }}</p>-->
    <!--            <p class="pp">2. {{ $t(`label.faqviptier2`) }}</p><p class="pp">{{ $t(`label.faqviptier2des`) }}</p>-->
    <!--            <p class="pp">3. {{ $t(`label.faqviptier3`) }}</p><p class="pp">{{ $t(`label.faqviptier3des`) }}</p>-->
    <!--            <p class="pp">4. {{ $t(`label.faqviptier4`) }}</p><p class="pp">{{ $t(`label.faqviptier4des`) }}</p>-->
    <!--            <p class="pp">5. {{ $t(`label.faqviptier5`) }}</p><p class="pp">{{ $t(`label.faqviptier5des`) }}</p>-->
    <!--            <p class="pp">6. {{ $t(`label.faqviptier6`) }}</p><p class="pp">{{ $t(`label.faqviptier6des`) }}</p>-->
    <!--            <p class="pp">7. {{ $t(`label.faqviptier7`) }}</p><p class="pp">{{ $t(`label.faqviptier7des`) }}</p>-->
    <!--            <p class="pp">8. {{ $t(`label.faqviptier8`) }}</p><p class="pp">{{ $t(`label.faqviptier8des`) }}</p>-->

    <!--          </div>-->
    <!--          <div class="rightsidetnc">-->
    <!--            <h2 style="font-size: 18px;display: flex; color: #FF9E22; padding-bottom: 20px;">{{ $t(`label.tnctitle`) }}</h2>-->
    <!--            <p class="pp">1. {{ $t(`label.tncviptier1`) }}</p>-->
    <!--            <p class="pp">2. {{ $t(`label.tncviptier2`) }}</p>-->
    <!--            <p class="pp">3. {{ $t(`label.tncviptier3`) }}</p>-->
    <!--            <p class="pp">4. {{ $t(`label.tncviptier4`) }}</p>-->
    <!--            <p class="pp">5. {{ $t(`label.tncviptier5`) }}</p>-->
    <!--            <p class="pp">6. {{ $t(`label.tncviptier6`) }}</p>-->
    <!--            <p class="pp">7. {{ $t(`label.tncviptier7`) }}</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import { REFERRAL_GET_OVERVIEW, REFERRAL_POST_COMMISSION, REFERRAL_RESET_COMMISSION, REFERRAL_GET_CONFIG } from '@/store/referral.module'
import stringFormat from 'string-format'
import currencySymbolMapp from 'currency-symbol-map'
import { MEMBER_WALLET, MEMBER_VIP_TIER_PROGRESS } from '@/store/member.module'
import i18n from '@/plugins/i18n'
import AppSeoContent from '@/components/layout/SEOContent.vue'

export default {
  name: 'viptier',
  components: {
    AppSeoContent,
    VueQrCode,
    AppLoginEmbedded
  },
  data: () => ({
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    showFaq: false,
    showTnc: false,
    dateOfBirthMenu: false,
    stringFormat: stringFormat,
    currencySymbolMapp: currencySymbolMapp,
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'tier',
    dates: ['', ''],
    overview: {},
    redeemCommissionAmt: 0.0,
    share_url: '',
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    notLoading: true,
    routeName: ROUTE_NAME,
    showProgress: false,
    tierBarBgColor: '',
    tierBarColor: '',
    tierColorThemeName: 'bronze',
    progressLevels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    currentProgress: 0,
    DesktopcurrentProgress: 0,
    tierName: '',
    currentProgressPercentage: 0,
    currentLv: 0,
    level: 1,
    slotpercentages: ['0.10', '0.50', '0.60', '0.80', '1.00', '1.20'],
    casinopercentages: ['N/A', '0.10', '0.30', '0.60', '0.80', '1.00'],
    sportpercentages: ['N/A', '0.20', '0.30', '0.80', '0.80', '1.00'],
    depositpercentages: ['1', '2', '3', '5', '8', '10'],
    depositrequirements: ['First Time Deposit (500 or more)', '1,000,000', '5,000,000', '10,000,000', '20,000,000', 'Special Invitation'],
    turnoverrequirements: ['5,000', '5,000', 'N/A', 'N/A', 'N/A', 'Special Invitation'],
    upgradebonuses: ['N/A', '2,000', '18,888', '38,888', '88,888', '168,888'],
    downgradecriterias: ['N/A', 'N/A', 'Inactive for 3 months', 'Inactive for 3 months', 'Inactive for 3 months', 'Special Invitation'],
    levelNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'],
    nextLv: 0,
    progressRequired: 0,
    currentLvSequence: 0
  }),
  created() {
    this.getVipTierProgress()
    this.metaTag()
  },
  computed: {
    combinedText() {
      const levelText = this.getLevelTextExact()
      const localeNumber = this.getLocaleNumber(levelText)

      return `${localeNumber}`
    },
    tiers() {
      return [
        { name: '01', sequence: 1, color: 'bronze', progress: 10.0, localizedName: this.getLocaleNumber('01') },
        { name: '02', sequence: 2, color: 'silver', progress: 20.0, localizedName: this.getLocaleNumber('02') },
        { name: '03', sequence: 3, color: 'gold', progress: 30.0, localizedName: this.getLocaleNumber('03') },
        { name: '04', sequence: 4, color: 'platinum', progress: 40.0, localizedName: this.getLocaleNumber('04') },
        { name: '05', sequence: 5, color: 'diamond', progress: 50.0, localizedName: this.getLocaleNumber('05') },
        { name: '06', sequence: 6, color: 'black', progress: 60.0, localizedName: this.getLocaleNumber('06') },
        { name: '07', sequence: 7, color: 'black', progress: 70.0, localizedName: this.getLocaleNumber('07') },
        { name: '08', sequence: 8, color: 'black', progress: 80.0, localizedName: this.getLocaleNumber('08') },
        { name: '09', sequence: 9, color: 'black', progress: 90.0, localizedName: this.getLocaleNumber('09') },
        { name: '10', sequence: 10, color: 'black', progress: 100, localizedName: this.getLocaleNumber('10') }
      ]
    },
    translatedLvSequence() {
      let lvl = this.currentLvSequence
      return i18n.locale === 'bd' ? lvl.toLocaleString('bn-BD') : lvl
    },
    cashPrize() {
      const percentageMap = {
        1: '500',
        2: '1000',
        3: '2000',
        4: '7500',
        5: '15000',
        6: '30000',
        7: '80000',
        8: '200000',
        9: '400000',
        10: '1000000'
      }
      // return percentageMap[this.level] || ''
      const levelText = percentageMap[this.level] || ''
      const localizedNumber = this.getLocaleDigit(levelText)

      return `৳${localizedNumber}`
    },
    coinPrize() {
      const percentageMap = {
        1: '1.00',
        2: '1.00',
        3: '1.50',
        4: '1.50',
        5: '2.00',
        6: '2.00',
        7: '2.50',
        8: '3.00',
        9: '4.00',
        10: '5.00'
      }
      // const levelText = percentageMap[this.level] || '';
      const levelText = percentageMap[this.level] || ''

      // Convert levelText to a float using parseFloat
      const levelAsFloat = parseFloat(levelText)
      console.log(levelAsFloat)
      // Check if levelAsFloat is a valid number
      if (!isNaN(levelAsFloat)) {
        const localizedNumber = this.getFloatNumber(levelAsFloat)
        return `${localizedNumber}%`
      } else {
        // Handle the case where the conversion to float fails
        return 'Invalid Level'
      }
    },
    lifeTimeDepoRequired() {
      const percentageMap = {
        1: '50000',
        2: '100000',
        3: '200000',
        4: '500000',
        5: '1000000',
        6: '2000000',
        7: '4000000',
        8: '10000000',
        9: '20000000',
        10: '50000000'
      }
      // return percentageMap[this.level] || ''
      const levelText = percentageMap[this.level] || ''
      const localizedNumber = this.getLocaleDigit(levelText)
      return `৳${localizedNumber}`
    },
    lifeTimeTORequired() {
      const percentageMap = {
        1: '250000',
        2: '400000',
        3: '600000',
        4: '1000000',
        5: '2000000',
        6: '',
        7: '',
        8: '',
        9: '',
        10: ''
      }
      // return percentageMap[this.level] || ''
      const levelText = percentageMap[this.level] || ''
      const localizedNumber = this.getLocaleDigit(levelText)

      // Check if levelText is empty, and return "N/A" in that case
      const prizeText = levelText ? `৳${localizedNumber}` : 'N/A'

      return prizeText
    },
    depositrequirement() {
      const percentageMap = {
        1: 'First Time Deposit',
        2: '1,000,000',
        3: '5,000,000',
        4: '10,000,000',
        5: '20,000,000',
        6: 'Special Invitation'
      }
      return percentageMap[this.level] || ''
    },
    turnoverrequirement() {
      const percentageMap = {
        1: '5,000',
        2: '5,000,000',
        3: 'N/A',
        4: 'N/A',
        5: 'N/A',
        6: 'Special Invitation'
      }
      return percentageMap[this.level] || ''
    },
    upgradebonus() {
      const percentageMap = {
        1: 'N/A',
        2: '2,000',
        3: '18,888',
        4: '38,888',
        5: '88,888',
        6: '168,888'
      }
      return percentageMap[this.level] || ''
    },
    downgradecriteria() {
      const percentageMap = {
        1: 'N/A',
        2: 'N/A',
        3: 'Inactive for 3 months',
        4: 'Inactive for 3 months',
        5: 'Inactive for 3 months',
        6: 'Special Invitation'
      }
      return percentageMap[this.level] || ''
    },
    imgSource() {
      return '/static/svg/vipassets/vip_' + this.tierColorThemeName + '.svg'
    },
    formattedValue() {
      const currencySymbol = this.currencySymbolMapp.currencySymbolMap[this.currentCurrency]
      const value = 10000
      return `${currencySymbol}${value}`
    },
    currentDate() {
      return new Date()
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },

    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    totalPoint() {
      return this.overview.downline_lv1 + this.overview.downline_lv2 + this.overview.downline_lv3
    },
    redeemCommissionDetailResponseComplete() {
      return this.$store.state.referral.redeemCommissionResponse.complete
    },
    referralConfig() {
      return this.$store.state.referral.config
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    webShareApiSupported() {
      return navigator.share
    },
    memberVipTierProgress() {
      return this.$store.state.member.vipTierProgress.pairing
    },
    vipTierUpgrade() {
      return this.$store.state.member.vipTierProgress.upgrade
    }
  },
  watch: {
    // memberVipTierProgress() {
    //   let vipTierProgress = this.$store.state.member.vipTierProgress
    //   if (Object.keys(vipTierProgress.pairing).length > 0) {
    //     let curPairing = vipTierProgress.pairing
    //     //progress found
    //     this.showProgress = true
    //     switch (curPairing.level_sequence) {
    //       case 1:
    //         this.tierColorThemeName = 'bronze'
    //         break
    //       case 2:
    //         this.tierColorThemeName = 'silver'
    //         break
    //       case 3:
    //         this.tierColorThemeName = 'gold'
    //         break
    //       case 4:
    //         this.tierColorThemeName = 'platinum'
    //         break
    //       default:
    //         this.tierColorThemeName = 'bronze'
    //         this.tierBarBgColor = '#1867c0'
    //     }
    //     this.tierBarBgColor = 'viptier' + this.tierColorThemeName
    //     this.tierName = curPairing.name
    //     this.currentLv = this.memberVipTGierProgress.level_sequence
    //     this.nextLv = this.memberVipTierProgress.level_sequence + 1
    //   } else {
    //     //no progress
    //     this.showProgress = false
    //   }
    // },
    memberVipTierProgress() {
      let vipTierProgress = this.$store.state.member.vipTierProgress
      if (vipTierProgress && vipTierProgress.pairing) {
        if (Object.keys(vipTierProgress.pairing).length > 0) {
          let curPairing = vipTierProgress.pairing
          this.showProgress = true
          this.tierName = curPairing.name
          this.currentLv = curPairing.level_sequence
          this.currentLvSequence = this.currentLv
          this.nextLv = curPairing.level_sequence + 1

          // Set current progress based on the level_sequence
          switch (curPairing.level_sequence) {
            case 1:
              ;(this.currentProgress = 6), (this.DesktopcurrentProgress = 6)
              break
            case 2:
              ;(this.currentProgress = 16), (this.DesktopcurrentProgress = 15)
              break
            case 3:
              ;(this.currentProgress = 26), (this.DesktopcurrentProgress = 25)
              break
            case 4:
              ;(this.currentProgress = 36), (this.DesktopcurrentProgress = 35)
              break
            case 5:
              ;(this.currentProgress = 46), (this.DesktopcurrentProgress = 45)
              break
            case 6:
              ;(this.currentProgress = 56), (this.DesktopcurrentProgress = 55)
              break
            case 7:
              ;(this.currentProgress = 66), (this.DesktopcurrentProgress = 65)
              break
            case 8:
              ;(this.currentProgress = 76), (this.DesktopcurrentProgress = 75)
              break
            case 9:
              ;(this.currentProgress = 86), (this.DesktopcurrentProgress = 85)
              break
            case 10:
              ;(this.currentProgress = 100), (this.DesktopcurrentProgress = 100)
              break
            default:
              ;(this.currentProgress = 0), (this.DesktopcurrentProgress = 0)
          }
        } else {
          this.showProgress = false
        }
      }
    },

    isLoggedIn() {
      if (this.isLoggedIn) {
        this.getVipTierProgress()
      }
    }
  },
  methods: {
    metaTag() {
      uiHelper.setTitle(`${locale.getMessage('meta.viptier_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.viptier_description')}`)
    },
    getLocaleNumber(_number) {
      let number = parseInt(_number)
      console.log('get Locale Number: ', number, this.language)
      return this.language === 'en' ? number.toLocaleString('en-US') : number.toLocaleString('bn-BD')
    },
    getLocaleDigit(_number) {
      const number = parseInt(_number)

      // // Use a custom function for formatting numbers with commas
      // const formattedNumber = this.formatNumberWithCommas(number);
      //
      // return this.language === 'en' ? formattedNumber : formattedNumber.replace(/,/g, '৳');
      return this.language === 'en' ? number.toLocaleString('en-US') : number.toLocaleString('bn-BD')
    },
    getFloatNumber(_number) {
      let number = parseFloat(_number)
      console.log('get Locale float: ', number, this.language)
      return this.language === 'en' ? number.toLocaleString('en-US') : number.toLocaleString('bn-BD')
    },
    // metaTag() {
    //   const currentUrl = window.location.href
    //   uiHelper.setCanonical(currentUrl)
    //   const domainWithProtocol = currentUrl.split('/')[2]
    //   const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
    //   const path = window.location.pathname
    //   const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
    //   const Links = `https://${domain}${cleanPath}`
    //   const Linksen = `https://${domain}/en-BD${cleanPath}`
    //   const Linksbn = `https://${domain}/bn-BD${cleanPath}`
    //   uiHelper.setHreflangLinks(Links)
    //   uiHelper.setHreflangLinksen(Linksen)
    //   uiHelper.setHreflangLinksbd(Linksbn)
    // },
    textColorUppercase() {
      return this.getLevelTextExact().toUpperCase()
    },
    showPreviousLevel() {
      if (this.level > 1) {
        this.level--
      } else {
        this.level = this.levelNames.length
      }
    },
    showNextLevel() {
      if (this.level < this.levelNames.length) {
        this.level++
      } else {
        this.level = 1
      }
    },
    getLevelTextBefore() {
      return this.levelNames[this.level - 2] || ''
    },
    getLevelTextExact() {
      return this.levelNames[this.level - 1] || ''
    },
    getLevelTextAfter() {
      return this.levelNames[this.level] || ''
    },
    getVipTierProgress() {
      this.$store.dispatch(MEMBER_VIP_TIER_PROGRESS)
    },
    toggleFaq() {
      this.showFaq = !this.showFaq
    },
    toggleTnc() {
      this.showTnc = !this.showTnc
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard')
      })
    },
    // copyToClipboard(referralCode) {
    //   const textField = document.createElement('textarea')
    //   textField.innerText = referralCode
    //   document.body.appendChild(textField)
    //   textField.select()
    //   document.execCommand('copy')
    //   textField.remove()
    //   this.isCopied = true
    //   setTimeout(() => { this.isCopied = false }, 3000)
    // },
    getReferralConfig() {
      this.$store.dispatch(`${REFERRAL_GET_CONFIG}`)
    },
    getConfig(index) {
      let config = this.referralConfig.find(c => c.level == index)
      if (config) {
        return config.percentage
      } else {
        return 0
      }
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.message,
        title: selectedMsg.subject
      }
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      }
    },
    getReferralOverview() {
      if (this.isLoggedIn) this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
    },
    redeemCommissionConfirmationDialog() {
      let self = this
      if (this.$refs.redeemForm.validate()) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.redeemConfirmation`)
        dialog.message.push(this.stringFormat(locale.getMessage(`message.ConfirmCommission`), self.$options.filters.currency(this.redeemCommissionAmt, this.currentCurrency)))
        dialog.button.push({
          title: locale.getMessage(`label.confirm`),
          action: () => this.redeemCommission()
        })
        this.openPageDialog(dialog)
      }
    },
    redeemCommission() {
      let obj = { amount: this.redeemCommissionAmt }
      this.$store.dispatch(`${REFERRAL_POST_COMMISSION}`, {
        obj
      })
    },
    redeemCommissionResponseCompleteDialog(response) {
      this.closePageDialog()
      if (response.code != 0 && !response.success) {
        this.$parent.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.$parent.openAlert(true, locale.getMessage(`message.commissionRedeemSuccess`))
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.overview.my_referral_code + '.Refer a friend now and earn commission now! ',
        url: this.share_url
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        let $f = this
        this.$copyText(this.share_url).then(function(e) {
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    }
  }
}
</script>

<style lang="scss">
.mini-icons-wrapper {
  display: flex;
  justify-content: space-evenly;
  height: 60px;
}

.tier-mini-icon {
  position: relative;
  top: 2px;
  width: 23px !important;
  height: 23px !important;
}

.enlarged-img {
  //width: calc(100% + 10px) !important;
  transform: scale(1.26);
  transition: transform 0.3s ease !important;
}

.mini-icon-desc-line {
  top: 5px;
  position: relative;
  width: 3px;
  height: 6px;
  order: 2;
}

.selected-line {
  background-color: #507201 !important;
  top: 7px !important;
}

.mini-icon-lvl-desc {
  order: 3;
  font-size: 0.5rem;
  padding-top: 8px;
}

.custom-vip-tier-progress .v-progress-linear__determinate {
  background: linear-gradient(90deg, #4c592d 0%, #6d9a00 100%) !important;
}

.custom-vip-tier-progress .v-progress-linear__background {
  background-color: #d9d9d9 !important;
  opacity: 1 !important;
}

.currentLevel {
  font-size: 18px;
  font-weight: bolder;
  padding-top: 20px !important;
  color: #4c592e;
}

.grayscale-effect {
  filter: grayscale(70%);
}

.climb-up-des-text {
  font-size: 18px;
}

.Height-Vip {
  height: 320px;
}

.custom_table {
  th,
  td {
    padding: 8px;
  }
}

.infospace {
  width: 55%;
}

.vip-all-tier-desktop {
  height: 87px !important;
  min-width: 48px !important;
  width: 105px !important;
  border-radius: 0px;
  overflow: inherit;
  margin: 10px 25px 0px;
  display: flex;
  flex-direction: column;
}

.vipcard_asset {
  height: 250px;
  width: 250px;
}

.tierpng {
  height: auto !important;
  min-width: auto !important;
  width: 100% !important;
  max-width: 100%;
}

.btnleft {
  background-color: transparent !important;
  z-index: 1;
  min-width: auto !important;
}

.btnright {
  background-color: transparent !important;
  z-index: 1;
  min-width: auto !important;
}

.text-covered {
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  background-color: #4c592e;
  padding: 10px 15px 10px 10px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.leftside {
  justify-content: center;
  align-items: center;
  display: flex;
}

.leftsidefaq {
  border-radius: 10px;
  width: 49%;
  background: #000661;
  padding: 20px;

  p {
    font-size: 12px;
  }
}

.rightsidetnc {
  border-radius: 10px;
  width: 49%;
  background: #000661;
  padding: 20px;

  p {
    font-size: 12px;
  }
}

.lines {
  color: blue;
  font-size: 24px;
  margin-bottom: 10px;
}

.linesmobile {
  justify-content: center;
  display: flex;
  color: blue;
  font-size: 14px;
  margin-bottom: 0px !important;
}

.v-progress-linear__determinate {
  border-radius: 13px;
  height: 12px;
}

.rightside {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 10%;
}

.custom-divider {
  padding: 0px !important;
  border-width: 0.8px !important;
  border-color: #060b5a !important;
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.viptitle {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background-color: #4c592e;
}

.titledesktop {
  color: #ff9e22;
  text-align: left;
  font-size: 18px;
  background-color: #0a0637;
  padding-left: 20px;
}

.title2 {
  color: #ff9e22;
  font-size: 18px;
  margin-top: 25px;
}

.titlewithbackgroundcolor {
  font-size: 18px;
  color: #ff9e22;
  padding-left: 20px;
  background-color: darkblue;
  padding-top: 10px;
  padding-bottom: 10px;
  //margin-left: -20px;
  //margin-right: -20px;
}

.noborder {
  border-bottom: 0px;
}

.v-expand-transition p {
  font-size: 12px;
}

.faqbtn,
.tncbtn {
  justify-content: start;
  margin-bottom: 10px;
}

.triangle {
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #ffffff transparent;
}

.triangle.up {
  transform: translate(-50%, -50%) rotate(180deg);
  top: 60%;
}

.triangle.down {
  transform: translate(-50%, -50%);
  top: 40%;
}

.faq-tnc-container {
  background-color: #fff;
  padding: 20px;
  color: #000;
}

.vip-tier {
  background-color: #fff;
  padding: 20px;
  color: #000000;
}

.vip-tierdesktop {
  padding-left: 10% !important;
  padding-right: 10% !important;
  background-color: #060936;
  padding: 20px;
  color: white;
}

.vip-level-upgrade-info {
  background-color: #0a0637;
  display: flex;
  justify-content: center;
  padding: 20px;
  color: white;
}

.climb-up-text {
  background-color: #fefcd7;
  //height: 590px;
  width: 100%;
  padding: 20px;
  border: 2px solid #4c592e;
  border-radius: 5px;
  font-family: 'Poppins-Medium', sans-serif;
}

.how-to-join-text {
  background-color: #fefcd7;
  width: 100%;
  border: 2px solid #4c592e;
  border-radius: 5px;
  font-family: 'Poppins-Medium', sans-serif;
}

.climb-up-text-special-text {
  font-size: 28px;
  font-weight: 900;
}

.vip-level-upgrade-info-inner-desktop {
  background-image: url('/static/svg/vipassets/How_to_Join_DesktopBg.jpg');
  background-size: cover;
  height: 400px;
  width: 100%;
  padding: 15px;
  border: 2px solid #ff9e22;
  border-radius: 5px;
}

.be-nagad88-vip {
  background-color: #060936;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  padding-top: 20px;
}

.vip-benefits {
  background-color: #060936;
  color: #ff9e22;
}

.upgrade-requirement {
  background-color: #060936;
  color: #ff9e22;
}

.retention-requirement {
  background-color: #060936;
  color: #ff9e22;
}

.pp {
  font-size: 16px;
}

.lefttext {
  text-align: left;
  color: #000;
  font-size: 20px;
  padding-left: 12px !important;
}

.lefttextdesktop {
  text-align: left;
  color: #000;
  font-size: 12px;
  width: 25%;
}

.righttext {
  padding-right: 12px !important;
  text-align: right;
  color: #4c592e;
  font-size: 20px;
}

.righttextdesktop {
  text-align: center;
  color: #4c592e;
  font-size: 12px;
  width: 110px;
}

.vip-level-upgrade-info p,
.be-nagad88-vip p {
  font-size: 12px;
}

.blue-text {
  color: #0047ff;
}

.progress-details-col {
  font-size: 15px;
}

.tier-all-png {
  height: auto !important;
  //min-width: 48px !important;
  //width: 70px!important;
  //margin: 7px 11px 0px;
  width: auto !important;
  padding-left: 3px;
  padding-right: 3px;
  min-width: auto !important;
  display: flex;
  flex-direction: column;
}

.mini-icon-desc-line {
  order: 2;
  background-color: #d9d9d9;
}

.tier-all-png-desktop {
  border-radius: 0px;
  overflow: inherit;
  width: 100% !important;
  height: auto !important;
  margin: 15px 2.7% 0px;
  display: flex;
  flex-direction: column;
}

.faqspan {
  font-size: 15px;
}

.headertext {
  font-size: 22px;
  color: #4c592e;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
}

//@media (max-width: 1600px) {
//  .vipcard_asset{
//    height: 200px;
//    width: 200px;
//  }
//  .tier-all-png-desktop {
//    width: 135px !important;
//    height: 80px !important;
//    margin: 3px 2px 0px;
//  }
//  .vip-all-tier-desktop {
//    height: 120px !important;
//    min-width: 48px !important;
//    width: 140px !important;
//    margin: 7px 6px 0px;
//  }
//}
//@media (max-width: 1500px){
//  .tier-all-png-desktop {
//    width: 115px !important;
//    height: 74px !important;
//    margin: 3px 4px 0px;
//  }
//  .vip-all-tier-desktop {
//    height: 105px !important;
//    min-width: 48px !important;
//    width: 125px !important;
//    margin: 7px 6px 0px;
//  }
//}
//
//
@media (max-width: 1500px) {
  .tier-all-png-desktop {
    width: 102px !important;
    height: 60px !important;
    margin: 3px 13px 0px;
  }
  .vip-all-tier-desktop {
    height: auto !important;
    max-width: 113px !important;
    margin: 7px 14px 0px;
  }
}

@media (max-width: 1400px) {
  .tier-all-png-desktop {
    width: 96px !important;
    height: 55px !important;
    margin: 3px 9px 0px;
  }
  .vip-all-tier-desktop {
    //height: 93px !important;
    //width: 110px !important;
    margin: 7px 10px 0px;
  }
}

//
//@media (max-width: 1280px){
//  .tier-all-png-desktop {
//    width: 106px !important;
//    height: 60px !important;
//    margin: 3px 3px 0px;
//  }
//}
@media screen and (max-width: 1280px) {
  .tier-all-png-desktop {
    width: 83px !important;
    height: 50px !important;
    margin: 3px 10px 0px;
  }
  .vip-all-tier-desktop {
    margin: 7px 9px 0px;
  }
  .tier-img {
    height: auto !important;
    max-width: 80px !important;
  }
}

@media (max-width: 1190px) {
  .tier-all-png-desktop {
    width: 72px !important;
    //height: 43px !important;
    margin: 3px 11px 0px;
  }
  .Height-Vip {
    height: 270px;
  }
  .vip-all-tier-desktop {
    //height: 88px !important;
    //width: 105px !important;
    margin: 7px 6px 0px;
    max-width: 90px !important;
  }
}

@media (max-width: 1095px) {
  .title2 {
    margin-bottom: 0px !important;
  }
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    //width: 83px !important;
    //height: 51px !important;
    margin: 3px 3px 0px;
  }
  .vip-all-tier-desktop {
    //height: 78px !important;
    //min-width: 48px !important;
    //width: 90px !important;
    //margin: 7px 6px 0px;
    max-width: 83px !important;
  }
  .tier-img {
    max-width: 77px !important;
  }
  .infospace {
    width: 65%;
  }
}

@media (max-width: 975px) {
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    //width: 75px !important;
    //height: 43px !important;
    margin: 3px 3px 0px;
  }
  .infospace {
    width: 70%;
  }
  .vip-all-tier-desktop {
    //height: 65px !important;
    //min-width: 48px !important;
    //width: 77px !important;
    //border-radius: 0px;
    //overflow: inherit;
    //margin: 10px 11px 7px;
    //display: flex;
    //flex-direction: column;
    //height: 106px !important;
    //width: 77px !important;
    margin: 10px 11px 7px;
  }
}

@media (max-width: 950px) {
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    width: 70px !important;
    //height: 43px !important;
    margin: 3px 3px 0px;
  }
  .vip-all-tier-desktop {
    //height: 68px !important;
    //min-width: 48px !important;
    //width: 80px !important;
    margin: 7px 6px 0px;
  }
}

@media (max-width: 900px) {
  .infospace {
    width: 80%;
  }
  .vip-level-upgrade-info-inner-desktop {
    width: 100%;
  }
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    width: 60px !important;
    //height: 35px !important;
    margin: 3px 2px 0px;
  }
  .tier-img {
    max-width: 65px !important;
  }
  .vip-all-tier-desktop {
    //height: 60px !important;
    //min-width: 48px !important;
    //width: 70px !important;
    margin: 7px 6px 0px;
  }
}

@media (max-width: 800px) {
  .Height-Vip {
    height: 220px;
  }
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .infospace {
    width: 100%;
  }
  .vip-all-tier-desktop {
    //height: 56px !important;
    //min-width: 48px !important;
    //width: 67px !important;
    margin: 7px 4px 0px;
  }
  .tier-all-png-desktop {
    width: 50px !important;
    //height: 29px !important;
    margin: 3px 2px 0px;
  }
  .tier-img {
    max-width: 55px !important;
  }
}

@media (max-width: 540px) {
  .text-covered {
    font-size: 16px;
  }
  .title2 {
    font-size: 16px;
  }
  .titlewithbackgroundcolor {
    font-size: 16px;
  }
  .h1 {
    font-size: 16px;
  }
}

@media (max-width: 490px) {
  .tierpng {
    height: auto !important;
    min-width: auto !important;
    width: 100% !important;
    max-width: 100%;
  }
  .text-covered,
  .faqspan {
    font-size: 14px;
  }
  .title2 {
    font-size: 14px;
  }
  .titlewithbackgroundcolor {
    font-size: 14px;
  }
  .lefttext,
  .righttext,
  .vip-level-upgrade-info p,
  .be-nagad88-vip p,
  .pp {
    font-size: 12px;
  }
  .btnleft {
    height: 30px !important;
    min-width: 50px !important;
  }
  .btnright {
    height: 30px !important;
    min-width: 50px !important;
  }
}

@media (max-width: 440px) {
  .tierpng {
    height: auto !important;
    min-width: auto !important;
    width: 80px !important;
  }
  //.lefttext, .righttext ,.vip-level-upgrade-info p,
  //.be-nagad88-vip p, .pp {
  //  font-size: 9px;
  //}
  .text-covered,
  .faqspan {
    font-size: 12px;
  }
  .vip-level-upgrade-info-inner {
    height: 715px;
  }
  .titlewithbackgroundcolor {
    font-size: 12px;
  }
  .title2 {
    font-size: 12px;
  }
  //.tier-all-png {
  //  margin: 7px 1% 0px;
  //}
  .btnleft {
    height: 25px !important;
    min-width: 45px !important;
  }
  .btnright {
    height: 25px !important;
    min-width: 45px !important;
  }
}

@media (max-width: 375px) {
  //.lefttext, .righttext ,.vip-level-upgrade-info p,
  //.be-nagad88-vip p, .pp {
  //  font-size: 7.7px;
  //}
  .text-covered,
  .faqspan {
    font-size: 9.7px;
  }
  .title2 {
    font-size: 9.7px;
  }
  .titlewithbackgroundcolor {
    font-size: 9.7px;
  }
  .headertext,
  .climb-up-des-text {
    font-size: 12px;
  }
  .climb-up-text-special-text {
    font-size: 14px;
  }
  .btnleft {
    height: 20px !important;
    min-width: 40px !important;
  }
  .btnright {
    height: 20px !important;
    min-width: 40px !important;
  }
  //.tier-all-png {
  //  margin: 7px 0.5% 0px;
  //}
  .progress-details-col {
    font-size: 15px;
  }
}

@media (max-width: 280px) {
  //.tier-all-png {
  //  height: auto !important;
  //  min-width: 35px !important;
  //  width: 35px !important;
  //  margin: 7px 1% 0px;
  //}
  .btnleft {
    height: 15px !important;
    width: 20px !important;
  }
  .btnright {
    height: 15px !important;
    width: 20px !important;
  }
  .vip-level-upgrade-info-inner {
    height: 834px;
  }
}
</style>
