<template>
    <div>
        <div class="container demo animated">
            <v-row no-gutters style="width: 100%;">
                <v-col cols="12">
                    <v-row no-gutters justify="space-between" class="px-2 py-1">
                        <v-col cols="8" class="d-flex align-center">
                            <span class="title_color--text">{{$t(`label.recentdeposit`)}}</span>
                        </v-col>
                        <v-col v-if="isLogin" cols="4" align="end" class="pt-2">
                            <v-btn rounded outlined color="title_color" small :to="{ name: routeName.DEPOSIT }"
                                   class="ply_btn_z_index">{{$t(`label.depositNow`)}}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-layout v-for="(n,k) in deposit_member" class="px-2 my-1">
                        <v-card style="width:100%;" class="text-center"
                                :color="k % 2 == 0 ? 'odd_prime' : 'even_prime'">
                            <v-row no-gutters
                                   :class="$vuetify.breakpoint.mdAndUp ? 'px-lg-6 px-sm-2' : 'px-lg-6 px-sm-2 py-0 px-2'">
                                <v-col cols="12">
                                    <v-list-item
                                            :class="['grow', $vuetify.breakpoint.mdAndDown ? 'px-0' :'']">
                                        <v-list-item-avatar color="grey darken-3">
                                            <v-img
                                                    class="elevation-6"
                                                    :src="`${mediaUrl}/avatar_icons/${n.level}.svg`"
                                            ></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'pl-10' : 'pl-1'">
                                            <v-list-item-title
                                                    class="text-lowercase font-weight-bold subtitle-2 text-left">
                                                {{n.username}}
                                            </v-list-item-title>
                                            <v-list-item-title
                                                    class="font-weight-bold caption grey--text text-left">
                                                {{n.gateway}}
                                            </v-list-item-title>
                                            <v-list-item-title
                                                    v-if="n.gateway.toLowerCase() == 'paynow'"
                                                    class="font-weight-bold caption grey--text text-left">
                                                {{$t(`label.duration`)}} : {{n.gateway.toLowerCase() == 'paynow' ? n.duration : '' }}  {{$t(`label.sec`)}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-row no-gutters class="px-2 col-6">
                                            <v-col cols="12" class="pa-0">
                                                <v-row
                                                        align="center"
                                                        justify="end"
                                                >
                                                    <v-chip
                                                            class="my-0 chips-size"
                                                            color="#262441"
                                                            text-color="white"
                                                    >
                                                        <span class="subheading">{{n.amount | currency}}</span>
                                                    </v-chip>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" class="pa-0">
                                                <v-row
                                                        align="center"
                                                        justify="end"
                                                >
                                                    <span class="caption">{{getDateTime()}}</span>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-layout>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {SHARED} from "@/constants/constants";
    import {ROUTE_NAME} from "@/constants/route.constants";
    import {Hooper, Slide, Navigation as HooperNavigation} from 'hooper';
    import 'hooper/dist/hooper.css';
    import {locale} from "@/util/localization-helper";

    export default {
        name: "RecentDeposit",
        components: {
            Hooper,
            Slide,
            HooperNavigation
        },
        props: {
            isLogin: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            getDateTime() {
                let dt = new Date();
                dt.setMinutes(dt.getMinutes() - this.getRandomInt(15));
                dt.setSeconds(dt.getSeconds() - this.getRandomInt(60));
                return dt.toLocaleString();
            },
            makeid(length) {
                let result = '';
                let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            getRandomInt(max) {
                return Math.floor(Math.random() * Math.floor(max));
            },
            getRandomMinMaxInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }
        },
        created() {
            for (let i = 0; i < 5; i++) {
                this.deposit_member.push({
                    username: this.makeid(2) + '****' + this.makeid(2),
                    amount: this.depositFigure[this.getRandomInt(this.depositFigure.length)],
                    level: this.level[this.getRandomInt(this.level.length)],
                    gateway: locale.getMessage(`paymentMethod.${this.bank[this.getRandomInt(this.bank.length)]}`),
                    seconds: this.getRandomInt(61),
                    duration: this.getRandomMinMaxInt(15, 40)
                })
            }
        },
        data: () => ({
            depositFigure: ['30', '50', '100', '150', '300', '600', '1000', '800', '620', '500', '80', '130', '90', '60', '70', '250', '320', '1200', '450'],
            bank: ['lb', 'pn'],
            level: ['level_1', 'level_2', 'level_3', 'level_4', 'level_5'],
            routeName: ROUTE_NAME,
            mediaUrl: SHARED.MEDIA_SERVER_URL,
            settings: {
                "dots": false,
                "infinite": true,
                "slidesToShow": 5,
                "slidesToScroll": 1,
                "vertical": true,
                "autoplay": true,
                "autoplaySpeed": 5000,
                "verticalSwiping": true,
                "arrows": false
            },
            deposit_member: [],
            colors: [
                'indigo',
                'warning',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
                'Fifth',
            ],
        })
    }
</script>

<style lang="scss">
    .hooper {
        height: 400px;
    }

    .v-application ul.hooper-track {
        padding-left: 0;
    }

    .chips-size {
        min-width: 120px !important;
        display: flex !important;
        justify-content: center !important;
    }

    .odd_prime {
        background: rgba(217, 217, 255, 0.32) !important;
        border: 1px solid #B9B9B9;
        box-sizing: border-box;
        box-shadow: inset 3px 4px 6px rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(100px);
    }

    .even_prime {
        background: rgba(116, 116, 234, 0.45) !important;
        border: 1px solid #7474EA;
        box-sizing: border-box;
        box-shadow: inset 3px 4px 6px rgba(255, 255, 255, 0.23);
        backdrop-filter: blur(100px);
    }

    .slick-list {
        width: auto !important;
    }

    .ply_btn_z_index {
        z-index: 3;
    }

    .container {
        position: relative;
        margin: auto;
        border: 4px solid transparent;
        box-sizing: border-box;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 0;
    }

    .animated {
        background-image: linear-gradient(#2A2F7E, #2A2F7E),
        linear-gradient(180deg, #7486e5, #B782D4 50%, #338BFE 75%, #DB7ECA);
        background-repeat: no-repeat;
        background-size: 100% 100%, 100% 200%;
        background-position: 0 0, 0 100%;
        background-origin: padding-box, border-box;
        animation: highlight 1s infinite alternate;
    }

    @keyframes highlight {
        100% {
            background-position: 0 0, 0 0;
        }
    }

    @media (max-width: 520px) {
        .chips-size {
            min-width: 70px !important;
        }
    }
</style>