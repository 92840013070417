import { memberService } from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import { SESSION, SHARED } from '@/constants/constants'
import router from '@/router'
import { ROUTE_NAME } from '@/constants/route.constants'

//define module name
const MODULE_NAME = 'member/'

//define actions name
const A_RESET_REWARDS_URL = 'resetRewardsUrl'
const A_GET_SMS_REQUIREMENT = 'getSmsRequirements'
const A_SEND_VERIFICATION_SMS = 'sendVerificationSms'
const A_VERIFY_SMS = 'verifySmsTacCode'
const A_REGISTER = 'registerMember'
const A_RESET_REGISTER_STORE = 'resetRegisterMemberStore'
const A_RESET_MOBILE_REGISTER_STORE = 'resetMobileRegisterMemberStore'
const A_LOGIN = 'login'
const A_RESET_LOGIN_STORE = 'resetLoginStore'
const A_RESET_MOBILE_LOGIN_STORE = 'resetMobileLoginStore'
const A_CHECK_TOKEN_VALIDITY = 'checkTokenValidity'
const A_LOGOUT = 'logout'
const A_RESET_LOGOUT_STORE = 'resetLogoutStore'
const A_REFRESH_TOKEN = 'refreshToken'
const A_WALLET = 'getWallet'
const A_DETAIL = 'getDetail'
const A_FORGOT_PASSWORD = 'forgotPassword'
const A_RESET_FORGOT_PASSWORD_STORE = 'restForgotPasswordStore'
const A_CHANGE_PASSWORD = 'changePassword'
const A_RESET_CHANGE_PASSWORD_STORE = 'resetChangePasswordStore'
const A_UPDATE_DETAIL = 'updateMemberDetail'
const A_RESET_UPDATE_DETAIL_STORE = 'resetUpdateMemberDetailStore'
const A_SEND_CONTACT_VERIFICATION_EMAIL = 'sendContactVerificationEmail'
const A_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE = 'resetSendContactVerificationEmailStore'
const A_UPDATE_EMAIL = 'updateMemberEmail'
const A_RESET_PASSWORD = 'resetMemberPassword'
const A_RESET_RESET_PASSWORD_STORE = 'resetResetMemberPasswordStore'
const A_CONTACT_VERIFICATION = 'verifyMemberContact'
const A_RESET_CONTACT_VERIFICATION_STORE = 'resetVerifyMemberContactStore'
const A_CHECK_WITHDRAW_STATUS = 'getWithdrawStatus'
const A_GET_SHORTCUT_LINK = 'getShortcutLink'
const A_RESET_SMS_VERIFICATION = 'resetSMSVerification'
const A_GET_REWARDS_URL = 'getRewardsUrl'
const A_GET_VIP_PROGRESS = 'getVipProgress'
const A_GET_IPL_VIP_PROGRESS = 'getIplVipProgress'
const A_SEND_OTP = 'sendOTP'
const A_RESET_SEND_OTP = 'resetSendOTP'
const A_VERIFY_OTP = 'verifyOTP'
const A_RESET_VERIFY_OTP = 'resetVerifyOtp'
const A_CHECK_USERNAME = 'checkUsername'
const A_RESET_CHECK_USERNAME = 'resetCheckUsername'
const A_GET_IPL_EVEMT_STATUS = 'getIPLEventStatus'
const A_TURNOVER_TICKET = 'getTurnoverTicket'
const A_RESET_TURNOVER_TICKET = 'resetTurnoverTicket'
const A_RESTRICT_WITHDRAWAL = 'checkRestrictWithdrawal'
const A_RESET_RESTRICT_WITHDRAWAL = 'resetCheckRestrictWithdrawal'
const A_REST_OTPRESULT = 'resetOtpResultResponse'
const A_UPDATE_PHONE_VERIFICATION_STATUS = 'updatePhoneVerificationStatusA'
const A_UPDATE_2ND_PHONE_VERIFICATION_STATUS = 'update2ndPhoneVerificationStatusA'
const A_MOBILE_REG_COMPLETE = 'A_MobileRegResponse'
const A_ONE_SIGNAL_PLAYER_IDS = 'oneSignalPlayerIds'
const A_RESET_ONE_SIGNAL_PLAYER_IDS = 'oneSignalPlayerIdsResetStore'
const A_ADMILL_REG_SUCCEED_TRACK = 'a_admillTrackingReggistration'
const A_REQUEST_FORGOT_PASSWORD_OTP = 'requestForgotPasswordOtp'
const A_RESET_FORGOT_PASSWORD_OTP = 'resetForgotPasswordOtp'
const A_VERIFY_FORGOT_PASSWORD_OTP = 'verifyForgotPasswordOtp'
const A_RESET_VERIFY_FORGOT_PASSWORD_OTP = 'resetVerifyForgotPasswordOtp'
const A_UPDATE_FORGOT_PASSWORD = 'updateForgotPassword'
const A_RESET_UPDATE_FORGOT_PASSWORD = 'resetUpdateForgotPassword'
const A_ADD_NEW_CONTACT_NUMBER = 'addNewContactNumber'
const A_RESET_NEW_CONTACT_NUMBER = 'resetAddNewContactNumber'
const A_REQUEST_CAPTCHA_CODE = 'requestCaptchaCode'
const A_VIP_TIER_PROGRESS = 'A_VIP_TIER_PROGRESS'
const A_RESET_VIP_TIER_PROGRESS = 'A_RESET_VIP_TIER_PROGRESS'

export const MEMBER_VIP_TIER_PROGRESS = MODULE_NAME + A_VIP_TIER_PROGRESS
export const MEMBER_RESET_VIP_TIER_PROGRESS = MODULE_NAME + A_RESET_VIP_TIER_PROGRESS
export const MEMBER_RESET_ADD_NEW_CONTACT = MODULE_NAME + A_RESET_NEW_CONTACT_NUMBER
export const ADMILL_REG_SUCCEED_TRACK = MODULE_NAME + A_ADMILL_REG_SUCCEED_TRACK
//define dispatch action
export const MOBILE_REG_COMPLETE = MODULE_NAME + A_MOBILE_REG_COMPLETE
export const RESET_REWARDS_URL = MODULE_NAME + A_RESET_REWARDS_URL
export const REWARDS_URL = MODULE_NAME + A_GET_REWARDS_URL
export const MEMBER_REGISTER = MODULE_NAME + A_REGISTER
export const MEMBER_GET_SMS_REQUIREMENT = MODULE_NAME + A_GET_SMS_REQUIREMENT
export const MEMBER_SMS_REQUEST = MODULE_NAME + A_SEND_VERIFICATION_SMS
export const MEMBER_SMS_VERIFY = MODULE_NAME + A_VERIFY_SMS
export const MEMBER_RESET_REGISTER_STORE = MODULE_NAME + A_RESET_REGISTER_STORE
export const MEMBER_RESET_MOBILE_REGISTER_STORE = MODULE_NAME + A_RESET_MOBILE_REGISTER_STORE
export const MEMBER_LOGIN = MODULE_NAME + A_LOGIN
export const MEMBER_RESET_LOGIN_STORE = MODULE_NAME + A_RESET_LOGIN_STORE
export const MEMBER_RESET_MOBILE_LOGIN_STORE = MODULE_NAME + A_RESET_MOBILE_LOGIN_STORE
export const MEMBER_CHECK_TOKEN_VALIDITY = MODULE_NAME + A_CHECK_TOKEN_VALIDITY
export const MEMBER_LOGOUT = MODULE_NAME + A_LOGOUT
export const MEMBER_RESET_LOGOUT_STORE = MODULE_NAME + A_RESET_LOGOUT_STORE
export const MEMBER_REFRESH_TOKEN = MODULE_NAME + A_REFRESH_TOKEN
export const MEMBER_WALLET = MODULE_NAME + A_WALLET
export const MEMBER_DETAIL = MODULE_NAME + A_DETAIL
export const MEMBER_FORGOT_PASSWORD = MODULE_NAME + A_FORGOT_PASSWORD
export const MEMBER_RESET_SMS_VERIFICATION = MODULE_NAME + A_RESET_SMS_VERIFICATION
export const MEMBER_RESET_FORGOT_PASSWORD_STORE = MODULE_NAME + A_RESET_FORGOT_PASSWORD_STORE
export const MEMBER_CHANGE_PASSWORD = MODULE_NAME + A_CHANGE_PASSWORD
export const MEMBER_RESET_CHANGE_PASSWORD_STORE = MODULE_NAME + A_RESET_CHANGE_PASSWORD_STORE
export const MEMBER_UPDATE_DETAIL = MODULE_NAME + A_UPDATE_DETAIL
export const MEMBER_RESET_UPDATE_DETAIL_STORE = MODULE_NAME + A_RESET_UPDATE_DETAIL_STORE
export const MEMBER_SEND_CONTACT_VERIFICATION_EMAIL = MODULE_NAME + A_SEND_CONTACT_VERIFICATION_EMAIL
export const MEMBER_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE = MODULE_NAME + A_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE
export const MEMBER_UPDATE_EMAIL = MODULE_NAME + A_UPDATE_EMAIL
export const MEMBER_RESET_PASSWORD = MODULE_NAME + A_RESET_PASSWORD
export const MEMBER_RESET_RESET_PASSWORD_STORE = MODULE_NAME + A_RESET_RESET_PASSWORD_STORE
export const MEMBER_CONTACT_VERIFICATION = MODULE_NAME + A_CONTACT_VERIFICATION
export const MEMBER_RESET_CONTACT_VERIFICATION_STORE = MODULE_NAME + A_RESET_CONTACT_VERIFICATION_STORE
export const MEMBET_CHECK_WITHDRAW_STATUS = MODULE_NAME + A_CHECK_WITHDRAW_STATUS
export const MEMBER_GET_SHORTCUT_LINK = MODULE_NAME + A_GET_SHORTCUT_LINK
export const MEMBER_GET_VIP_PROGRESS = MODULE_NAME + A_GET_VIP_PROGRESS
export const MEMBER_GET_IPL_VIP_PROGRESS = MODULE_NAME + A_GET_IPL_VIP_PROGRESS
export const MEMBER_SEND_OTP = MODULE_NAME + A_SEND_OTP
export const MEMBER_RESET_SEND_OTP = MODULE_NAME + A_RESET_SEND_OTP
export const MEMBER_VERIFY_OTP = MODULE_NAME + A_VERIFY_OTP
export const MEMBER_RESET_VERIFY_OTP = MODULE_NAME + A_RESET_VERIFY_OTP
export const MEMBER_CHECK_USERNAME = MODULE_NAME + A_CHECK_USERNAME
export const MEMBER_RESET_CHECK_USERNAME = MODULE_NAME + A_RESET_CHECK_USERNAME
export const GET_IPL_EVENT_STATUS = MODULE_NAME + A_GET_IPL_EVEMT_STATUS
export const MEMBER_TURNOVER_TICKET = MODULE_NAME + A_TURNOVER_TICKET
export const MEMBER_RESET_TURNOVER_TICKET = MODULE_NAME + A_RESET_TURNOVER_TICKET
export const MEMBER_RESTRICT_WITHDRAWAL = MODULE_NAME + A_RESTRICT_WITHDRAWAL
export const MEMBER_RESET_RESTRICT_WITHDRAWAL = MODULE_NAME + A_RESET_RESTRICT_WITHDRAWAL
export const MEMBER_REST_OTPRESULT = MODULE_NAME + A_REST_OTPRESULT
export const MEMBER_PHONE_VERIFICATION_STATUS = MODULE_NAME + A_UPDATE_PHONE_VERIFICATION_STATUS
export const MEMBER_2ND_PHONE_VERIFICATION_STATUS = MODULE_NAME + A_UPDATE_2ND_PHONE_VERIFICATION_STATUS
export const ONE_SIGNAL_PLAYER_IDS = MODULE_NAME + A_ONE_SIGNAL_PLAYER_IDS
export const ONE_SIGNAL_PLAYER_IDS_RESET_STORE = MODULE_NAME + A_RESET_ONE_SIGNAL_PLAYER_IDS
export const MEMBER_ADD_NEW_CONTACT = MODULE_NAME + A_ADD_NEW_CONTACT_NUMBER
export const MEMBER_REQUEST_FORGOT_PASSWORD = MODULE_NAME + A_REQUEST_FORGOT_PASSWORD_OTP
export const MEMBER_RESET_REQUEST_FORGOT_PASSWORD = MODULE_NAME + A_RESET_FORGOT_PASSWORD_OTP
export const MEMBER_VERIFY_FORGOT_PASSWORD = MODULE_NAME + A_VERIFY_FORGOT_PASSWORD_OTP
export const MEMBER_RESET_VERIFY_FORGOT_PASSWORD = MODULE_NAME + A_RESET_VERIFY_FORGOT_PASSWORD_OTP
export const MEMBER_UPDATE_FORGOT_PASSWORD = MODULE_NAME + A_UPDATE_FORGOT_PASSWORD
export const MEMBER_RESET_UPDATE_FORGOT_PASSWORD = MODULE_NAME + A_RESET_UPDATE_FORGOT_PASSWORD
export const MEMBER_REQUEST_CAPTCHA_CODE = MODULE_NAME + A_REQUEST_CAPTCHA_CODE

//define mutations name
const M_ADMILL_REG_SUCCEED_TRACK = 'm_admillTrackRegSucceed'
const M_MOBILE_REG_RESP = 'M_setMobileRegResp'
const M_REWARDS_URL = 'setRewardsUrl'
const M_FAILURE = 'setFailure'
const M_SMS_VERIFICATION_REQUIRED = 'sendVerificationSmsRequired'
const M_SEND_VERIFICATION_SMS = 'sendVerificationSmsComplete'
const M_VERIFY_SMS = 'verifySmsComplete'
const M_REGISTER = 'registerMemberComplete'
const M_RESET_REGISTER_STORE = 'resetRegisterStoreComplete'
const M_RESET_MOBILE_REGISTER_STORE = 'resetMobileRegisterStoreComplete'
const M_LOGIN = 'loginComplete'
const M_RESET_LOGIN_STORE = 'resetLoginStoreComplete'
const M_RESET_MOBILE_LOGIN_STORE = 'resetMobileLoginStoreComplete'
const M_RESET_SMS = 'resetSMS'
const M_CHECK_TOKEN_VALIDITY = 'checkTokenValidityComplete'
const M_LOGOUT = 'logoutComplete'
const M_SESSION_TIME_OUT_LOGOUT = 'sessionTimedOutLogoutComplete'
const M_RESET_LOGOUT_STORE = 'resetLogoutStoreComplete'
const M_REFRESH_TOKEN = 'refreshTokenComplete'
const M_WALLET = 'setWallet'
const M_DETAIL = 'setDetail'
const M_FORGOT_PASSWORD = 'forgotPasswordComplete'
const M_RESET_FORGOT_PASSWORD_STORE = 'restForgotPasswordStoreComplete'
const M_CHANGE_PASSWORD = 'changePasswordComplete'
const M_RESET_CHANGE_PASSWORD_STORE = 'resetPasswordStoreComplete'
const M_UPDATE_DETAIL = 'updateMemberDetailComplete'
const M_RESET_UPDATE_DETAIL_STORE = 'resetUpdateMemberDetailComplete'
const M_SEND_CONTACT_VERIFICATION_EMAIL = 'sendContactVerificationEmailComplete'
const M_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE = 'resetSendContactVerificationEmailStoreComplete'
const M_UPDATE_EMAIL = 'updateMemberEmailComplete'
const M_RESET_PASSWORD = 'resetMemberPasswordComplete'
const M_RESET_RESET_PASSWORD_STORE = 'resetResetMemberPasswordStoreComplete'
const M_CONTACT_VERIFICATION = 'verifyMemberContactComplete'
const M_RESET_CONTACT_VERIFICATION_STORE = 'resetVerifyMemberContactStoreComplete'
const M_CHECK_WITHDRAW_STATUS = 'setWithdrawStatus'
const M_SET_SHORTCUT_LINK = 'setShortcutLink'
const M_RESET_REWARDS_URL = 'resetRewardsUrl'
const M_VIP_PROGRESS = 'setVipProgress'
const M_IPL_VIP_PROGRESS = 'setIplVipProgress'
const M_IPL_EVENT_STATUS = 'setIplEventStatus'
const M_OTP_SENT = 'setOTPSent'
const M_RESET_OTP_SENT = 'resetOTPSent'
const M_OTP_VERIFIED = 'setotpVerified'
const M_RESET_OTP_VERIFIED = 'resetotpVerified'
const M_CHECK_USERNAME = 'setCheckUsernameResult'
const M_RESET_CHECK_USERNAME = 'resetCheckUsernameResult'
const M_TURNOVER_TICKET = 'setTurnoverTicket'
const M_RESET_TURNOVER_TICKET = 'resetTurnoverTicket'
const M_RESTRICT_WITHDRAWAL = 'setRestrictWithdrawal'
const M_RESET_RESTRICT_WITHDRAWAL = 'resetRestrictWithdrawal'
const M_RESET_OTPResult = 'resetOTPResult'
const M_UPDATE_PHONE_VERIFICATION_STATUS = 'updatePhoneVerificationStatusM'
const M_UPDATE_2ND_PHONE_VERIFICATION_STATUS = 'update2ndPhoneVerificationStatusM'
const M_ONE_SIGNAL_PLAYER_IDS = 'setOneSignalPlayerIds'
const M_RESET_ONE_SIGNAL_PLAYER_IDS = 'resetOneSignalPlayerIds'
const M_REQUEST_FORGOT_PASSWORD = 'requestForgotPassword'
const M_RESET_REQUEST_FORGOT_PASSWORD = 'resetRequestForgotPassword'
const M_VERIFY_FORGOT_PASSWORD = 'verifyForgotPassword'
const M_RESET_VERIFY_FORGOT_PASSWORD = 'resetVerifyForgotPassword'
const M_UPDATE_FORGOT_PASSWORD = 'updateForgotPassword'
const M_RESET_UPDATE_FORGOT_PASSWORD = 'resetUpdateForgotPassword'
const M_SET_ADD_NEW_NUMBER_CONTACT = 'setAddNewNumberContact'
const M_RESET_ADD_NEW_NUMBER_CONTACT = 'resetAddNewNumberContact'
const M_REQUEST_CAPTCHA_CODE = 'setRequestCaptchaCode'
const M_RESET_VIP_TIER_PROGRESS = 'resetVipTierProgress'
const M_VIP_TIER_PROGRESS = 'm_vip_tier_progress'

//init app state
const state = {
  addNewNumberContact: {
    code: 0,
    success: false,
    complete: false
  },
  resetForgotPasswordSent: {
    complete: false,
    code: 0,
    success: false,
    data: false,
    error: null,
    countDownTimer: 0,
    sendInterval: null
  },
  verifyForgotPasswordSent: {
    complete: false,
    success: false,
    code: 0,
    error: '',
    contact: '',
    membercode: ''
  },
  changeForgotPassword: {
    complete: false,
    success: false,
    code: 0
  },
  oneSignalPlayerIds: {
    complete: false,
    success: false,
    code: 0,
    data: []
  },
  turnoverTicket: {
    complete: false,
    success: false,
    code: 0,
    data: []
  },
  restrictWithdrawal: {
    complete: false,
    success: false,
    is_restrict: false,
    pending_ticket: 0
  },
  isLoggedIn: false,
  rewards: {
    success: false,
    complete: false,
    rewardsUrl: ''
  },
  info: {
    memberCode: '',
    password: '',
    surname: '',
    name: '',
    gender: '',
    dateOfBirth: '',
    address: '',
    province: '',
    country: '',
    postcode: '',
    nationality: '',
    placeOfBirth: '',
    currency: '',
    isActive: '',
    isGameAllow: '',
    isNameVerified: '',
    isAccessRestricted: '',
    registrationSite: '',
    contacts: [],
    group: '',
    email: '',
    mobile: '',
    line: '',
    mbrRefferalCode: ''
  },
  vipProgress: {
    current_deposit_progress: 0,
    current_icon_path: '',
    current_level_name: '',
    current_rollover_progress: 0,
    has_pending_claim: false,
    member_progress_type: 1,
    next_icon_path: '',
    next_level_name: '',
    required_account_rollover: 0,
    required_deposit_amount: 0
  },
  iplVipProgress: {
    current_deposit_progress: 0,
    current_icon_path: '',
    current_level_name: '',
    current_rollover_progress: 0,
    has_pending_claim: false,
    member_progress_type: 1,
    next_icon_path: '',
    next_level_name: '',
    required_account_rollover: 0,
    required_deposit_amount: 0
  },
  iplEventDate: {
    status: false,
    startDate: '',
    endDate: ''
  },
  walletBalance: {
    wallets: [],
    totalBalance: 0,
    withdrawableBalance: 0,
    error: false,
    refreshing: false
  },
  withdrawStatus: {},
  loginResponse: {
    action: 'login',
    complete: false,
    success: false,
    code: 0
  },
  mobileLoginResponse: {
    action: 'login',
    complete: false,
    success: false,
    code: 0
  },
  logoutResponse: {
    action: 'logout',
    complete: false,
    success: false,
    code: 0,
    isSessionTimedOut: false
  },
  registerResponse: {
    action: 'register',
    complete: false,
    success: false,
    code: 0,
    membercode: '',
    memberID: ''
  },
  mobileRegisterResponse: {
    action: 'register',
    complete: false,
    success: false,
    code: 0
  },
  forgotPasswordResponse: {
    action: 'forgotPassword',
    complete: false,
    success: false,
    code: 0
  },
  resetPasswordResponse: {
    action: 'resetPassword',
    complete: false,
    success: false,
    code: 0
  },
  changePasswordResponse: {
    action: 'changePassword',
    complete: false,
    success: false,
    code: 0
  },
  updateDetailResponse: {
    action: 'updateMemberDetail',
    complete: false,
    success: false,
    code: 0
  },
  sendContactVerificationEmailResponse: {
    action: 'sendContactVerificationEmail',
    complete: false,
    success: false,
    code: 0,
    contactType: 'email'
  },
  verifyContactResponse: {
    action: 'verifyContact',
    complete: false,
    success: false,
    code: 0
  },
  smsVerificationRequired: {
    required: false,
    loaded: false,
    success: false,
    action: A_GET_SMS_REQUIREMENT
  },
  smsVerificationSent: {
    complete: false,
    loaded: false,
    success: false,
    countDownTimer: 0,
    code: 0,
    sendInterval: null,
    action: A_SEND_VERIFICATION_SMS,
    message: null
  },
  smsVerificationResult: {
    loaded: false,
    success: false,
    code: 0,
    message: '',
    action: A_VERIFY_SMS
  },
  getShortcutResult: {
    loaded: false,
    payload: '',
    action: A_GET_SHORTCUT_LINK
  },
  errorMsg: '',
  otpSent: {
    completed: false,
    loaded: false,
    success: false,
    countDownTimer: 0,
    code: 0,
    sendInterval: null,
    action: 'sendOTP',
    message: null
  },
  otpVerified: {
    completed: false,
    success: false,
    key: ''
  },
  checkUsernameResult: {
    completed: false,
    success: false,
    code: 0
  },
  requestCaptchaCode: {
    complete: false,
    success: false,
    code: 0,
    data: []
  },
  vipTierProgress: {
    pairing: {},
    upgrade: {},
    downgrade: {}
  }
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_RESET_SEND_OTP]({ commit }) {
    commit(M_RESET_OTP_SENT)
  },
  [A_RESET_NEW_CONTACT_NUMBER]({ commit }) {
    commit(M_RESET_ADD_NEW_NUMBER_CONTACT)
  },
  [A_ADD_NEW_CONTACT_NUMBER]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.addNewNumberContact(filter).then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_ADD_NEW_NUMBER_CONTACT, { data })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_ADD_NEW_NUMBER_CONTACT, { data: error })
      }
    )
  },
  [A_REQUEST_FORGOT_PASSWORD_OTP]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.requestForgotPassword(data).then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_REQUEST_FORGOT_PASSWORD, { data })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_REQUEST_FORGOT_PASSWORD, { data: error })
      }
    )
  },
  [A_RESET_FORGOT_PASSWORD_OTP]({ commit }) {
    commit(M_RESET_REQUEST_FORGOT_PASSWORD)
  },
  [A_VERIFY_FORGOT_PASSWORD_OTP]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.verifyForgotPassword(data).then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_VERIFY_FORGOT_PASSWORD, { data })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_VERIFY_FORGOT_PASSWORD, { data: error })
      }
    )
  },
  [A_RESET_VERIFY_FORGOT_PASSWORD_OTP]({ commit }) {
    commit(M_RESET_VERIFY_FORGOT_PASSWORD)
  },
  [A_UPDATE_FORGOT_PASSWORD]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.updateForgotPassword(data).then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPDATE_FORGOT_PASSWORD, { data })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPDATE_FORGOT_PASSWORD, { data: error })
      }
    )
  },
  [A_RESET_UPDATE_FORGOT_PASSWORD]({ commit }) {
    commit(M_RESET_UPDATE_FORGOT_PASSWORD)
  },
  [A_ADMILL_REG_SUCCEED_TRACK]({ commit }, { adformObj }) {
    memberService.pushAdmillRegSucceed(adformObj.cookie, adformObj.membercode)
  },
  [A_ONE_SIGNAL_PLAYER_IDS]({ commit }, { data }) {
    memberService.oneSignalPlayerIds(data).then(
      data => {
        commit(M_ONE_SIGNAL_PLAYER_IDS, { data })
      },
      error => {
        commit(M_ONE_SIGNAL_PLAYER_IDS, { data: error })
      }
    )
  },
  [A_RESET_ONE_SIGNAL_PLAYER_IDS]({ commit }) {
    commit(M_RESET_ONE_SIGNAL_PLAYER_IDS)
  },
  [A_TURNOVER_TICKET]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.getTurnoverTicket(filter).then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_TURNOVER_TICKET, { data })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_TURNOVER_TICKET, { data: error })
      }
    )
  },
  [A_RESET_TURNOVER_TICKET]({ commit }) {
    commit(M_RESET_TURNOVER_TICKET)
  },
  [A_RESTRICT_WITHDRAWAL]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.turnoverChecking().then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_RESTRICT_WITHDRAWAL, { data })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_RESTRICT_WITHDRAWAL, { data: error })
      }
    )
  },
  [A_RESET_RESTRICT_WITHDRAWAL]({ commit }) {
    commit(M_RESET_RESTRICT_WITHDRAWAL)
  },
  [A_RESET_REWARDS_URL]({ commit }) {
    commit(M_RESET_REWARDS_URL)
  },
  [A_GET_REWARDS_URL]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.getRewardsUrl().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_REWARDS_URL, data)
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_REWARDS_URL, { data: error })
      }
    )
  },
  [A_VERIFY_SMS]({ dispatch, commit }, { registerObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.verifyContactBySms(registerObj).then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_VERIFY_SMS, { data })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_VERIFY_SMS, { data: error })
      }
    )
  },
  [A_SEND_VERIFICATION_SMS]({ dispatch, commit }, { registerObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.sendContactVerificationRequest(registerObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SEND_VERIFICATION_SMS, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SEND_VERIFICATION_SMS, { result })
      }
    )
  },
  [A_GET_SMS_REQUIREMENT]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    if (state.smsVerificationRequired.loaded) {
      dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      return
    }
    memberService.getSmsVerificationRequirements().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SMS_VERIFICATION_REQUIRED, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SMS_VERIFICATION_REQUIRED, { result })
      }
    )
  },
  [A_REGISTER]({ dispatch, commit }, { registerObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.register(registerObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_REGISTER, { result })
        commit(M_MOBILE_REG_RESP, { result })
        let loginObj = registerObj
        loginObj.option = SHARED.DEFAULT_LOGIN_PASSWORD_METHOD
        try {
          srtmCommands.push({
            event: 'track.user.registration',
            payload: {
              action: 'complete',
              userId: loginObj.memberCode
            }
          })
          console.log('done reg sradar!')
        } catch (err) {
          console.log(err)
        }
        dispatch(`${MEMBER_LOGIN}`, { loginObj }, { root: true })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_REGISTER, { result })
        commit(M_MOBILE_REG_RESP, { result })
      }
    )
  },
  [A_RESET_SMS_VERIFICATION]({ commit }) {
    commit(M_RESET_SMS)
  },
  [A_RESET_REGISTER_STORE]({ commit }) {
    commit(M_RESET_REGISTER_STORE)
  },
  [A_RESET_MOBILE_REGISTER_STORE]({ commit }) {
    commit(M_RESET_MOBILE_REGISTER_STORE)
  },
  [A_LOGIN]({ dispatch, commit }, { loginObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.login(loginObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        let username = loginObj.memberCode
        commit(M_LOGIN, { result, username })
        dispatch(`${MEMBER_DETAIL}`, {}, { root: true })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_LOGIN, { result })
      }
    )
  },
  [A_RESET_LOGIN_STORE]({ commit }) {
    commit(M_RESET_LOGIN_STORE)
  },
  [A_RESET_MOBILE_LOGIN_STORE]({ commit }) {
    commit(M_RESET_MOBILE_LOGIN_STORE)
  },
  [A_CHECK_TOKEN_VALIDITY]({ dispatch, commit }, { requiredLoading }) {
    if (requiredLoading) {
      dispatch(`${SHARED_LOADING}`, {}, { root: true })
    }
    memberService.validateToken().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CHECK_TOKEN_VALIDITY, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CHECK_TOKEN_VALIDITY, { result })
      }
    )
  },
  [A_LOGOUT]({ dispatch, commit }, { logoutObj, sessionTimedOut }) {
    if (!sessionTimedOut) {
      dispatch(`${SHARED_LOADING}`, {}, { root: true })
      memberService.logout(logoutObj).then(
        data => {
          let result = data
          dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
          commit(M_LOGOUT, { result })
        },
        error => {
          let result = error
          dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
          commit(M_LOGOUT, { result })
        }
      )
    } else {
      commit(M_SESSION_TIME_OUT_LOGOUT)
    }
  },
  [A_RESET_LOGOUT_STORE]({ commit }) {
    commit(M_RESET_LOGOUT_STORE)
  },
  [A_REFRESH_TOKEN]({ commit }) {
    memberService.refreshToken().then(
      data => {
        let result = data
        commit(M_REFRESH_TOKEN, { result })
      },
      error => {
        let result = error
        commit(M_REFRESH_TOKEN, { result })
      }
    )
  },
  [A_WALLET]({ commit }) {
    let wallets = []
    memberService.getWallet().then(
      data => {
        let result = data
        commit(M_WALLET, { result })
      },
      error => {
        let result = error
        commit(M_WALLET, { result })
      }
    )

    // memberService.getWallet().then(
    //     data => {
    //         wallets = data.data.wallets
    //         return memberService.getSpecifyWallet(wallets)
    //     }).then(data => {
    //         let result = {
    //             wallets : wallets,
    //             specificWallets: data.map(d => {
    //                 if(d.success){
    //                     return d.data
    //                 }
    //             })
    //         }
    //         commit(M_WALLET, {result})
    //     },
    //     error => {
    //         let result = error
    //         commit(M_WALLET, {result})
    //     })
  },
  async [A_DETAIL]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    await memberService.getDetail().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DETAIL, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DETAIL, { result })
      }
    )
  },
  [A_FORGOT_PASSWORD]({ dispatch, commit }, { forgotPasswordObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.forgotPassword(forgotPasswordObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FORGOT_PASSWORD, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FORGOT_PASSWORD, { result })
      }
    )
  },
  [A_RESET_FORGOT_PASSWORD_STORE]({ commit }) {
    commit(M_RESET_FORGOT_PASSWORD_STORE)
  },
  [A_CHANGE_PASSWORD]({ dispatch, commit }, { changePasswordObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.changePassword(changePasswordObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CHANGE_PASSWORD, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CHANGE_PASSWORD, { result })
      }
    )
  },
  [A_RESET_CHANGE_PASSWORD_STORE]({ commit }) {
    commit(M_RESET_CHANGE_PASSWORD_STORE)
  },
  [A_UPDATE_DETAIL]({ dispatch, commit }, { memberObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.updateMember(memberObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPDATE_DETAIL, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPDATE_DETAIL, { result })
      }
    )
  },
  [A_RESET_UPDATE_DETAIL_STORE]({ commit }) {
    commit(M_RESET_UPDATE_DETAIL_STORE)
  },
  [A_SEND_CONTACT_VERIFICATION_EMAIL]({ dispatch, commit }, { verificationObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.sendContactVerificationEmail(verificationObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SEND_CONTACT_VERIFICATION_EMAIL, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SEND_CONTACT_VERIFICATION_EMAIL, { result })
      }
    )
  },
  [A_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE]({ commit }) {
    commit(M_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE)
  },
  [A_UPDATE_EMAIL]({ commit }, { memberEmail }) {
    memberService.updateMemberEmail(memberEmail).then(
      data => {
        let result = data
        commit(M_UPDATE_EMAIL, { result })
      },
      error => {
        let result = error
        commit(M_UPDATE_EMAIL, { result })
      }
    )
  },
  [A_RESET_PASSWORD]({ dispatch, commit }, { resetPasswordObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.resetPassword(resetPasswordObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_RESET_PASSWORD, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_RESET_PASSWORD, { result })
      }
    )
  },
  [A_CONTACT_VERIFICATION]({ dispatch, commit }, { verificationObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.verifyContact(verificationObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CONTACT_VERIFICATION, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CONTACT_VERIFICATION, { result })
      }
    )
  },
  [A_CHECK_WITHDRAW_STATUS]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    memberService.getWithdrawStatus().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CHECK_WITHDRAW_STATUS, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CHECK_WITHDRAW_STATUS, { result })
      }
    )
  },
  [A_GET_SHORTCUT_LINK]({ dispatch, commit }, { guid }) {
    dispatch(`${SHARED_LOADING}`, { root: true })
    memberService.getShortenedLink(guid).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_SHORTCUT_LINK, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_SHORTCUT_LINK, { result })
      }
    )
  },
  [A_GET_VIP_PROGRESS]({ commit }) {
    memberService.getVipProgress().then(
      data => {
        let result = data
        //dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
        commit(M_VIP_PROGRESS, { result })
      },
      error => {
        let result = error
        //dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
        commit(M_VIP_PROGRESS, { result })
      }
    )
  },
  [A_GET_IPL_VIP_PROGRESS]({ commit }) {
    memberService.getIplVipProgress().then(
      data => {
        let result = data
        //dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
        commit(M_IPL_VIP_PROGRESS, { result })
      },
      error => {
        let result = error
        //dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
        commit(M_IPL_VIP_PROGRESS, { result })
      }
    )
  },
  [A_GET_IPL_EVEMT_STATUS]({ commit }) {
    memberService.getIplEventStatus().then(
      data => {
        let result = data
        commit(M_IPL_EVENT_STATUS, { result })
      },
      error => {
        let result = error
        commit(M_IPL_EVENT_STATUS, { result })
      }
    )
  },

  async [A_SEND_OTP]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    await memberService.sendOTP(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_OTP_SENT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_OTP_SENT, { result })
      }
    )
  },
  async [A_VERIFY_OTP]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    await memberService.verifyOTP(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_OTP_VERIFIED, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_OTP_VERIFIED, { result })
      }
    )
  },
  [A_RESET_VERIFY_OTP]({ commit }) {
    commit(M_RESET_OTP_VERIFIED)
  },
  [A_RESET_RESET_PASSWORD_STORE]({ commit }) {
    commit(M_RESET_RESET_PASSWORD_STORE)
  },
  [A_RESET_VERIFY_OTP]({ commit }) {
    commit(M_RESET_OTP_SENT)
  },
  async [A_CHECK_USERNAME]({ dispatch, commit }, { registerObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    await memberService.checkUsername(registerObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CHECK_USERNAME, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CHECK_USERNAME, { result })
      }
    )
  },
  [A_RESET_CHECK_USERNAME]({ commit }) {
    commit(M_RESET_CHECK_USERNAME)
  },
  [A_REST_OTPRESULT]({ commit }) {
    commit(M_RESET_OTPResult)
  },
  [A_UPDATE_PHONE_VERIFICATION_STATUS]({ commit }, index) {
    commit(M_UPDATE_PHONE_VERIFICATION_STATUS, index)
  },
  [A_UPDATE_2ND_PHONE_VERIFICATION_STATUS]({ commit }) {
    commit(M_UPDATE_2ND_PHONE_VERIFICATION_STATUS)
  },
  [A_RESET_VIP_TIER_PROGRESS]({ commit }) {
    commit(M_RESET_VIP_TIER_PROGRESS)
  },
  [A_VIP_TIER_PROGRESS]({ commit }) {
    memberService.getVipTierProgress().then(
      data => {
        commit(M_VIP_TIER_PROGRESS, { data })
      },
      error => {
        commit(M_VIP_TIER_PROGRESS, { data: error })
      }
    )
  },
  [A_REQUEST_CAPTCHA_CODE]({ commit }, { data }) {
    memberService.requestCaptchaCode(data).then(data => {
      commit(M_REQUEST_CAPTCHA_CODE, { data })
    })
  }
}

//to update state values (called by actions)
const mutations = {
  [M_REQUEST_FORGOT_PASSWORD](state, { data }) {
    if (data.success) {
      state.resetForgotPasswordSent = {
        complete: true,
        code: data.code,
        success: data.success,
        data: data.data,
        error: data.error,
        countDownTimer: data.success ? 300 : 0,
        sendInterval: setInterval(() => {
          state.resetForgotPasswordSent.countDownTimer = state.resetForgotPasswordSent.countDownTimer - 1
          if (state.resetForgotPasswordSent.countDownTimer <= 0) {
            clearInterval(state.resetForgotPasswordSent.sendInterval)
          }
        }, 1000)
      }
    } else {
      state.resetForgotPasswordSent = {
        complete: true,
        code: data.code,
        success: data.success,
        data: data.data,
        error: data.error,
        countDownTimer: 0
      }
    }
  },
  [M_RESET_REQUEST_FORGOT_PASSWORD](state) {
    state.resetForgotPasswordSent = {
      complete: false,
      code: 0,
      success: false,
      data: false,
      error: null,
      countDownTimer: 0,
      sendInterval: null
    }
  },
  [M_VERIFY_FORGOT_PASSWORD](state, { data }) {
    state.verifyForgotPasswordSent = {
      complete: true,
      success: data.success,
      code: data.code,
      error: data.error,
      contact: typeof data.data != 'undefined' ? data.data.contact : '',
      membercode: typeof data.data != 'undefined' ? data.data.membercode : ''
    }
  },
  [M_RESET_VERIFY_FORGOT_PASSWORD](state) {
    state.verifyForgotPasswordSent = {
      complete: false,
      success: false,
      code: 0,
      error: '',
      contact: '',
      membercode: ''
    }
  },
  [M_UPDATE_FORGOT_PASSWORD](state, { data }) {
    state.changeForgotPassword = {
      complete: true,
      success: data.success,
      code: data.code
    }
  },
  [M_RESET_UPDATE_FORGOT_PASSWORD](state) {
    state.changeForgotPassword = {
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_VIP_TIER_PROGRESS](state, { data }) {
    if (data) {
      state.vipTierProgress.pairing = data.pairing ? data.pairing : null
      state.vipTierProgress.upgrade = data.upgrade ? data.upgrade : null
      state.vipTierProgress.downgrade = data.downgrade ? data.downgrade : null
    }
  },
  [M_RESET_VIP_TIER_PROGRESS](state) {
    state.vipTierProgress = {
      pairing: {},
      upgrade: {},
      downgrade: {}
    }
  },
  [M_RESTRICT_WITHDRAWAL](state, { data }) {
    state.restrictWithdrawal = {
      complete: true,
      success: data.success,
      is_restrict: data.data.is_restrict,
      pending_ticket: data.data.pending_ticket
    }
  },
  [M_RESET_RESTRICT_WITHDRAWAL](state) {
    state.restrictWithdrawal = {
      success: false,
      complete: false,
      is_restrict: false,
      pending_ticket: 0
    }
  },
  [M_TURNOVER_TICKET](state, { data }) {
    state.turnoverTicket = {
      complete: true,
      success: data.success,
      code: data.code,
      data: data.data
    }
  },
  [M_ONE_SIGNAL_PLAYER_IDS](state, { data }) {
    state.oneSignalPlayerIds = {
      complete: true,
      success: data.success,
      code: data.code,
      data: data.data
    }
  },
  [M_RESET_ONE_SIGNAL_PLAYER_IDS](state) {
    state.oneSignalPlayerIds = {
      success: false,
      complete: false,
      code: 0,
      data: ''
    }
  },
  [M_RESET_TURNOVER_TICKET](state) {
    state.turnoverTicket = {
      complete: false,
      success: false,
      code: 0,
      data: []
    }
  },
  [M_RESET_REWARDS_URL](state) {
    state.rewards = {
      complete: false,
      success: false,
      rewardsUrl: ''
    }
  },
  [M_REWARDS_URL](state, data) {
    state.rewards = {
      complete: true,
      success: data.success,
      rewardsUrl: data.data.rewards_url
    }
  },
  [M_RESET_SMS](state) {
    state.smsVerificationSent = {
      complete: false,
      loaded: false,
      success: false,
      countDownTimer: 0,
      code: 0,
      sendInterval: null,
      action: A_SEND_VERIFICATION_SMS,
      message: null
    }
  },
  [M_SEND_VERIFICATION_SMS](state, { result }) {
    state['smsVerificationSent'].complete = true
    if (!result.success) {
      state['smsVerificationSent'].message = result.error
      state['smsVerificationSent'].code = result.code
    } else {
      if (result.preverified) {
        // state["smsVerificationResult"].loaded = true;
        // state["smsVerificationResult"].success = true;
        state['smsVerificationSent'].code = '3.32.6'
        state['smsVerificationSent'].message = result.preverified
      }
      state['smsVerificationSent'].loaded = result.success
      state.smsVerificationSent.success = result.success
      state.smsVerificationSent.countDownTimer = 300
      var interval = setInterval(() => {
        state.smsVerificationSent.countDownTimer = state.smsVerificationSent.countDownTimer - 1
        //console.log(state.smsVerificationSent.countDownTimer)
        if (state.smsVerificationSent.countDownTimer <= 0) {
          clearInterval(interval)
        }
      }, 1000)
    }
  },
  [M_SMS_VERIFICATION_REQUIRED](state, { result }) {
    state.smsVerificationRequired.required = result.data && result.data.verification_required
    state.smsVerificationRequired.loaded = !!result.data
    state.smsVerificationRequired.success = !!result.data
  },
  [M_REGISTER](state, { result }) {
    state.registerResponse = {
      action: 'register',
      complete: true,
      success: result.success,
      code: result.code,
      membercode: result.success ? result.data.membercode : ''
    }

    if (result.success && result.data.id) {
      state.registerResponse.memberID = result.data.id
    }
  },
  [M_MOBILE_REG_RESP](state, { result }) {
    state.mobileRegisterResponse = {
      action: 'register',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_RESET_MOBILE_REGISTER_STORE](state) {
    state.mobileRegisterResponse = {
      action: 'register',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_RESET_REGISTER_STORE](state) {
    state.registerResponse = {
      action: 'register',
      complete: false,
      success: false,
      code: 0,
      membercode: '',
      memberID: ''
    }
  },

  [M_LOGIN](state, { result, username }) {
    if (result.access_token) {
      localStorage.setItem(SESSION.USERNAME, username)
      localStorage.setItem(SESSION.TOKEN, result.access_token)
      localStorage.setItem(SESSION.TOKEN_EXPIRY, new Date().setSeconds(result.expires_in))
      state.info.memberCode = username
      state.isLoggedIn = true

      state.loginResponse = {
        action: 'login',
        complete: true,
        success: true,
        code: 0,
        error: null
      }

      state.mobileLoginResponse = {
        action: 'login',
        complete: true,
        success: true,
        code: 0,
        error: null
      }
    } else {
      state.loginResponse = {
        action: 'login',
        complete: true,
        success: result.success,
        code: result.code,
        error: null
      }

      state.mobileLoginResponse = {
        action: 'login',
        complete: true,
        success: result.success,
        code: result.code,
        error: null
      }
    }
  },
  [M_RESET_LOGIN_STORE](state) {
    state.loginResponse = {
      action: 'login',
      complete: false,
      success: false,
      code: 0,
      error: null
    }
  },
  [M_RESET_MOBILE_LOGIN_STORE](state) {
    state.mobileLoginResponse = {
      action: 'login',
      complete: false,
      success: false,
      code: 0,
      error: null
    }
  },
  [M_CHECK_TOKEN_VALIDITY](state, { result }) {
    state.isLoggedIn = false
    if (result.success) {
      let d = result.data
      state.info.memberCode = d.membercode
      state.info.isActive = d.is_active
      state.info.isGameAllow = d.is_game_allowed
      state.info.isNameVerified = d.is_name_verified
      state.info.isAccessRestricted = d.is_access_restricted
      state.isLoggedIn = true
    } else {
      state.isLoggedIn = false
      localStorage.removeItem(SESSION.USERNAME)
      localStorage.removeItem(SESSION.TOKEN)
      localStorage.removeItem(SESSION.TOKEN_EXPIRY)
      //router.push({name: ROUTE_NAME.HOME})

      state.logoutResponse = {
        action: 'sessionTimedOut',
        complete: true,
        success: true,
        code: result.code,
        isSessionTimedOut: true
      }
    }
  },
  [M_LOGOUT](state, { result }) {
    if (result.success) {
      state.isLoggedIn = false
      localStorage.removeItem(SESSION.USERNAME)
      localStorage.removeItem(SESSION.TOKEN)
      localStorage.removeItem(SESSION.TOKEN_EXPIRY)
      router.push({ name: ROUTE_NAME.HOME })
      state.logoutResponse = {
        action: 'logout',
        complete: true,
        success: true,
        code: 0,
        isSessionTimedOut: false
      }
    } else {
      state.logoutResponse = {
        action: 'sessionTimedOut',
        complete: true,
        success: false,
        code: result.code,
        isSessionTimedOut: false
      }
      state.vipTierProgress = {
        pairing: {},
        upgrade: {},
        downgrade: {}
      }
    }
  },
  [M_RESET_LOGOUT_STORE](state) {
    state.logoutResponse = {
      action: 'logout',
      complete: false,
      success: false,
      code: 0,
      isSessionTimedOut: false
    }
  },
  [M_SESSION_TIME_OUT_LOGOUT](state) {
    state.isLoggedIn = false
    localStorage.removeItem(SESSION.USERNAME)
    localStorage.removeItem(SESSION.TOKEN)
    localStorage.removeItem(SESSION.TOKEN_EXPIRY)

    state.logoutResponse = {
      action: 'sessionTimedOut',
      complete: true,
      success: true,
      code: 0,
      isSessionTimedOut: true
    }
  },
  [M_REFRESH_TOKEN](state, { result }) {
    if (result.access_token) {
      localStorage.setItem(SESSION.TOKEN, result.access_token)
      localStorage.setItem(SESSION.TOKEN_EXPIRY, new Date().setSeconds(result.expires_in))
    } else {
      state.isLoggedIn = false
      localStorage.removeItem(SESSION.USERNAME)
      localStorage.removeItem(SESSION.TOKEN)
      localStorage.removeItem(SESSION.TOKEN_EXPIRY)

      state.logoutResponse = {
        action: 'sessionTimedOut',
        complete: true,
        success: true,
        code: 0,
        isSessionTimedOut: true
      }
    }
  },
  [M_WALLET](state, { result }) {
    let walletBalance = {
      wallets: [],
      totalBalance: 0,
      bonusBalance: 0,
      withdrawableBalance: 0,
      error: false,
      refreshing: true
    }
    state.walletBalance = walletBalance
    if (result.data.wallets) {
      let d = result.data.wallets
      let bonus = result.data.bonus ?? 0
      d.forEach(wallet => {
        // let sWallet = result.specificWallets.find(sw=> { return sw.wallet_code == wallet.wallet_code})
        let balance = isNaN(parseFloat(wallet.balance)) ? 0 : parseFloat(wallet.balance)
        walletBalance.wallets.push({
          walletCode: wallet.wallet_code,
          balance: balance,
          isPrimary: wallet.is_primary,
          isLocked: wallet.is_locked
        })
        walletBalance.totalBalance += parseFloat(balance)
      })
      walletBalance.totalBalance += parseFloat(bonus)
    } else {
      state.walletBalance.error = true
    }
  },
  [M_DETAIL](state, { result }) {
    if (result.success) {
      let d = result.data
      state.info = {
        memberCode: d.membercode,
        gender: d.gender,
        password: 'thisisfakepassword',
        surname: d.surname,
        name: d.name,
        dateOfBirth: d.date_of_birth,
        address: d.address,
        province: d.province,
        country: d.country,
        postcode: d.postcode,
        nationality: d.nationality,
        placeOfBirth: d.place_of_birth,
        currency: d.currency,
        isActive: d.is_active,
        isGameAllow: d.is_game_allowed,
        isNameVerified: d.is_name_verified,
        registrationSite: d.registration_site,
        contacts: d.contacts,
        group: d.member_group,
        rewardBalance: d.reward_balance,
        email:
          d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.EMAIL.toLowerCase() && x.is_active).length > 0
            ? d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.EMAIL.toLowerCase() && x.is_active).find(x => x.is_primary)
            : {
                value: '',
                is_verified: 0,
                is_primary: 1,
                is_active: 0,
                contact_tye: SHARED.EMAIL
              },
        mobile:
          d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.MOBILE.toLowerCase() && x.is_active).length > 0
            ? d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.MOBILE.toLowerCase() && x.is_active).find(x => x.is_primary)
            : {
                value: '',
                is_verified: 0,
                is_primary: 1,
                is_active: 0,
                contact_tye: SHARED.MOBILE
              },
        secondaryMobile:
          d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.MOBILE.toLowerCase() && x.is_active && !x.is_primary).length > 0
            ? d.contacts.reverse().find(x => x.contact_type.toLowerCase() == SHARED.MOBILE.toLowerCase() && x.is_active && !x.is_primary)
            : {
                value: '',
                is_verified: 0,
                is_primary: 1,
                is_active: 0,
                contact_tye: SHARED.MOBILE
              },
        mobileList:
          d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.MOBILE.toLowerCase() && x.is_active).length > 0
            ? d.contacts
                .filter(x => x.contact_type.toLowerCase() == SHARED.MOBILE.toLowerCase() && x.is_active)
                .sort((a, b) => b.is_primary - a.is_primary)
                .sort((a, b) => a.id - b.id)
            : [],
        line:
          d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.LINE.toLowerCase() && x.is_active).length > 0
            ? d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.LINE.toLowerCase() && x.is_active).find(x => x.is_primary)
            : {
                value: '',
                is_verified: 0,
                is_primary: 1,
                is_active: 0,
                contact_tye: SHARED.LINE
              },
        mbrRefferalCode: d.mbr_refferal_code,
        preferredLanguage: d.preferred_language
      }

      localStorage.setItem(SESSION.USERNAME, d.membercode)
      localStorage.setItem(SESSION.CURRENCY, d.currency)
    }
  },
  [M_FORGOT_PASSWORD](state, { result }) {
    let s = state.forgotPasswordResponse
    s.complete = true
    s.success = result.success
    s.code = result.code
  },
  [M_RESET_FORGOT_PASSWORD_STORE](state) {
    let s = state.forgotPasswordResponse
    s.complete = false
    s.success = false
    s.code = 0
  },
  [M_CHANGE_PASSWORD](state, { result }) {
    let s = state.changePasswordResponse
    s.complete = true
    s.success = result.success
    s.code = result.code
  },
  [M_RESET_CHANGE_PASSWORD_STORE](state) {
    let s = state.changePasswordResponse
    s.complete = false
    s.success = false
    s.code = 0
  },
  [M_UPDATE_DETAIL](state, { result }) {
    let s = state.updateDetailResponse
    s.complete = true
    s.success = result.success
    s.code = result.code
  },
  [M_RESET_UPDATE_DETAIL_STORE](state) {
    let s = state.updateDetailResponse
    s.complete = false
    s.success = false
    s.code = 0
  },
  [M_SEND_CONTACT_VERIFICATION_EMAIL](state, { result }) {
    let s = state.sendContactVerificationEmailResponse
    s.complete = true
    s.success = result.success
    s.code = result.code
  },
  [M_RESET_SEND_CONTACT_VERIFICATION_EMAIL_STORE](state) {
    let s = state.sendContactVerificationEmailResponse
    s.complete = false
    s.success = false
    s.code = 0
  },
  [M_UPDATE_EMAIL](state, { result }) {
    let d = result.data
    state.info.email =
      d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.EMAIL.toLowerCase() && x.is_active).length > 0
        ? d.contacts.filter(x => x.contact_type.toLowerCase() == SHARED.EMAIL.toLowerCase() && x.is_active).find(x => x.is_primary)
        : null
  },
  [M_RESET_PASSWORD](state, { result }) {
    state.resetPasswordResponse.complete = true
    state.resetPasswordResponse.success = result.success
    state.resetPasswordResponse.code = result.code
  },
  [M_RESET_RESET_PASSWORD_STORE](state) {
    state.resetPasswordResponse.complete = false
    state.resetPasswordResponse.success = false
    state.resetPasswordResponse.code = 0
  },

  [M_CONTACT_VERIFICATION](state, { result }) {
    state.verifyContactResponse.complete = true
    state.verifyContactResponse.success = result.success
    state.verifyContactResponse.code = result.code
  },
  [M_RESET_CONTACT_VERIFICATION_STORE](state) {
    state.verifyContactResponse.complete = false
    state.verifyContactResponse.success = false
    state.verifyContactResponse.code = 0
  },
  [M_CHECK_WITHDRAW_STATUS](state, { result }) {
    state.withdrawStatus = {}
    if (result.success) {
      let d = result.data
      state.withdrawStatus.emailVerified = d.email_verified
      state.withdrawStatus.mobileVerified = d.mobile_verified
      state.withdrawStatus.lineVerified = d.line_verified
      state.withdrawStatus.profileComplete = d.profile_completion
      state.withdrawStatus.turnoverHit = d.turnover
    }
  },
  [M_SET_SHORTCUT_LINK](state, { result }) {
    state.getShortcutResult = {}
    state.getShortcutResult.loaded = true
    state.getShortcutResult.payload = result.payload
    state.getShortcutResult.success = result.success
  },
  [M_FAILURE](state, error) {
    state.errorMsg = error
  },
  [M_VIP_PROGRESS](state, { result }) {
    if (result.success) {
      state.vipProgress = result.data
    }
  },
  [M_IPL_VIP_PROGRESS](state, { result }) {
    if (result.success) {
      state.iplVipProgress = result.data
    }
  },
  [M_IPL_EVENT_STATUS](state, { result }) {
    if (result.success) {
      let resp = result.data
      state.iplEventDate.status = resp.event_status
      state.iplEventDate.startDate = resp.event_date.start
      state.iplEventDate.endDate = resp.event_date.end
    }
  },
  [M_OTP_SENT](state, { result }) {
    state.otpSent.completed = true
    if (!result.success) {
      state.otpSent.message = result.error
      state.otpSent.code = result.code
    } else {
      state.otpSent.loaded = result.success
      state.otpSent.success = result.success
      state.otpSent.countDownTimer = 180
      var interval = setInterval(() => {
        state.otpSent.countDownTimer = state.otpSent.countDownTimer - 1
        if (state.otpSent.countDownTimer <= 0) {
          clearInterval(interval)
        }
      }, 1000)
    }
  },
  [M_RESET_OTP_SENT](state) {
    state.otpSent.completed = false
    state.otpSent.message = {}
    state.otpVerified.success = false
  },
  [M_OTP_VERIFIED](state, { result }) {
    state.otpVerified.completed = result.success
    state.otpVerified.success = result.success
    state.otpVerified.key = result.encrypt_data
  },
  [M_RESET_OTP_VERIFIED](state) {
    state.otpVerified.completed = false
    state.otpVerified.success = false
    state.otpVerified.key = ''
  },
  [M_CHECK_USERNAME](state, { result }) {
    state.checkUsernameResult.completed = true
    state.checkUsernameResult.success = result.success
    state.checkUsernameResult.code = result.code
  },
  [M_RESET_CHECK_USERNAME](state) {
    state.checkUsernameResult.completed = false
    state.checkUsernameResult.success = false
    state.checkUsernameResult.code = 0
  },
  [M_RESET_OTPResult](state) {
    state['smsVerificationResult'].loaded = false
    state['smsVerificationResult'].success = false
    state['smsVerificationResult'].message = ''
  },
  [M_VERIFY_SMS](state, { data }) {
    state['smsVerificationResult'].loaded = true
    state['smsVerificationResult'].success = data.success
    state['smsVerificationResult'].message = data.message != undefined ? data.message : ''
    state['smsVerificationResult'].code = data.code
  },
  [M_UPDATE_PHONE_VERIFICATION_STATUS](state, index) {
    // state.info.mobile.is_verified = truef
    let l = state.info.mobileList.length
    if (index <= l - 1) {
      state.info.mobileList[index].is_verified = true
    }
  },
  [M_UPDATE_2ND_PHONE_VERIFICATION_STATUS](state) {
    state.info.secondaryMobile.is_verified = true
  },
  [M_SET_ADD_NEW_NUMBER_CONTACT](state, { data }) {
    state.addNewNumberContact = {
      complete: true,
      success: data.success,
      code: data.code,
      message: data.message
    }
  },
  [M_RESET_ADD_NEW_NUMBER_CONTACT](state) {
    state.addNewNumberContact = {
      complete: false,
      success: false,
      code: 0,
      message: ''
    }
  },
  [M_REQUEST_CAPTCHA_CODE](state, { data }) {
    state.requestCaptchaCode = {
      complete: true,
      code: data.code,
      success: data.success,
      data: data.data
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
