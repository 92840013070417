<template>
  <v-row no-gutters>
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'language_panel px-3' : 'language_panel px-5'">
      <v-row no-gutters align="center" justify="space-between" v-for="(lang, index) in filterLanguages" :key="lang.currency">
        <v-col cols="2">
          <v-avatar class="language-button" :size="$vuetify.breakpoint.xsOnly ? '35' : '48'">
            <img :src="`/static/image/country/${lang.img}.svg`" />
          </v-avatar>
        </v-col>
        <v-col cols="2" class="text-center">
          <label>{{ lang.currencyCd }} {{ lang.currency }}</label>
        </v-col>
        <!-- <v-col cols="4" class="text-right px-3" v-for="l in lang.language" :key="l.cd">
            <v-btn v-model="selectedLanguage" class="full-width" outlined>{{l.title}}</v-btn>
        </v-col> -->
        <v-col cols="8">
          <v-btn-toggle v-model="selectedLanguage" class="full-width">
            <v-row no-gutters>
              <v-col cols="6" class="text-right" :class="$vuetify.breakpoint.xsOnly ? 'px-1' : 'px-3'" v-for="l in lang.language" :key="l.cd">
                <v-btn :value="lang.currency + l.cd" class="full-width font-weight-bold" outlined @click="changeLanguage(l.cd, lang.currency)">{{ l.title }}</v-btn>
              </v-col>
            </v-row>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" class="my-2">
          <v-divider v-if="index < languageList.length - 1" class="language-divider"></v-divider>
        </v-col>
      </v-row>
      <!-- <v-row no-gutters  align="center">
         <v-col cols="2">
             <v-avatar class="language-button">
                 <img :src="`/static/image/country/bd.svg`"/>
             </v-avatar>
         </v-col>
         <v-col cols="2">
             <label>₹ INR</label>
         </v-col>
         <v-col cols="4">
             <v-btn color="black" outlined>English</v-btn>
         </v-col>
         <v-col cols="4">
             <v-btn color="black" outlined>Bengeli</v-btn>
         </v-col>
     </v-row> -->
    </v-col>
  </v-row>
</template>
<script>
import stingFormat from 'string-format'
import { formValidator, uiHelper, errorCodeHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { MEMBER_LOGIN } from '@/store/member.module'
import { SHARED_LOADING } from '@/store/shared.module'

export default {
  name: 'appLanguage',
  props: {
    closeAction: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  data: () => ({
    languageList: [
      {
        currency: 'BDT',
        currencyCd: '৳',
        img: 'BDT',
        language: [
          {
            title: 'English',
            cd: 'en'
          },
          {
            title: 'Bengali',
            cd: 'bd'
          }
        ]
      }
      // {
      //     currency: 'INR',
      //     currencyCd: '₹',
      //     img: "INR",
      //     language: [
      //         {
      //             title: 'English',
      //             cd: 'en'
      //         },
      //         {
      //             title: 'Tamil',
      //             cd: 'ta'
      //         }
      //     ]
      // }
    ],
    selectedLanguage: '',
    currentLanguage: uiHelper.getLanguage()
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    currentCurrency() {
      return uiHelper.getCurrency()
    },
    filterLanguages() {
      if (this.isLoggedIn) {
        let list = this.languageList.filter(l => l.currency == this.currentCurrency)
        if (list && list.length > 0) return list
        else return this.languageList
      } else {
        return this.languageList
      }
    }
  },
  created() {
    this.selectedLanguage = this.currentCurrency + this.currentLanguage
  },
  methods: {
    getCountryCode(code) {
      let REG = /^[a-z]{2,}(?:-[A-Z][a-z]*)*-([A-Z]{2})$/
      var match = code.match(REG)
      if (!match) return ''
      return match[1]
    },
    changeLanguage(languageCd, currCd) {
      this.$store.dispatch(`${SHARED_LOADING}`)
      const langRegion = (languageCd == 'bd' ? 'bn' : languageCd) + '-' + currCd.substring(0, 2)
      uiHelper.removeCookie('regionLocale')
      uiHelper.removeCookie('language')
      uiHelper.removeCookie('changeLanguage')
      uiHelper.setCookie('regionLocale', langRegion)
      uiHelper.setCookie('language', languageCd)
      uiHelper.setCookie('changeLanguage', true)
      uiHelper.setCurrency(currCd)
      let extractPath = window.location.pathname.split('/')
      const results = extractPath.filter(element => {
        return element !== ''
      })

      if (results.length > 0) {
        let region = results[0]
        //test is valid locale
        let isValidCountryCode = this.getCountryCode(region)
        if (isValidCountryCode) {
          const newArr = [uiHelper.getRegionLocale(), ...results.slice(1)]
          const joinUrl = newArr.join('/')
          window.location.replace(window.location.origin + '/' + joinUrl)
        } else {
          this.$router.push({ params: { lang: uiHelper.getRegionLocale() } })
          window.location.reload()
        }
      } else {
        this.$router.push({ params: { lang: uiHelper.getRegionLocale() } })
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="scss">
.language_panel {
  color: #acacac;

  .language-divider {
    border-color: #ebebeb;
  }

  .v-btn {
    color: #acacac;

    &.v-btn--active {
      color: #fddd04;
      border-color: #fddd04 !important;
      border: 2px solid #fddd04 !important;
    }
  }
}

@media (max-width: 425px) {
  .language_panel {
    font-size: 12px;

    .v-btn {
      font-size: 12px !important;
      height: 35px !important;
    }
  }
}
</style>
