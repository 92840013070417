<template>
  <div class="leftpane-info">
    <v-row no-gutters v-if="!isLoggedIn" class="mleftpane-info leftpane-info-before-login my-2">
      <v-col cols="6" @click="goToLoginPage()" class="bottom-navigator-before-login-second d-flex justify-center align-center no-gutters">
        <v-btn class="leftpane-info-login-btn navigator-btn" rounded>
          <b>{{ $t(`label.login`) }}</b>
        </v-btn>
      </v-col>
      <v-col cols="6" @click="goToRegisterPage()" class="bottom-navigator-before-login-first d-flex justify-center align-center no-gutters">
        <v-btn class="leftpane-info-register-btn navigator-btn" rounded>
          <b>{{ $t(`label.register`) }}</b>
        </v-btn>
      </v-col>
    </v-row>
    <div v-else class="leftpane-info my-6 leftpane-info-after-login">
      <v-row no-gutters>
        <v-col cols="2"></v-col>
        <v-col>
          <span>{{ memberInfo.memberCode }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="2"></v-col>
        <v-col class="member-level">
          <span>{{ $t(`label.level`) }} : {{ memberVipTierProgress }}</span>
        </v-col>
      </v-row>
      <v-row class="memberInfo" no-gutters>
        <v-col>
          <v-row style="justify-content: right;padding-right:1.5rem;" no-gutters>
            <span class="span-balance mb-2">{{ memberWallet.totalBalance | currency(currentCurrency) }}</span>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3" class="loyalpts-icon-col"><img :src="`/static/svg/mobileMenu/leftpane-coin.svg`" alt width="40" height="40" /></v-col>
        <v-col class="loyalpts-figure-col">
          <span class="container--fluid span-loyalPts-figure">{{ memberInfo.rewardBalance ? memberInfo.rewardBalance : 0 }} {{ $t(`label.coins`) }}</span>
        </v-col>
      </v-row>
      <v-row class="deposit-withdrawal-row pt-4">
        <v-btn @click="toDeposit()" class="deposit-btn">{{ $t(`label.deposit`) }}</v-btn>
        <v-btn @click="toWithdrawal()" class="withdrawal-btn">{{ $t(`label.withdrawal`) }}</v-btn>
      </v-row>
    </div>

    <div class="left-pane-wrapper-div">
      <v-row no-gutters justify="center" class="mx-0">
        <v-col cols="12" class="download-banner">
          <v-btn class="bhaggo-downloadApp-btn" @click="downloadApk">{{ $t(`mobileMenuTitle.downloadApp`) }}</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { MEMBER_VIP_TIER_PROGRESS } from '@/store/member.module'
import { uiHelper } from '@/util'

export default {
  name: 'appLeftpaneInfo',
  data: () => ({
    currentCurrency: uiHelper.getCurrency()
  }),
  computed: {
    // memberLevel() {
    //   return this.$store.state.member.info.group.toLocaleLowerCase().replace('level', '')
    // },
    memberVipTierProgress() {
      return this.$store.state.member.vipTierProgress.pairing.level_sequence
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    }
  },
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.getVipTierProgress()
      }
    },
  },
  methods: {
    getVipTierProgress() {
      this.$store.dispatch(MEMBER_VIP_TIER_PROGRESS)
    },
    downloadApk() {
      uiHelper.downloadAPK()
    },
    toDeposit() {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT
      })
    },
    toWithdrawal() {
      this.$router.push({
        name: ROUTE_NAME.WITHDRAWAL
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN
      })
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER
      })
    }
  }
}
</script>

<style lang="scss">
.leftpane-info {
  .leftpane-info-before-login {
    height: 65px;
    align-items: center;
    .navigator-btn {
      height: 35px;
      width: 110px;
      border-radius: 32px;
      font-size: 0.9rem;
      text-transform: capitalize;
    }
    .leftpane-info-login-btn {
      background: #efefef;
    }
    .leftpane-info-register-btn {
      background: radial-gradient(64.36% 672.4% at 35.64% 50%, #a1ac2f 0%, #757c2d 100%);
      color: #ffffff;
    }
  }

  .leftpane-info-after-login {
    align-items: center;
    justify-content: center;
    font-size: 23px;
    .memberInfo {
      height: 40px;
    }
    .member-level {
    }
    .deposit-withdrawal-row {
      justify-content: center;
      .deposit-btn {
        text-transform: capitalize;
        height: 33px !important;
        border-radius: 32px 32px 32px 32px;
        background: radial-gradient(farthest-corner at 0% 50%, #a1ac2f, #757c2d);
        color: #ffffff;
        width: 40% !important;
        margin: 0 5px;
      }
      .withdrawal-btn {
        text-transform: capitalize;
        height: 33px !important;
        border-radius: 32px 32px 32px 32px;
        color: #000000;
        background-color: #eaeaea;
        width: 40% !important;
        margin: 0 5px;
      }
    }

    .span-balance {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      text-transform: uppercase;

      color: #000000;
    }

    .span-loyalPts {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.15em;
      text-transform: uppercase;

      color: #aaaaaa;
    }
    .loyalpts-icon-col {
      padding-left: 28px;
      padding-bottom: 0;
    }
    .loyalpts-figure-col {
      padding-bottom: 0;
    }

    .row-loyalPts-figure {
      align-items: center;
      .span-loyalPts-figure {
        font-size: 24px;
        line-height: 31px;
        text-transform: uppercase;

        color: #000000;
      }
    }
  }
}

.left-pane-wrapper-div {
  background-color: #ffd569;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  .download-banner {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .bhaggo-downloadApp-btn {
    text-transform: uppercase;
    color: #000000;
    background-color: #ffd569 !important;
  }
}
</style>
