import { apiHelper } from '@/util'
import { API, KEY } from '@/constants/constants'
import md5 from 'md5'
import format from 'string-format'

const PAYMENT = {
  MERCHANTS: 'Merchants',
  TRANSACTION: 'Transactions',
  MEMBER: 'Members',
  CURRENCY: 'Currency',
  CANCEL: 'Cancel',
  BANKS: 'banks',
  MEMBER_BANK_ACCOUNTS: 'member_bank_accounts',
  MERCHANTS_BANK_ACCOUNTS: 'BankAccounts',
  AVAILABLE_METHODS: 'payment_methods',
  PROCESS_TRANSACTION: 'process',
  GATEWAY_SUPPORTED_BANK: 'GatewaySupportedBank',
  PAYMENT: 'payment',
  API_URL: API.PAYMENT_URL,
  MERCHANTS_CODE: KEY.PAYMENT_MERCHANTS_CODE,
  MERCHANTS_KEY: KEY.PAYMENT_MERCHANTS_KEY,
  CONFIGURATION: 'configuration/depositPercentage'
}

export const paymentService = {
  getBank,
  getAvailableMethod,
  getMerchantBankAccount,
  getOnlinePaymentSupportedBank,

  /* Member Bank */
  getMemberBankAccount,
  putMemberBankAccount,
  deleteMemberBankAccount,
  newMemberBankAccount,

  /*Transaction*/
  getTransaction,
  cancelTransaction,
  postTransaction,
  processTransaction,

  /*History*/
  getPaymentHistory,

  getDepositPercentConfiguration
}

function getBank(obj) {
  return apiHelper().get(`/${PAYMENT.BANKS}`)
}

function getAvailableMethod(obj) {
  return apiHelper().get(`/${PAYMENT.AVAILABLE_METHODS}`)
}

function getMerchantBankAccount(obj) {
  return apiHelper(PAYMENT.API_URL).get(`/${PAYMENT.MERCHANTS}/${PAYMENT.MERCHANTS_CODE}/${PAYMENT.MEMBER}/${obj.memberCode}/${PAYMENT.CURRENCY}/${obj.currency}/${PAYMENT.MERCHANTS_BANK_ACCOUNTS}`)
}

function getOnlinePaymentSupportedBank(obj) {
  return apiHelper(PAYMENT.API_URL).get(`/${PAYMENT.MERCHANTS}/${PAYMENT.MERCHANTS_CODE}/${PAYMENT.MEMBER}/${obj.memberCode}/${PAYMENT.GATEWAY_SUPPORTED_BANK}`)
}

/* MEMBER BANK ACCOUNT */
function getMemberBankAccount(obj) {
  return apiHelper().get(`/${PAYMENT.MEMBER_BANK_ACCOUNTS}`)
}

function putMemberBankAccount(obj) {
  let requestOptions = {
    accountName: obj.memberBankAccountName,
    accountNumber: obj.memberBankAccountNumber,
    province: obj.memberBankProvince,
    branch: obj.memberBankBranch,
    city: obj.memberBankCity,
    bankName: obj.memberBankName,
    updatedBy: obj.memberCode
  }
  return apiHelper(PAYMENT.API_URL).put(`/${PAYMENT.MEMBER_BANK_ACCOUNTS}/${obj.memberBankAccountId}`, requestOptions)
}

function deleteMemberBankAccount(obj) {
  return apiHelper(PAYMENT.API_URL).delete(`/${PAYMENT.MEMBER_BANK_ACCOUNTS}/${obj.memberBankAccountId}`)
}

function newMemberBankAccount(obj) {
  let requestOptions = {
    account_name: obj.memberBankAccountName,
    account_number: obj.memberBankAccountNumber,
    bank_code: obj.bankCode,
    bank_name: obj.memberBankName
  }

  return apiHelper().post(`/${PAYMENT.MEMBER_BANK_ACCOUNTS}`, requestOptions)
}

/* END MEMBER BANK ACCOUNT */

/* TRANSACTION */
function getTransaction(obj) {
  let unsign_signature = format('merchantcode={0}&membercode={1}&currencycode={2}&key={3}', PAYMENT.MERCHANTS_CODE, obj.memberCode, obj.currency, PAYMENT.MERCHANTS_KEY)
  let requestOptions = {
    params: {
      fromdate: obj.fromDate,
      todate: obj.toDate,
      transtype: obj.transType,
      signature: md5(unsign_signature),
      page: obj.page,
      itemsperpage: obj.itemsPerPage
    }
  }
  return apiHelper(PAYMENT.API_URL).get(
    `/${PAYMENT.MERCHANTS}/${PAYMENT.MERCHANTS_CODE}/${PAYMENT.MEMBER}/${obj.memberCode}/${PAYMENT.CURRENCY}/${obj.currency}/${PAYMENT.TRANSACTION}`,
    requestOptions
  )
}

function cancelTransaction(obj) {
  let unsign_signature = format('merchantcode={0}&transid={1}&key={2}', PAYMENT.MERCHANTS_CODE, obj.transactionId, PAYMENT.MERCHANTS_KEY)
  let requestOptions = {
    params: {
      transid: obj.transactionId,
      merchantcode: PAYMENT.MERCHANTS_CODE,
      signature: md5(unsign_signature)
    }
  }

  return apiHelper(PAYMENT.API_URL).put(
    `/${PAYMENT.MERCHANTS}/${PAYMENT.MERCHANTS_CODE}/${PAYMENT.TRANSACTION}/${obj.transactionId}/${PAYMENT.CANCEL}?signature=${requestOptions.params.signature}`,
    requestOptions
  )
}

function signRequest(obj, keys = []) {
  if (keys.length === 0) {
    keys = Object.keys(obj)
  }
  keys.sort()
  let unsign = ''
  // eslint-disable-next-line no-unused-vars
  for (const dictKey of keys) {
    const keystring = dictKey.toLocaleLowerCase()
    const keyvalue = obj[dictKey]
    unsign += `${keystring}=${keyvalue}&`
  }
  unsign += `key=${PAYMENT.MERCHANTS_KEY}`
  return unsign
}

function postTransaction(obj) {
  if (obj.transactionType == 'Deposit') {
    if (obj.methodCode == 'NLB') {
      let formData = new FormData()
      if (typeof obj.rawAttachment != 'undefined' && obj.rawAttachment != "") {
        formData.append('attachment', obj.rawAttachment)
      }
      formData.append('amount', obj.amount)
      formData.append('transaction_type', obj.transactionType)
      formData.append('method_code', obj.methodCode)
      formData.append('gateway_provider_code', obj.gatewayProviderCode)
      formData.append('member_account_number', obj.memberBankAccountNumber)
      formData.append('member_account_name', obj.memberBankAccountName)
      formData.append('member_account_bank_code', obj.memberBankName)
      formData.append('gateway_bank_code', obj.gatewayAccountBankId)
      formData.append('company_bank_code', obj.merchantLocalBankCode)
      return apiHelper().post(`/${PAYMENT.PAYMENT}`, formData)
    } else {
      const requestOptions = {
        amount: Number(obj.amount),
        transaction_type: obj.transactionType,
        method_code: obj.methodCode,
        gateway_provider_code: obj.gatewayProviderCode,
        member_account_number: obj.memberBankAccountNumber,
        member_account_name: obj.memberBankAccountName,
        member_account_bank_code: obj.gatewayAccountBankId,
        gateway_bank_code: obj.gatewayAccountBankId,
        company_bank_code: obj.merchantBankCode
      }
      // eslint-disable-next-line no-unused-vars
      for (let key in requestOptions) {
        let value = requestOptions[key]
        if (typeof value === 'undefined' || value === null || value.toString().length === 0) {
          delete requestOptions[key]
        }
      }
      return apiHelper().post(`/${PAYMENT.PAYMENT}`, requestOptions)
    }
  } else if (obj.transactionType == 'Withdrawal') {
    if(obj.methodCode == 'NWLB') {
      const requestOptions = {
        amount: Number(obj.amount),
        transaction_type: obj.transactionType,
        method_code: obj.methodCode,
        gateway_provider_code: obj.gatewayProviderCode,
        member_account_number: obj.memberBankAccountNumber,
        member_account_name: obj.memberBankAccountName,
        member_account_bank_code: obj.memberBankName,
        gateway_bank_code: obj.gatewayAccountBankId
      }
      // eslint-disable-next-line no-unused-vars
      for (let key in requestOptions) {
        let value = requestOptions[key]
        if (typeof value === 'undefined' || value === null || value.toString().length === 0) {
          delete requestOptions[key]
        }
      }
      return apiHelper().post(`/${PAYMENT.PAYMENT}`, requestOptions)
    } else {
      const requestOptions = {
        amount: Number(obj.amount),
        transaction_type: obj.transactionType,
        method_code: obj.methodCode,
        gateway_provider_code: obj.gatewayProviderCode,
        member_account_number: obj.memberBankAccountNumber,
        member_account_name: obj.memberBankAccountName,
        member_account_bank_code: obj.gatewayAccountBankId,
        gateway_bank_code: obj.gatewayAccountBankId
      }
      // eslint-disable-next-line no-unused-vars
      for (let key in requestOptions) {
        let value = requestOptions[key]
        if (typeof value === 'undefined' || value === null || value.toString().length === 0) {
          delete requestOptions[key]
        }
      }
      return apiHelper().post(`/${PAYMENT.PAYMENT}`, requestOptions)
    }
  }
  /*const requestOptions = {
    amount: Number(obj.amount),
    transaction_type: obj.transactionType,
    method_code: obj.methodCode,
    gateway_provider_code: obj.gatewayProviderCode,
    member_account_number: obj.memberBankAccountNumber,
    member_account_name: obj.memberBankAccountName,
    member_account_bank_code: obj.gatewayAccountBankId,
    gateway_bank_code: obj.gatewayAccountBankId,
    company_bank_code: obj.merchantBankCode == null || obj.merchantBankCode == '' ? obj.merchantLocalBankCode : obj.merchantBankCode,
    attachment: obj.rawAttachment
    merchantCode: obj.merchantCode,
    transTypeName: obj.transactionType,
    methodCode: obj.methodCode,
    memberRealName: obj.memberRealName,
    memberCode: obj.memberCode,
    currencyCode: obj.currency,
    amount: Number(obj.amount),
    oriCurrencyCode: obj.oriCurrency,
    oriAmount: Number(obj.oriAmount),
    gatewayAccountID: Number(obj.gatewayAccountId),
    bankAccountShortCode: obj.merchantBankCode,
    memberBankAccountID: Number(obj.memberBankAccountId),
    memberAccountName: obj.memberBankAccountName,
    memberAccountNumber: obj.memberBankAccountNumber,
    memberAccountBankName: obj.memberBankName,
    memberAccountProvince: obj.memberBankProvince,
    memberAccountBranch: obj.memberBankBranch,
    memberAccountCity: obj.memberBankCity,
    memberBankDepositedTime: obj.depositDateTime.toString(),
    bankReferrerID: obj.bankReferrerID,
    gatewayBankID: Number(obj.gatewayAccountBankId),
    memberEmail: obj.memberEmail,
    memberContactIdOrNumber: obj.memberContactIdorNumber,
    merchantFrontendURL: obj.transactionSuccessUrl,
    attachmentType: obj.attachmentFileType,
    attachment: obj.attachment || null,
    ipAddress: obj.ipAddress,
    saveBankAccountOptions: obj.saveNewBank ? 1 : 0,
    language: obj.language,
    platformType: obj.platform
  }
  // eslint-disable-next-line no-unused-vars
  for (let key in requestOptions) {
    let value = requestOptions[key]
    if (typeof value === 'undefined' || value === null || value.toString().length === 0) {
      delete requestOptions[key]
    }
  }
  return apiHelper().post(`/${PAYMENT.PAYMENT}`, requestOptions)*/
}

function processTransaction(obj) {
  return apiHelper().post(`/${PAYMENT.PAYMENT}/${obj.transactionId}/${PAYMENT.PROCESS_TRANSACTION}`)
}

function getPaymentHistory(data) {
  const requestBody = {
    params: {
      page: data.page,
      type: data.type,
      start_time: data.startTime,
      end_time: data.endTime,
      size: data.size
    }
  }
  return apiHelper().get(`/${PAYMENT.PAYMENT}`, requestBody)
}

/* END TRANSACTION */

function getDepositPercentConfiguration() {
  return apiHelper().get(`/${PAYMENT.CONFIGURATION}`)
}
