<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : ''">
    <v-row no-gutters justify="space-between">
      <v-col cols=10>
        <label style="float:left" v-if="enabledLabel" class="input-field-label ma-0 text-capitalize d-block"
               :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
          {{ label }}<span v-if="!isOptional" class="red--text ml-1">*</span></label>
      </v-col>
      <v-col cols=2>
        <v-tooltip right v-if="tooltip !== ''">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" style="float:right"
                    dark
                    v-bind="attrs"
                    v-on="on">
              help
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols=12>
        <v-text-field
            :error-messages="errorMsg"
            v-if="type == 'text' || type == 'password'"
            class="input-field elevation-0 hide-details"
            :class="customClass"
            :value="value"
            outlined
            color="buttonPrimary"
            :readonly="readonly"
            :disabled="readonly"
            :label="hint"
            :placeholder="placeholder"
            :prefix="prefix"
            :persistent-hint="hint != ''"
            :type="type == 'password' ? (showPassword ? 'text' : 'password') : type"
            @click:append="type == 'password' ? (showPassword = !showPassword) : (showPassword = !showPassword)"
            :append-icon="type == 'password' && allowShowPassword ? (showPassword ? 'mdi-eye' : 'mdi-eye-off') : icon"
            :rules="isOptional ? (value.length > 0 ? rules : []) : rules"
            @input="input"
            @change="change"
            @keyup="keyup"
            @keydown.delete="deleteText"
            @keydown.space="preventLeadingSpace"
            @keydown.native.space.prevent
            @blur="blur"
            :clearable="clearable"
            :solo="solo"
            autocomplete="new-password"
        >
          <template v-slot:append-outer>
            <slot name="form-field-append-outer"></slot>
          </template>
        </v-text-field>

        <v-select
            v-if="type == 'select'"
            class="input-field"
            :class="customClass"
            :items="items"
            :value="value"
            :disabled="disabled"
            outlined
            color="buttonPrimary"
            :label="hint"
            :placeholder="placeholder"
            :hide-details="hint == '' && rules == null"
            :persistent-hint="hint != ''"
            :rules="isOptional ? (value.length > 0 ? rules : []) : rules"
            :item-text="displayText"
            item-value="value"
            @input="input"
            @change="change"
            :clearable="clearable"
        ></v-select>

        <v-file-input color="buttonPrimary" v-if="type == 'fileinput'" :label="label" class="input-field" prepend-icon
                      outlined @input="input" @change="change" :value="value"></v-file-input>
        <!-- <v-row no-gutters v-if="type == 'phoneNo'">
          <v-col cols=2>
          </v-col>
          <v-col cols=10>
          </v-col> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'AppFormField',
  props: {
    solo: {
      required: false,
      default: false,
    },
    value: {
      required: true,
      default: ''
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    hint: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: Array,
      required: false,
      rules: () => []
    },
    isOptional: {
      type: Boolean,
      required: false,
      default: false
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    displayText: {
      required: false,
      default: 'displayText'
    },
    change: {
      type: Function,
      required: false,
      default: function () {
      }
    },
    deleteText:{
      type: Function,
      required: false,
      default: function () {
      }
    },
    keyup: {
      type: Function,
      required: false,
      default: function () {
      }
    },
    allowShowPassword: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    enabledLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    errorMsg: {
      type: String,
      required: false
    },
    blur: {
      type: Function,
      required: false,
      default: function () {
      }
    },
  },
  data: () => ({
    showPassword: false
  }),
  methods: {
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault()
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, '')
    },
    input(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.v-text-field--outlined {
  border-radius: 10px;

  fieldset {
    border-width: 1px;
    border-color: #3A3A3A;
  }
}

.v-text-field--outlined.v-input--is-verify fieldset {
  border-color: green;
  border-width: 2px;
}

.v-input .v-label {
  line-height: 14px !important;
}

.input-field-label {
  font-size: 16px;
}

.input-field {
  .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 0px !important;
  }

  .v-input__append-outer {
    margin-top: 0px !important;
  }

  &.v-input--is-readonly {
    input {
      color: #3A3A3A !important;
      opacity: 0.7;
    }

    .v-input__slot {
      background-color: rgba(0, 0, 0, 0.1);
    }


  }

  .v-input__control {
    margin-bottom: 2px;

    .v-input__slot {
      // border-radius: 25px;
      min-height: 40px;
      height: 40px;
      margin-bottom: 2px;

      .v-text-field__slot {
        .v-label {
          line-height: 14px !important;
          top: 15px;
        }
      }

      .v-input__append-inner {
        margin-top: 14px;
      }

      .v-select__slot {
        .v-input__append-inner {
          margin-top: 8px;
        }
      }
    }

    .v-text-field__details {
      //padding: 0px 4px;
      .v-messages__message {
        font-size: 11px;
      }
    }
  }

}

@media (max-width: 959px) {
  .v-input {
    font-size: 14px;
  }
  .input-field {
    .v-input__control {
      margin-bottom: 2px;

      .v-input__slot {
        // border-radius: 25px;
        min-height: 35px !important;
        height: 35px !important;
        margin-bottom: 2px;

        .v-text-field__slot {
          .v-label {
            line-height: 14px !important;
            top: 12px !important;
          }
        }

        .v-input__append-inner {
          margin-top: 6px !important;
        }
      }

      .v-text-field__details {
        //padding: 0px 4px;
        margin-bottom: 5px !important;

        .v-messages__message {
          font-size: 10px;
        }
      }
    }
  }
  .input-field-label {
    font-size: 14px;
  }
  .input-field {
    .v-label {
      font-size: 14px !important;
    }
  }


}

@media (max-width: 425px) {
  .input-field {
    .v-input__control {
      margin-bottom: 2px;

      .v-input__slot {
        // border-radius: 25px;
        min-height: 35px !important;
        height: 35px !important;
        margin-bottom: 2px;

        .v-text-field__slot {
          .v-label {
            line-height: 14px !important;
            top: 12px !important;
          }
        }

        .v-input__append-inner {
          margin-top: 6px !important;
        }
      }

      .v-text-field__details {
        //padding: 0px 4px;
        margin-bottom: 5px !important;

        .v-messages__message {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
