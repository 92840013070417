<template>
  <div class="bg_home">
    <app-home-banner :filteredAnnouncement="filteredAnnouncement"></app-home-banner>
    <!--    <div class="homepage-banner"></div>-->
    <div class="bg_filter">
      <app-member-balance :parent="this.routeName.HOME"></app-member-balance>
      <app-game-tab-holder></app-game-tab-holder>
      <!--      <app-home-highlight></app-home-highlight>-->
      <app-download-client></app-download-client>
      <app-referral v-if="isLoggedIn"></app-referral>
      <app-seo-content></app-seo-content>

      <!-- <v-row v-if="$vuetify.breakpoint.mdAndDown">
        <v-col cols="12" class="px-12 pb-0">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" class="px-8" no-gutters v-if="$vuetify.breakpoint.mdAndDown">
        <v-col cols="3" class="text-center">
          <v-btn @click="selectionType = 'all'" min-height="60" :elevation="0"
                 :color="selectionType == 'all' ? 'primary' : 'transparent'">
            <v-row no-gutters>
              <v-col cols="12">
                <nav-all class="icon_gameHome"></nav-all>
              </v-col>
              <v-col cols="12">
                {{ $t(`label.all`) }}
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-btn @click="selectionType = 'rng'" min-height="60" :elevation="0"
                 :color="selectionType == 'rng' ? 'primary' : 'transparent'">
            <v-row no-gutters>
              <v-col cols="12">
                <nav-slot class="icon_gameHome"></nav-slot>
              </v-col>
              <v-col cols="12">
                {{ $t(`label.rng_home`) }}
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-btn @click="selectionType = 'casino'" min-height="60" :elevation="0"
                 :color="selectionType == 'casino' ? 'primary' : 'transparent'">
            <v-row no-gutters>
              <v-col cols="12">
                <nav-casino class="icon_gameHome"></nav-casino>
              </v-col>
              <v-col cols="12">
                {{ $t(`label.ld_home`) }}
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-btn @click="selectionType = 'sports'" min-height="60" :elevation="0"
                 :color="selectionType == 'sports' ? 'primary' : 'transparent'">
            <v-row no-gutters>
              <v-col cols="12">
                <nav-sports class="icon_gameHome"></nav-sports>
              </v-col>
              <v-col cols="12">
                {{ $t(`label.sb_home`) }}
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.mdAndDown">
        <v-col cols="12" class="px-12 pt-0">
          <v-divider></v-divider>
        </v-col>
      </v-row> -->
      <!-- <app-game-card :openLoginDialog="this.openLoginDialog" :gameType="'rng'"
                     v-show="selectionType=='all' || selectionType == 'rng' || $vuetify.breakpoint.lgAndUp"
                     :selectionNavigator="selectionType"></app-game-card>
      <app-game-card :openLoginDialog="this.openLoginDialog" :gameType="'ld'"
                     v-show="selectionType=='all' || selectionType == 'casino' || $vuetify.breakpoint.lgAndUp"
                     :selectionNavigator="selectionType"></app-game-card> -->
      <!-- <app-sport-casino-holder class="hidden-lg-and-up"
                               v-show="selectionType=='all' || selectionType == 'sports' || $vuetify.breakpoint.lgAndUp"></app-sport-casino-holder> -->
      <!-- <v-row no-gutters justify="center" align="center">
        <v-col cols="12" :lg="4" class="px-6">
          <hooper style="height: 470px;" :touchDrag="true"
                  :mouseDrag="true" :wheelControl="false">
            <slide>
              <recent-deposit :isLogin="isLoggedIn"></recent-deposit>
            </slide>
            <slide>
              <recent-withdrawal></recent-withdrawal>
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </v-col>
        <v-col cols="12" :lg="4" :class="['px-6', $vuetify.breakpoint.mdAndDown ? 'my-10' : 'my-0']">
          <recent-winner></recent-winner>
        </v-col>
      </v-row> -->
      <v-row class="fill-height" justify="center">
        <v-divider vertical></v-divider>
      </v-row>
      <div class="coin_box_gif" v-if="showCoin && configuration.coin_grab.enabled == true">
        <div style="position:relative">
          <v-btn @click="dontShowMeAgain()" outlined x-small absolute right rounded class="px-0" color="black" style="min-width: 20px; top:-15px; right:-5px;">
            <v-icon size="14px">close</v-icon>
          </v-btn>
          <img :src="grabCoin.home_gif" :aspect-ratio="200 / 200" width="65" height="65" class="grab_coin_icon" @click="navigateToCoinPage()" />
        </div>
      </div>
      <div v-if="configuration.spin_wheel.enabled == true">
        <v-img :src="wof.event_setting.home_gif_url" :aspect-ratio="489 / 516" width="72" height="70" class="wofClass" @click="navigateWOFPage()"></v-img>
      </div>
      <!--      <div>-->
      <v-img :src="`/static/svg/layout/telegram.svg`" :aspect-ratio="489 / 516" width="60" height="60" class="fab-contactUs" @click="goToTelegram"></v-img>
      <!--      <v-img :src="`/static/svg/layout/whatsapp.png`" :aspect-ratio="489/516" width="50" height="50"-->
      <!--             class="fab-contactUsWhatsapp" @click="goToWhatsapp"></v-img>-->
    </div>
  </div>
</template>

<script>
import { DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppHomeBanner from '@/components/home/HomeBanner.vue'
import RecentWinner from '@/components/home/RecentWinner'
import RecentWithdrawal from '@/components/home/RecentWithdrawal'
import RecentDeposit from '@/components/home/RecentDeposit'
import AppPlayWithUs from '@/components/home/PlayWithUs.vue'
import { AnnouncementType } from '@/constants/enums'
import { uiHelper, locale } from '@/util'
import { ANNOUNCEMENT_LIST } from '@/store/announcement.module'
import { SHARED, CACHE_KEY, SESSION } from '@/constants/constants'
import AppSportCasinoHolder from '@/components/home/SportCasinoHolder.vue'
import NavSlot from '@/assets/svg/rng_icon.svg'
import NavCasino from '@/assets/svg/ld_icon.svg'
import NavAll from '@/assets/svg/all_icon.svg'
import NavSports from '@/assets/svg/sportsbook_icon.svg'
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
import { MEMBER_DETAIL } from '@/store/member.module'
import AppHomeHighlight from '@/components/home/Highlight.vue'
import AppGameTabHolder from '@/components/home/GameTabHolder.vue'
import AppReferral from '@/components/member/referral'
import { WHEEL_MODULE } from '@/store/wheel.module'
import { GRAB_COIN_SETTING } from '@/store/bonus.module'
import { REWARD_CONFIGURATION_STATUS } from '@/store/reward.module'
import AppDownloadClient from '@/components/home/DownloadClient.vue'
import AppMemberBalance from '@/components/member/MemberBalance.vue'
import AppSeoContent from '@/components/layout/SEOContent.vue'

export default {
  name: 'Home',
  // metaInfo: {
  //   title: `${locale.getMessage('meta.home_title')}`,
  //   titleTemplate: `%s | ${uiHelper.getHost()}`,
  //   meta: [{charset: 'utf-8'}, {
  //     name: 'description',
  //     content: `${locale.getMessage('meta.home_description')}`
  //   }, {name: 'keyword', content: `${locale.getMessage('meta.home_keyword')}`}]
  // },
  components: {
    Hooper,
    Slide,
    HooperPagination,
    RecentWinner,
    AppHomeBanner,
    AppPlayWithUs,
    RecentDeposit,
    RecentWithdrawal,
    AppSportCasinoHolder,
    NavSlot,
    NavCasino,
    NavAll,
    NavSports,
    AppHomeHighlight,
    AppGameTabHolder,
    AppReferral,
    AppDownloadClient,
    AppMemberBalance,
    AppSeoContent
  },
  data: () => ({
    showCoin: true,
    selectionType: 'all',
    storageAnnouncement: null,
    currentLanguage: uiHelper.getLanguage(),
    filteredAnnouncement: [],
    isIOS: uiHelper.IsIos(),
    routeName: ROUTE_NAME
  }),
  computed: {
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      return d
    },
    wof() {
      return this.$store.state.wheel.wheel
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    announcementList() {
      return this.$store.state.announcement.announcements
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    configuration() {
      let d = this.$store.state.reward.configurationStatus
      if (d.coin_grab.enabled) {
        // this.getGrabCoinSetting()
      }
      return d
    }
  },
  watch: {
    announcementList() {
      this.storageAnnouncement = this.$store.state.announcement.announcements
      this.populateAnnouncement(this.storageAnnouncement)
    }
  },
  created() {
    this.checkShouldHideCoin()
    this.getWOF()
    this.checkIncomingAction()
    this.getAnnouncement()
    this.getMemberDetail()
    this.checkReferCode()
    this.getConfigurationStatus()
    this.metaTag()
  },
  methods: {
    metaTag() {
      console.log('current language', locale.getCurrentLanguage())
      let homeTitle = locale.getMessage('meta.home_title')
      uiHelper.setTitle(homeTitle)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.home_description')}`)
    },
    goToWhatsapp() {
      const whatsappURL = 'https://wa.me/message/ORV7ULEUGP7RP1'
      window.open(whatsappURL, '_blank')
    },
    goToTelegram() {
      let url = uiHelper.getTelegramLink()
      window.open(url, '_blank')
    },
    getConfigurationStatus() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${REWARD_CONFIGURATION_STATUS}`, { filter })
    },
    checkReferCode() {
      let admillClickId = this.$route.query.clickid
      let r = this.$route.query.refer_code
      let af = this.$route.query.af
      let u_s = this.$route.query.utm_source
      let u_m = this.$route.query.utm_medium
      let u_c = this.$route.query.utm_campaign
      let s2 = this.$route.query.s2
      let gnClickId = this.$route.query.click_id
      let trackierCid = uiHelper.getCidFromUrl()

      if (admillClickId) {
        window._admillClickId = admillClickId
      }
      if (typeof u_s != 'undefined' && u_s != '' && u_s != null) {
        uiHelper.setCookie(SESSION.UTM_SOURCE, u_s, 1)
      }
      if (typeof u_m != 'undefined' && u_m != '' && u_m != null) {
        uiHelper.setCookie(SESSION.UTM_MEDIUM, u_m, 1)
      }
      if (typeof u_c != 'undefined' && u_c != '' && u_c != null) {
        uiHelper.setCookie(SESSION.UTM_CAMPAIGN, u_c, 1)
      }
      if (typeof s2 != 'undefined' && s2 != '' && s2 != null) {
        uiHelper.setCookie(SESSION.UTM_S2, s2, 1)
      }
      if (typeof af != 'undefined' && af != '' && af != null) {
        uiHelper.setCookie(SESSION.AFF_CODE, af, 365)
        if (typeof gnClickId != 'undefined' && gnClickId != '' && gnClickId != null) {
          uiHelper.setCookie(SESSION.GN_CLICKID, gnClickId, 1)
        }
      } else {
        if (typeof r != 'undefined' && r != '' && r != null) {
          uiHelper.setCookie(SESSION.REFER_CODE, r, 365)
        }
      }
    },
    getGrabCoinSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, { filter })
    },
    checkShouldHideCoin() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_COIN) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_COIN) == 1) {
        this.showCoin = false
      } else {
        this.showCoin = true
      }
    },
    dontShowMeAgain() {
      this.showCoin = false
      uiHelper.setCookie(SESSION.HIDE_COIN, 1, 0.5)
    },
    navigateToCoinPage() {
      this.$router.push({
        name: ROUTE_NAME.GRAB_COIN,
        params: {
          type: 'coin'
        }
      })
    },
    navigateWOFPage() {
      if (uiHelper.getPlatform() == DevicePlatform.DESKTOP) {
        this.$router.push({
          name: ROUTE_NAME.WOF
        })
      } else {
        this.$router.push({
          name: ROUTE_NAME.MWOF
        })
      }
    },
    getWOF() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, { filter })
    },
    getMemberDetail() {
      if (this.isLoggedIn) this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    goToAffilateProfilePage() {
      this.$router.push({
        name: ROUTE_NAME.AFFILIATE_LINK
      })
    },
    goToAffilateHomePage() {
      this.$router.push({
        name: ROUTE_NAME.AFFILIATE_HOME
      })
    },
    goToDepositPage() {
      if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
        let routeData = this.$router.resolve({
          name: ROUTE_NAME.DEPOSIT
        })
        window.open(routeData.href)
      } else {
        this.$router.push({
          name: ROUTE_NAME.DEPOSIT
        })
      }
    },
    getAnnouncement() {
      if (this.storageAnnouncement == null) {
        let obj = {
          site: uiHelper.getPlatform(),
          type: AnnouncementType.ALL,
          page: '',
          itemsPerPage: ''
        }
        this.$store.dispatch(`${ANNOUNCEMENT_LIST}`, { obj })
      } else {
        this.populateAnnouncement(this.storageAnnouncement)
      }
    },
    populateAnnouncement(announcementData) {
      let p = []
      announcementData.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      this.filteredAnnouncement = p
    },
    checkIncomingAction() {
      let action = this.$route.params.action

      if (action == SHARED.RESET_PASSWORD) {
        let memberCode = this.$route.params.memberCode
        let key = this.$route.params.key
        if (memberCode != null && memberCode != undefined && key != null && key != undefined) this.$parent.openResetPasswordDialog(memberCode, key)
      } else if (action == SHARED.INFO_VERIFICATION) {
        let memberCode = this.$route.params.memberCode
        let otp = this.$route.params.otp
        let type = this.$route.params.type
        let contact = this.$route.params.contact
        if (memberCode != null && memberCode != undefined && otp != null && otp != undefined && type != null && type != undefined)
          this.$parent.memberContactVerification(memberCode, otp, type, contact)
      } else if (action == SHARED.REGISTER) {
        this.openRegisterDialog()
      }
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>
<style lang="scss">
.fab-contactUs {
  position: fixed;
  right: 20px;
  bottom: 24%;
  right: 0;
  z-index: 5;
  text-decoration: none;
  margin-right: 8px;
}

.fab-contactUsWhatsapp {
  position: fixed;
  bottom: 15%;
  right: 4px;
  z-index: 5;
  text-decoration: none;
  margin-right: 8px;
}

.active_click {
  background-color: white;
}

.icon_gameHome {
  fill: white;
  color: black;
  stroke: #9c9ff3;
  width: 40px;
  height: 33px;
  padding-top: 4px;
}

.hooper-pagination {
  position: absolute;
  bottom: -6%;
  right: 52%;
  transform: translateX(52%);
  display: flex;
  padding: 5px 10px;

  ol.hooper-indicator {
    padding-left: 0;
  }
}

.hooper-indicator {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}

.bg_home {
  // background-image: url('/static/image/background/home_page_bg.jpg');
  background-color: #ebebeb;
  // background-position: 0 -300px;
}

.bg_filter {
  padding: 3% 3%;
}

.theme--light {
  &.v-application {
    background-color: #333333 !important;
  }
}

.partner-box {
  height: 450px;
  position: relative;

  .partner-row {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;

    .partner-col {
      position: relative;

      .partner-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .partner-desc {
        border-bottom: 4px solid var(--v-primary-base);
      }

      .partner-box-trophy {
        position: absolute;
        bottom: 0;
        right: 600px;
      }

      .partner-box-footballer {
        position: absolute;
        bottom: 0px;
        height: 420px;
        right: 100px;
        width: 800px;
      }
    }
  }
}

.joinInTheFun {
  border-bottom: 2px solid;
  border-color: var(--v-secondary-base);
  border-radius: 0px !important;
  cursor: pointer;
  color: var(--v-secondary-base);
  font-size: 16px;

  &:hover {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);
  }
}

.announcement-marquee-box {
  max-width: 1840px;
}

.home-announcment-content {
  p {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1650px) {
  .partner-box {
    .partner-row {
      .partner-col {
        .partner-box-trophy {
          right: 550px;
        }

        .partner-box-footballer {
          height: 380px;
          right: 80px;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .partner-box {
    .partner-row {
      .partner-col {
        .partner-box-trophy {
          right: 360px;
          width: 280px;
        }

        .partner-box-footballer {
          height: auto;
          right: 50px;
          width: 600px;
        }
      }
    }
  }

  .joinInTheFun,
  .home-announcment-title,
  .home-announcment-content p {
    font-size: 15px;
  }
}

@media (max-width: 1350px) {
  .partner-box {
    height: 340px;

    .partner-row {
      .partner-col {
        .partner-title {
          width: 300px;
        }

        .partner-box-footballer {
          width: 500px;
        }

        .partner-box-trophy {
          right: 275px;
          width: 220px;
        }
      }
    }
  }
}

@media (max-width: 1263px) {
  .partner-box .partner-row .partner-col .partner-desc {
    font-size: 26px;
  }
  .joinInTheFun,
  .home-announcment-title,
  .home-announcment-content p {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .partner-box .partner-row .partner-col .partner-desc {
    font-size: 24px;
  }
}

@media (max-width: 959px) {
  .bg_filter {
    background-color: white;
  }

  .partner-box {
    height: 600px;
  }

  .partner-box .partner-row .partner-col.partner-box-message {
    height: 300px;
  }
  .partner-box .partner-row .partner-col.partner-box-image {
    height: 300px;
  }
}

@media (max-width: 599px) {
  .hooper-pagination {
    bottom: -3%;
  }

  .bg_home {
    .bg_filter {
      padding: 3% 3%;
    }
  }
  .partner-box {
    height: 300px;
  }
  .partner-box .partner-row .partner-col.partner-box-image {
    display: none;
  }
  .joinInTheFun,
  .home-announcment-title,
  .home-announcment-content p {
    font-size: 13px;
  }
}

@media (max-width: 425px) {
  .hooper-pagination {
    bottom: -5%;
  }
  // .v-badge__badge {
  //   bottom: 8px !important;
  // }
  .partner-box .partner-row .partner-col .partner-title {
    width: 220px;
  }
}

.wofClass {
  bottom: 20%;
  left: 0.5%;
  z-index: 1 !important;
  cursor: pointer;
  position: fixed !important;
}
</style>
