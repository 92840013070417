<template>
  <v-row no-gutters style="position:relative; height:470px;">
    <v-col cols="12" :lg="10" class="mx-auto">
      <v-row no-gutters justify="space-between">
        <v-col cols="8">
          <span class="title_color--text">{{ $t(`label.recentWinner`) }}</span>
        </v-col>
        <v-col cols="4" align="end" class="pt-2">
          <v-btn rounded outlined color="title_color" small :to="{ name: routeName.SLOT }"
                 class="ply_btn_z_index">{{ $t('label.playNow') }}
          </v-btn>
        </v-col>
      </v-row>
      <div class='wrapper'>
        <div class='carousel'>
          <div class='carousel__item' v-for="(n,k) in gameWinnerList" :key="k">
            <div class='carousel__item-head'>
              <div class="gameImageWrapper">
                <div class="imageWrapper"><img
                    :src="`${mediaUrl}/game_icons/en/${n.provider}/${n.image}.jpg`"
                    :alt="'bhaggo-'+ n.image"
                    class="gameImage swiper-lazy swiper-lazy-loaded">
                  <div class="game_mask"></div>
                </div>
              </div>
            </div>
            <div class='carousel__item-body'>
              <v-row no-gutters>
                <v-col cols="12">
                  <p v-if="$vuetify.breakpoint.mdAndUp" class="title my-0">{{ n.winner_name }}</p>
                  <span v-else style="font-size:22px;">{{ n.winner_name }}</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <p v-if="$vuetify.breakpoint.mdAndUp" class='body-1 my-0'
                     style="margin-top:15px;">{{ n.amount | currency }}</p>
                  <span v-else class='body-1' style="margin-top:15px;">{{ n.amount | currency }}</span>
                </v-col>
              </v-row>
              <p style="line-height: 10px;">
                <span
                    :class="['font-weight-bold', $vuetify.breakpoint.mdAndDown ? 'subtitle-2': 'title']">{{
                    n.gameName
                  }}</span>
                <br/>
                <span>{{ n.time }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {ROUTE_NAME} from "@/constants/route.constants";
import {SHARED} from "@/constants/constants";
import {locale} from "@/util";

export default {
  created() {
    for (let i = 0; i < 9; i++) {
      let j = this.getRandomInt(this.gameObject.length);
      this.gameWinnerList.push({
        winner_name: this.makeid(1) + '****' + this.makeid(2),
        amount: this.genRand(300, 1000, 2),
        gameName: this.gameObject[j]["gameName"],
        image: this.gameObject[j]["image"],
        provider: this.gameObject[j]["provider"],
        time: this.getRandomInt(180) + " sec ago",
      })
    }
  },
  methods: {
    makeid(length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    genRand(min, max, decimalPlaces) {
      let rand = Math.random() < 0.5 ? ((1 - Math.random()) * (max - min) + min) : (Math.random() * (max - min) + min);  // could be min or max or anything in between
      let power = Math.pow(10, decimalPlaces);
      return Math.floor(rand * power) / power;
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    getRandomMinMaxInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  data: () => ({
    routeName: ROUTE_NAME,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    gameObject: [
      {
        'provider': 'pp',
        'image': 'vs20bonzgold_0',
        'gameName': 'Bonanza Gold'
      },
      {
        'provider': 'pp',
        'image': 'vs25gldox_0',
        'gameName': 'Golden Ox'
      },
      {
        'provider': "pp",
        'image': "vs10wildtut_0",
        'gameName': "Mysterious Egypt™"
      },
      {
        'provider': 'pp',
        'image': 'vs5aztecgems_0',
        'gameName': 'Aztec Gems'
      },
      {
        'provider': 'pp',
        'image': 'vs5joker_0',
        'gameName': "Joker's Jewels"
      },
      {
        'provider': 'pp',
        'image': 'vs10firestrike_0',
        'gameName': 'Fire Strike'
      },
      {
        'provider': 'pp',
        'image': 'vs20fruitsw_0',
        'gameName': 'Sweet Bonanza'
      },
      {
        'provider': 'pp',
        'image': 'vs9aztecgemsdx_0',
        'gameName': 'Aztec Gems Deluxe'
      },
      {
        'provider': 'pp',
        'image': 'vs10cowgold_0',
        'gameName': 'Cowboys Gold'
      },
      {
        'provider': 'pp',
        'image': 'vs20rhino_0',
        'gameName': 'Great Rhino'
      },
      {
        'provider': 'pp',
        'image': 'vs20vegasmagic_0',
        'gameName': 'Vegas Magic'
      },
      {
        'provider': 'pp',
        'image': 'vs20xmascarol_0',
        'gameName': 'Christmas Carol Megaways'
      },
      {
        'gameName': 'Panther Pays',
        'provider': 'pt',
        'image': 'gpas_panthpays_pop_0',
      },
      {
        'gameName': 'Bonus Bear',
        'provider': 'pt',
        'image': 'bob_0',
      },
      {
        'gameName': 'A Night Out',
        'provider': 'pt',
        'image': 'hb_0',
      },
      {
        'gameName': 'Moon Princess',
        'provider': 'png',
        'image': 'moonprincess_334',
      },
      {
        'gameName': 'Rise of Olympus',
        'provider': 'png',
        'image': 'riseofolympus_352',
      },
      {
        'gameName': 'Tome of Madness',
        'provider': 'png',
        'image': 'tomeofmadness_365',
      },
      {
        'gameName': 'Gemix',
        'provider': 'png',
        'image': 'gemix_286',
      },
    ],
    gameWinnerList: [
      // {
      //   'gameName': 'Bonanza Gold',
      //   'time': '30 sec ago',
      //   'provider': 'pp',
      //   'image': 'vs20bonzgold_0',
      //   'winner_name': 'ammar****',
      //   'amount': '23.50'
      // },
      // {
      //   'gameName': 'Aztec Gems',
      //   'time': '15 sec ago',
      //   'provider': 'pp',
      //   'image': 'vs20bonzgold_0',
      //   'winner_name': 'ammar****',
      //   'amount': '23.50'
      // },
      // {
      //   'gameName': 'Viking Runecraft',
      //   'time': '20 sec ago',
      //   'provider': 'png',
      //   'image': 'vikingrunecraft_319',
      //   'winner_name': 'i9qee****',
      //   'amount': '30'
      // },
      // {
      //   'gameName': '777 Golden Wheel',
      //   'time': '60 sec ago',
      //   'provider': 'gpi',
      //   'image': 'goldenwheel_0',
      //   'winner_name': 'ko123ww****',
      //   'amount': '100'
      // },
      // {
      //   'gameName': 'Wild Walker',
      //   'time': '9 min ago',
      //   'provider': 'pp',
      //   'image': 'vs25walker_0',
      //   'winner_name': 'ahm1****',
      //   'amount': '5'
      // },
      // {
      //   'gameName': '7 Piggie',
      //   'time': '5 sec ago',
      //   'provider': 'pp',
      //   'image': 'vs7pigs_0',
      //   'winner_name': 'chen79***',
      //   'amount': '13'
      // },
      //
      // {
      //   'gameName': 'Great Rhino',
      //   'time': '20 min ago',
      //   'provider': 'pp',
      //   'image': 'vswaysrhino_0',
      //   'winner_name': '9013w***',
      //   'amount': '10.90'
      // }
    ]
  }),
  name: "RecentWinner"
}
</script>

<style lang="scss">
.ply_btn_z_index {
  z-index: 3;
}

.title_color--text {
  font-size: 26px;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.gameImageWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gameImageWrapper .imageWrapper {
  height: 90px;
  width: 125px;
  position: relative;
  overflow: hidden;
  border-radius: 35%;
  border: 3px solid white;
}

.gameImageWrapper .imageWrapper img {
  max-height: 90px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.wrapper {
  margin-top: -50px;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: flex;
  justify-content: center;
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.carousel__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  padding: 0 0px;
  opacity: 0;
  -webkit-filter: drop-shadow(0 2px 2px #555);
  filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  -webkit-animation: carousel-animate-vertical 54s linear infinite;
  animation: carousel-animate-vertical 54s linear infinite;
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(6s * -1);
  animation-delay: calc(6s * -1);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(6s * 0);
  animation-delay: calc(6s * 0);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(6s * 1);
  animation-delay: calc(6s * 1);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(6s * 2);
  animation-delay: calc(6s * 2);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(6s * 3);
  animation-delay: calc(6s * 3);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(6s * 4);
  animation-delay: calc(6s * 4);
}

.carousel__item:nth-child(7) {
  -webkit-animation-delay: calc(6s * 5);
  animation-delay: calc(6s * 5);
}

.carousel__item:nth-child(8) {
  -webkit-animation-delay: calc(6s * 6);
  animation-delay: calc(6s * 6);
}

.carousel__item:last-child {
  -webkit-animation-delay: calc(-6s * 2);
  animation-delay: calc(-6s * 2);
}

.carousel__item-head {
  border-radius: 50%;
  background-color: #d7f7fc;
  position: absolute;
  left: 4%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.carousel__item-body {
  width: 100%;
  border-radius: 70px;
  padding: 5px 50px 5px 160px;
  background: transparent linear-gradient(180deg, #FFA15C 0%, #E2D200 100%) 0% 0% no-repeat padding-box;
}

.title {
  font-size: 20px;
  margin-top: 10px;
}

@media (max-width: 1399px) {
  .carousel__item-head {
    left: -15%;
  }
  .carousel__item-body {
    padding: 5px 20px 5px 90px;
  }
}

@-webkit-keyframes carousel-animate-vertical {
  0% {
    -webkit-transform: translateY(100%) scale(0.5);
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%,
  11.1111111111% {
    -webkit-transform: translateY(100%) scale(0.7);
    transform: translateY(100%) scale(0.7);
    opacity: .4;
    visibility: visible;
  }
  14.1111111111%,
  22.2222222222% {
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  25.2222222222%,
  33.3333333333% {
    -webkit-transform: translateY(-100%) scale(0.7);
    transform: translateY(-100%) scale(0.7);
    opacity: .4;
    visibility: visible;
  }
  36.3333333333% {
    -webkit-transform: translateY(-100%) scale(0.5);
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(-100%) scale(0.5);
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes carousel-animate-vertical {
  0% {
    -webkit-transform: translateY(100%) scale(0.5);
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%,
  11.1111111111% {
    -webkit-transform: translateY(100%) scale(0.7);
    transform: translateY(100%) scale(0.7);
    opacity: .4;
    visibility: visible;
  }
  14.1111111111%,
  22.2222222222% {
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  25.2222222222%,
  33.3333333333% {
    -webkit-transform: translateY(-100%) scale(0.7);
    transform: translateY(-100%) scale(0.7);
    opacity: .4;
    visibility: visible;
  }
  36.3333333333% {
    -webkit-transform: translateY(-100%) scale(0.5);
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(-100%) scale(0.5);
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@media(max-width: 599px) {
  .wrapper {
    margin-top: 0;
  }

  .carousel__item-head {
    left: 5%;
  }

  .carousel__item-body {
    padding: 5px 26px 5px 170px;
  }
}

</style>