<template>
  <div class="play-box">
    <v-row class="play-row mx-0" justify="center">
      <v-col cols="12" class="play-col px-0">
        <div class="text-center play-title">
          <p class="text-center display-1 primary--text font-weight-bold">
            <label class="pb-1 play-desc">{{ $t(`message.whyPlayWithUs`) }}</label>
          </p>
        </div>
      </v-col>
      <v-col cols="7" class="play-now-progress-bar-box">
        <v-row justify="center">
          <v-col class="align" cols="12" sm="4">
            <v-progress-circular rotate="-90" size="280" width="25" value="85" color="primary" class="play-now-progress-bar">
              <v-card flat>
                <v-card-text class="pa-0 font-weight-thin">
                  <label class="play-now-progress-bar-title primary--text">20</label>
                  <label class="play-now-progress-bar-message text-lowercase">{{ $t(`label.sec`) }}</label>
                </v-card-text>
                <v-card-text class="pa-0 text-center">
                  <label class="black--text font-weight-bold play-now-progress-bar-desc">{{ $t(`label.avgDepositTime`) }}</label>
                </v-card-text>
              </v-card>
            </v-progress-circular>
          </v-col>
          <v-col class="align" cols="12" sm="4">
            <v-progress-circular rotate="-90" size="280" width="25" value="90" color="primary_2" class="play-now-progress-bar">
              <v-card flat>
                <v-card-text class="pa-0 font-weight-thin">
                  <label class="play-now-progress-bar-title primary_2--text">6</label>
                  <label class="play-now-progress-bar-message text-lowercase">{{ $t(`label.sec`) }}</label>
                </v-card-text>
                <v-card-text class="pa-0 text-center">
                  <label class="black--text font-weight-bold play-now-progress-bar-dsc">{{ $t(`label.avgPayoutTime`) }}</label>
                </v-card-text>
              </v-card>
            </v-progress-circular>
          </v-col>
          <v-col class="align" cols="12" sm="4">
            <v-progress-circular rotate="-90" size="280" width="25" value="95" color="primary_3" class="play-now-progress-bar">
              <v-card flat>
                <v-card-text class="pa-0 font-weight-thin">
                  <label class="play-now-progress-bar-title secondary--text">98%</label>
                </v-card-text>
                <v-card-text class="pa-0 text-center">
                  <label class="black--text font-weight-bold play-now-progress-bar-desc">{{ $t(`label.statisfiedPlayers`) }}</label>
                </v-card-text>
              </v-card>
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="play-now-description-box">
        <div>
          <p class="mb-0 font-weight-bold play-now-title">{{ $t(`label.securedTransactions`) }}</p>
          <p class="play-now-desc">{{ $t(`message.securedTransactions`) }}</p>
        </div>
        <div>
          <p class="mb-0 font-weight-bold play-now-title">{{ $t(`label.liveChatSupport`) }}</p>
          <p class="play-now-desc">{{ $t(`message.liveChatSupport`) }}</p>
        </div>
        <div>
          <p class="mb-0 font-weight-bold play-now-title">{{ $t(`label.gameSelection`) }}</p>
          <p class="play-now-desc">{{ $t(`message.gameSelection`) }}</p>
        </div>
      </v-col>
      <v-col class="text-center play-with-us-button" cols="12">
        <app-button :title="buttonTitle" :maxWidth="500" :action="buttonAction"></app-button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'appPlayWithUs',
  props: {
    buttonAction: {
      type: Function,
      required: true,
      default: () => {}
    },
    buttonTitle: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.play-now-progress-bar {
  width: 100% !important;
  text-align: center;
  .play-now-progress-bar-title {
    font-size: 60px;
  }
  .play-now-progress-bar-message {
    font-size: 34px;
  }
  .play-now-progress-bar-desc {
    font-size: 16px;
  }
}

.play-now-title {
  font-size: 20px;
  font-weight: bold;
}
.play-now-desc {
  font-size: 16px;
}

.play-box {
  position: relative;
  padding: 30px 0;
  .play-row {
    height: 100%;
    .play-col {
      position: relative;
      .play-desc {
        border-bottom: 4px solid var(--v-primary-base);
      }
    }
  }
}

@media (max-width: 1650px) {
}
@media (max-width: 1500px) {
  .play-now-title {
    font-size: 18px;
    font-weight: bold;
  }

  .play-now-desc {
    font-size: 14px;
  }

  .play-now-progress-bar {
    .v-progress-circular__info {
      padding-top: 10px;
    }
    .play-now-progress-bar-title {
      font-size: 55px;
    }
    .play-now-progress-bar-message {
      font-size: 30px;
    }
    .play-now-progress-bar-desc {
      font-size: 14px;
    }
  }
}
@media (max-width: 1350px) {
}
@media (max-width: 1263px) {
  .play-box .play-row .play-col .play-desc {
    font-size: 26px;
  }

  .play-now-progress-bar-box {
    padding: 0 25px;
    min-width: 100%;
    margin: auto 0;

    .play-now-progress-bar {
      .play-now-progress-bar-title {
        font-size: 60px;
      }
      .play-now-progress-bar-message {
        font-size: 34px;
      }
      .play-now-progress-bar-desc {
        font-size: 16px;
      }
    }
  }
  .play-now-description-box {
    padding: 0 25px;
    min-width: 100%;
    margin: auto 0;
  }
}
@media (max-width: 1024px) {
  .play-box .play-row .play-col .play-desc {
    font-size: 24px;
  }
}
@media (max-width: 959px) {
  .play-now-progress-bar-box .play-now-progress-bar {
    width: 200px !important;
    height: 200px !important;
  }
  .play-now-progress-bar-box {
    text-align: center;
    .play-now-progress-bar {
      width: 100% !important;
      text-align: center;
      .play-now-progress-bar-title {
        font-size: 45px;
      }
      .play-now-progress-bar-message {
        font-size: 25px;
      }
      .play-now-progress-bar-desc {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 599px) {
  .play-now-progress-bar-box .play-now-progress-bar {
    width: 200px !important;
    height: 200px !important;
  }
  .play-with-us-button {
    padding: 0 25px;
  }
}
@media (max-width: 425px) {
  .play-now-title {
    font-size: 14px;
  }
  .play-now-desc {
    font-size: 12px;
  }
}
</style>
