/* e.g. import { ROUTE_NAME } from '@/constants/route.constants' */
const ROUTE_NAME = Object.freeze({
  HOME: 'home',
  /* BANK */
  DEPOSIT: 'deposit',
  DEPOSIT_SUCCESS: 'deposit-success',
  WITHDRAWAL: 'withdrawal',
  TRANSACTION: 'transaction',
  PROCESS_TRANSACTION: 'process-transaction',
  PAY_SECURE:'pay-secure',
  TRANSFER : 'transfer',
  CLAIM_VOUCHER: 'claim-voucher',
  VIP: 'vip',
  IPLVIP:'iplVip',
  VIPTIER: 'vip-tier',
  GRAB_COIN:'grab-coin',
  /* PAYMENT */
  VENDOR_POPUP: 'vendor-popup',
  WOF:'wheel-of-fortune',
  MWOF: 'mwheel-of-fortune',
  /* PROFILE */
  PROFILE_MENU: 'profile-menu',
  PROFILE_LOGOUT: 'profile-logout',
  PROFILE: 'profile',
  PERSONAL: 'personal',
  BANK_ACCOUNT: 'bank-account',
  INBOX: 'inbox',
  RESET_PASSWORD: 'reset-password',
  CHANGE_PASSWORD: 'change-password',
  CONTACT_VERIFICATION: 'contact-verification',
  TRANSACTION_HISTORY: 'transaction-history',
  REWARDS: 'rewards',
  /* TURNOVER */
  TURNOVER: 'tuenover',
  /* BONUS */
  BONUS: 'promotion',
  BONUS_IN_PROGRESS: 'bonus-in-progress',
  BONUS_HISTORY: 'bonus-history',
  /* OTHER */
  ANNOUNCEMENT: 'announcement',
  /* SPORTSBOOK */
  SPORTSBOOK: 'sportsbook',
  SPORTSBOOK_BY_PROVIDER: 'sportsbookByProvider',
  /* CASINO */
  CASINO: 'casino',
  CASINO_BY_PROVIDER: 'casinoByProvider',
  /* ESPORTS */
  ESPORTS: 'esports',
  ESPORTS_BY_PROVIDER: 'esportsByProvider',
  /* FISHING */
  FISHING: 'fishing',
  FISHING_BY_PROVIDER: 'fishingByProvider',
  /* CRASH */
  CRASH: 'crash',
  CRASH_BY_PROVIDER: 'crashByProvider',
  /* SLOT */
  SLOT: 'slot',
  SLOT_BY_PROVIDER: 'slotByProvider',
  SLOT_BY_PROVIDER_AND_GAMENAME: 'slotByProviderAndGameName',
  SLOT_BY_GAMENAME: 'slotByGameName',
  /* SPECIAL */
  SPECIAL: 'special',
  SPECIAL_BY_PROVIDER: 'specialByProvider',
  SHORTCUT: 'shortcut',
  /* TABLE GAME*/
  TABLE_GAMES: 'tablegames',
  TABLE_GAMES_BY_PROVIDER: 'tablegamesByProvider',
  /* LOTTERY */
  LOTTERY: 'lottery',
  LOTTERY_BY_PROVIDER: 'lotteryByProvider',
  /* CRICKET GAME*/
  CRICKET: 'cricket',
  CRICKET_BY_PROVIDER: 'cricketByProvider',
  /* GAME */
  LAUNCH_GAME_LOBBY: 'gameLobby',
  /* PROMOTION */
  PROMOTION: 'promotion',
  PROMOTION_IN_PROGRESS: 'promotion-in-progress',
  PROMOTION_HISTORY: 'promotion-history',
  /* REWARDS */
  REWARDS_BY_TYPE:'rewardsByType',
  TOURNAMENT: 'tournament',
  /* REFERRAL */
  REFERRAL:'referral',
  REFERRAL_TIER:'tier',
  REFERRAL_SUMMARY:'summary',
  REFERRAL_REPORT:'report',
  REFERRAL_HISTORY:'history',
  /* AFFILIATE */
  AFFILIATE_HOME: 'affiliate-home',
  AFFILIATE_MEMBER_REPORT: 'affilaite-member',
  AFFILIATE_MEMBER_DETAIL_REPORT: 'affilaite-detail-member',
  AFFILIATE_GAME_REPORT: 'affiliate-game-report',
  AFFILIATE_AGENT_REPORT: 'affiliate-agent-report',
  AFFILIATE_LINK: 'affilaite-link',
  AFFILIATE_BANK_ACCOUNT: 'affilaite-bank-account',
  AFFILIATE_REFERRAL: 'affiliate-referral',
  AFFILIATE_TRANSACTION: 'affilaite-transaction',
  AFFILIATE_WITHDRAWAL: 'affilaite-withdrawal',
  /* INFO */
  FAQ: 'faq',
  ABOUT_US: 'aboutUs',
  CONTACT_US: 'contactUs',
  /* ERROR */
  ERROR_403: 'ip-restriction',
  ERROR_404: 'page-not-found',
  REGISTER_MOBILE: 'register-mobile',
  /* HISTORY */
  PLAYER_HISTORY: 'player-history',
  REGISTER:'register',
  LOGIN:'login',
  MOBILE_ACCOUNT:'mobile-account',
  MOBLE_HISOTRY:'mobile-history',
  REFERRAL_SUMMARY_REPORT:'/referral/summary/report',
  REFERRAL_SUMMARY_HISTORY:'/referral/summary/history',
  MOBILE_TRANSACTION_HISTORY:'/profileHistory/transaction-history',
  MOBILE_PROMO_HISTORY:'/profileHistory/promo-history',
  MOBILE_BETTING_HISTORY:'/profileHistory/betting-history',
  MOBILE_TO_HISTORY:'/profileHistory/turnover-history',
  AFF_NEWS:'affiliate-news'
})

export { ROUTE_NAME }
