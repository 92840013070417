<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : ''">
    <v-row no-gutters justify="space-between">
      <v-col no-gutters cols=10>
        <label style="float:left" class="input-field-label ma-0 text-capitalize d-block">
          {{ $t(`field.captcha`) }}<span v-if="!isOptional" class="red--text ml-1">*</span></label>
      </v-col>
      <v-col no-gutters cols=2></v-col>
      <v-col no-gutters cols=8>
        <v-text-field
            class="input-field elevation-0 hide-details"
            :value="value"
            outlined
            type="text"
            :rules="rules"
            :error-messages="errorMsg"
            @input="input"
            @keydown.space="preventLeadingSpace"
            @keydown.native.space.prevent
            autocomplete="new-password"
            :autofocus="true">
          <template v-slot:append-outer>
            <slot name="form-field-append-outer"></slot>
          </template>
          <template v-slot:append>
            <slot name="form-field-append"></slot>
          </template>
        </v-text-field>
      </v-col>
      <v-col no-gutters cols=4 @click="refreshCaptchaCode" class="captcha-code">
        <captcha-code class="captcha" ref="code" :enable-click="true" text-fill-color="#ffffff" :width="68"></captcha-code>
        <a class="refresh"><v-icon>mdi-refresh</v-icon></a>
      </v-col>
    </v-row>
  </div>
</template>

<script >
import {MEMBER_REQUEST_CAPTCHA_CODE} from "@/store/member.module";

export default {
  name: 'Captcha',
  props: {
    value: {
      required: true,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: Array,
      required: false,
      rules: () => []
    },
    isOptional: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMsg: {
      type: String,
      required: false
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.requestCaptchaCode();
      setInterval(this.refreshCaptchaCode, 120000);
    })
  },
  methods: {
    refreshCaptchaCode() {
      this.$refs.code.changeCode();
      this.requestCaptchaCode();
    },
    requestCaptchaCode () {
      let data = {
        captcha_id: this.$refs.code.id,
        captcha_code: this.$refs.code.getCode()
      };
      this.$store.dispatch(`${MEMBER_REQUEST_CAPTCHA_CODE}`, {
        data
      });
    },
    getCode () {
      return this.$refs.code.getCode();
    },
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault()
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, '')
    },
    input(value) {
      this.$emit('input', value);
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshCaptchaCode);
  },
}
</script>

<style lang="scss">
.v-text-field--outlined {
  border-radius: 10px;

  fieldset {
    border-width: 1px;
    border-color: #3A3A3A;
  }
}

.v-text-field--outlined.v-input--is-verify fieldset {
  border-color: green;
  border-width: 2px;
}

.v-input .v-label {
  line-height: 14px !important;
}

.input-field-label {
  font-size: 16px;
}

.input-field {
  .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 0px !important;
  }

  .v-input__append-outer {
    margin-top: 0px !important;
  }

  &.v-input--is-readonly {
    input {
      color: #3A3A3A !important;
      opacity: 0.7;
    }

    .v-input__slot {
      background-color: rgba(0, 0, 0, 0.1);
    }


  }

  .v-input__control {
    margin-bottom: 2px;

    .v-input__slot {
      // border-radius: 25px;
      min-height: 40px;
      height: 40px;
      margin-bottom: 2px;

      .v-text-field__slot {
        .v-label {
          line-height: 14px !important;
          top: 15px;
        }
      }

      .v-input__append-inner {
        margin-top: 14px;
      }

      .v-select__slot {
        .v-input__append-inner {
          margin-top: 8px;
        }
      }
    }

    .v-text-field__details {
      //padding: 0px 4px;
      .v-messages__message {
        font-size: 11px;
      }
    }
  }

}

@media (max-width: 959px) {
  .v-input {
    font-size: 14px;
  }
  .input-field {
    .v-input__control {
      margin-bottom: 2px;

      .v-input__slot {
        // border-radius: 25px;
        min-height: 35px !important;
        height: 35px !important;
        margin-bottom: 2px;

        .v-text-field__slot {
          .v-label {
            line-height: 14px !important;
            top: 12px !important;
          }
        }

        .v-input__append-inner {
          margin-top: 6px !important;
        }
      }

      .v-text-field__details {
        //padding: 0px 4px;
        margin-bottom: 5px !important;

        .v-messages__message {
          font-size: 10px;
        }
      }
    }
  }
  .input-field-label {
    font-size: 14px;
  }
  .input-field {
    .v-label {
      font-size: 14px !important;
    }
  }


}

@media (max-width: 425px) {
  .input-field {
    .v-input__control {
      margin-bottom: 2px;

      .v-input__slot {
        // border-radius: 25px;
        min-height: 35px !important;
        height: 35px !important;
        margin-bottom: 2px;

        .v-text-field__slot {
          .v-label {
            line-height: 14px !important;
            top: 12px !important;
          }
        }

        .v-input__append-inner {
          margin-top: 6px !important;
        }
      }

      .v-text-field__details {
        //padding: 0px 4px;
        margin-bottom: 5px !important;

        .v-messages__message {
          font-size: 10px;
        }
      }
    }
  }
}

.captcha-code {
  display: flex;
  justify-content: flex-end;
  .captcha {
    min-height: 40px;
    height: 40px;
    text-align: center;
    background-color: var(--v-primary-base);
  }

  .refresh {
    display: inline-flex;
    min-height: 40px;
    height: 40px;
    width: 28px;
    background-color: var(--v-primary-base);

    i {
      color: white;
    }
  }
}
</style>
