<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'pt-0 pb-4' : 'py-4'">
    <v-row no-gutters class="font-weight-bold" align="end">
      <v-col cols="10">
        {{ $t(`label.matchesHighlight`) }}
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn color="black" text height="auto" class="text-capitalize pr-0 body-2 text-decoration-underline" :to="{ name: routeName.CRICKET }">
          {{ $t(`button.more`) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="mx-0">
      <!-- desktop view-->
      <v-col cols="12" class="pt-2 highlights-container hidden-sm-and-down">
        <div class="matches-box">
          <div class="match-item" v-for="(league, index) in matchesHighlights" :key="index">
            <!-- <v-img width="200" height="200" class="game-card-image-avatar" src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg" alt/> -->
            <v-row no-gutters class="flex-nowrap">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col>
                    <v-card
                      class="pa-2 mb-1 mr-1 font-weight-bold cricket-name-header"
                      :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
                      dark
                      outlined
                      tile
                    >
                      <v-icon dense class="color-base-yellow mb-1 mr-3">emoji_events</v-icon>
                      {{ league.league_name }}
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="9">
                    <v-card
                      light
                      class="pa-2 mb-1 mr-1 grey lighten-4"
                      :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
                      outlined
                      tile
                    >
                      {{ league.home_team }}
                    </v-card>
                  </v-col>
                  <v-col cols="3">
                    <v-card
                      light
                      class="pa-2 mb-1 mr-1 grey lighten-4"
                      :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
                      outlined
                      tile
                    >
                      {{ parseFloat(league.home_odds).toFixed(2) }}
                    </v-card>
                  </v-col>
                  <v-col cols="9">
                    <v-card
                      light
                      class="pa-2 mb-1 mr-1 grey lighten-4"
                      :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
                      outlined
                      tile
                    >
                      {{ league.away_team }}
                    </v-card>
                  </v-col>
                  <v-col cols="3">
                    <v-card
                      light
                      class="pa-2 mb-1 mr-1 grey lighten-4"
                      :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
                      outlined
                      tile
                    >
                      {{ parseFloat(league.away_odds).toFixed(2) }}
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card
                      light
                      class="pa-2 grey lighten-4 mr-1"
                      :class="index == 0 ? 'border-bottom-left-radius' : index == matchesHighlights.length - 1 ? 'border-bottom-right-radius' : ''"
                      outlined
                      tile
                    >
                      <v-row no-gutters>
                        <v-col cols="9">
                          {{ league.match_date }}
                        </v-col>
                        <v-col cols="3" class="text-right">
                          <v-btn color="black" class="primary-button embedded-mobile-login-button font-weight-bold yellow--text" :to="{ name: routeName.CRICKET }">{{ $t(`button.betNow`) }}</v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <!-- mobile view-->
      <v-col cols="12" class="pt-2 highlights-container hidden-md-and-up">
        <div class="matches-box">
          <div class="match-item" v-for="(league, index) in matchesHighlights" :key="index">
            <!-- <v-img width="200" height="200" class="game-card-image-avatar" src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg" alt/> -->
            <v-row no-gutters class="flex-nowrap">
              <v-col cols="12">
                <v-card class="mobile-match-card" dark>
                  <v-row>
                    <v-col cols="12" class="ml-5 mt-2 font-weight-bold ">
                      {{ league.league_name }}
                    </v-col>
                  </v-row>
                  <v-row class="mobile-detail-wrapper">
                    <v-col cols="4" class="mobile-match-details">
                      <span class="team-title">{{ league.home_team }}</span>
                    </v-col>
                    <v-col cols="4" class="mobile-match-details match-date">
                      {{ league.match_date }}
                    </v-col>
                    <v-col cols="4" class="mobile-match-details">
                      <span class="team-title">{{ league.away_team }}</span>
                    </v-col>
                  </v-row>
                </v-card>
                <!--                <v-row no-gutters>-->
                <!--                 <v-col>-->
                <!--                   <v-card>-->
                <!--                    <v-row>-->
                <!--                      <v-col cols="4" class="mobile-match-details">-->
                <!--                        {{ league.home_team }}-->
                <!--                      </v-col>-->
                <!--                      <v-col cols="4" class="mobile-match-details">-->
                <!--                        {{ league.match_date }}-->
                <!--                      </v-col>-->
                <!--                      <v-col cols="4" class="mobile-match-details">-->
                <!--                        {{ league.away_team }}-->
                <!--                      </v-col>-->
                <!--                    </v-row>-->
                <!--                   </v-card>-->
                <!--                 </v-col>-->
                <!--                </v-row>-->
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import stingFormat from 'string-format'
import { formValidator, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { GAME_MATCHES_HIGHLIGHTS } from '@/store/game.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import { locale } from '@/util/localization-helper'
export default {
  name: 'appHomeHighlight',
  data: () => ({
    highlights: null,
    routeName: ROUTE_NAME
  }),
  created() {
    this.getMatchesHighlights()
  },
  computed: {
    matchesHighlights() {
      let k = this.$store.state.game.matchesHighlights
      return _.take(k, 6)
    }
  },
  /*watch: {
    matchesHighlights() {
      this.highlights = this.$store.state.game.matchesHighlights
      return this.highlights
    }
  },*/
  methods: {
    changeLanguage(language) {
      this.$store.dispatch(`${SHARED.LOADING}`)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', language)
      window.location.reload()
    },
    getMatchesHighlights() {
      this.$store.dispatch(`${GAME_MATCHES_HIGHLIGHTS}`)
    }
  }
}
</script>

<style lang="scss">
// .v-card {
//     border-radius: 5px 0 0 5px !important;
// }
.highlights-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;
  .border-top-left-radius {
    border-top-left-radius: 10px !important;
  }
  .border-top-right-radius {
    border-top-right-radius: 10px !important;
  }
  .border-bottom-left-radius {
    border-bottom-left-radius: 10px !important;
  }
  .border-bottom-right-radius {
    border-bottom-right-radius: 10px !important;
  }
}
.matches-box {
  margin-bottom: 5px;
  .match-item {
    display: inline-block;
    width: 360px; // padding-right: 20px;
    margin: 0 15px 0 0;
  }
  .mobile-match-card {
    &.v-card {
      text-overflow: ellipsis;
      overflow: hidden;
      width: auto;
      white-space: nowrap;
      background-color: #1d1d1d;
      border-radius: 15px;
      color: #ffffff;
    }
  }
  .mobile-detail-wrapper {
    background-color: #333333;
    height: 100px;
    .mobile-match-details {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .team-title {
      white-space: break-spaces;
      overflow: hidden;
      padding: 0 5px 0 5px;
      text-align: center;
    }
    .match-date {
      white-space: pre-line;
      text-overflow: ellipsis;
      text-align: center;
      align-items: unset;
      color: var(--v-newMobilePrimary-base);
    }
  }
}
@media (max-width: 599px) {
  .highlights-container {
    font-size: 14px;
  }

  .matches-box {
    .match-item {
      width: 285px;
    }
  }
}

.cricket-name-header {
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}
</style>
