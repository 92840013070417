import { referralService } from '@/services'
import {SHARED_LOADING, SHARED_UNLOADING} from '@/store/shared.module'

//define module name
const MODULE_NAME = 'referral/'

//define actions name
const A_REFERRAL_OVERVIEW = 'overview'
const A_REFERRAL_DOWNLINES = 'downlines'
const A_REFERRAL_REPORT = 'report'
const A_REFERRAL_COMMISSION = 'commission'
const A_RESET_REFERRAL_COMMISSION_STORE = 'resetCommissionResponse'
const A_REFERRAL_CONFIG = 'config'

//define dispatch action
export const REFERRAL_GET_OVERVIEW = MODULE_NAME + A_REFERRAL_OVERVIEW
export const REFERRAL_GET_DOWNLINES = MODULE_NAME + A_REFERRAL_DOWNLINES
export const REFERRAL_GET_REPORT = MODULE_NAME + A_REFERRAL_REPORT
export const REFERRAL_POST_COMMISSION = MODULE_NAME + A_REFERRAL_COMMISSION 
export const REFERRAL_RESET_COMMISSION = MODULE_NAME + A_RESET_REFERRAL_COMMISSION_STORE
export const REFERRAL_GET_CONFIG = MODULE_NAME + A_REFERRAL_CONFIG

//define mutations name
const M_REFERRAL_OVERVIEW = 'setReferralOverview'
const M_REFERRAL_DOWNLINES = 'setReferralDownlines'
const M_REFERRAL_REPORT = 'setReferralReport'
const M_REFERRAL_COMMISSION = 'setCommissionResponse'
const M_RESET_REFERRAL_COMMISSION = 'resetCommissionResponse'
const M_REFERRAL_CONFIG = 'setReferralConfig'

//init app state
const state = {
  overview: {},
  downlines: {},
  reports: {},
  commission: {},
  redeemCommissionResponse: {
    action: 'commission',
    complete: false,
    success: false,
    code: 0
  },
  config: []
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_REFERRAL_OVERVIEW]({ commit }) {
    referralService.referralOverview().then(
      data => {
        let result = data
        commit(M_REFERRAL_OVERVIEW, {result})
      },
      error => {
        let data = error
        commit(M_REFERRAL_OVERVIEW, {result})
      }
    )
  },
  [A_REFERRAL_DOWNLINES]({ commit }) {
    referralService.referralDownlines().then(
      data => {
        let result = data
        commit(M_REFERRAL_DOWNLINES, {result})
      },
      error => {
        let data = error
        commit(M_REFERRAL_DOWNLINES, {result})
      }
    )
  },
  [A_REFERRAL_REPORT]({ commit }) {
    referralService.referralReport().then(
      data => {
        let result = data
        commit(M_REFERRAL_REPORT, {result})
      },
      error => {
        let data = error
        commit(M_REFERRAL_REPORT, {result})
      }
    )
  },
  [A_REFERRAL_COMMISSION]({dispatch, commit}, {obj}) {
    dispatch(`${SHARED_LOADING}`, {}, {root: true})
    referralService.redeemCommission(obj).then(
        data => {
            let result = data
            dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            commit(M_REFERRAL_COMMISSION, {result})
        },
        error => {
            let result = error
            dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            commit(M_REFERRAL_COMMISSION, {result})
        }
    )
  },
  [A_RESET_REFERRAL_COMMISSION_STORE]({commit}) {
    commit(M_RESET_REFERRAL_COMMISSION)
  },
  [A_REFERRAL_CONFIG]({ commit }) {
    referralService.referralConfig().then(
      data => {
        let result = data
        commit(M_REFERRAL_CONFIG, {result})
      },
      error => {
        let data = error
        commit(M_REFERRAL_CONFIG, {result})
      }
    )
  }
}

//to update state values (called by actions)
const mutations = {
  [M_REFERRAL_OVERVIEW](state, {result}) {
    if(result.success) {
        state.overview = result.data
    }
  },
  [M_REFERRAL_DOWNLINES](state, {result}) {
    if(result.success) {
        state.downlines = result.data
    }
  },
  [M_REFERRAL_REPORT](state, {result}) {
    if(result.success) {
        state.reports = result.data
    }
  },
  [M_REFERRAL_COMMISSION](state, {result}) {
    let s = state.redeemCommissionResponse
    s.complete = true
    s.success = result.success
    s.code = result.code
  },
  [M_RESET_REFERRAL_COMMISSION](state) {
      let s = state.redeemCommissionResponse
      s.complete = false
      s.success = false
      s.code = 0
  },
  [M_REFERRAL_CONFIG](state, {result}) {
    if(result.success) {
        state.config = result.data
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
