<template>
  <v-dialog persistent :value="dialogShow" :max-width="maxWidth" :max-height="maxHeight" style="position:relative;" content-class="box_instant_login">
    <v-icon size="30px" :class="['dialog-close-icon white--text close_instant']" color="white" @click="closeAction" v-if="closeAction != null" alt="close button">mdi-close-circle-outline</v-icon>
    <v-card class="dialog-card" style="border-radius:10px;" color="white">
      <v-row no-gutters class="dialog-header">
        <v-carousel cycle height="auto" interval="1000000" hide-delimiter-background :hide-delimiters="true" :show-arrows="arrayInfo.length > 1" show-arrows-on-hover>
          <v-carousel-item v-for="(k, i) in arrayInfo" :key="i">
            <v-sheet height="100%" color="transparent">
              <v-row no-gutters justify="space-between" :class="$vuetify.breakpoint.mdAndDown ? 'dialog-header' : 'dialog-header'">
                <v-col cols="12" class="d-flex flex-column">
                  <div>
                    <label class="dialog-title text-capitalize d-block full-width white--text primary pa-2 max_height_setting">
                      {{ $t(`label.importantAnnouncement`) }}
                    </label>
                    <v-row no-gutters class="pt-2 pb-0 px-2" v-if="k.message">
                      <v-col>
                        <template v-if="isImage(k.message)">
                          <v-img
                            :src="k.message.replace(/<\/?p>/g, '').replace(/<img[^>]+src=&quot;([^&quot;]+)&quot;[^>]*>/i, '$1')"
                            height="350px"
                            width="300px"
                            object-fit="contain"
                            class="mx-auto d-block"
                          ></v-img>
                        </template>
                        <template v-else>
                          <label class="full-width dialog-subtitle" v-html="k.message">
                            {{ k.message.replace(/<[p>]*>/g, ' ').replace(/<[/p>]*>/g, ' ') }}
                          </label>
                        </template>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center align-center mt-1">
                        <v-btn color="secondary" @click="closeAction">{{ $t(`label.understood`) }}</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="2" class="d-flex justify-content-center align-content-center">
                  <v-icon class="full-width" :class="['white--text dialog-close-icon']" @click="closeAction" v-if="closeAction != null">
                    mdi-close
                  </v-icon>
                </v-col>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import Template from '../../public/static/html/promotion/template.html'

export default {
  name: 'InstantLoginPopupDialog',
  components: { Template },
  props: {
    arrayInfo: {
      type: Array,
      required: false,
      default: null
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 500
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 500
    },
    closeAction: {
      required: false,
      default: null
    },
    dialogShow: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    isImage(url) {
      let regex = /<img\s+[^>]*src="([^">]+)"[^>]*>/i
      let match = regex.exec(url)

      return match && match.length > 1
    },
    goToPromoPage() {
      this.closeAction()
      this.$router.push({
        name: ROUTE_NAME.PROMOTION
      })
    }
  }
}
</script>

<style>
.close_instant {
  position: absolute;
  width: auto;
  float: right;
  top: 17px;
  right: 2%;
  z-index: 99999;
}

.box_instant_login {
  height: 480px !important;
  box-shadow: none !important;
}

.popupImage {
  text-align: center;
}

.popupImage > img {
  text-align: center;
  width: 430px;
  height: auto;
  aspect-ratio: 430/532;
  padding: 5px;
  border-radius: 20px;
}

@media (max-width: 750px) {
  .popupImage > img {
    width: 385px;
  }

  .close_instant {
    right: 20px;
  }
}

@media (max-width: 450px) {
  .popupImage > img {
    width: 305px;
  }

  .close_instant {
    right: 5px;
  }
}

.max_height_setting {
  max-height: 270px;
  overflow-y: auto;
}
</style>
