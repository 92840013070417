<template>
    <div>
        <div class="container demo animated">
            <v-row no-gutters style="width: 100%;">
                <v-col cols="12">
                    <v-row no-gutters justify="space-between" class="px-2 py-1">
                        <v-col cols="12">
                            <span class="title_color--text">{{$t(`label.recentWithdrawal`)}}</span>
                        </v-col>
                    </v-row>
                    <v-layout v-for="(n,k) in withdrawal_member" class="px-2 my-1">
                        <v-card style="width:100%;" class="text-center"
                                :color="k % 2 == 0 ? 'odd_prime' : 'even_prime'">
                            <v-row no-gutters :class="$vuetify.breakpoint.mdAndUp ? 'px-lg-6 px-sm-2' : 'px-lg-6 px-sm-2 py-0 px-2'">
                                <v-col cols="12">
                                    <v-list-item :class="['grow', $vuetify.breakpoint.mdAndDown ? 'px-0' :'']">
                                        <v-list-item-avatar color="grey darken-3">
                                            <v-img
                                                    class="elevation-6"
                                                    :src="`${mediaUrl}/avatar_icons/withdrawal.svg`"
                                            ></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'pl-10' : 'pl-1'">
                                            <v-list-item-title
                                                    class="font-weight-bold subtitle-2 text-left text-lowercase">
                                                {{n.username}}
                                            </v-list-item-title>
                                            <v-list-item-title
                                                    class="font-weight-bold caption grey--text text-left">
                                                {{n.gateway}}
                                            </v-list-item-title>
                                            <v-list-item-title
                                                    class="font-weight-bold caption grey--text text-left">
                                                {{$t(`label.duration`)}} : {{ n.duration }}  {{$t(`label.min`)}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-row no-gutters class="px-2 col-6">
                                            <v-col cols="12" class="pa-0">
                                                <v-row
                                                        align="center"
                                                        justify="end"
                                                >
                                                    <v-chip
                                                            class="my-0 chips-size"
                                                            color="#262441"
                                                            text-color="white"
                                                    >
                                                        <span class="subheading">{{n.amount | currency}}</span>
                                                    </v-chip>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" class="pa-0">
                                                <v-row
                                                        align="center"
                                                        justify="end"
                                                >
                                                    <span class="caption">{{getDateTime()}}</span>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-layout>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {SHARED} from "@/constants/constants";
    import {Hooper, Slide} from 'hooper';
    import 'hooper/dist/hooper.css';
    import {locale} from "@/util/localization-helper";

    export default {
        name: "RecentWithdrawal",
        components: {
            Hooper,
            Slide
        },
        created() {
            for (let i = 0; i < 5; i++) {
                this.withdrawal_member.push({
                  username: this.makeid(2) + '****' + this.makeid(2),
                    amount: this.genRand(50, 1000, 2),
                    level: this.level[this.getRandomInt(this.level.length)],
                    gateway: locale.getMessage(`paymentMethod.${this.bank[0]}`),
                    seconds: this.getRandomInt(61),
                    duration:this.getRandomMinMaxInt(3,6)
                })
            }
        },
        methods: {
            getRandomMinMaxInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min + 1)) + min;
            },
            getDateTime(){
                let dt =  new Date();
                dt.setMinutes(dt.getMinutes() - this.getRandomInt(15));
                return dt.toLocaleString();
            },
            makeid(length) {
                let result = '';
                let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            genRand(min, max, decimalPlaces) {
                let rand = Math.random() < 0.5 ? ((1 - Math.random()) * (max - min) + min) : (Math.random() * (max - min) + min);  // could be min or max or anything in between
                let power = Math.pow(10, decimalPlaces);
                return Math.floor(rand * power) / power;
            },
            getRandomInt(max) {
                return Math.floor(Math.random() * Math.floor(max));
            }
        },
        data: () => ({
            bank: ['lb'],
            level: ['level_1', 'level_2', 'level_3', 'level_4', 'level_5'],
            mediaUrl: SHARED.MEDIA_SERVER_URL,
            settings: {
                "dots": false,
                "infinite": true,
                "slidesToShow": 5,
                "slidesToScroll": 1,
                "vertical": true,
                "autoplay": true,
                "autoplaySpeed": 5000,
                "verticalSwiping": true,
                "arrows": false
            },
            withdrawal_member: [],
            colors: [
                'indigo',
                'warning',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
                'Fifth',
            ],
        })
    }
</script>

<style lang="scss">
    .chips-size {
        min-width: 120px !important;
        display: flex !important;
        justify-content: flex-end !important;
    }

    .odd_prime {
        background: rgba(217, 217, 255, 0.32) !important;
        border: 1px solid #B9B9B9;
        box-sizing: border-box;
        box-shadow: inset 3px 4px 6px rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(100px);
    }

    .even_prime {
        background: rgba(116, 116, 234, 0.45) !important;
        border: 1px solid #7474EA;
        box-sizing: border-box;
        box-shadow: inset 3px 4px 6px rgba(255, 255, 255, 0.23);
        backdrop-filter: blur(100px);
    }

    @media (max-width: 520px) {
        .chips-size {
            min-width: 70px !important;
        }
        .title_color--text {
            font-size: 18px;
        }
    }
</style>