import {ROUTE_NAME} from '@/constants/route.constants'
/* e.g. import { SHARED, SESSION } from '@/constants/constants' */
const SHARED = Object.freeze({
    GA_TRACKING_CODE: process.env.VUE_APP_GOOGLE_ANALYTICS,
    SUPPORTED_LANGUAGES: process.env.VUE_APP_AVAILABLE_LANGUAGE.split(','),
    BONUS_JSON_URL: process.env.VUE_APP_BONUS_JSON,
    DESKTOP_BANNER_JSON_URL: process.env.VUE_APP_DESKTOP_BANNER_JSON,
    MOBILE_BANNER_JSON_URL: process.env.VUE_APP_MOBILE_BANNER_JSON,
    MENU_GAME_POSITION: ['sb', 'ld', 'rng', 'fishing', 'lottery', 'special'],
    DEFAULT_LAYOUT: 'layout-master',
    BLANK_LAYOUT: 'blank-layout-master',
    DEFAULT_LANGUAGE: process.env.VUE_APP_DEFAULT_LANGUAGE,
    FALLBACK_LANGUAGE: 'en',
    DEFAULT_CURRENCY: process.env.VUE_APP_DEFAULT_CURRENCY,
    FINGERPRINT_KEY: process.env.VUE_APP_FINGERPRINT_KEY,
    ONE_SIGNAL_APP_ID: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
    DEFAULT_DATE: new Date().toISOString().substr(0, 10),
    DEFAULT_PRIOR_ONE_MONTH_START_TIME: new Date(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).getFullYear(), new Date(Date.now() - new Date().getTimezoneOffset() * 60000).getMonth() - 1, 2).toISOString().substr(0, 10) + ' 00:00:00',
    DEFAULT_SEVEN_DAYS:
        new Date(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
            .toISOString()
            .substr(0, 10) + ' 00:00:00',
    DEFAULT_PAGE_NUMBER: 1,
    DEFAULT_PAGE_SIZE: 10,
    DEFAULT_ALL_VALUE: -1,
    DEFAULT_ALL_VALUE_STR: '',
    DEFAULT_LOGIN_PASSWORD_METHOD: '2',
    PAGE_SIZE_LIST: [10, 50, 100],
    CONFIG_PAGE_SIZE: 100,
    DIVIDER_CLOSE: 'close',
    DIVIDER_NEXT: 'arrow_forward_ios',
    TOKEN_EXPIRY: 3000,
    HISTORY_STATUS: 'history',
    PENDING_STATUS: 'pending',
    MEDIA_SERVER_URL: process.env.VUE_APP_MEDIA_SERVER_URL,
    MEDIA_DOMAIN: process.env.VUE_APP_MEDIA_DOMAIN,
    SERVER_NAME: process.env.VUE_APP_SERVER,
    SPIN_WHEEL_URL: process.env.VUE_APP_SPIN_MEDIA_SERVER_URL,
    DEPOSIT: 'Deposit',
    WITHDRAWAL: 'Withdrawal',
    EMAIL: 'email',
    MOBILE: 'mobile',
    LINE: 'line',
    ENTER_KEY: 'Enter',
    RESET_PASSWORD: 'resetPassword',
    REGISTER: 'register',
    INFO_VERIFICATION: 'infoVerification',
    MIN_JACKPOT_VALUE: '1520733',
    AFFILIATE_DOMAIN: [
        {
            domain: 'www.bhaggo.vip',
            affiliateCode: 'fqshr3l26l'
        }
    ],
    MEMBER_HEADER_NAVIGATOR_LINK: [
        {
            routeName: ROUTE_NAME.PROFILE,
            text_key: 'account',
            requiredLogin: true,
            showOnlyAfterLogin: true
        },
        {
            routeName: ROUTE_NAME.TRANSACTION,
            text_key: 'history',
            requiredLogin: true,
            showOnlyAfterLogin: true
        },
        {
            routeName: ROUTE_NAME.BANK_ACCOUNT,
            text_key: 'bankAccount',
            requiredLogin: true,
            showOnlyAfterLogin: true
        },
        {
            routeName: ROUTE_NAME.AFFILIATE_LINK,
            text_key: 'affiliate',
            requiredLogin: false,
            showOnlyAfterLogin: true
        },
        {
            routeName: ROUTE_NAME.FAQ,
            text_key: 'faq',
            requiredLogin: false,
            showOnlyAfterLogin: false
        },
        {
            routeName: ROUTE_NAME.ABOUT_US,
            text_key: 'aboutUs',
            requiredLogin: false,
            showOnlyAfterLogin: false
        }
    ],
    PROFILE_AFFILIATE_LINK: [
        {
            text_key: 'qrCode',
            routeName: ROUTE_NAME.AFFILIATE_LINK,
            accessLevel: [1, 2]
        },
        {
            text_key: 'memberList',
            routeName: ROUTE_NAME.AFFILIATE_MEMBER_REPORT,
            accessLevel: [1, 2]
        },
        {
            text_key: 'memberDetailReport',
            routeName: ROUTE_NAME.AFFILIATE_MEMBER_DETAIL_REPORT,
            accessLevel: [1, 2]
        },
        {
            text_key: 'gameReport',
            routeName: ROUTE_NAME.AFFILIATE_GAME_REPORT,
            accessLevel: [1, 2]
        },
        {
            text_key: 'agentReport',
            routeName: ROUTE_NAME.AFFILIATE_AGENT_REPORT,
            accessLevel: [2]
        }
    ]
})

const SESSION = Object.freeze({
    UTM_SOURCE: 'u_s',
    UTM_MEDIUM: 'u_m',
    UTM_CAMPAIGN: 'u_c',
    UTM_S2: 'u_s2',
    HIDE_COIN: 'hide_coin',
    REFER_CODE: 'refer_code',
    AFF_CODE: 'af_code',
    GN_CLICKID: 'gn_click',
    HIDE_ANG_PAO: 'hide_angpao',
    VIP: 'vip',
    CURRENCY: 'currency',
    LANGUAGE: 'language',
    USERNAME: 'username',
    TOKEN: 'authToken',
    TOKEN_EXPIRY: 'tokenExpiry',
    LAST_ACTIVITY_TIME: 'lastActivityTime',
    USER_MODULES: 'userModules',
    USER_FILTER: 'userFilter',
    ANNOUNCEMENT_FILTER: 'announcementFilter',
    BONUS_FILTER: 'bonusFilter',
    DEPOSIT_TRANSACTION_ID: 'depositTransactionId',
    DEPOSIT_AMOUNT: 'depositAmount',
    DEPOSIT_BONUS_ID: 'depositBonusId',
    REGION_LOCALE:'regionLocale',
    CID: 'cid',
    REGION_LOCALE:'regionLocale'
})

const CACHE_KEY = Object.freeze({
    ANNOUNCEMENT: 'announcement',
    GAME_LIST: 'gameList',
    GAME_LIST_TYPE_NAME: 'gameListTypeName',
    GAME_PROVIDER_TYPE: 'gameProviderType',
    JACKPOT: 'jackpot',
    HOMEPAGE_HOTGAMELIST :'homepageHotGames'
})

const KEY = Object.freeze({
    PAYMENT_MERCHANTS_CODE: process.env.VUE_APP_PAYMENT_MERCHANTS_CODE,
    PAYMENT_MERCHANTS_KEY: process.env.VUE_APP_PAYMENT_MERCHANTS_KEY,
    AFFILIATE_PAYMENT_MERCHANTS_CODE: process.env.VUE_APP_AFFILIATE_PAYMENT_MERCHANTS_CODE,
    AFFILIATE_PAYMENT_MERCHANTS_KEY: process.env.VUE_APP_AFFILIATE_PAYMENT_MERCHANTS_KEY
})

const API = Object.freeze({
    BASE_URL: process.env.VUE_APP_API_BASE_URL,
    PAYMENT_URL: process.env.VUE_APP_API_PAYMENT_URL,
    CMS_URL: process.env.VUE_APP_CMS_API_URL
})

const DATE = Object.freeze({
    SHORT: 'YYYY-MM-DD',
    LONG: 'YYYY-MM-DD HH:mm:ss',
    WEEK: 'DD MMMM YYYY',
    TIMER: 'MMM DD, YYYY',
    TIME: 'HH:mm:ss'
})

const TIME = Object.freeze({
    FULL: 'HH:mm:ss Z',
    HALF: 'hh:mm A'
})

const REGEX = Object.freeze({
    username: /^[a-zA-Z0-9]+$/,
    password: /^[a-zA-Z0-9]+$/,
    mobileNumberChina: /^(?=\d{11}$)^1(?:3\d|4[57]|5[^4\D]|66|7[^249\D]|8\d|9[89])\d{8}$/,
    mobileNumberBDT: /^\d{10}$/,
    mobileNumber: /^\d{10,11}$/,
    referralCode: /^[a-zA-Z0-9]+$/,
    realname: /^[a-zA-Z\u4e00-\u9eff-\u0E00-\u0E7F./_]+( [a-zA-Z\u4e00-\u9eff-\u0E00-\u0E7F./_]+)*$/,
    invalidCharacters: /^[^;:：；<>《》=*]+$/,
    address: /^[a-zA-Z\u4e00-\u9eff-\u0E00-\u0E7F./_]+( [a-zA-Z\u4e00-\u9eff-\u0E00-\u0E7F./_]+)*$/,
    number: /^[\d]+$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    line: /^[a-zA-Z0-9_-]+$/,
    integer: /^[0-9]+(\.[0-9]{0,0})?$/,
    decimalTwo: /^-?[0-9]+(\.[0-9]{0,2})?$/,
    decimalFour: /^-?[0-9]+(\.[0-9]{0,4})?$/,
    bankNumberSixDigit: /^[0-9]{6}$/,
    notAllowMultipleSpace: /^\S+(?: \S+)*$/
})

const MobileHeaderPageName = {
    /*general pages*/
    [ROUTE_NAME.REGISTER]:'Registration',
    [ROUTE_NAME.DEPOSIT]:'Deposit',
    [ROUTE_NAME.MOBILE_ACCOUNT] : 'My Profile',
    [ROUTE_NAME.WITHDRAWAL]:'Withdrawal',
    [ROUTE_NAME.CLAIM_VOUCHER]:'Claim Voucher',
    [ROUTE_NAME.CHANGE_PASSWORD]:'Change Password',
    [ROUTE_NAME.PROMOTION]:'Promotion',
    [ROUTE_NAME.LOGIN]:'Login',
    [ROUTE_NAME.TOURNAMENT]:'Tournament',
    [ROUTE_NAME.FAQ]:'FAQ',
    [ROUTE_NAME.ABOUT_US]:'About us',
    [ROUTE_NAME.CONTACT_US]:'Contact Us',
    [ROUTE_NAME.VIP]:'Player Level',
    [ROUTE_NAME.BANK_ACCOUNT]:'Bank Details',
    [ROUTE_NAME.REWARDS]:'Rewards',
    [ROUTE_NAME.INBOX]:'Inbox',
    [ROUTE_NAME.CHANGE_PASSWORD]:'Change Password',
    [ROUTE_NAME.ANNOUNCEMENT]:'Announcement',
    [ROUTE_NAME.PERSONAL]:'My Profile',
    [ROUTE_NAME.PROFILE]:'My Profile',
    [ROUTE_NAME.RESET_PASSWORD]:'Reset Password',

    /*mobile specific page*/
    [ROUTE_NAME.MOBLE_HISOTRY]:'History',
    [ROUTE_NAME.MOBILE_TRANSACTION_HISTORY]:'Transaction History',
    [ROUTE_NAME.MOBILE_PROMO_HISTORY]:'Promotion History',
    [ROUTE_NAME.MOBILE_BETTING_HISTORY]:'Betting History',
    [ROUTE_NAME.MOBILE_TO_HISTORY]:'Turnover History',


    /*Referarl*/
    [ROUTE_NAME.REFERRAL]:'Referral',
    [ROUTE_NAME.REFERRAL_SUMMARY]:'Referral',
    [ROUTE_NAME.REFERRAL_TIER]:'Referral',
    [ROUTE_NAME.REFERRAL_SUMMARY_HISTORY]:'Referral Summary History',
    [ROUTE_NAME.REFERRAL_SUMMARY_REPORT]:'Referral Summary Report',

    /*Games Pages*/
    [ROUTE_NAME.CRICKET]:'Cricket Betting',
    [ROUTE_NAME.CRICKET_BY_PROVIDER]:'Cricket Betting',
    [ROUTE_NAME.SPORTSBOOK]:'Sports',
    [ROUTE_NAME.SPORTSBOOK_BY_PROVIDER]:'Sports',
    [ROUTE_NAME.CASINO]:'Live Casino',
    [ROUTE_NAME.CASINO_BY_PROVIDER]:'Live Casino',
    [ROUTE_NAME.SLOT]:'Slot',
    [ROUTE_NAME.SLOT_BY_PROVIDER]:'Slot',
    [ROUTE_NAME.SLOT_BY_PROVIDER_AND_GAMENAME]:'Slot',
    [ROUTE_NAME.SLOT_BY_GAMENAME]:'Slot',
    [ROUTE_NAME.TABLE_GAMES]:'Table',
    [ROUTE_NAME.TABLE_GAMES_BY_PROVIDER]:'Table',
    [ROUTE_NAME.LOTTERY]:'Lottery',
    [ROUTE_NAME.LOTTERY_BY_PROVIDER]:'Lottery',
    [ROUTE_NAME.CRASH]:'Crash',
    [ROUTE_NAME.CRASH_BY_PROVIDER]:'Crash',

    /*reward*/
    [ROUTE_NAME.WOF]:'Wheel of Fortune',
    [ROUTE_NAME.MWOF]:'Wheel of Fortune',
    [ROUTE_NAME.GRAB_COIN]:'Coin Grab',

        /*referral*/
    [ROUTE_NAME.REFERRAL_REPORT]:'Referral Report',
    [ROUTE_NAME.REFERRAL_HISTORY]:'Referral History',

    /*affiliate*/
    [ROUTE_NAME.AFFILIATE_WITHDRAWAL]:'Affiliate Withdrawal',
    [ROUTE_NAME.AFFILIATE_TRANSACTION]:'Affiliate Transaction',
    [ROUTE_NAME.AFFILIATE_BANK_ACCOUNT]: 'Affiliate Bank Details',
    [ROUTE_NAME.AFFILIATE_HOME]:'Affiliate Profile',

}

// this.MobileHeaderPageName[ROUTE_NAME.MOBILE_ACCOUNT] = 'Account'
export {SHARED, SESSION, CACHE_KEY, KEY, API, DATE, TIME, REGEX , MobileHeaderPageName}
