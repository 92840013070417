<template>
  <v-row no-gutters>
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'login_panel ' : 'login_panel px-5'">
      <!--      <v-row v-if="isMobileLogin" style="margin: unset">-->
      <!--        <v-col cols="12" class="mobile-header text-center">-->
      <!--          {{ $t(`label.loginToBabu`) }}-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <v-row>
        <v-col cols="12">
          <v-form ref="loginForm" :class="isMobileLogin ? 'ma-8' : ''">
            <app-form-field
              :enabledLabel="true"
              v-model.trim="loginObj.memberCode"
              :label="$t(`field.username`)"
              :placeholder="$t(`fieldHint.fillUpHere`)"
              :tooltip="$t(`fieldHint.username`)"
              :rules="validator.usernameRules()"
            ></app-form-field>
            <app-form-field
              :enabledLabel="true"
              v-model.trim="loginObj.password"
              :label="$t(`field.password`)"
              :placeholder="$t(`fieldHint.fillUpHere`)"
              :tooltip="$t(`fieldHint.password`)"
              :rules="validator.passwordRules()"
              :allowShowPassword="false"
              type="password"
            ></app-form-field>
            <v-row no-gutters class="mb-3">
              <v-col cols="12" class="text-right forgot-password">
                <v-btn
                  text
                  height="auto"
                  v-on:click="isMobileLogin ? openSelfForgotPasswordDialog() : openForgotPasswordDialog()"
                  class="text-capitalize pr-0 font-weight-bold title_color2--text body-3 text-decoration-underline "
                >
                  {{ $t(`label.forgotPassword`) }}
                </v-btn>
              </v-col>
            </v-row>
            <!-- <app-button :action="this.login" :title="$t(`button.login`)"></app-button> -->
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn height="auto" @click="this.login" depressed class="primary-button mobile-login-btn theme-button text-capitalize pa-2 font-weight-bold">
                  {{ $t(`button.login`) }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-center">
                <label v-show="loginErrorMsg" class="red--text subtitle-2">{{ loginErrorMsg }}</label>
                <label v-show="fullPageLoginErrMsg" class="red--text subtitle-2">{{ fullPageLoginErrMsg }}</label>
              </v-col>
            </v-row>
            <v-row no-gutters class="py-6">
              <v-col cols="12">
                <v-divider class="dialog-divider"></v-divider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <label class="input-field-label ma-0 pb-1 d-block title_color2--text">
                  {{ $t(`message.dontHaveAccount`) }}
                </label>
              </v-col>
            </v-row>
            <v-row no-gutters class="pb-10">
              <v-col cols="12">
                <app-button class="dialog-button bhagyoPrimary" :action="this.openRegister" :title="$t(`button.register`)"></app-button>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <!-- <p class="mt-3 text-center caption">
          <v-btn @click="this.closeAction" text :ripple="false" class="text-decoration-underline">
              {{$t(`label.notNow`) }}
          </v-btn>
      </p> -->
      <!--            <p class="mt-5 text-center body-1">-->
      <!--                {{ $t(`message.dontHaveAccount`) }}-->
      <!--                <v-btn text height="auto" @click="openRegister"-->
      <!--                       class="pa-0 text-uppercase font-weight-bold secondary&#45;&#45;text">{{ $t(`label.signUp`) }}-->
      <!--                </v-btn>-->
      <!--            </p>-->
    </v-col>
    <!-- FORGOT PASSWORD DIALOG -->
    <app-dialog :dialogShow="forgotPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)" :closeAction="this.closeForgotPasswordDialog">
      <app-forgot-password :openRegisterDialog="openRegister" :openResetPasswordDialog="openResetPasswordDialog"></app-forgot-password>
    </app-dialog>
    <app-dialog :dialogShow="resetPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)" :closeAction="this.closeResetPasswordDialog">
      <app-reset-password :resetPasswordMemberCode="resetPasswordObj.memberCode" :resetPasswordContact="resetPasswordObj.contact"></app-reset-password>
    </app-dialog>
  </v-row>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import stingFormat from 'string-format'
import { formValidator, uiHelper, errorCodeHelper, locale } from '@/util'
import { SESSION, SHARED } from '@/constants/constants'
import { MEMBER_LOGIN, MEMBER_RESET_LOGIN_STORE, MEMBER_RESET_MOBILE_LOGIN_STORE, MEMBER_RESET_UPDATE_FORGOT_PASSWORD } from '@/store/member.module'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppForgotPassword from '@/components/member/forgotPassword'
import AppResetPassword from '@/components/member/resetPassword'

export default {
  name: 'appLogin',
  components: { AppForgotPassword, AppResetPassword },
  mounted() {
    this.$refs.loginForm.reset()
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
      default: () => {}
    },
    openRegisterDialog: {
      type: Function,
      required: true,
      default: () => {}
    },
    openForgotPasswordDialog: {
      type: Function,
      required: true,
      default: () => {}
    },
    loginErrorMsg: {
      type: String,
      required: true,
      default: ''
    },
    isMobileLogin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    // pageDialogShow: false,
    // pageDialog: {
    //   title: '',
    //   closePageDialog: () => {},
    //   messageTitle: '',
    //   note: '',
    //   message: [],
    //   button: []
    // },
    resetPasswordObj: {
      key: '',
      memberCode: ''
    },
    resetPasswordDialogShow: false,
    forgotPasswordDialogShow: false,
    validator: formValidator,
    stingFormat: stingFormat,
    retainSignIn: false,
    loginObj: {
      memberCode: '',
      password: '',
      domain: '',
      platform: '',
      option: SHARED.DEFAULT_LOGIN_PASSWORD_METHOD,
      fp: ''
    },
    fullPageLoginErrMsg: ''
  }),
  computed: {
    // changeForgotPasswordResponse() {
    //   return this.$store.state.member.changeForgotPassword.complete
    // },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    selfLoginResponseComplete() {
      return this.$store.state.member.mobileLoginResponse.complete
    }
  },
  watch: {
    changeForgotPasswordResponse() {
      //new forgotpassword flow
      let response = this.$store.state.member.changeForgotPassword
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.updateForgotPasswordCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_FORGOT_PASSWORD}`)
      }
    },
    isLoggedIn() {
      this.$refs.loginForm.reset()
    },
    selfLoginResponseComplete() {
      //only handle to display single page login error msg , redirection still handled by layoutmaster.vue 's loginResp complete
      let response = this.$store.state.member.mobileLoginResponse
      if (response.code != 0 && !response.success) {
        if (this.isMobileLogin) {
          this.fullPageLoginErrMsg = errorCodeHelper.getErrorCodeDesc(response.code)
        }
      }
      this.$store.dispatch(`${MEMBER_RESET_MOBILE_LOGIN_STORE}`)
    }
  },
  methods: {
    openPageDialog(dialog) {
      this.pageDialog = dialog
      this.pageDialogShow = true
    },
    openResetPasswordDialog(memberCode, contact) {
      this.resetPasswordObj.memberCode = memberCode
      this.resetPasswordObj.contact = contact
      this.closeForgotPasswordDialog()
      this.resetPasswordDialogShow = true
    },
    closeResetPasswordDialog() {
      this.resetPasswordObj.memberCode = ''
      this.resetPasswordObj.contact = ''
      this.resetPasswordDialogShow = false
    },
    // selfLoginResponseComleteDialog(){}
    closeForgotPasswordDialog() {
      this.forgotPasswordDialogShow = false
    },
    openSelfForgotPasswordDialog() {
      this.forgotPasswordDialogShow = true
      console.log('opened forgot password')
    },
    openRegister() {
      this.$refs.loginForm.reset()
      if (this.isMobileLogin) {
        this.$router.push({
          name: ROUTE_NAME.REGISTER
        })
      } else {
        this.openRegisterDialog()
      }
    },

    login() {
      if (this.$refs.loginForm.validate()) {
        let jObj = this
        this.loginObj.domain = uiHelper.getHostname()
        this.loginObj.platform = uiHelper.getPlatform()
        this.loginObj.language = uiHelper.getLanguage()
        let existFp = typeof uiHelper.getCookie('fp_visitor') != 'undefined' && uiHelper.getCookie('fp_visitor') != null && uiHelper.getCookie('fp_visitor') != ''
        if (existFp) {
          jObj.loginObj.fp = uiHelper.getCookie('fp_visitor')
          let loginObj = this.loginObj
          this.$store.dispatch(`${MEMBER_LOGIN}`, {
            loginObj
          })
        } else {
          try {
            jObj.loginObj.fp = uiHelper.getFingerprints()
            if (jObj.loginObj.fp != '' && jObj.loginObj.fp != null) {
              uiHelper.setCookie('fp_visitor', jObj.loginObj.fp, 30)
              let loginObj = jObj.loginObj
              this.$store.dispatch(`${MEMBER_LOGIN}`, {
                loginObj
              })
            }
          } catch (err) {
            console.log(err)
            let loginObj = this.loginObj
            this.$store.dispatch(`${MEMBER_LOGIN}`, {
              loginObj
            })
          }
        }
        // try {
        //   srtmCommands.push({
        //     event: 'track.user.login',
        //     payload: {
        //       action: 'complete',
        //       userId: this.loginObj.memberCode
        //     }
        //   })
        //   console.log('done sradar login')
        // } catch (err) {
        //   console.log(err)
        // }
      }
    }
  }
}
</script>

<style lang="scss">
.v-application {
  .mobile-login-btn {
    background-color: var(--v-newMobileSecondaryLighter-base) !important;
    color: #000000;
    border: solid 1px black;
  }
}
.login_panel {
  .bhagyoPrimary {
    background: var(--v-newMobilePrimary-base) !important;
    color: #ffffff !important;
  }

  .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #ffffff;
    color: #2699fb;
  }

  .theme--dark.v-label {
    color: #2699fb;
  }

  .theme--dark.v-input input,
  .theme--dark.v-input textarea,
  .theme--dark.v-icon {
    color: #2699fb;
  }

  .input-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 0;
    margin-right: 5px;
  }

  .dialog-divider {
    border-width: 0.8px !important;
    border-color: #acacac !important;
    height: 100%;
  }

  .forgot-password {
    margin-top: -15px;
  }
}
</style>
