<template>
  <div class="full-width">
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'px-2' : 'px-5'">
      <!--      <v-row v-if="isMobileRegistration">-->
      <!--        <v-col cols="12" class="mobile-header text-center">-->
      <!--          {{ this.step<2 ? $t(`label.registerNow`) :$t(`label.welcomeToBrand`)}}-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <v-row no-gutters v-if="isMobileRegistration">
        <v-col cols="12" class="mt-5 text-center">
          <v-item-group v-model="step" class="text-center d-inline-flex" mandatory>
            <v-item v-for="(n, index) in regSteps" :key="`btn-${n}`">
              <div class="mx-auto itemDiv" :class="index == 1 ? 'lastItemDiv' : ''">
                <v-btn
                  class="mobile-resgiter-dialog-pagination"
                  active-class="mobile-resgiter-dialog-pagination-active"
                  width="30"
                  height="30"
                  icon
                  :input-value="step == n - 1"
                  @click="onChangePagination(n - 1)"
                  :class="step == index + 1 || regSuccessfully ? 'mobile-resgiter-dialog-pagination-active' : ''"
                >
                  {{ n }}
                </v-btn>
              </div>
            </v-item>
            <v-item>
              <div class="itemDiv">
                <v-btn
                  class="mobile-resgiter-dialog-pagination"
                  width="30"
                  height="30"
                  active-class="mobile-resgiter-dialog-pagination-active"
                  icon
                  :class="regSuccessfully == true ? 'mobile-resgiter-dialog-pagination-active' : ''"
                >
                  <v-icon style="width: 30px;height: 30px;font-size: 30px">check_circle_outline</v-icon>
                </v-btn>
              </div>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-window v-model="step" :class="isMobileRegistration ? 'ma-8' : ''">
            <v-window-item :value="0">
              <v-form ref="registerForm1">
                <v-card-text class="ma-0 pa-0">
                  <app-form-field
                    ref="username"
                    :enabledLabel="true"
                    v-model.trim="registerObj.memberCode"
                    :label="$t(`field.username`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    :tooltip="$t(`fieldHint.username`)"
                    :errorMsg="userNameErrorMsg"
                    :blur="checkUsername"
                    :rules="validator.usernameRules()"
                  ></app-form-field>
                  <app-form-field
                    :enabledLabel="true"
                    v-model.trim="registerObj.password"
                    :label="$t(`field.password`)"
                    :placeholder="$t(`fieldHint.fillUpPwdHere`)"
                    :tooltip="$t(`fieldHint.password`)"
                    :rules="validator.passwordRules()"
                    :allowShowPassword="false"
                    type="password"
                  ></app-form-field>
                  <app-form-field
                    :enabledLabel="true"
                    v-model.trim="confirmedPassword"
                    :label="$t(`field.confirmPassword`)"
                    :placeholder="$t(`fieldHint.confirmPassword`)"
                    :rules="validator.confirmPasswordRules(confirmedPassword, registerObj.password)"
                    :allowShowPassword="false"
                    type="password"
                  ></app-form-field>
                  <app-form-field
                    :enabledLabel="true"
                    :label="$t(`field.currency`)"
                    v-model.trim="registerObj.currency"
                    :disabled="currencyList.length == 1"
                    type="select"
                    :rules="validator.dropDownListRules()"
                    :items="currencyList"
                  ></app-form-field>
                </v-card-text>
                <v-row no-gutters>
                  <v-col cols="12">
                    <app-button class="dialog-button" :class="isMobileRegistration ? 'mobile-next-btn' : ''" :action="this.nextPage" :title="$t(`button.next`)"></app-button>
                    <!-- <v-btn height="auto" @click="step++" color="black" depressed class="primary-button theme-button text-capitalize pa-3 font-weight-bold yellow--text subtitle-1">{{ $t(`button.next`) }}
                    </v-btn> -->
                  </v-col>
                </v-row>
              </v-form>
            </v-window-item>
            <v-window-item :value="1">
              <v-form ref="registerForm2">
                <v-card-text class="ma-0 pa-0">
                  <label class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                    {{ $t(`field.phoneNumber`) }}
                    <span class="red--text ml-1">*</span>
                  </label>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <!-- <v-select elevation="2" class="input-field mr-2" item-text="text" item-value="value" :items="mobileNumberPrefixList" dense outlined></v-select> -->
                      <app-form-field
                        v-model.trim="registerObj.currency"
                        :placeholder="mobileNumberPrefixList[0].text"
                        type="select"
                        :rules="validator.dropDownListRules()"
                        :items="mobileNumberPrefixList"
                        :display-text="this.getMobileNumberDisplayText"
                        :disabled="mobileNumberPrefixList.length == 1"
                        :custom-class="'mr-2 reg-mobile-prefix'"
                      ></app-form-field>
                    </v-col>
                    <v-col cols="7">
                      <app-form-field
                        :enabledLabel="false"
                        v-model.trim="registerObj.mobileNumber"
                        :placeholder="$t(`fieldHint.fillUpHere`)"
                        :rules="validator.mobileNumberRules(defaultLanguage)"
                      ></app-form-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters justify="space-between">
                     <v-col cols=4 md=3>
                       <label class="input-field-label ma-0 text-capitalize d-block"
                              :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">{{ $t(`field.otpNo`) }}<span
                           class="red--text ml-1">*</span></label>
                     </v-col>
                     <v-col cols=8 md=9 class="text-right">
                       <v-btn v-show="smsRequestAvailable && smsVerificationSent" text height="auto"
                              @click="requestSmsVerificationCode"
                              class="text-capitalize pr-0 font-weight-bold title_color2--text"
                              :class="$vuetify.breakpoint.smAndDown ? 'caption':'body-2'">
                         {{ $t(`button.notReceivedOtp`) }}
                       </v-btn>
                     </v-col>
                   </v-row>
                    <v-row no-gutters>
                     <v-col cols=5 class="pr-2">
                       <v-btn height="auto" :disabled="smsVerificationSent && !smsVerificationResult "
                              @click="this.requestSmsVerificationCode" color="black" depressed
                              class="otp-button theme-button font-weight-bold yellow--text">{{ $t(`button.requestOtp`) }}
                       </v-btn>
                     </v-col>
                     <v-col cols=7>
                       <app-form-field :enabledLabel=false v-model.trim="registerObj.verificationCode"
                                       :placeholder="$t(`fieldHint.fillUpHere`)"
                                       :rules="validator.requiredRules()"></app-form-field>
                     </v-col>
                   </v-row> -->
                  <v-row no-gutters>
                    <v-col cols="12">
                      <app-form-field
                        :enabledLabel="true"
                        v-model.trim="registerObj.referralCode"
                        :label="$t(`field.referralCode`)"
                        :placeholder="$t(`fieldHint.optional`)"
                        :rules="validator.referralCodeRules()"
                        :readonly="isReferralCodeReadonly"
                        :isOptional="true"
                      ></app-form-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-checkbox class="mt-0 pt-0">
                        <template v-slot:label>
                          <p class="caption pt-2 mb-0">
                            <label>{{ $t(`message.registerDisclaimer_1`) }}</label>
                            <br />
                            <label class="text-decoration-underline font-weight-bold" style="cursor:pointer" @click="openRegisterTncDialog">{{ $t(`message.registerDisclaimer_2`) }}</label>
                          </p>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <app-button
                        class="dialog-button"
                        :class="isMobileRegistration ? 'mobile-next-btn' : ''"
                        :action="this.register"
                        :title="isMobileRegistration ? $t(`button.mobileRegister`) : $t(`button.register`)"
                      ></app-button>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <label v-show="registerErrorMsg" class="red--text subtitle-2">{{ registerErrorMsg }}</label>
                      <label v-show="errorMsg" class="red--text subtitle-2">{{ errorMsg }}</label>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-window-item>
            <v-window-item v-if="isMobileRegistration" :value="2" class="mobile-reg-success text-center">
              <v-img src="/static/svg/round-circle-check.svg" width="65" class="mx-auto mb-7"></v-img>
              <span class="font-weight-bold">{{ $t(`message.mobileRegSuccessTitle`) }}</span>
              <p>{{ $t(`message.mobileRegSuccessMsg1`) }}</p>
              <span class="font-weight-bold">{{ $t(`message.mobileRegSuccessMsg2`) }}</span>
              <div class="mobile-reg-success-footer">
                <v-btn class="gotoDepo-btn font-weight-bold" :to="{ name: routeName.DEPOSIT }">{{ $t(`label.mobileRegDepositNow`) }}</v-btn>
                <v-btn class="gotoHome-btn font-weight-bold" :to="{ name: routeName.HOME }">{{ $t(`label.gotoHomePage`) }}</v-btn>
              </div>
            </v-window-item>
            <!-- <v-window-item :value="2">
                <div class="pa-4 text-center">
                    <v-img class="mb-4" contain height="128" src="https://cdn.vuetifyjs.com/images/logos/v.svg"></v-img>
                    <h3 class="text-h6 font-weight-light mb-2">
                        Welcome to Vuetify
                    </h3>
                    <span class="text-caption grey--text">Thanks for signing up!</span>
                </div>
            </v-window-item> -->
            <v-row no-gutters v-if="!isMobileRegistration">
              <v-col cols="12" class="mt-5">
                <v-item-group v-model="step" class="text-center" mandatory>
                  <v-item v-for="n in regSteps" :key="`btn-${n}`">
                    <v-btn
                      color="white"
                      class="ma-2 resgiter-dialog-pagination"
                      active-class="resgiter-dialog-pagination-active"
                      x-small
                      icon
                      :input-value="step == n - 1"
                      @click="onChangePagination(n - 1)"
                    >
                      {{ n }}
                    </v-btn>
                  </v-item>
                  <v-item>
                    <v-btn active-class="resgiter-dialog-pagination-active" icon :disabled="true">
                      <v-icon>check_circle</v-icon>
                    </v-btn>
                  </v-item>
                </v-item-group>
              </v-col>
            </v-row>
          </v-window>
        </v-col>
      </v-row>

      <!-- <p class="mt-5 text-center body-1">
              {{ $t(`message.alreadyHaveAccount`) }}
              <v-btn text height="auto" @click="openLogin" class="pa-0 text-uppercase font-weight-bold secondary--text">{{ $t(`label.signIn`) }}
              </v-btn>
          </p> -->
      <div v-if="step == 0 && !isMobileRegistration">
        <v-row no-gutters class="py-6">
          <v-col cols="12">
            <v-divider class="dialog-divider"></v-divider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <label class="input-field-label ma-0 pb-1 d-block title_color2--text">
              {{ $t(`message.alreadyHaveAccount`) }}
            </label>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn height="auto" @click="openLogin" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">
              {{ $t(`button.login`) }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <app-dialog :dialogShow="registerTncDialog.show" :max-width="880" :title="registerTncDialog.title" :closeAction="this.closeRegisterTncDialog">
      <v-container class="fill-height px-6">
        <iframe class="registerTnc-iframe" :src="registerTncUrl" frameborder="0"></iframe>
      </v-container>
      <!-- <app-button :action="this.closeRegisterTncDialog" :title="$t(`label.agree`)"></app-button> -->
    </app-dialog>
  </div>
</template>

<script>
import stringFormat from 'string-format'
import { formValidator, uiHelper, locale } from '@/util'
import {
  MEMBER_REGISTER,
  MEMBER_SMS_REQUEST,
  MEMBER_SMS_VERIFY,
  MEMBER_GET_SMS_REQUIREMENT,
  MEMBER_CHECK_USERNAME,
  MEMBER_RESET_CHECK_USERNAME,
  MEMBER_LOGIN,
  MEMBER_RESET_REGISTER_STORE,
  MEMBER_RESET_MOBILE_REGISTER_STORE
} from '@/store/member.module'
import { SESSION, SHARED } from '@/constants/constants'
import { errorCodeHelper } from '@/util/error-code-helper'
import { MEMBER_RESET_SMS_VERIFICATION } from '@/store/member.module'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import Template from '../../../public/static/html/promotion/template.html'
import { ROUTE_NAME } from '@/constants/route.constants'

export default {
  name: 'appRegister',
  components: { Template },
  mounted() {
    this.checkAffiliateReferralCode()
    this.checkPhoneVerificationRequirements()
    try {
      srtmCommands.push({
        event: 'track.user.registration',
        payload: {
          action: 'start'
        }
      })
    } catch (err) {
      console.log(err)
    }
  },
  props: {
    openLoginDialog: {
      type: Function,
      required: true,
      default: () => {}
    },
    registerErrorMsg: {
      type: String,
      required: true,
      default: ''
    },
    isMobileRegistration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    routeName: ROUTE_NAME,
    regSuccessfully: false,
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    registerTncUrl: '',
    registerTncDialog: {
      show: false,
      title: locale.getMessage(`label.registerAccountAgreement`)
    },
    validator: formValidator,
    stringFormat: stringFormat,
    isReferralCodeReadonly: false,
    registerObj: {
      memberCode: '',
      password: '',
      currency: uiHelper.getCurrency(),
      email: '',
      mobileNumber: '',
      verificationCode: '',
      line: '',
      referralCode: '',
      isEarlyBird: '0',
      domain: '',
      language: '',
      platform: '',
      fp: '',
      agent_team: '',
      utm_source: uiHelper.getCookie(SESSION.UTM_SOURCE),
      utm_medium: uiHelper.getCookie(SESSION.UTM_MEDIUM),
      utm_campaign: uiHelper.getCookie(SESSION.UTM_CAMPAIGN),
      s2: uiHelper.getCookie(SESSION.UTM_S2),
      cid: sessionStorage.getItem(SESSION.CID)
    },
    confirmedPassword: '',
    step: 0,
    regSteps: 2,
    mobileNumberPrefixList: [
      // {
      //     text: "+91",
      //     value: "INR"
      // },
      {
        text: '+880',
        value: 'BDT'
      }
    ],
    currencyList: [
      // 'INR',
      'BDT'
    ],
    errorMsg: undefined,
    userNameErrorMsg: undefined
  }),
  computed: {
    selfRegisterResponseComplete() {
      return this.$store.state.member.mobileRegisterResponse.complete
    },
    smsRequired() {
      return this.$store.state.member.smsVerificationRequired.required
    },
    smsVerificationSent() {
      return this.$store.state.member.smsVerificationSent.success
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.success
    },
    smsVerificationError() {
      return this.$store.state.member.smsVerificationResult.message
    },
    smsRequestError() {
      return this.$store.state.member.smsVerificationSent.message
    },
    smsRequestAvailable() {
      return this.$store.state.member.smsVerificationSent.countDownTimer <= 0
    },
    smsCountdownTimer() {
      return this.$store.state.member.smsVerificationSent.countDownTimer
    },
    checkUsernameResult() {
      return this.$store.state.member.checkUsernameResult.completed
    }
  },
  watch: {
    smsRequestError() {
      if (this.$store.state.member.smsVerificationSent.complete && !this.$store.state.member.smsVerificationSent.success) {
        this.errorMsg = errorCodeHelper.getErrorCodeDesc(this.$store.state.member.smsVerificationSent.code)
      }
    },
    checkUsernameResult() {
      if (this.$store.state.member.checkUsernameResult.completed) {
        if (this.$store.state.member.checkUsernameResult.success) {
          this.userNameErrorMsg = undefined
        } else {
          if (this.$store.state.member.checkUsernameResult.code == -2) {
            this.userNameErrorMsg = locale.getMessage(`fieldErrorMessage.usernameExceedLength`)
          } else if (this.$store.state.member.checkUsernameResult.code == -1) {
            this.userNameErrorMsg = locale.getMessage(`fieldErrorMessage.usernameUsed`)
          }
        }
        this.$store.dispatch(`${MEMBER_RESET_CHECK_USERNAME}`)
      }
    },
    selfRegisterResponseComplete() {
      let response = this.$store.state.member.mobileRegisterResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Register`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.selfRegisterResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_MOBILE_REGISTER_STORE}`)
      }
    }
  },
  methods: {
    selfRegisterResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        if (this.isMobileRegistration) {
          this.errorMsg = errorCodeHelper.getErrorCodeDesc(response.code)
        }
      } else {
        this.regSuccessfully = true
        this.step = 2
      }
    },
    checkUsername() {
      if (this.registerObj.memberCode.trim() !== '') {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_CHECK_USERNAME}`, {
          registerObj
        })
      }
    },
    onChangePagination(page) {
      if (page == 1) {
        if (this.$refs.registerForm1.validate()) {
          this.step = page
        }
      } else {
        this.step = page
      }
    },
    getMobileNumberDisplayText(data) {
      return data.text
    },
    errorDialog(errorCode) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.register`)
      dialog.dialogXButton = null
      dialog.message.push(errorCodeHelper.getErrorCodeDesc(errorCode))
      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      //this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      this.$eventHub.$emit('close-page-dialog', true)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    checkPhoneVerificationRequirements() {
      this.$store.dispatch(`${MEMBER_GET_SMS_REQUIREMENT}`)
    },
    openRegisterTncDialog() {
      this.registerTncUrl = `${process.env.BASE_URL}static/html/other/${uiHelper.getLanguage()}/register-tnc.html`
      this.registerTncDialog.show = true
      this.registerTncDialog.dialogXButton = this.closePromotionTncDialog
    },
    closeRegisterTncDialog() {
      this.registerTncDialog.show = false
    },
    openLogin() {
      this.$refs.registerForm1.reset()
      if (this.$refs.registerForm2) this.$refs.registerForm2.reset()
      this.openLoginDialog()
    },
    register() {
      // if (this.smsRequired && !this.smsVerificationSent && !this.smsVerificationResult.success) {
      //     this.requestSmsVerificationCode()
      //     return
      // }
      if (this.$refs.registerForm2.validate()) {
        //this.registerObj.currency = uiHelper.getCurrency()
        let jObj = this
        this.registerObj.platform = uiHelper.getPlatform()
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let existFp = typeof uiHelper.getCookie('fp_visitor') != 'undefined' && uiHelper.getCookie('fp_visitor') != null && uiHelper.getCookie('fp_visitor') != ''
        if (existFp) {
          this.errorMsg = ''
          jObj.registerObj.fp = uiHelper.getCookie('fp_visitor')
          let registerObj = this.registerObj
          this.$store.dispatch(`${MEMBER_REGISTER}`, {
            registerObj
          })
        } else {
          try {
            this.errorMsg = ''
            jObj.registerObj.fp = uiHelper.getFingerprints()
            if (jObj.registerObj.fp != '' && jObj.registerObj.fp != null) {
              uiHelper.setCookie('fp_visitor', jObj.registerObj.fp, 30)
              let registerObj = jObj.registerObj
              this.$store.dispatch(`${MEMBER_REGISTER}`, {
                registerObj
              })
            }
          } catch (err) {
            log(err)
            this.errorMsg = ''
            let registerObj = this.registerObj
            this.$store.dispatch(`${MEMBER_REGISTER}`, {
              registerObj
            })
          }
        }
        this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      }
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      if (this.registerObj.mobileNumber) {
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
      }
    },
    async verifySmsCode() {
      if (this.$refs.registerForm.validate()) {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
          registerObj
        })
      }
    },
    checkAffiliateReferralCode() {
      let af = this.$route.query.af
      let gnClickId = this.$route.query.click_id
      let r = this.$route.query.refer_code
      let cid = uiHelper.getCidFromUrl()

      if (typeof af != 'undefined' && af != '' && af != null) {
        uiHelper.setCookie(SESSION.AFF_CODE, af, 365)
        if (typeof gnClickId != 'undefined' && gnClickId != '' && gnClickId != null) {
          uiHelper.setCookie(SESSION.GN_CLICKID, gnClickId, 1)
        }
      } else {
        if (typeof r != 'undefined' && r != '' && r != null) {
          uiHelper.setCookie(SESSION.REFER_CODE, r, 365)
        }
      }

      let referCode = uiHelper.getCookie(SESSION.REFER_CODE)
      let afCode = uiHelper.getCookie(SESSION.AFF_CODE)
      if (afCode != null && afCode != '') {
        this.registerObj.agent_team = afCode
        this.isReferralCodeReadonly = true
      } else if (referCode != null && referCode != '') {
        this.registerObj.referralCode = referCode
        this.isReferralCodeReadonly = true
      } else {
        let affiliateDomain = SHARED.AFFILIATE_DOMAIN.find(x => x.domain.toLowerCase() == window.location.host.toLowerCase())
        if (affiliateDomain != null && affiliateDomain != undefined) {
          this.registerObj.referralCode = affiliateDomain.affiliateCode
          this.isReferralCodeReadonly = true
        } else {
          this.registerObj.referralCode = ''
          this.isReferralCodeReadonly = false
        }
      }

      if (cid) {
        this.registerObj.cid = cid
      }
    },
    nextPage() {
      if (this.$refs.registerForm1.validate() && !this.userNameErrorMsg) this.step++
    }
  }
}
</script>

<style lang="scss">
.mobile-reg-success-footer {
  bottom: 0;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .gotoDepo-btn {
    background-color: var(--v-newMobileSecondary-base) !important;
    color: #000000;
    margin: 0 50px 15px 50px;
    text-transform: none;
  }
  .gotoHome-btn {
    background-color: var(--v-newMobilePrimary-base) !important;
    color: #ffffff;
    margin: 0 50px 5px 50px;
    text-transform: none;
  }
}
.mobile-reg-success {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  line-height: 1;
}
.v-application {
  .buttonPrimary.mobile-next-btn {
    background-color: var(--v-newMobilePrimary-base) !important;
    color: #ffffff;
  }
}
.mobile-header {
  background-color: #000000;
  color: var(--v-newMobilePrimary-base);
}
.otp-button {
  color: black;
  border-radius: 10px;
  width: 100%;
  font-size: 14px !important;
  padding: 9px !important;
  text-transform: capitalize;
}

.registerTnc-iframe {
  width: 100%;
  height: 500px;
}

.sms-confirm-button.col {
  padding-left: 1em;

  button {
    margin-top: 0 !important;
    height: 50px !important;
  }
}

.resgiter-dialog-pagination {
  background-color: #acacac;
  color: #ffffff !important;
}

.itemDiv {
  width: 50px;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .mobile-resgiter-dialog-pagination {
    background-color: #d9d9d9;
    z-index: 2;
    &.v-btn--active {
      .v-btn__content {
        color: #ffffff !important;
      }
      .v-icon {
        color: #ffffff !important;
      }
    }
    .v-btn__content {
      color: #ffffff;
    }
  }

  .mobile-resgiter-dialog-pagination-active {
    background-color: #333333;
    opacity: unset;
    &.v-btn {
      .v-btn__content {
        color: #ffffff !important;
      }
    }

    &::before {
      opacity: unset;
    }
  }
}

.lastItemDiv {
  &::before,
  &::after {
    width: 30px;
    color: red;
    right: 15px;
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background-color: #2c3e50;
  }

  &::before {
    left: -15px;
  }
  &::after {
    right: -6px;
    width: 23px !important;
  }
}

.resgiter-dialog-pagination-active {
  background-color: #fddd04;
}

.resgiter-dialog-pagination.theme--light.v-btn.v-btn--disabled {
  color: #ffffff !important;
}

@media (max-width: 959px) {
  .otp-button {
    //width: 102 !important;
    height: 35px !important;
    font-size: 13px !important;
    padding: 10px !important;
  }
}

@media (max-width: 599px) {
  .otp-button {
    //width: fit-content !important;
    height: 35px !important;
    font-size: 13px !important;
    padding: 11px !important;
  }
}

@media (max-width: 425px) {
  .otp-button {
    //width: fit-content !important;
    height: 35px !important;
    font-size: 13px !important;
    padding: 7px !important;
  }
}

@media (max-width: 375px) {
  .otp-button {
    //width: fit-content !important;
    height: 35px !important;
    font-size: 11px !important;
    padding: 4px !important;
  }
  .reg-mobile-prefix.v-input {
    font-size: 12px !important;

    .v-select__selection--comma {
      margin: 7px 0px 7px 0px !important;
    }

    .v-input__icon {
      height: 14px !important;
      width: 14px !important;
      min-width: 14px !important;
    }
  }
}
</style>
