import {rewardService} from '@/services'
import {SHARED_LOADING, SHARED_UNLOADING} from '@/store/shared.module'
import {SESSION, SHARED} from '@/constants/constants'
import router from '@/router'
import {ROUTE_NAME} from '@/constants/route.constants'

//define module name
const MODULE_NAME = 'reward/'

const A_GET_REWARD_WALLET_LOG = 'getRewardWallet'
const A_GET_CHECK_IN_DAY = 'getCheckInDay'
const A_POST_CHECK_IN_DAY = 'postCheckInDay'
const A_ACCEPT_CHALLENGE = 'acceptChallenge'
const A_GET_MEMBER_CHALLENGE = 'getChallengeByMember'
const A_GET_DAILY_CHECK_IN_PRIZE_LIST = 'getDailyCheckInPrizeList'
const A_RESET_POST_CHECK_IN_DAY = 'resetPostCheckInDay'
const A_CONFIGURATION_STATUS = 'getConfigurationStatus'

export const REWARD_GET_WALLET_LOG = MODULE_NAME + A_GET_REWARD_WALLET_LOG
export const REWARD_GET_CHECK_IN_DAY = MODULE_NAME + A_GET_CHECK_IN_DAY
export const REWARD_POST_CHECK_IN_DAY = MODULE_NAME + A_POST_CHECK_IN_DAY
export const REWARD_ACCEPT_CHALLENGE = MODULE_NAME + A_ACCEPT_CHALLENGE
export const REWARD_GET_MEMBER_CHALLENGE = MODULE_NAME + A_GET_MEMBER_CHALLENGE
export const REWARD_GET_DAILY_CHECK_IN_PRIZE_LIST = MODULE_NAME + A_GET_DAILY_CHECK_IN_PRIZE_LIST
export const REWARD_RESET_POST_CHECK_IN_DAY = MODULE_NAME + A_RESET_POST_CHECK_IN_DAY
export const REWARD_CONFIGURATION_STATUS = MODULE_NAME + A_CONFIGURATION_STATUS

const M_SET_REWARD_WALLET_LOG = 'setRewardWallet'
const M_SET_CHECK_IN_DAY = 'setCheckInDay'
const M_POST_CHECK_IN_DAY = 'postCheckInDay'
const M_SET_ACCEPT_CHAllENGE = 'acceptChallengeResponse'
const M_SET_MEMBER_CHAllENGE = 'setMemberChallenge'
const M_SET_DAILY_CHECK_IN_PRIZE_LIST = 'setDailyCheckInPrizeList'
const M_RESET_POST_CHECK_IN_DAY = 'resetPostCheckInDayResponse'
const M_CONFIGURATION_STATUS = 'setConfigurationStatus'

const state = {
    rewardWalletLog: {
        pagination: {},
        data: []
    },
    checkInDay: [],
    checkInDayResponse: {
        complete: false,
        success: false,
        data: '',
        code: 0
    },
    acceptChallengeResponse: {
        success: false,
        complete: false,
        code: 0
    },
    memberChallenge: {},
    dailyCheckInPrizeList: {},
    configurationStatus: {
        spin_wheel: {
            key: '',
            enabled: ''
        },
        daily_check_in: {
            key: '',
            enabled: ''
        },
        coin_grab: {
            key: '',
            enabled: ''
        }
    }
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
    [A_GET_REWARD_WALLET_LOG]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, { root: true })
        rewardService.getRewardWalletLog(obj).then(
            data => {
                let result = data
                commit(M_SET_REWARD_WALLET_LOG, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            },
            error => {
                let result = error
                commit(M_SET_REWARD_WALLET_LOG, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            }
    )},
    [A_GET_CHECK_IN_DAY]({dispatch, commit}) {
        dispatch(`${SHARED_LOADING}`, {}, { root: true })
        rewardService.getCheckInDay().then(
            data => {
                let result = data
                commit(M_SET_CHECK_IN_DAY, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            },
            error => {
                let result = error
                commit(M_SET_CHECK_IN_DAY, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            }
    )},
    [A_POST_CHECK_IN_DAY]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, { root: true })
        rewardService.postCheckInDay(obj).then(
            data => {
                let result = data
                commit(M_POST_CHECK_IN_DAY, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            },
            error => {
                let result = error
                commit(M_POST_CHECK_IN_DAY, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            }
    )},
    [A_GET_MEMBER_CHALLENGE]({dispatch, commit}) {
        dispatch(`${SHARED_LOADING}`, {}, { root: true })
        rewardService.getChallengeByMember().then(
            data => {
                let result = data
                commit(M_SET_MEMBER_CHAllENGE, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            },
            error => {
                let result = error
                commit(M_SET_MEMBER_CHAllENGE, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            }
    )},
    [A_ACCEPT_CHALLENGE]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, { root: true })
        rewardService.acceptMemberChallenge(obj).then(
            data => {
                let result = data
                commit(M_SET_ACCEPT_CHAllENGE, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            },
            error => {
                let result = error
                commit(M_SET_ACCEPT_CHAllENGE, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            }
    )},
    [A_GET_DAILY_CHECK_IN_PRIZE_LIST]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, { root: true })
        rewardService.getDailyCheckInRewardPrizeList(obj).then(
            data => {
                let result = data
                commit(M_SET_DAILY_CHECK_IN_PRIZE_LIST, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            },
            error => {
                let result = error
                commit(M_SET_DAILY_CHECK_IN_PRIZE_LIST, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
            }
    )},
    [A_RESET_POST_CHECK_IN_DAY]({commit}) {
        commit(M_RESET_POST_CHECK_IN_DAY)
    },
    async [A_CONFIGURATION_STATUS]({ dispatch, commit }, { filter }) {
        dispatch(`${SHARED_LOADING}`, {}, { root: true })
        await rewardService.getRewardConfiguration(filter).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
                commit(M_CONFIGURATION_STATUS, { result })
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
                commit(M_CONFIGURATION_STATUS, { result })
            }
        )
    }
}

//to update state values (called by actions)
const mutations = {
    [M_CONFIGURATION_STATUS](state, { result }) {
        if (result.success) {
            state.configurationStatus = result.data
        }
    },
    [M_SET_REWARD_WALLET_LOG](state, { result }) {
        if(result.success){
            state.rewardWalletLog.data = result.data
            state.rewardWalletLog.pagination = result.pagination
        }
    },
    [M_SET_CHECK_IN_DAY](state, { result }) {
        if(result.success){
            state.checkInDay = result.data
        }
    },
    [M_POST_CHECK_IN_DAY](state, { result }) {
        state.checkInDayResponse.complete = true
        state.checkInDayResponse.success = result.success
        state.checkInDayResponse.data = result.data
        state.checkInDayResponse.code = result.code
    },
    [M_RESET_POST_CHECK_IN_DAY](state) {
        state.checkInDayResponse.complete = false
        state.checkInDayResponse.success = false
        state.checkInDayResponse.data = false
        state.checkInDayResponse.code = false
    },
    [M_SET_ACCEPT_CHAllENGE](state, { result }) {
        state.acceptChallengeResponse.success = result.success
        state.acceptChallengeResponse.complete = result.complete
        state.acceptChallengeResponse.code = result.code
    },
    [M_SET_MEMBER_CHAllENGE](state, { result }) {
        if(result.success){
            state.memberChallenge = result.data
        }
    },
    [M_SET_DAILY_CHECK_IN_PRIZE_LIST](state, { result }) {
        if(result.success){
            state.dailyCheckInPrizeList = result.data
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
