<template>
  <v-row no-gutters>
    <v-col cols="12" class="px-8">
      <v-row no-gutters>
        <v-col cols="12" class="mt-5">
          <p>{{ $t('label.registerSuccessMessage') }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="mt-5">
          <v-item-group class="text-center" mandatory>
            <v-item v-for="n in 2" :key="`btn-${n}`" v-slot="{ active, toggle }">
              <v-btn color="white" class="ma-2 resgiter-dialog-pagination" x-small icon :input-value="active" @click="toggle">
                {{ n }}
              </v-btn>
            </v-item>
            <v-item>
              <v-icon dense class="ml-1 resgiter-dialog-pagination-active">done</v-icon>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-6">
        <v-col cols="12">
          <v-divider class="dialog-divider"></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <p class="caption pt-2">
            <label>{{ $t(`label.registerSuccessNote`) }}</label>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <app-button class="dialog-button" :title="$t(`label.depositNow`)" :action="this.goToDepositPage"></app-button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper } from '@/util'
import { SESSION } from '@/constants/constants'

export default {
  name: 'registerSuccess',
  props: {
    closeRegisterSuccessDialog: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  created() {
    if (uiHelper.getCookie(SESSION.UTM_S2) != null) {
      this.firePixel()
    }
    if (uiHelper.getCookie(SESSION.GN_CLICKID) != null) {
      this.fireGnPixel()
    }
  },
  methods: {
    fireGnPixel() {
      try {
        let url = 'https://grindnetwork.go2cloud.org/aff_goal?a=lsr&goal_name=reg_finished&adv_id=507&transaction_id=' + uiHelper.getCookie(SESSION.GN_CLICKID)
        $.get(url, function(data) {
          console.log(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    firePixel() {
      try {
        let url = 'https://egqqk.rdtk.io/postback?clickid=' + uiHelper.getCookie(SESSION.UTM_S2) + '&sum=1'
        $.get(url, function(data) {
          console.log(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    goToDepositPage() {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT
      })
      this.closeRegisterSuccessDialog()
    }
  }
}
</script>

<style lang="scss">
.resgiter-dialog-pagination {
  background-color: #ACACAC;
  color: #ffffff !important;

}

.resgiter-dialog-pagination-active {
  background-color: #FDDD04;
  border-radius: 30px
}

.resgiter-dialog-pagination.theme--light.v-btn.v-btn--disabled {
  color: #ffffff !important;

}
</style>
