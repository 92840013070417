import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale } from '@/util'

Vue.use(VueRouter)
Vue.use(VueMeta)
const homePage = { name: ROUTE_NAME.HOME }

const loginGuard = function(to, from, next) {
  if (uiHelper.isAuthenticated()) {
    //next()
    setRegionLangGuard(to, from, next)
  } else {
    next(homePage)
  }
}

const setRegionLangGuard = function(to, from, next) {
  let fromRegionLang = from.params && from.params.lang ? from.params.lang : ''
  let toRegionLang = to.params && to.params.lang ? to.params.lang : ''

  //check if regionLang path provided , if yes then set i18n locale , cause component's onCreated(inject meta's translation) run first before App.vue set the language
  let path = window.location.pathname
  let reg = /(en|bn)-[a-zA-Z]{2}/g
  if (reg.test(path)) {
    reg.lastIndex = 0
    let arrReg = reg.exec(path)
    if (arrReg && arrReg.length > 0) {
      let language = arrReg[0].substring(0, 2)
      switch (language) {
        case 'bn':
          language = 'bd'
          break
      }
      if (locale.getCurrentLanguage() !== language) {
        locale.setCurrentLanguage(language)
      }
    }
  }

  if (toRegionLang === '') {
    if (fromRegionLang === '') {
      //prev route got no region lang param/path ,eg : {hostname}/cricket
      next()
    } else {
      //prev route got regionLang param/path but in code no specific any > then assign regionLang according to prev route , eg : prev route : {hostname}/bn-BD/home , destination : {hostname}/cricket
      // fromRegionLang = 'bn-BD'
      next({ name: to.name, params: { ...to.params, lang: fromRegionLang } })
    }
  } else {
    //destination route hv specified regionLang param/path
    next()
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/home/', redirect: '/', pathToRegexpOptions: { strict: true } },
    {
      path: '/sc',
      name: ROUTE_NAME.SHORTCUT,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/sc/shortcut.vue')
    },
    {
      path: '/reset-password',
      name: ROUTE_NAME.RESET_PASSWORD,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/resetPassword.vue')
    },
    {
      path: '/contact-verification',
      name: ROUTE_NAME.CONTACT_VERIFICATION,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/contactVerification.vue')
    },
    { path: '/register/', redirect: { name: ROUTE_NAME.REGISTER }, pathToRegexpOptions: { strict: true } },
    {
      path: '/:lang?/register',
      name: ROUTE_NAME.REGISTER,
      meta: { layout: 'layout-master' },
      props: { registerErrorMsg: '', openLoginDialog: () => {}, isMobileRegistration: true },
      component: () => import('@/components/member/register.vue')
    },
    {
      path: '/:lang?/login',
      name: ROUTE_NAME.LOGIN,
      meta: { layout: 'layout-master' },
      props: { loginErrorMsg: '', openRegisterDialog: () => {}, openLanguageDialog: () => {}, closeAction: () => {}, openForgotPasswordDialog: () => {}, isMobileLogin: true },
      component: () => import('@/components/member/login.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/register-mobile',
      name: ROUTE_NAME.REGISTER_MOBILE,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/registerMobile.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/affiliate/referral',
      name: ROUTE_NAME.AFFILIATE_REFFERAL,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliate/referral.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/affiliate/news',
      name: ROUTE_NAME.AFF_NEWS,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliate/promoNews.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/sportsbook',
      component: () => import('@/views/sportsbook/sportsbookLayout.vue'),
      children: [
        {
          path: '/:lang?/sportsbook',
          name: ROUTE_NAME.SPORTSBOOK,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'sb' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: ':provider',
          name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'sb' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/fishing',
      component: () => import('@/views/fishing/fishingLayout.vue'),
      children: [
        {
          path: '/:lang?/fishing',
          name: ROUTE_NAME.FISHING,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'fishing' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: ':provider',
          name: ROUTE_NAME.FISHING_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'fishing' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/crash',
      component: () => import('@/views/crash/crashLayout.vue'),
      children: [
        {
          path: '/:lang?/crash',
          name: ROUTE_NAME.CRASH,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'crash' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: ':provider',
          name: ROUTE_NAME.CRASH_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'crash' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/special',
      component: () => import('@/views/special/specialLayout.vue'),
      children: [
        {
          path: '/:lang?/special',
          name: ROUTE_NAME.SPECIAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/special/special.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/special/special.vue')
        }
      ]
    },
    {
      path: '/:lang?/casino',
      component: () => import('@/views/casino/casinoLayout.vue'),
      // beforeEnter: (to, from, next) => {
      //   debugger
      //   setRegionLangGuard(to, from, next)
      // },
      children: [
        {
          path: '/:lang?/casino',
          name: ROUTE_NAME.CASINO,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'ld' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: ':provider',
          name: ROUTE_NAME.CASINO_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'ld' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/slot',
      component: () => import('@/views/slot/slotLayout.vue'),
      children: [
        {
          path: '/:lang?/slot',
          name: ROUTE_NAME.SLOT,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'rng' },
          component: () => import('@/views/slot/slot.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: ':provider',
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'rng' },
          component: () => import('@/views/slot/slot.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: ':provider/:gamename',
          name: ROUTE_NAME.SLOT_BY_PROVIDER_AND_GAMENAME,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'rng' },
          component: () => import('@/views/slot/slot.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'game/:gamename',
          name: ROUTE_NAME.SLOT_BY_GAMENAME,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'rng' },
          component: () => import('@/views/slot/slot.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/tablegames',
      component: () => import('@/views/table/tableLayout.vue'),
      children: [
        {
          path: '/:lang?/tablegames',
          name: ROUTE_NAME.TABLE_GAMES,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'table' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: ':provider',
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'table' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/lottery',
      component: () => import('@/views/lottery/lotteryLayout.vue'),
      children: [
        {
          path: '/:lang?/lottery',
          name: ROUTE_NAME.LOTTERY,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'lottery' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: ':provider',
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'lottery' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/cricket',
      component: () => import('@/views/cricket/cricketLayout.vue'),
      children: [
        {
          path: '/:lang?/cricket',
          name: ROUTE_NAME.CRICKET,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'cricket' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: '/:lang?/cricket/:provider',
          name: ROUTE_NAME.CRICKET_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'cricket' },
          component: () => import('@/views/game/game.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/playgame/:provider/:type',
      name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
      meta: { layout: 'blank-layout-master' },
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      component: () => import('@/views/game/play.vue')
    },
    {
      path: '/:lang?/deposit_vendor',
      name: ROUTE_NAME.VENDOR_POPUP,
      meta: { layout: 'blank-layout-master' },
      component: () => import('@/views/bank/vendorPopUp.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/bank',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/bank/bankLayout.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      children: [
        {
          path: 'deposit',
          name: ROUTE_NAME.DEPOSIT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/deposit.vue')
        },
        {
          path: 'deposit-success',
          name: ROUTE_NAME.DEPOSIT_SUCCESS,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/depositSuccess.vue')
        },
        {
          path: 'withdrawal',
          name: ROUTE_NAME.WITHDRAWAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/withdrawal.vue')
        },
        {
          path: 'transaction',
          name: ROUTE_NAME.TRANSACTION,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/transaction.vue')
        },
        {
          path: 'voucher',
          name: ROUTE_NAME.CLAIM_VOUCHER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/claimVoucher.vue')
        },
        {
          path: 'transfer',
          name: ROUTE_NAME.TRANSFER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/transfer.vue')
        },
        {
          path: 'vip',
          name: ROUTE_NAME.VIP_PROFILE,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/vipPage.vue')
        },
        {
          path: 'affiliate/bonus',
          name: ROUTE_NAME.AFFILIATE_REFERRAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/affiliateBonus.vue')
        },
        {
          path: 'transaction-history',
          name: ROUTE_NAME.TRANSACTION_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/transactionHistory.vue')
        },
        {
          path: 'turnover-history',
          name: ROUTE_NAME.TURNOVER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/turnoverHistory.vue')
        },
        {
          path: 'player-history',
          name: ROUTE_NAME.PLAYER_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/history/historyOverview.vue')
        }
      ]
    },
    {
      path: '/:lang?/payment/process',
      name: ROUTE_NAME.PROCESS_TRANSACTION,
      meta: { layout: 'blank-layout-master' },
      component: () => import('@/views/bank/process.vue')
    },
    {
      path: '/:lang?/payment/pay-secure',
      name: ROUTE_NAME.PAY_SECURE,
      meta: { layout: 'blank-layout-master' },
      component: () => import('@/views/bank/paysecure.vue')
    },
    {
      path: '/:lang?/profile/menu',
      name: ROUTE_NAME.PROFILE_MENU,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/menu.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/profile/logout',
      name: ROUTE_NAME.PROFILE_LOGOUT,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/logout.vue')
    },
    {
      path: '/:lang?/profileAccount',
      name: ROUTE_NAME.MOBILE_ACCOUNT,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/mobileAccount'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/profileHistory',
      name: ROUTE_NAME.MOBLE_HISOTRY,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/mobileHistoryLayout'),
      children: [
        {
          //wallet history
          path: 'transaction-history',
          name: ROUTE_NAME.MOBILE_TRANSACTION_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/transactionHistory.vue')
        },
        {
          //turnover
          path: 'turnover-history',
          name: ROUTE_NAME.MOBILE_TO_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/turnoverHistory.vue')
        },
        {
          //player-history
          path: 'betting-history',
          name: ROUTE_NAME.MOBILE_BETTING_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/history/historyOverview.vue')
        },
        {
          path: 'promo-history',
          name: ROUTE_NAME.MOBILE_PROMO_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/promotion/history.vue')
        }
      ],
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/profile',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/profileLayout.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      children: [
        {
          path: 'account',
          name: ROUTE_NAME.PROFILE,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/account.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'personal',
          name: ROUTE_NAME.PERSONAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/personal.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'message',
          name: ROUTE_NAME.ANNOUNCEMENT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/announcement.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'change-password',
          name: ROUTE_NAME.CHANGE_PASSWORD,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/changePassword.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'bank-account',
          name: ROUTE_NAME.BANK_ACCOUNT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/bankAccount.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'inbox',
          name: ROUTE_NAME.INBOX,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/inbox.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/profileRewards',
      name: ROUTE_NAME.REWARDS,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/rewards.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/rewards',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/rewardLayout.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      },
      children: [
        {
          path: ':type',
          name: ROUTE_NAME.REWARDS_BY_TYPE,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/rewards/rewards.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/promotion',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/promotion/promotionLayout.vue'),
      children: [
        {
          path: '/:lang?/promotion',
          name: ROUTE_NAME.PROMOTION,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/promotion/promotion.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'in-progress',
          name: ROUTE_NAME.PROMOTION_IN_PROGRESS,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/promotion/inprogress.vue'),
          beforeEnter: (to, from, next) => {
            loginGuard(to, from, next)
          }
        },
        {
          path: 'history',
          name: ROUTE_NAME.PROMOTION_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/promotion/history.vue'),
          beforeEnter: (to, from, next) => {
            loginGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/info',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/info/infoLayout.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      },
      children: [
        {
          path: 'faq',
          name: ROUTE_NAME.FAQ,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/info/faq.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'about-us',
          name: ROUTE_NAME.ABOUT_US,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/info/aboutUs.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        },
        {
          path: 'contact-us',
          name: ROUTE_NAME.CONTACT_US,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/info/contactUs.vue'),
          beforeEnter: (to, from, next) => {
            setRegionLangGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/affiliate/home',
      name: ROUTE_NAME.AFFILIATE_HOME,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliate/home.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/affiliate',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliate/affiliateLayout.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      children: [
        {
          path: 'members',
          name: ROUTE_NAME.AFFILIATE_MEMBER_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/memberReport.vue')
        },
        {
          path: 'members-detail',
          name: ROUTE_NAME.AFFILIATE_MEMBER_DETAIL_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/memberDetailReport.vue')
        },
        {
          path: 'games-report',
          name: ROUTE_NAME.AFFILIATE_GAME_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/gameReport.vue')
        },
        {
          path: 'agent-report',
          name: ROUTE_NAME.AFFILIATE_AGENT_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/agentReport.vue')
        },
        {
          path: 'link',
          name: ROUTE_NAME.AFFILIATE_LINK,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/link.vue')
        }
      ]
    },
    {
      path: '/:lang?/affiliate/bank',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliatePayment/bankLayout.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      children: [
        {
          path: 'withdrawal',
          name: ROUTE_NAME.AFFILIATE_WITHDRAWAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliatePayment/withdrawal.vue')
        },
        {
          path: 'transaction',
          name: ROUTE_NAME.AFFILIATE_TRANSACTION,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliatePayment/transaction.vue')
        },
        {
          path: 'account',
          name: ROUTE_NAME.AFFILIATE_BANK_ACCOUNT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliatePayment/account.vue')
        }
      ]
    },
    {
      path: '/:lang?/luckywheel',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/WheelOfFortune.vue'),
      name: ROUTE_NAME.WOF,
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/mluckywheel',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/MobileWheelOfFortune.vue'),
      name: ROUTE_NAME.MWOF,
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/grab-coin',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/GrabCoin.vue'),
      name: ROUTE_NAME.GRAB_COIN,
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/tournament',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/Tournament.vue'),
      name: ROUTE_NAME.TOURNAMENT,
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/referral',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/referral/referralLayout.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      },
      children: [
        {
          path: 'tier',
          name: ROUTE_NAME.REFERRAL_TIER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/referralTier.vue')
        },
        {
          path: 'summary',
          name: ROUTE_NAME.REFERRAL_SUMMARY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/referralSummary.vue'),
          children: [
            {
              path: 'report',
              name: ROUTE_NAME.REFERRAL_SUMMARY_REPORT,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/referral/referralReport.vue')
            },
            {
              path: 'history',
              name: ROUTE_NAME.REFERRAL_SUMMARY_HISTORY,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/referral/referralHistory.vue')
            }
          ]
        },
        {
          path: 'report',
          name: ROUTE_NAME.REFERRAL_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/referralReport.vue')
        },
        {
          path: 'history',
          name: ROUTE_NAME.REFERRAL_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/referralHistory.vue')
        }
      ]
    },
    {
      path: '/:lang?/vip',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/vip/vipLayout.vue'),
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      },
      children: [
        {
          path: 'vipProfile',
          name: ROUTE_NAME.VIP,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/vip/vip.vue')
        }
      ]
    },
    {
      path: '/:lang?/viptiers',
      meta: { layout: 'layout-master' },
      // name: ROUTE_NAME.VIPTIER,
      component: require('@/views/viptiers/viptier.vue').default,
      // beforeEnter: (to, from, next) => {
      //   const { params } = to;
      //
      //     // Proceed to the route
      //     next();
      //   } else {
      //     // Redirect to the /register path
      //     next('/vip-tier');
      //   }
      // },
      children: [
        {
          path: 'vip-tier',
          name: ROUTE_NAME.VIPTIER,
          meta: { layout: 'layout-master' },
          component: require('@/views/viptiers/viptier.vue').default
        }
      ]
    },
    {
      path: '/iplVip',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/iplVip/iplVipLayout.vue'),
      children: [
        {
          path: 'iplVipProfile',
          name: ROUTE_NAME.IPLVIP,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/iplVip/iplVip.vue')
        }
      ]
    },
    {
      path: '/:lang?/error',
      component: () => import('@/views/error/errorLayout.vue'),
      children: [
        {
          path: 'ip-restriction',
          name: ROUTE_NAME.ERROR_403,
          meta: { layout: 'blank-layout-master' },
          component: () => import('@/views/error/403.vue')
        },
        {
          path: 'page-not-found',
          name: ROUTE_NAME.ERROR_404,
          meta: { layout: 'blank-layout-master' },
          component: () => import('@/views/error/404.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: { name: ROUTE_NAME.HOME }
    },
    {
      path: '/home',
      redirect: '/',
      pathToRegexpOptions: { strict: true },
      beforeEnter: (to, from, next) => {
        next()
        // testGuard(to, from, next)
      }
    },
    {
      path: ['/:lang?'],
      alias: '/:lang/home',
      name: ROUTE_NAME.HOME,
      meta: {
        layout: 'layout-master'
      },
      component: require('@/views/home.vue').default,
      pathToRegexpOptions: { strict: true },
      beforeEnter: (to, from, next) => {
        // console.log('home.vue beforeEnter , i18n locale lange : ' + locale.getCurrentLanguage())
        setRegionLangGuard(to, from, next)
        // testGuard(to, from, next)
      }
    },
    {
      path: '/',
      name: ROUTE_NAME.HOME,
      meta: { layout: 'layout-master' },
      component: require('@/views/home.vue').default,
      beforeEnter: (to, from, next) => {
        setRegionLangGuard(to, from, next)
      }
    },
    {
      path: '*',
      redirect: { name: ROUTE_NAME.ERROR_404 }
    }
  ],
  /* TO AUTO BACK TO TOP */
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
