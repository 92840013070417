<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import {uiHelper, locale} from '@/util'
import {SHARED, CACHE_KEY} from '@/constants/constants'
import {GAME_PROVIDERS_TYPE_CONFIG} from '@/store/game.module'

export default {
  name: 'App',
  // metaInfo: {
  //   title: `${locale.getMessage('meta.default_title')}`,
  //   titleTemplate: `%s | ${uiHelper.getHost()}`,
  //   meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.default_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.default_keyword')}` }]
  // },
  computed: {
    layout() {
      return this.$route.meta.layout || SHARED.BLANK_LAYOUT
    }
  },
  data: () => ({
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`)
  }),
  async created() {
    this.browserDetec()
    await this.getGameProviderTypeInfo()
    this.initializePageLanguage()
  },
  methods: {
    browserDetec() {
      let browser_detail = navigator.userAgent
      if (
          browser_detail.indexOf('Opera') > -1 ||
          browser_detail.indexOf('OPR') > -1 ||
          browser_detail.indexOf('MSIE') > -1 ||
          browser_detail.indexOf('MZBrowser') > -1 ||
          browser_detail.indexOf('UBrowser') > -1 ||
          browser_detail.indexOf('.NET') > -1
      ) {
        window.location.href = '/static/html/error/browser-not-support.html'
      }
    },
    async getGameProviderTypeInfo() {
      if(!this.storageGameProviderType){
        let providerTypeConfigObj = {
          currency: uiHelper.getCurrency(),
          platform: uiHelper.getPlatform()
        }
        await this.$store.dispatch(`${GAME_PROVIDERS_TYPE_CONFIG}`, {providerTypeConfigObj})
      }
    },
    initializePageLanguage() {
      let language = uiHelper.getCookie('language')
      let path = window.location.pathname
      let reg = /(en|bn)-[a-zA-Z]{2}/g

      if (reg.test(path)) {
        reg.lastIndex = 0;
        //assign regionLocale cookie
        //assign langguage cookie
        //setCurrentLanguage
        let arrReg = reg.exec(path) //en-BD

        if (arrReg.length > 0) {
          let regionLocale = arrReg[0]

          language = regionLocale.substring(0, 2) //en

          //switch bn into current system language (bd)
          switch (language) {
            case 'bn':
              language = 'bd'
              break
          }


          locale.setCurrentLanguage(language)
          uiHelper.setCookie('regionLocale', regionLocale)
          uiHelper.setCookie('language', language)
        }

      } else {
        //en //bd
        locale.setCurrentLanguage(language)
        uiHelper.setCookie('language', language)
      }

      // if (language == null) {
      //   language = SHARED.DEFAULT_LANGUAGE
      // }
      // locale.setCurrentLanguage(language)
      // uiHelper.setCookie('language', language)
    }
  }
}
</script>

<style lang="scss">
// html {
//   scroll-behavior: smooth;
//   overflow-y: auto !important;
// }

* {
  word-break: normal;
}

.page {
  background-color: #2e2e2e;
}

.full-width {
  width: 100%;
}

.full-height,
html,
body,
#app,
.page {
  overflow: auto;
}

.dialog-card {
  overflow-x: hidden;
}

/* Pagination Css */
.v-pagination {
  margin: 15px 0px;

  li {
    button {
      margin: 0px;
      border-radius: 0px !important;
      box-shadow: none !important;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-color: var(--v-base_2-base);
      min-height: 40px;
      min-width: 40px !important;
      font-size: 16px;
    }

    &:last-child {
      button {
        border-right: 1px solid;
        border-color: var(--v-base_2-base);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    &:first-child {
      button {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }
  }
}

/* Table CSS */
// .v-data-table__wrapper {
//   table {
//     .v-data-table-header-mobile {
//       display: none;
//     }
//     .v-data-table-header {
//       tr {
//         background-color: var(--v-base_3-base);
//         th {
//           span {
//             color: var(--v-base-base);
//           }
//         }
//       }
//     }
//     tbody {
//       tr:nth-child(2n) {
//         background-color: #2d3454 !important;
//       }
//     }
//     td,
//     th {
//       border-bottom: 0px !important;
//     }
//   }
// }

/* Pagination Css */
.v-pagination {
  margin: 15px 0px;

  li {
    button {
      margin: 0px;
      border-radius: 0px !important;
      box-shadow: none !important;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-color: var(--v-base_2-base);
      min-height: 40px;
      min-width: 40px !important;
      font-size: 16px;
    }

    &:last-child {
      button {
        border-right: 1px solid;
        border-color: var(--v-base_2-base);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    &:first-child {
      button {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }
  }
}
</style>
